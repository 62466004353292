import { USER_TYPE } from 'helpers'
import { EstimateService } from 'ducks/types'

/** TYPES**/
export const configTypes = {
  SET_CONFIG_VALUE: 'SET_CONFIG_VALUE',
  SWITCH_USER_TYPE: 'SWITCH_USER_TYPE',
  SET_ERROR: 'SET_ERROR'
}

/** ACTIONS**/
export interface ConfigValue {
  type: string
  value: any
}

export interface ConfigSetValueAction {
  type: typeof configTypes.SET_CONFIG_VALUE
  payload: ConfigValue
}

export interface ErrorValue {
  show: boolean
  type: string
}

export interface SwitchUserTypeAction {
  type: typeof configTypes.SWITCH_USER_TYPE
  payload: null
}

export interface DateRange {
  startDate?: Date
  endDate?: Date
}

export interface Pagination {
  start: number
  limit: number
  total: number
}

export interface Stat {
  status: 'In Progress' | 'Expired' | 'Completed' | 'Scheduling' | 'Needs Attention' | 'Dispatched' | 'Hold' | 'Approved'
  count: number
}

export interface SetErrorAction {
  type: typeof configTypes.SET_ERROR
  payload: ErrorValue
}

/** CONFIG**/
export interface ConfigType {
  publicLoading: boolean
  userType: USER_TYPE
  paymentsOption: string | null
  dashboardDateRange: DateRange | null
  estimateDateRange: DateRange | null
  estimateStatus: string | null
  estimatePagination: Pagination | null
  estimateStats: Stat[] | null
  jobDateRange: DateRange | null
  jobStatus: string | null
  jobPagination: Pagination | null
  jobStats: Stat[]
  overlayLoading: boolean
  paymentsYear: number | null
  paymentsFilter: string | null
  estimatesServices: EstimateService[]
  error: ErrorValue
  isAdmin: boolean | null
  redirectAfterLogin: string | null
  redirectPath: string | null
  registerActiveStep: number | null
  showReferralModal: boolean
  registrationLoading: boolean
  registerImg: string
}

export type ConfigActionTypes =
  | ConfigSetValueAction
  | SwitchUserTypeAction
  | SetErrorAction
