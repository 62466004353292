
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { obAgent, obHomeowner, obInstitutionalClient } from '../../../../assets'
import { configActions, userActions } from '../../../../ducks/actions'
import { getUser, getNewEstimateValue, getConfigValue } from '../../../../ducks/selectors'
import { UserType } from '../../../../ducks/user/types'
import { USER_TYPE, history, isEmpty } from '../../../../helpers'
import { useIsXsScreen } from '../../../../hooks'
import { RegisterLoader } from '../../../templates'
import { Box, Stepper, Grid, SvgIcon, Icon } from '../../../UI'
import { StepperProps } from '../../../UI/CustomUI/organisms/Stepper/types'
import { Company, Location, ProfessionalType } from './Steps'
import useStyles from './styles'
import Role from './Steps/Role'
import HouseSvg from './../../../../assets/icons/custom/house.svg'

const RegisterLogged: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const replace = history.useReplace()
  const [, setRegisterLoading] = useState(false)
  const registerLoading = useSelector(getConfigValue('registrationLoading'))
  const registerImg = useSelector(getConfigValue('registerImg'))

  // const [registerImg, setRegisterImg] = useState(obInstitutionalClient)
  const user = useSelector(getUser)
  const [xsScreen] = useIsXsScreen()
  // const registerActiveStep = useSelector(getConfigValue('registerActiveStep')) || 0

  const [activeStep, setActiveStep] = useState(0)

  const bgColor = () => {
    let color = ''
    switch (user.clientType) {
      case USER_TYPE.HOMEOWNER:
        color = 'var(--head-text-color)'
        break
      case USER_TYPE.BROKER:
        color = 'var(--head-text-color)'
        break
      default:
        color = 'var(--head-text-color)'
        break
    }

    return color
  }

  const estimate = useSelector(getNewEstimateValue('estimate'))
  const redirectPath = useSelector(getConfigValue('redirectPath'))
  const contacts = estimate.properties?.contacts ? estimate.properties.contacts : []

  const steps: StepperProps['steps'] = [
    {
      content: <Role />,
      disableBack: true,
      nextText: 'Next',
      actionNext: () => {
        dispatch(
          userActions.updateUser(
            {
              clientType: user.clientType,
            },
            (succ) => {
              if (succ) {
                setActiveStep(activeStep + 1)
              }
              // dispatch(configActions.setConfigValue({ type: 'registerActiveStep', value: registerActiveStep + 1 }))
              // setActiveStep(activeStep + 1)
            }
          )
        )
      }
    },
  ]

  if (user.clientType === USER_TYPE.HOMEOWNER) {
    steps.push({
      content: <Location />,
      disableBack: true,
      /* nextText: 'Next',
      actionNext: () => {
        dispatch(configActions.setConfigValue({ type: 'registrationLoading', value: true }))

        const request: Partial<UserType> = {
          address: user?.address,
          registrationComplete: true,
          phone: user.phone || parseInt(contacts[0]?.phone.replaceAll('-', '')),
        }
        dispatch(configActions.setConfigValue({ type: 'showReferralModal', value: true }))

        if (!isEmpty(user?.companyName)) request.companyName = user?.companyName?.toLowerCase()
        dispatch(userActions.updateUser(request, () => {
          updateRegisterImg(user.clientType)
          dispatch(configActions.setConfigValue({ type: 'registrationLoading', value: false }))
          if (redirectPath && redirectPath !== "/p/docusignSuccess") {
            replace(redirectPath)
          } else {
            replace('/p/dashboard')
          }
        }))
      } */
    })
  } else {
    const isInstitutional = user?.clientType === USER_TYPE.INSTITUTIONAL

    steps.push(
      {
        content: <ProfessionalType isInstitutional={isInstitutional} />,
        nextText: 'Next',
        actionNext: () => {
          let request: Partial<UserType> = {}
          if (isInstitutional) {
            request.institutionalRole = user.institutionalRole
          } else {
            request.clientType = user.clientType
          }
          dispatch(userActions.updateUser(request, (succ) => {
            setRegisterLoading(false)
            dispatch(configActions.setConfigValue({ type: 'registrationLoading', value: false }))

            if (succ) {
              setActiveStep(activeStep + 1)

              // dispatch(configActions.setConfigValue({ type: 'registerActiveStep', value: registerActiveStep + 1 }))
            }
          }))
        }
      },
      {
        content: <Company />,
        nextText: 'Next',
        actionNext: () => {
          const request: Partial<UserType> = {
            companyName: user.companyName,
            phone: user.phone || parseInt(contacts[0]?.phone.replaceAll('-', '')),
            nar: user.nar || false,
            address: user.address,
            registrationComplete: true,
          }
          dispatch(userActions.updateUser(request, (succ) => {
            setRegisterLoading(false)
            dispatch(configActions.setConfigValue({ type: 'registrationLoading', value: false }))

            if (redirectPath && redirectPath !== "/p/docusignSuccess") {
              replace(redirectPath)
            } else {
              replace('/p/dashboard')
              if (user.clientType !== USER_TYPE.INSTITUTIONAL) {
                dispatch(configActions.setConfigValue({ type: 'showReferralModal', value: true }))
              }
            }
          }))
        }
      }
    )
  }

  const updateNewUser = (attr: keyof UserType, value: any) => {
    dispatch(userActions.setUserValue({ attr, value }))
  }

/*   const updateRegisterImg = (value: USER_TYPE) => {
    switch (value) {
      case USER_TYPE.BROKER:
        setRegisterImg(obAgent)
        break
      case USER_TYPE.HOMEOWNER:
        setRegisterImg(obHomeowner)
        break
      case USER_TYPE.INSTITUTIONAL:
        setRegisterImg(obInstitutionalClient)
        break
      default:
        break
    }
  } */

  const fetchCurrentUser = useCallback(
    () => {
      dispatch(userActions.fetchCurrentUser(() => { }))
    }, [dispatch]
  )

  useEffect(() => {
    fetchCurrentUser()
  }, [fetchCurrentUser])

  return (
    <RegisterLoader loading={registerLoading} icon={registerImg || obHomeowner}>
      <Grid className={classes.container} style={{ backgroundColor: !xsScreen ? bgColor() : 'white' }}>
        <img src={HouseSvg} alt="texture" className={classes.textureBottom} />
        <Grid item className={classes.center}>
          <Box className={classes.root}>
            <Box className={classes.content}>
              <Stepper
                withLogo
                steps={steps}
                activeStep={activeStep}
                setActiveStepAction={(step: number) => setActiveStep(step)}
                saveNewValueAction={(value: { attr: keyof UserType, value: any }) => updateNewUser(value.attr, value.value)}
                fixButtons
                showHeader={false}
                className={classes.stepper}
                center={false}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RegisterLoader>
  )
}

export default RegisterLogged
