import feedback from './feedback'
import estimates from './estimates'
import inspections from './inspections'
import files from './files'
import jobs from './jobs'
import orders from './orders'
import payments from './payments'
import properties from './properties'
import territories from './territories'
import users from './users'
import invoice from './invoice'
import subscriptions from './subscriptions'
import employees from './employees'
import promos from './promos'
import discovery from './discovery'

const punchlist = {
  feedback,
  estimates,
  inspections,
  employees,
  files,
  jobs,
  orders,
  payments,
  properties,
  territories,
  users,
  invoice,
  subscriptions,
  promos,
  discovery
}

export default punchlist
