import { useEffect, FC, useState } from 'react'

import { Document, Page, pdfjs } from 'react-pdf'
import { Card, Box, IconButton, Dialog, Button, CircularProgress } from '../../..'
import { icons, photoDefault } from '../../../../../assets'
import useStyles from './styles'
import { DocumentPreviewProps } from './types'
import { applicationMimeTypes, videoMimeTypes } from '../../../../../helpers/files'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const DocumentPreview: FC<DocumentPreviewProps> = ({
  file, fileURL, actions, expandable = true, previewActions, dialogActions, previewHeight
}) => {
  const classes = useStyles()

  const tmppath = file ? URL.createObjectURL(file) : fileURL
  const fileExtension = tmppath ? tmppath.slice((tmppath.lastIndexOf('.') - 2 >>> 0) + 2) : ''

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [attempts, setAttempts] = useState(5)
  const [error, setError] = useState(false)
  const [filePath, setFilePath] = useState(tmppath)

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleError = () => {
    if (attempts > 0) {
      setAttempts(attempts - 1)
      setLoading(true)
      setTimeout(() => {
        setFilePath(tmppath)
        setLoading(false)
      }, 1000)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const getPreviewContent = () => {
    if (loading) return <CircularProgress color='primary' size={44} />
    if (error) return <img alt='' src={photoDefault} className={classes.previewDefault} />
    if (videoMimeTypes.includes(fileExtension)) {
      return (
        <video className={classes.preview} preload='metadata'>
          <source src={tmppath} />
        </video>
      )
    }
    if (applicationMimeTypes.includes(fileExtension)) {
      return (
        <Document onLoadError={() => alert('Error loading pdf')} file={file}>
          <Page width={140} pageNumber={1} />
        </Document>
      )
    }
    return (<img alt='' src={filePath} onError={handleError} className={classes.preview} />)
  }

  const getContent = () => {
    if (videoMimeTypes.includes(fileExtension)) {
      return (
        <video controls className={classes.video}>
          <source src={tmppath} />
        </video>
      )
    }
    if (applicationMimeTypes.includes(fileExtension)) {
      return (
        <Document onLoadError={() => alert('Error loading pdf')} file={file}>
          <Page width={250} pageNumber={1} />
        </Document>
      )
    }
    return (<img alt='' src={error ? photoDefault : filePath} className={classes.preview} />)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            width: '100%'
          }
        }}
      > {getContent()}
        <Box className={classes.actions}>
          {actions && dialogActions && (
            dialogActions.map((enable, index) => {
              if (enable) {
                return actions[index]
              }
              return null
            })
          )}
          <Button
            fullWidth
            size='small'
            color='inherit'
            variant='text'
            onClick={() => { setOpen(false) }}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Card className={classes.card}>
        {expandable && !loading && (
          <IconButton
            className={classes.full}
            component='span'
            onClick={() => setOpen(true)}
          >
            <icons.Fullscreen />
          </IconButton>)}
        <Box
          onClick={() => { if (expandable && !loading) setOpen(true) }}
          style={{
            height: `${previewHeight ?? ''}`, // `${actions && previewActions ? '100px' : ''}`,
            cursor: expandable && !loading ? 'pointer' : 'default'
          }}
          className={classes.imageBox}
        >
          {getPreviewContent()}
        </Box>
        {actions && previewActions && (
          <Box className={classes.actions}>
            {previewActions.map((enable, index) => {
              if (enable) {
                return actions[index]
              }
              return null
            })}
          </Box>
        )}
      </Card>
    </>
  )
}

export default DocumentPreview
