import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content auto',
      // gridAutoRows: 'min-content min-content auto',
      height: '100%',
      paddingTop: '24px'
    },
    info: {
      margin: "auto"
    },
    table: {
      paddingRight: '24px',
      [theme.breakpoints.down('md')]: {
        padding: "0 12px"
      },
    },
    tableComponent: {
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.grey[200],
      borderRadius: 12
    },
    button: {
      width: '80%',
      height: '32px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      }
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '16px'
    },
    description: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '12px'
    },
    row: {
      '&:hover': {
        backgroundColor: 'var(--row-focus)'
      }
    },
    cellHead: {
      backgroundColor: 'var(--background-color-secondary)',
      color: 'var(--head-text-color)',
      borderWidth: 0,
      fontFamily: 'Lato',
      fontSize: '16px'
    },
    cell: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 14,
      borderWidth: 0
    },
    icon: {
      fontSize: 16,
      marginRight: '10px',
      marginBottom: '3px'
    },
    active: {
      fontFamily: 'NextArtHeavy',
      fontWeight: 900,
      fontSize: '20px',
      lineHeight: '150%',
      [theme.breakpoints.down('xs')]: {
        lineHeight: 1,
        marginTop: '1rem'
      }
    },
    content: {
      marginTop: '32px',
      display: 'grid',
      alignContent: 'start'
    },
    noResultTitle: {
      fontSize: '38px',
      fontFamily: 'Lato !important',
      maxWidth: 'none !important',
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        whiteSpace: 'normal'
      }
    },
    noResultDescription: {
      fontSize: '20px',
      fontFamily: 'Lato !important',
      maxWidth: 'none !important',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        whiteSpace: 'normal'
      }
    },
    noResultButtonsContainer: {
      display: 'flex',
      width: '80%',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        width: '100%',
        height: '96px'
      }
    },
    noResultViewButton: {
      width: '45%',
      height: '38px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    },
    noResultViewButtonAlt: {
      width: '45%',
      height: '38px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--button-focus)'
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    },
    ilustration: {
      width: '220px',
      alignSelf: 'center',
      margin: '0px 32px 0px 0px'
    }
  })
)
