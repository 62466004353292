import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Slide,
  Fade,
  SvgIcon,
  Typography,
  Button,
  Avatar,
  Tooltip,
} from '../../../MaterialUI'
import useStyles from './styles'
import { history } from '../../../../../helpers'
import {
  useIsXsScreen,
  useIsSmScreen,
  useIsMdScreen,
} from '../../../../../hooks'
import { SideMenuProps, SideMenuSlideProps } from './types'
import {
  bosscatLogo,
  bosscatLogoMellohome,
  icons,
  obFinishProfile,
  obArrow,
  newItem,
  vipMedal,
  construction,
} from 'assets'
import { ScrollWrapper } from '../../../../templates'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, isRegisterCompleted } from '../../../../../ducks/selectors'
import SideMenuContactModal from '../SideMenuContactModal'
import { getSubscriptions } from 'ducks/subscriptions/selectors'
import { subscriptionsActions } from 'ducks/actions'
import WelcomeVIPModal from 'components/pages/Desktop/OrderMaintenance/components/WelcomeVIPModal'

const SideMenuSlide: FC<SideMenuSlideProps> = ({
  children,
  smallScreen,
  open,
}) => {
  if (smallScreen) {
    return (
      <Slide
        direction="right"
        timeout={{ enter: 600, exit: 300 }}
        in={!smallScreen || open}
      >
        {children}
      </Slide>
    )
  }
  return children
}

const SideMenu: FC<SideMenuProps> = ({
  options,
  subOptions,
  estimateOptions,
  showOrderMaintenance,
}) => {
  const [open, setOpen] = useState(false)
  const [delay, setDelay] = useState(false)
  const user = useSelector(getUser)

  const classes = useStyles()
  const dispatch = useDispatch()
  const isMellohome = user.userProperties.source === 'loandepot'

  const registerCompleted = useSelector(isRegisterCompleted)
  // const subscriptions = useSelector(getSubscriptions("subscriptions"))

  const push = history.usePush()
  const currentPath = history.getCurrentPath()

  const fetchSubscriptions = useCallback(() => {
    dispatch(subscriptionsActions.fetchSubscriptions({}))
  }, [dispatch])

  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const [mdScreen] = useIsMdScreen()
  const smallScreen = xsScreen || smScreen || mdScreen

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelay(open)
    }, 600)
    return () => clearTimeout(timer)
  }, [open])

  useEffect(() => {
    fetchSubscriptions()
  }, [fetchSubscriptions])

  const handleListItemClick = (option: any) => {
    push('p/' + option.path)
    //push(option.path)
    setOpen(false)
  }

  const handleCloseMenu = () => {
    setOpen(false)
  }
  const handleOpenMenu = () => {
    setOpen(true)
  }

  return (
    <>
      {smallScreen && !open && !delay && (
        <Fade in={!delay}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={handleOpenMenu}
          >
            <icons.Menu className={classes.menuIcon} />
          </IconButton>
        </Fade>
      )}
      {smallScreen && open && delay && (
        <Fade in={delay}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={handleCloseMenu}
          >
            <icons.ChevronLeft className={classes.menuIcon} />
          </IconButton>
        </Fade>
      )}
      <Box hidden={!smallScreen || !open} className={classes.slideMenuBack} />
      <SideMenuSlide smallScreen={smallScreen} open={open}>
        <Box className={smallScreen ? classes.rootMobile : classes.root}>
          <ScrollWrapper
            absoluteStyle={smallScreen ? { position: 'relative' } : {}}
          >
            <Box className={classes.logo} mb={3}>
              <img alt="" src={isMellohome ? bosscatLogoMellohome : bosscatLogo} />
            </Box>
            <List className={classes.options} component="nav">
              {options.map((option, index) => {
                return (
                  <ListItem
                    key={index}
                    button
                    disabled={option.disabled || !registerCompleted}
                    className={
                      currentPath?.replace('/p/', '') === option.path
                        ? classes.selectedOption
                        : classes.option
                    }
                    onClick={() => handleListItemClick(option)}
                  >
                    <ListItemIcon className={classes.icon}>
                      <SvgIcon
                        component={option?.icon}
                        style={{
                          color:
                            currentPath?.replace('/p/', '') === option.path
                              ? 'var(--input-color)'
                              : 'inherit',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontSize: '18px',
                            fontWeight:
                              currentPath?.replace('/p/', '') === option.path
                                ? 900
                                : 400,
                          }}
                        >
                          {option.text}
                        </Typography>
                      }
                    />
                  </ListItem>
                )
              })}
            </List>
            {showOrderMaintenance ? (
              <Slide
                direction="right"
                in={showOrderMaintenance}
                mountOnEnter
                unmountOnExit
              >
                <List className={classes.options} component="nav">
                  <ListItem
                    key={'maintenance'}
                    button
                    disabled={!registerCompleted}
                    className={
                      currentPath === '/p/maintenance'
                        ? classes.selectedOption
                        : classes.option
                    }
                    selected={currentPath === '/p/maintenance'}
                    onClick={() => handleListItemClick({ path: 'maintenance' })}
                  >
                    <ListItemIcon className={classes.icon}>
                      <SvgIcon
                        component={icons.House}
                        style={{
                          color:
                            currentPath === '/p/maintenance'
                              ? 'var(--input-color)'
                              : 'inherit',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontSize: '18px',
                            fontWeight:
                              currentPath === '/p/maintenance' ? 900 : 400,
                          }}
                        >
                          Maintenance
                        </Typography>
                      }
                    />
                    <Box className={classes.newItemWrapper}>
                      <Typography className={classes.newItem}>NEW</Typography>
                      <img
                        alt=""
                        src={newItem}
                        style={{ width: '12px', height: '12px' }}
                        className={classes.newItemImg}
                      />
                    </Box>
                  </ListItem>
                </List>
              </Slide>
            ) : (
              <></>
            )}
            <Box className={classes.subOptions}>
              {subOptions && (
                <>
                  <Divider className={classes.divider} />
                  {subOptions.map((subOption, index) => {
                    return (
                      <React.Fragment key={index}>
                        {subOption.title && (
                          <Typography>{subOption.title}</Typography>
                        )}
                        <Button
                          type="submit"
                          variant={subOption.variant}
                          size="large"
                          disabled={!registerCompleted}
                          className={
                            subOption.variant === 'contained'
                              ? classes.button
                              : classes.buttonReverse
                          }
                          onClick={() => handleListItemClick(subOption)}
                          fullWidth
                        >
                          {subOption.text}
                        </Button>
                      </React.Fragment>
                    )
                  })}
                </>
              )}
              {estimateOptions && (
                <Button
                  variant="contained"
                  onClick={() => push('estimate')}
                  className={classes.button}
                  endIcon={<SvgIcon component={icons.Assignment} />}
                >
                  Get an Estimate
                </Button>
              )}
              {showOrderMaintenance && (
                <Button
                  variant="contained"
                  onClick={() => {
                    push('maintenance')
                    setOpen(false)
                  }}
                  className={`${classes.button} ${classes.maintenanceButton}`}
                  endIcon={
                    <img src={construction} alt="Order Maintenance button" />
                  }
                >
                  Order Maintenance
                </Button>
              )}
              {!registerCompleted && (
                <Box
                  className={classes.titleContainer}
                  onClick={() => push('u/register')}
                  style={{ cursor: 'pointer' }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Avatar
                      alt=""
                      src={obFinishProfile}
                      className={classes.iconJob}
                      variant="square"
                    />
                    <Avatar
                      alt=""
                      src={obArrow}
                      className={classes.iconArrow}
                      variant="square"
                    />
                  </Box>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Box className={classes.titleDetail}>
                      <Typography className={classes.smallInfo}>
                        Finish creating your profile to start using the App.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classes.line} />
            <List className={classes.options} component="nav">
              <ListItem
                key={'shared-estimates'}
                button
                disabled={!registerCompleted}
                className={classes.option}
                selected={currentPath === 'shared-estimates'}
                onClick={() =>
                  handleListItemClick({ path: 'shared-estimates' })
                }
              >
                <ListItemIcon className={classes.icon}>
                  <SvgIcon component={icons.AssignmentInd} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Shared Estimates</Typography>}
                />
                <Tooltip
                  title={
                    'Shared Estimates are Estimates requested by another party that have been shared with you.'
                  }
                >
                  <icons.Info />
                </Tooltip>
              </ListItem>
            </List>
            <SideMenuContactModal onClick={() => setOpen(false)} />
          </ScrollWrapper>
        </Box>
      </SideMenuSlide>
      {/* Maintenance modal VIP */}
      {user?.protectPlanIsNew && <WelcomeVIPModal />}
    </>
  )
}

export default SideMenu
