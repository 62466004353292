import { Theme, createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0px 0px',
      width: '100%'
    },
    label: {
      minWidth: '86px'
    },
    rightSeparation: {
      '& p': {
        fontFamily: 'BetterSansRegular'
      },
      marginRight: '8px'
    },
    listItem: {
      display: 'flex',
      minHeight: '58px',

      justifyContent: 'space-between !important',
      width: 'inherit'
    },
    item: {
      '& span': {
        fontFamily: 'BetterSansRegular',
        fontWeight: 600
      },
      fontSize: '16px',
      lineHeight: '24px'
    },
    itemDesc: {
      '& span': {
        fontFamily: 'BetterSansRegular',
        fontWeight: 600
      },
      fontSize: '14px',
      lineHeight: '24px'
    },
    itemType: {
      '& span': {
        fontFamily: 'BetterSansRegular'
      },
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '5px 0px 0px 0px'
    },
    itemEnd: {
      '& span': {
        fontFamily: 'BetterSansRegular'
      },
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      alignSelf: 'flex-end',
      marginRight: '12px'
      // maxWidth: '100px'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px'
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    price: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '58px',
      color: 'var(--head-text-color)'
    },
    icon: {
      color: 'var(--head-text-color)',
      margin: '18px 8px'
    },
    expand: {
      color: 'var(--white-color)'
    },
    expandBox: {
      display: 'flex',
      width: '26px',
      height: '26px',
      borderRadius: '4px',
      backgroundColor: 'var(--better-color)',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'var(--better-bold-color)',
        borderColor: 'var(--better-bold-color)',
        boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
      }
    },
    itemPercent: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--white-color)'
    },
    descr: {
      fontFamily: 'BetterSansRegular',
      margin: '4px 10px 0px 10px'
    },
    container: {
      width: '100%',
      padding: '0px 20px 15px 20px',
      backgroundColor: 'var(--background-color-secondary)'
    },
    image: {
      margin: '6px 0px 4px 0px'
    },
    buttonRemove: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      fontFamily: 'BetterSansRegular',
      fontSize: '14px',
      color: 'var(--white-color)',
      backgroundColor: 'var(--better-delete-color)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '160px'
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: 'var(--expired-color)'
      }
    },
    buttonAdd: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      fontFamily: 'BetterSansRegular',
      fontSize: '14px',
      color: 'var(--white-color)',
      backgroundColor: 'var(--better-color)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '160px'
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      }
    },
    buttonClose: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'white',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      }
    },
    buttonContent: {
      justifyContent: 'flex-end'
    },
    containerBox: {
      display: 'flex',
      width: 'fit-content',
      flexDirection: 'column',
      margin: '1rem 0'
    },
    italic:{
      fontStyle: 'italic',
    },
    title:{
      fontStyle: 'initial',
    },
    alert: {
      position: 'absolute',
      zIndex: 1,
      width: '673px',
      height: 'auto',
      [theme.breakpoints.down('lg')]: {
        width: '480px'
      },
      [theme.breakpoints.down('md')]: {
        width: '320px'
      },
      marginTop: '10px',
      backgroundColor: 'var(--white-color)',
      border: '2px solid var(--border-color)',
      borderRadius: '8px',
      paddingBottom: 20
    }
  })
)
