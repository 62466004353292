import storeInfo from 'ducks/store'
import { concatSeparator } from 'helpers'
import { Estimate, PaymentOption } from 'ducks/types'
import { NewEstimateType } from './types'

export const getNewEstimateStep = () => (): any => {
  const { newEstimate } = storeInfo.store.getState() || {}
  return newEstimate?.step || 0
}

export const getNewEstimateValue = (type: string) => (): any => {
  const { newEstimate } = storeInfo.store.getState()
  if (!newEstimate) return null
  return newEstimate[type as keyof NewEstimateType] || null
}

export const getNewEstimateValues = () => (): NewEstimateType => {
  const { newEstimate } = storeInfo.store.getState()
  return newEstimate
}

export const getNewEstimateAddress = (): string => {
  const { newEstimate } = storeInfo.store.getState()
  const { city, line_1: street1 } = newEstimate.address
  const address = concatSeparator(city ?? '', street1 ?? '', ', ')
  return address || 'No address'
}

export const getNewEstimateFullAddress = (): string => {
  const { newEstimate } = storeInfo.store.getState()
  const { city, line_1: street1, state, zipCode } = newEstimate.address
  let address = concatSeparator(street1 ?? '', city ?? '', ', ')
  address = concatSeparator(address, state + ' ' + zipCode, ', ')
  return concatSeparator(address, 'USA', ', ') || 'No address'
}

export const getNewEstimateData = () => (): Partial<Estimate> => {
  const { newEstimate } = storeInfo.store.getState()
  return newEstimate?.estimate
}

export const getNewEstimatePaymentOptions =
  () => (): PaymentOption[] | null => {
    const { newEstimate } = storeInfo.store.getState()
    return newEstimate.paymentOptions
  }

export const getNewEstimatePaymentOptionById =
  (id: string) => (): PaymentOption | null => {
    const { newEstimate } = storeInfo.store.getState()
    return (
      newEstimate.paymentOptions?.find((option: any) => option.id === id) ?? null
    )
  }

export const getNewEstimateSelectedOption = () => (): PaymentOption | null => {
  const { newEstimate } = storeInfo.store.getState()
  return newEstimate?.paymentOption
}

export const getNewEstimateExpediteOption = (selectedOption?: PaymentOption, paymentOptions?: PaymentOption[]) => (): PaymentOption | null => {
  const { newEstimate } = storeInfo.store.getState()
  const selected = selectedOption || newEstimate?.paymentOption
  if (!selected) return null
  return (
    paymentOptions?.find(
      (option) => option?.enable && option?.date < selected?.date
    ) ?? null
  )
}
export const getNewEstimateSubstep = () => (): any => {
  const {
    newEstimate: { substep },
  } = storeInfo.store.getState()
  return substep || 0
}

export const getNewEstimateOtherRequestsList = () => (): string => {
  const { newEstimate: { otherRequestsList } } = storeInfo.store.getState()
  return otherRequestsList || ''
}

export const getNewEstimateOtherRequestsUpload = () => (): string => {
  const { newEstimate: { otherRequestsUpload } } = storeInfo.store.getState()
  return otherRequestsUpload || ''
}

export const getNewEstimateItems = () => (): any[] => {
  const { newEstimate: { items } } = storeInfo.store.getState()
  return items || []
}
