import { Grid } from "@material-ui/core"
import { getUser } from "ducks/selectors";
import { USER_TYPE } from "helpers/constants";
import { useState } from "react";
import { useSelector } from "react-redux";
import TabNavigation from "../../organisms/TabNavigation";

import useStyles from './styles';
import ContactsListTab from "./tabs/ContactsList";
import NewContactTab from "./tabs/NewContact";

const AddContactModal = ({ setOpenModal }: { setOpenModal: (open: boolean) => void }) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)

  const onSuccess = () => {
    setSelectedTab(1)
  }

  const TABS = [
    {
      label: 'New Contact',
      content: <NewContactTab setOpenModal={setOpenModal} onSuccess={onSuccess} />,
    },
    {
      label: 'Contact List',
      content: <ContactsListTab />,
    },
  ]

  const getSelectedTab = (_selectedTab: number) => {
    setSelectedTab(_selectedTab)
  }

  return (
    <Grid>
      <TabNavigation
        tabs={TABS}
        getSelectedTab={getSelectedTab}
        variant="containedCircular"
        className={classes.tab}
        selectedTab={selectedTab}
      />
    </Grid>
  )
}

export default AddContactModal