/* eslint-disable no-useless-escape */
import {
  isEmpty as isEmptyLodash,
  capitalize as capitalizeLodash,
  reduce,
} from 'lodash'
import moment from 'moment'
import { ITEM_CATEGORY, USER_TYPE } from './constants'
import { fall, spring, summer, winter, obAgent, obHomeowner, obInstitutionalClient, icons  } from 'assets'
import { PerkType } from 'components/pages/Desktop/OrderMaintenance/Dashboard/PerksModal/PerkBox/types'

export * from './category'
export * from './constants'
export * from './files'
export * as history from './history'
export * from './optionLists'
export * from './states'
export * from './filesUrlParser'
export * from './parsePhone'

/** MASKS *******************************/
export const PHONE_MASK_INPUT = [
  /[1-9]/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const PHONE_MASK_REGEXP = /^([0-9]+(-[0-9]+)+)$/
export const PHONE_MASK_REGEXP_NO_SCOPE = /^([0-9]+([0-9]+)+)$/
/****************************************/

/** EMAILS ******************************/
export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
/****************************************/

/** PASSWORDS ***************************/
export const containsLowercase = (str: string): boolean => /[a-z]/.test(str)
export const containsUppercase = (str: string): boolean => /[A-Z]/.test(str)
export const containsNumber = (str: string): boolean => /\d/.test(str)
export const containsLetter = (str: string): boolean => /[A-Za-z]/.test(str)
export const containsNumberLetter = (str: string): boolean =>
  containsNumber(str) && containsLetter(str)

export const validatePassword = (psw: string): boolean =>
  psw.length >= 6 &&
  containsUppercase(psw) &&
  containsLowercase(psw) &&
  containsNumberLetter(psw)
/****************************************/

/** OBJECTS *****************************/
export const isEmpty = (object: any): boolean => {
  if (typeof object === 'string') {
    return isEmptyLodash(object.trim())
  }
  return isEmptyLodash(object)
}
/****************************************/

/** STRINGS *****************************/
export const capitalize = (string?: string | undefined): string => {
  return capitalizeLodash(string)
}

export const formatPhone = (
  phone: string | number | null | undefined
): string => {
  if (phone) {
    const phoneCopy = phone.toString()
    if (phoneCopy.length === 10) {
      return phoneCopy
        .substring(0, 3)
        .concat('-')
        .concat(phoneCopy.substring(3, 6))
        .concat('-')
        .concat(phoneCopy.substring(6))
    }
  }
  return ''
}

export const concatSeparator = (
  fstStr: string,
  sndStr: string,
  separator: string
): string => {
  if (!fstStr) return ''
  return fstStr?.concat(
    fstStr && !isEmpty(fstStr) && sndStr && !isEmpty(sndStr) ? separator : '',
    sndStr || ''
  )
}

export const errorTextTryingTo = (text: string): string => {
  if (!isEmpty(text))
    return 'An error occurred trying to '
      .concat(text)
      .concat(". We'll fix it soon!")
  return "An unexpected error occurred. We'll fix it soon!"
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
/****************************************/

/* DATES ********************************/
export const formatTimestamp = (
  timestamp: number | null | undefined,
  format: string
): string => {
  if (timestamp !== null && timestamp !== undefined) {
    return moment.unix(timestamp).format(format)
  }
  return ''
}

export const formatTimestampDate = (timestamp: number): Date => {
  if (timestamp !== null) {
    return new Date(timestamp * 1000)
  }
  return new Date()
}

export const formatDateToUnixTimestamp = (date?: Date): number => {
  return moment(date).unix()
}

export const dateFormatString = (
  date: string | Date,
  isString = true
): string => {
  let result = ''
  let toFormat

  if (date instanceof moment) toFormat = date
  else if (date !== null) toFormat = isString ? moment(date) : date

  if (moment(date).isValid()) {
    result = moment(toFormat).format('MM/DD/YYYY')
  }
  return result
}
/****************************************/

/** NUMBERS *****************************/
/* eslint-disable */
export const round = (value: number, exp: number) => {
  const roundedPrice = (
    Math.round((value + Number.EPSILON) * 100) / 100
  ).toFixed(2);
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  })
  return formatter.format(Number(roundedPrice))
}

/****************************************/

export const privacyLink = 'https://www.bosscathome.com/privacy/'
export const workOrdersTerms = 'https://bosscathome.com/work-order-terms/'

export const getHeaderStatus = (status: string): string => {
  const statusMap = {
    APPROVED: 'You’re all set!',
    APPROVED__SECONDARY: 'You’re all set!',
    PENDING_FURTHER: 'Thank you!',
    MISSING_DETAILS: 'Awaiting further details',
  } as { [key: string]: string }
  return statusMap[status] as string
}

export const getTextStatus = (status: string): string => {
  const statusMap = {
    APPROVED:
      'A BOSSCAT Success Manager will contact you soon to schedule the requested repairs. If you have any questions or need assitance, please call us at 888-887-8624.',
    APPROVED__SECONDARY:
      'Your approval has been processed successfully. A BOSSCAT Success Manager will be in touch throughout the repair process. If you have any questions or need assitance, please call us at 888-887-8624.',
    MISSING_DETAILS:
      'Please try submitting the approval again when you have the required approver contact information.',
    PENDING_FURTHER:
      'We’ve sent a copy of this request to the contact(s) provided.We’ll let you know when it’s been approved.',
  } as { [key: string]: string }
  return statusMap[status] as string
}

export const isPercentageOf = (amount: number, total: number): number => {
  return Math.floor((amount / total) * 100)
}

export const isRoundPercentageOf = (amount: number, total: number): number => {
  return Math.round((amount / total) * 100)
}

export const getTextGuarantee = (): string => {
  return 'As part of the repair process with BOSSCAT, this property will carry a 1 year workmanship guarantee for all repairs addressed by BOSSCAT, unless noted otherwise on invoice. This guarantee is transferable based upon the property, rather than ownership. This invoice serves as a transference of guarantee through closing.'
}

export const getTextUrl = (): string => {
  return 'Bosscat :: https://www.bosscathome.com/ :: 888-887-8624 :: customercare@bosscathome.com'
}

export const flattenedAddress = (place: any) => {
  const city = place.address_components.find((i: any) => {
    return i.types.includes('locality')
  })?.long_name
  const sublocality = place.address_components.find((i: any) => {
    return i.types.includes('sublocality')
  })?.long_name
  const state = place.address_components.find((i: any) =>
    i.types.includes('administrative_area_level_1')
  )?.short_name
  const zipCode = place.address_components.find((i: any) =>
    i.types.includes('postal_code')
  )?.long_name
  const street_number = place.address_components.find((i: any) =>
    i.types.includes('street_number')
  )?.long_name
  const line_1 = place.address_components.find((i: any) =>
    i.types.includes('route')
  )?.long_name
  const administrative_area_level_2 = place.address_components.find((i: any) =>
    i.types.includes('administrative_area_level_2')
  )?.long_name
  const country = place.address_components.find((i: any) =>
    i.types.includes('country')
  )?.long_name
  return {
    city: city || sublocality,
    state,
    zipCode,
    line_1,
    street_number,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    formatted_address: place.formatted_address,
    county: administrative_area_level_2,
    country
  }
}

export const formatParams = (params: any) => {
  if (!params) return ''
  const paramsFormated = Object.keys(params)
    .filter((key) => params[key] !== null)
    .map((key) => {
      const value = encodeURIComponent(params[key])
      return value ? `${key}=${value}` : ''
    })
    .join('&')
  return paramsFormated
}

export const sortItemsByCategories = (items: any): any => {
  const sortBy = Object.keys(ITEM_CATEGORY);
  const res = Object.entries(items).sort((a: any, b: any) => sortBy.indexOf(a[0]) - sortBy.indexOf(b[0]));
  return Object.fromEntries(res);
};

export const addDays = (date: Date | number, days = 1, exp?: number) => {
  if (!date) return null
  const result = new Date(exp ? (date as number * exp) : date)
  result.setDate(result.getDate() + days);
  return result;
}

export const updateObjectByPath: any = ({ obj, path, value }: { obj: any, path: string, value: any }) => {
  const [head, ...rest] = path.split('/')

  return {
    ...obj,
    [head]: rest.length
      ? updateObjectByPath({ obj: obj[head], path: rest.join('/'), value })
      : value
  }
}

export const appendHttpToUrl = (url: string): string => {
  if (url.startsWith('http')) return url

  return `https://${url}`
}

export const getDifferenceBetweenObjects: any = (
  differences: any,
  acc: any = [],
  pathToConcat?: string,
  op?: 'replace' | 'remove' | 'add',
) => {
  return reduce(
    differences,
    (result: any, value: any, key: any) => {
      const concatedPath = pathToConcat ? `${pathToConcat}/${key}` : key;
      if (typeof value === "object") {
        const lastPath = concatedPath.split("/")[
          concatedPath.split("/").length - 1
        ];
        return getDifferenceBetweenObjects(
          differences[lastPath],
          result,
          concatedPath,
          op || "replace",
        );
      } else {
        if (!value) return result
        return result.concat({
          op: op || "replace",
          path: `/${concatedPath}`,
          value: value
        });
      }

    },
    [...acc]
  );
};



export const PERKS: PerkType[] = [
  {
    title: 'Spring',
    img: spring,
    backgroundColor: '#EDFDF6',
    bullets: ['Dryer vent and hood cleaning (1 dryer)',
      'Detection systems check (smoke, gas, C02, water)',
      'Air filter replacement (up to two)',
      'Home health assessment']
  },
  {
    title: 'Summer',
    img: summer,
    backgroundColor: '#FCFEF5',
    bullets: [
      ' HVAC light maintenance ',
      'Exhaust fan service (kitchen and up to three bathrooms)',
      'Air filter replacement (up to two)',
      'Home health assessment'
    ]
  },
  {
    title: 'Fall',
    img: fall,
    backgroundColor: '#FFFBF0',
    bullets: [
      'Water heater maintenance for one water heater',
      'Sink trap cleaning',
      'Drain cleaning (kitchen and up to two bathtubs)',
      'Air filter replacement (up to two)',
      'Home Health Assessment'
    ]
  },
  {
    title: 'Winter',
    img: winter,
    backgroundColor: '#FAFDFF',
    bullets: [
      'HVAC light maintenance',
      'Gutter cleaning (up to two hours)',
      'Air filter replacement (up to two)',
      'Home health assessment'
    ]
  }
]

export const roleOptions = [
  {
    title: `I'm a Homeowner/Homebuyer`,
    icon: obHomeowner,
    id: USER_TYPE.HOMEOWNER,
  },
  {
    title: `I represent a Homeowner/Homebuyer`,
    icon: obAgent,
    id: USER_TYPE.BROKER,
  },
  {
    title: `I'm an Institutional Investor`,
    icon: obInstitutionalClient,
    id: USER_TYPE.INSTITUTIONAL,
  },
]

export const agentOptions = [
  {
    label: 'Real Estate Agent/Broker',
    key: USER_TYPE.BROKER
  },
  {
    label: 'Home Inspector',
    key: USER_TYPE.INSPECTOR
  },
  {
    label: 'Closing Coordinator',
    key: USER_TYPE.CLOSING_COORDINATOR
  },
  {
    label: 'Property Manager',
    key: USER_TYPE.PROPERTY_MANAGER
  },
  {
    label: 'Other Real Estate Professional',
    key: USER_TYPE.OTHER
  }
]

export const institutionalOptions = [
  {
    label: `SFR Property Owner`,
    key: 'SFR Property Owner'
  },
  {
    label: 'iBuyer',
    key: 'iBuyer'
  },
  {
    label: 'REO Asset Manager',
    key: 'REO Asset Manager'
  },
  {
    label: 'Property Manager',
    key: 'Property Manager'
  },
  {
    label: 'Other',
    key: 'Other'
  }
]