import { FC, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { format } from 'date-fns'
import { Range, TextFieldProps } from './types'
import useStyles from './styles'
import { STextField } from '../../..'
import { InputAdornment, Button, Typography } from '@material-ui/core'
import { icons } from '../../../../../assets'
import { SingleDatePicker } from './SingleDatePicker'
import { MultiDatePicker } from './MultiDatePicker'
import { isEmpty } from '../../../../../helpers'

/**
 *
 * @param value `Date || Range` to show as default date
 * @param onChange `function` to execute on calendar's change
 * @param allowRange `boolean` to allow or not range's selection. `(default) -> false`
 * @param minDate `Date` min date to pick dates after it
 * @returns
 */
const DatePicker: FC<TextFieldProps> = ({
  value,
  onChange,
  allowRange = false,
  minDate,
  error = false,
  showPopUpCalendar = true,
  className,
  shouldScrollOnError
}) => {
  const classes = useStyles()
  const formatDate = 'MM/dd/yyyy'

  const [show, setShow] = useState(false)
  const [reload, setReload] = useState(false)
  const [showAllHistory, setShowAllHistory] = useState(isEmpty(value))
  const [firstDatePicked, setFirstDatePicker] = useState<boolean>(false)

  const compRef = useRef<HTMLDivElement>(null)

  const popup = (): void => {
    setShow(!show)
  }

  const allHistory = (): void => {
    setShowAllHistory(true)
    onChange({})
    setReload(!reload)
    popup()
  }

  const thisMonth = (): void => {
    setShowAllHistory(false)
    const newValue: Range = {
      startDate: moment()
        .startOf('month')
        .toDate(),
      endDate: moment()
        .endOf('month')
        .toDate()
    }
    onChange(newValue)
    setReload(!reload)
    popup()
  }

  const lastMonth = (): void => {
    setShowAllHistory(false)
    const x = new Date()
    x.setDate(1)
    x.setMonth(x.getMonth() - 1)
    const y = new Date()
    y.setDate(0)

    const newValue: Range = {
      startDate: x,
      endDate: y
    }
    onChange(newValue)
    setReload(!reload)
    popup()
  }

  const thisWeek = (): void => {
    setShowAllHistory(false)
    const newValue: Range = {
      startDate: moment()
        .startOf('week')
        .toDate(),
      endDate: moment()
        .endOf('week')
        .toDate()
    }
    onChange(newValue)
    setReload(!reload)
    popup()
  }

  const handleOnChange = (event: any) => {
    if (allowRange && !firstDatePicked) {
      setFirstDatePicker(true)
    } else {
      popup()
      setFirstDatePicker(false)
    }
    setShowAllHistory(false)
    onChange(event)
  }

  const renderCalendarPicker = () => {
    if (!allowRange) {
      return (
        <SingleDatePicker
          onChange={handleOnChange}
          value={value as Date}
          minDate={minDate}
        />
      )
    } else {
      return (
        <MultiDatePicker onChange={handleOnChange} range={value as Range} />
      )
    }
  }

  const formatDateToShow = () => {
    if (showAllHistory && allowRange) {
      return 'All History'
    }
    if (allowRange) {
      const range = value as Range
      return range && range.startDate && range.endDate
        ? format(range.startDate, formatDate) +
        '  -  ' +
        format(range.endDate, formatDate)
        : ''
    }
    return value instanceof Date ? format(value, formatDate) : ''
  }

  const scrollIntoView = () => {
    if (compRef.current) {
      compRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    error && shouldScrollOnError && scrollIntoView()
  }, [error])

  return (
    <div className={className} ref={compRef}>
      <STextField
        error={error}
        variant='standard'
        label=''
        className={classes.comboParent}
        value={formatDateToShow()}
        onClick={popup}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Button aria-label="calendar-button" variant='text' size='small'/*  onClick={popup} */>
                <icons.EventNote />
              </Button>
            </InputAdornment>
          )
        }}
      />
      {show && showPopUpCalendar && (
        <div className={`${classes.root} DatePicker`}>
          {allowRange && (
            <div className={classes.bar}>
              <Button variant='text' size='small' onClick={allHistory}>
                <Typography className={classes.title}> All History </Typography>
              </Button>
              <Button variant='text' size='small' onClick={lastMonth}>
                <Typography className={classes.title}> Last Month </Typography>
              </Button>
              <Button variant='text' size='small' onClick={thisMonth}>
                <Typography className={classes.title}> This Month </Typography>
              </Button>
              <Button variant='text' size='small' onClick={thisWeek}>
                <Typography className={classes.title}> This Week </Typography>
              </Button>
            </div>
          )}
          {renderCalendarPicker()}
        </div>
      )}
    </div>
  )
}

export default DatePicker
