import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#FFC633',
      marginBottom: '10px',
      borderRadius: '8px',
      maxWidth: '93vw',
      margin: '10px auto 0',
      padding: '0 5px'
    },
    text: {
      padding: '6px 8px 4px'
    },
    warning: {
      padding: '3px 0px'
    }
  })
)
