import { UpdateRequest } from 'api/types'
import { Estimate } from 'ducks/types'

/** TYPES **/
export const estimateTypes = {
  FETCH_ESTIMATE: 'FETCH_ESTIMATE',
  SET_ESTIMATE: 'SET_ESTIMATE',
  UPDATE_ESTIMATE_BY_PATH: 'UPDATE_ESTIMATE_BY_PATH',
  APPROVAL: 'APPROVAL',
  SHARE_ESTIMATE: 'SHARE_ESTIMATE'
}

export interface ShareEstimateRequest {
  email: string
  role: string
  firstName: string
  lastName: string
}

/** ACTIONS **/
export interface FetchEstimateAction {
  type: typeof estimateTypes.FETCH_ESTIMATE
  payload: string
  callback?: (succ: boolean, estimate?: Estimate) => void
}

export interface UpdateEstimateByPathAction {
  type: typeof estimateTypes.UPDATE_ESTIMATE_BY_PATH
  payload: UpdateRequest
  callback: (succ: boolean, estimateId?: string) => void
}

export interface ShareEstimateAction {
  type: typeof estimateTypes.SHARE_ESTIMATE
  payload: Partial<ShareEstimateRequest>
  callback?: (succ: boolean) => void
}

export interface ApprovalEstimateAction {
  type: typeof estimateTypes.FETCH_ESTIMATE
  payload: Partial<Estimate>
  callback?: (succ: boolean, status: string) => void
}

export interface SetEstimateAction {
  type: typeof estimateTypes.SET_ESTIMATE
  payload: Estimate
}

export interface UpdateEstimatePayload {
  type: typeof estimateTypes.UPDATE_ESTIMATE_BY_PATH
  payload: UpdateRequest
  callback?: (succ: boolean) => void
}

export type EstimateActions =
  | FetchEstimateAction
  | SetEstimateAction
  | UpdateEstimatePayload
  | ApprovalEstimateAction
  | ShareEstimateAction

/** REDUCER **/
export type EstimateType = Estimate | null
