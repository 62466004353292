import { FC } from 'react'
import { Box, Typography } from '../../..'
import useStyles from './styles'
import { UserDetailsProps } from './types'
import { useIsXsScreen } from '../../../../../hooks'

const UserDetails: FC<UserDetailsProps> = ({ primary, secondary }) => {
  const classes = useStyles()
  const [xsScreen] = useIsXsScreen()

  return (
    <Box>
      <Box className={classes.root}>
        <Typography className={classes.primaryText}>{primary}</Typography>
        <Box className={classes.sndContainer}>
          {!xsScreen && secondary.map((snd, index) => {
            return (
              <Box key={index} className={classes.sndContent}>
                {snd.length > 0 && <Typography className={classes.secondaryText}> • </Typography>}
                <Typography key={index} className={classes.secondaryText}>{snd}</Typography>
              </Box>
            )
          })}

          {xsScreen &&
            <Box className={classes.sndContent}>
              {secondary[0].length > 0 && <Typography className={classes.secondaryText}> • </Typography>}
              <Typography className={classes.secondaryText}>{secondary[0]}</Typography>
            </Box>}
        </Box>
      </Box>

      {xsScreen &&
        <Box>
          {secondary.map((snd, index) => {
            return (
              <Box key={index} className={index > 0 ? classes.row : classes.empty}>
                {index > 0 && <Typography key={index} style={{ paddingLeft: 0 }} className={classes.secondaryText}>{snd}</Typography>}
              </Box>
            )
          })}
        </Box>}
    </Box>
  )
}

export default UserDetails
