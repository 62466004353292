import { FC, useState } from 'react'
import { icons, east } from 'assets'
import { useIsXsScreen } from '../../../../../hooks'
import { Typography, Button, Avatar, SvgIcon, Box, Popover } from '../../..'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { BottomBarProps } from './types'

const BottomBarEstimate: FC<BottomBarProps> = ({
  disabledForward,
  onApprove,
  onShare,
  rmForward = false,
  price,
  showButtons,
  showBetterStyle = false,
  showApproveButton = false,
  isPunchlistPlanner = false,
  shared = false,
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const [xsScreen] = useIsXsScreen()
  const classes = showBetterStyle ? stylesBetter : styles
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const openPopover = Boolean(anchorEl)

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.row}>
      {!xsScreen && (
        <div className={classes.column}>
          <div className={classes.rowTitle}>
            <Typography className={classes.total}> Total: </Typography>
            <Typography className={classes.price}> {price} </Typography>
          </div>
          <>
            <div className={classes.rowTitle}>
              <Typography variant="caption">
                Includes one-year warranty
              </Typography>
            </div>
            <div className={classes.rowTitle}>
              <Typography variant="caption">
                Estimate valid for 30 days
              </Typography>
            </div>
          </>
        </div>
      )}
      {showButtons && (
        <div className={classes.rowButtons}>
          {!shared && (
            <Button
              type="submit"
              variant="contained"
              size="small"
              className={classes.shareButton}
              fullWidth
              onClick={onShare}
              endIcon={<SvgIcon component={icons.Share} />}
            >
              <Typography> Share Estimate </Typography>
            </Button>
          )}
          {/* <Button
            type='submit'
            variant='contained'
            size='small'
            endIcon={<icons.Phone className={classes.icon} />}
            style={{ textTransform: isPunchlistPlanner ? 'uppercase' : 'none' }}
            className={classes.speak}
            fullWidth
            onClick={onContactUs}
          >
            <Typography> Contact Us </Typography>
          </Button> */}
          {!isPunchlistPlanner &&
            !rmForward &&
            showApproveButton &&
            !shared && (
              <Box>
                <Box
                  aria-owns={openPopover ? 'minimum-price-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    className={classes.forward}
                    endIcon={
                      <Avatar alt="" src={east} className={classes.icon} />
                    }
                    disabled={disabledForward}
                    fullWidth
                    onClick={onApprove}
                  >
                    <Typography> Approve Estimate </Typography>
                  </Button>
                </Box>
                <Popover
                  id="minimum-price-popover"
                  open={openPopover && !!disabledForward}
                  anchorEl={anchorEl}
                  style={{
                    pointerEvents: 'none',
                    color: 'grey',
                  }}
                  anchorOrigin={{
                    vertical: -35,
                    horizontal: -30,
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography style={{ padding: '5px' }}>
                    Total is under the BOSSCAT minimum.
                  </Typography>
                </Popover>
              </Box>
            )}
        </div>
      )}
    </div>
  )
}

export default BottomBarEstimate
