/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { FC, useState, useEffect, useCallback, ReactNode, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  ValuesComponent, Grid, Typography, TableContainer, Table,
  TableHead, TableRow, TableCell, TableBody, Paper, Button, Tag,
  DatePicker, Autocomplete, Progress, ZeroResultView, TableFooter, TableSortLabel,
  Pagination, CircularProgress, Box
} from 'components/UI'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import useStyles from './styles'
import { DesktopPage, PrivateLoader } from 'components/templates'
import {
  JOB_PUBLIC_STATUS, formatTimestamp, history, round, JOB_PAYMENT_STATUS, formatDateToUnixTimestamp
} from 'helpers'
import { configActions, jobsActions } from 'ducks/actions'
import { getConfigValue, getJobs, getJobsIndicators } from 'ducks/selectors'
import { JobsIndicators } from 'ducks/jobs/types'
import { Job } from 'ducks/types'
import { Range } from 'components/UI/CustomUI/atoms/DatePicker/types'
import SearchInput from 'components/UI/CustomUI/molecules/SearchInput'
import { useClassName, useIsLgScreen, useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'hooks'
import JobItem from './JobItem'
import { debounce } from 'lodash'

const initialIndicators = {
  total: 0,
  upcoming: 0,
  inProgress: 0,
  attention: 0,
  completed: 0
}

const statusOptions = [
  { key: 0, value: 'Any Status' },
  ...Object.keys(JOB_PUBLIC_STATUS).map((status, index) => {
    return {
      key: index + 1,
      value: JOB_PUBLIC_STATUS[status as keyof typeof JOB_PUBLIC_STATUS]
    }
  })
]

const Jobs: FC = () => {
  const classes = useStyles()
  const className = useClassName()
  const dispatch = useDispatch()
  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const [mdScreen] = useIsMdScreen()
  const [lgScreen] = useIsLgScreen()
  const pushForward = history.usePushForward()

  const jobStatus = useSelector(getConfigValue('jobStatus'))
  const jobPagination = useSelector(getConfigValue('jobPagination'))
  const initialStatus = jobStatus ?? statusOptions[0]

  const [pageLoading, setPageLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(false)
  const [statsLoading, setStatsLoading] = useState(false)
  const [pageChange, setPageChange] = useState(false)

  const [status, setStatus] = useState<any>(initialStatus)
  const [dateRange, setDateRange] = useState<Range | null>(null)
  const [indicators, setIndicators] = useState<JobsIndicators>(initialIndicators)
  const [searchInput, setSearchInput] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const [sortBy, setSortBy] = useState<string>('completedOn')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [pageStart, setPageStart] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const jobs = useSelector(getJobs())
  const jobsIndicators = useSelector(getJobsIndicators(), shallowEqual)
  const cardView = xsScreen || smScreen || mdScreen || lgScreen

  const fetchJobs = useCallback(() => {
    setTableLoading(true)
    dispatch(
      jobsActions.fetchJobs({
        startDate: dateRange?.startDate ? formatDateToUnixTimestamp(dateRange?.startDate) : undefined,
        endDate: dateRange?.endDate ? formatDateToUnixTimestamp(dateRange?.endDate) : undefined,
        status: status?.key !== 0 ? status?.value : undefined,
        start: pageStart,
        limit: rowsPerPage,
        sortBy,
        sortOrder,
        search
      },
        (_succ: boolean) => {
          setPageChange(false)
          setPageLoading(false)
          setTableLoading(false)
        }
      )
    )
  }, [dateRange, status, pageStart, rowsPerPage, sortBy, sortOrder, search])

  const fetchJobsStats = useCallback(() => {
    setStatsLoading(true)
    dispatch(
      jobsActions.fetchJobsStats({
        startDate: dateRange?.startDate ? formatDateToUnixTimestamp(dateRange?.startDate) : undefined,
        endDate: dateRange?.endDate ? formatDateToUnixTimestamp(dateRange?.endDate) : undefined,
        status: status?.key !== 0 ? status?.value : undefined,
        search: search
      },
        (_succ: boolean) => {
          setStatsLoading(false)
          setIndicators(jobsIndicators)
        }
      )
    )
  }, [dateRange, status, search])

  useEffect(() => {
    fetchJobs()
    fetchJobsStats()
    dispatch(configActions.setConfigValue({ type: 'jobDateRange', value: dateRange }))
    dispatch(configActions.setConfigValue({ type: 'jobStatus', value: status }))
  }, [dateRange, status, fetchJobs, search])

  useEffect(() => {
    setIndicators((statsLoading || tableLoading) && !pageChange ? initialIndicators : jobsIndicators)
  }, [statsLoading, tableLoading, jobsIndicators.total])

  const handleSelect = (rangeSelected: Range) => {
    setDateRange(rangeSelected)
  }

  const getNoResultTitle = (): ReactNode => {
    switch (status?.value) {
      case 'Any Status':
        return !dateRange?.endDate && !dateRange?.startDate
          ? 'You have no jobs yet'
          : 'No jobs found in this date range'
      default:
        return (
          <p>
            No Jobs with <b>{status?.value}</b> status
          </p>
        )
    }
  }

  const getNoResultDescription = (): ReactNode => {
    switch (status?.value) {
      case 'Any Status':
        return !dateRange?.endDate && !dateRange?.startDate
          ? "We couldn't find a Job to show you"
          : "Try selecting another range of dates or selecting 'All history'."
      default:
        return "Please select another status or 'Any Status' to see all the Jobs you have."
    }
  }

  const handleSort = (columnName: string) => {
    const tempsortBy = sortBy
    setPageChange(true)
    setSortBy(columnName)
    setSortOrder(columnName === tempsortBy ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'desc')
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageChange(true)
    setPageStart(newPage * rowsPerPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageChange(true)
    setRowsPerPage(parseInt(event.target.value, 10))
    setPageStart(0)
  }

  const changeSearchFilter = useCallback((searchInput: string) => setSearch(searchInput), [])
  const onSearchInputChange = useMemo(() => debounce(changeSearchFilter, 500), [searchInput])
  const handleSearchInputChange = (event: any) => {
    setSearchInput(event.target.value)
    onSearchInputChange(event.target.value)
  }

  return (
    <DesktopPage title='Jobs'>
      <PrivateLoader loading={pageLoading}>
        <Grid container spacing={3} className={classes.root}>
          <Grid container item xs={12} spacing={3} className={classes.filters}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1'> Date Range: </Typography>
              <DatePicker
                onChange={handleSelect}
                value={dateRange}
                allowRange
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Autocomplete
                label='Status:'
                value={status}
                options={statusOptions}
                onChange={(value: any) => {
                  setStatus(value)
                }}
                className={classes.statusSelect}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1'> Search:</Typography>
              <SearchInput
                value={searchInput}
                onChange={handleSearchInputChange} />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} className={classes.valueComponents}>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title='Total'
                titleColor='primary'
                information='Total number of jobs'
                value={indicators.total}
              />
            </Grid>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title='Upcoming'
                titleColor='warning'
                information='Total number of upcoming jobs. We’re working to get these scheduled and started ASAP!'
                value={indicators.upcoming}
              />
            </Grid>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title='In Progress'
                titleColor='info'
                information='Total number of jobs that are currently in progress'
                value={indicators.inProgress}
              />
            </Grid>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title='Attention'
                titleColor='error'
                information='Total number of jobs that require your attention. These are usually awaiting further approvals or a deposit payment before the work can continue.'
                value={indicators.attention}
              />
            </Grid>
            <Grid item className={classes.valueItem}>
              <ValuesComponent
                title='Completed'
                titleColor='success'
                information='Total number of completed jobs'
                value={indicators.completed}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} className={classes.tableContainer}>
            <Grid item xs={12} className={classes.table}>
              <PrivateLoader loading={!pageChange && tableLoading} building='fragment'>
                <TableContainer
                  component={Paper}
                  className={!cardView ? classes.tableComponent : classes.tableOff}
                >
                  {pageChange && (
                    <Box className={classes.pageChange}>
                      <CircularProgress disableShrink size={86} thickness={4} />
                    </Box>)}

                  {cardView &&
                    <Grid container item xs={12} spacing={3}>
                      {jobs.length > 0
                        ? jobs.map((job: Job) => <JobItem key={job.id} job={job} />)
                        : null}
                      {jobs.length > 0 && (
                        <TableFooter>
                          <TableRow>
                            <Pagination
                              count={jobPagination?.total || 0}
                              colspan={8}
                              rowsPerPage={rowsPerPage}
                              page={pageStart / rowsPerPage}
                              handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      )}
                    </Grid>}

                  {!cardView &&
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.cellHead}>
                            Ref
                          </TableCell>
                          <TableCell className={classes.cellHead}>
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('address')}
                              direction={sortBy === 'address' ? sortOrder : 'asc'}
                            >
                              Address
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.cellHead} align='center'>
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('percentItemsComplete')}
                              direction={sortBy === 'percentItemsComplete' ? sortOrder : 'asc'}
                            >
                              Progress
                            </TableSortLabel>
                          </TableCell>

                          <TableCell className={classes.cellHead} align='center'>
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('createdOn')}
                              direction={sortBy === 'createdOn' ? sortOrder : 'asc'}
                            >
                              Approved
                            </TableSortLabel>
                          </TableCell>



                          <TableCell className={classes.cellHead} align='center'>
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('startedOn')}
                              direction={sortBy === 'startedOn' ? sortOrder : 'asc'}
                            >
                              Started
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.cellHead} align='center'>
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('completedOn')}
                              direction={sortBy === 'completedOn' ? sortOrder : 'asc'}
                            >
                              Completed
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.cellHead} align='center'>
                            <TableSortLabel
                              classes={{ root: classes.tableSortLabel }}
                              onClick={() => handleSort('publicStatus')}
                              direction={sortBy === 'publicStatus' ? sortOrder : 'asc'}
                            >
                              Status
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.cellHead} align='left'>
                            <TableSortLabel
                              classes={{
                                root: classes.tableSortLabel
                              }}
                              onClick={() => handleSort('totalValue')}
                              direction={sortBy === 'totalValue' ? sortOrder : 'asc'}
                            >
                              Cost
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.cellHead} align='center'>
                            Actions
                          </TableCell>
                          <TableCell
                            className={classes.cellHead}
                            align='center'
                          />
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {jobs.length > 0
                          ? jobs.map((job: Job) => {
                            const { id, startedOn, completedOn, publicStatus, createdOn, percentItemsComplete, properties, paymentStatus } = job
                            const { invoiceId, totalWithTax, address } = properties || {}
                            const { line_1, city, state } = address || {}
                            return (
                              <TableRow key={id} className={classes.row}>
                                <TableCell className={classes.cell}>
                                  <Typography className={classes.title}>
                                    {properties?.publicId}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  <Typography className={classes.title}>
                                    {line_1}
                                  </Typography>
                                  <Typography className={classes.description}>
                                    {`${city}, ${state}`}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.cell}
                                  align='center'
                                >
                                  <Progress className={classes.noMargin} value={percentItemsComplete} />
                                </TableCell>

                                <TableCell
                                  className={classes.cell}
                                  align='center'
                                >
                                  <Typography className={classes.title}>
                                    {formatTimestamp(createdOn, 'MM/DD/YYYY')}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  className={classes.cell}
                                  align='center'
                                >
                                  <Typography className={classes.title}>
                                    {formatTimestamp(startedOn, 'MM/DD/YYYY')}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.cell}
                                  align='center'
                                >
                                  <Typography className={classes.title}>
                                    {formatTimestamp(completedOn, 'MM/DD/YYYY')}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={`${classes.cell} ${classes.status}`}
                                  align='center'
                                >
                                  <Tag type={publicStatus} variation='body1' />
                                </TableCell>
                                <TableCell
                                  className={classes.cell}
                                  align='center'
                                >
                                  <Typography className={className(classes.title, classes.leftAlignment)}>
                                    {`$${round(totalWithTax ?? 0, 2)}`}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.cell}
                                  align='center'
                                >
                                  <Button
                                    type='submit'
                                    variant='contained'
                                    className={classes.buttonView}
                                    onClick={() => {
                                      pushForward(`${id}`)
                                    }}
                                  >
                                    View
                                  </Button>
                                </TableCell>

                                <TableCell
                                  className={classes.cell}
                                  align='center'
                                >
                                  <Button
                                    type='submit'
                                    variant='contained'
                                    className={`${classes.button} ${paymentStatus === JOB_PAYMENT_STATUS.PAID ? classes.buttonPaid : ''}`}
                                    onClick={() => {
                                      pushForward(`${id}/invoice/${invoiceId}`)
                                    }}
                                    disabled={invoiceId === null}
                                  >
                                    {paymentStatus === JOB_PAYMENT_STATUS.PAID ? 'View Invoice' : 'Pay'}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          })
                          : null}
                      </TableBody>
                      {jobs.length > 0 && (
                        <TableFooter>
                          <TableRow>
                            <Pagination
                              count={jobPagination?.total || 0}
                              colspan={8}
                              rowsPerPage={rowsPerPage}
                              page={pageStart / rowsPerPage}
                              handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      )}
                    </Table>}
                  {jobs.length < 1 && (
                    <ZeroResultView
                      title={getNoResultTitle()}
                      description={getNoResultDescription()}
                    />
                  )}
                </TableContainer>
              </PrivateLoader>
            </Grid>
          </Grid>
        </Grid>
      </PrivateLoader>
    </DesktopPage>
  )
}

export default Jobs
