import { CONTACT_ROLE } from './constants'
import { states, allStates, canadaStates, countries } from './states'

export interface OptionType {
  key: string
  value: string
}

/** States option list ******************/
export const getStatesOptionList = (): OptionType[] => {
  return states.map(state => {
    return {
      key: state?.abbreviation,
      value: state?.abbreviation?.concat(' - ', state.name)
    }
  })
}

export const getStatesOptionListCanada = (): OptionType[] => {
  return canadaStates.map(state => {
    return {
      key: state?.abbreviation,
      value: state?.abbreviation?.concat(' - ', state.name)
    }
  })
}

export const getCountriesOptionList = (): OptionType[] => {
  return countries.map(country => {
    return {
      key: country?.abbreviation,
      value: country?.name
    }
  })
}

export const getSelectedAsOption = (state: any): OptionType | null => {
  if (!state || !state.abbreviation) return null
  return {
    key: state?.abbreviation,
    value: state?.abbreviation?.concat(' - ', state.name)
  }
}



export const getCountrySelectedAsOption = (country: any): OptionType | null => {
  if (!country) return null

  const code = countries.find(opt => opt.name === country)?.abbreviation
  if (code) {
    return {
      key: code,
      value: country
    }
  } else {
    return null
  }
}

export const getAllStatesOptionList = (): OptionType[] => {
  return allStates.map(state => {
    if (state.abbreviation === 'all') {
      return {
        key: state.abbreviation,
        value: state.name
      }
    } else {
      return {
        key: state.abbreviation,
        value: state.abbreviation.concat(' - ', state.name)
      }
    }
  })
}

export const getSelectedAllAsOption = (state: any): OptionType => {
  if (state.abbreviation === 'all') {
    return {
      key: state.abbreviation,
      value: state.name
    }
  } else {
    return {
      key: state.abbreviation,
      value: state.abbreviation.concat(' - ', state.name)
    }
  }
}

export const getStatesAbbreviation = (): string[] => {
  return states.map(state => state.abbreviation)
}

/****************************************/

/** Enums to option lists ***************/
export const getContactRoleOpt = (): OptionType[] => {
  return [
    { key: CONTACT_ROLE.LISTING_AGENT, value: 'Selling/Listing Agent' },
    { key: CONTACT_ROLE.BUYERS_AGENT, value: 'Buyer’s Agent' },
    { key: CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR, value: 'Closing Coordinator - Buyer’s Agent' },
    { key: CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR, value: 'Closing Coordinator - Seller’s Agent' },
    { key: CONTACT_ROLE.HOME_BUYER, value: 'Home Buyer' },
    { key: CONTACT_ROLE.HOMEOWNER, value: 'Homeowner' },
    { key: CONTACT_ROLE.HOME_SELLER, value: 'Home Seller' },
    { key: CONTACT_ROLE.INSPECTOR, value: 'Inspector' },
    { key: CONTACT_ROLE.OTHER, value: 'Other' }
  ]
}

/** Enums to option lists ***************/
export const getContactRoleOptions = (): OptionType[] => {
  return [
    { key: CONTACT_ROLE.LISTING_AGENT, value: 'Selling/Listing Agent' },
    { key: CONTACT_ROLE.BUYERS_AGENT, value: 'Buyer’s Agent' },
    { key: CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR, value: 'Closing Coordinator - Buyer’s Agent' },
    { key: CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR, value: 'Closing Coordinator - Seller’s Agent' },
    { key: CONTACT_ROLE.HOMEOWNER, value: 'Homeowner' },
    { key: CONTACT_ROLE.HOME_BUYER, value: 'Home Buyer' },
    { key: CONTACT_ROLE.HOME_SELLER, value: 'Home Seller' },
    { key: CONTACT_ROLE.INSPECTOR, value: 'Inspector' },
    { key: CONTACT_ROLE.OTHER, value: 'Other' }
  ]
}
/****************************************/

export const getPaymentOptions = (): OptionType[] => {
  return [
    { key: '0', value: 'Homeowner (at the time of repairs)' },
    { key: '1', value: 'Pay at Close' },
    { key: '2', value: 'Other' }
  ]
}

export const getDateProjectOptions = (): OptionType[] => {
  return [
    { key: 'ONE_WEEK', value: 'Within a week' },
    { key: 'THIRTY_DAYS', value: 'Within 30 days' },
    { key: 'SIXTY_DAYS', value: 'Within 60 days' },
    { key: 'OVER_SIXTY_DAYS', value: 'In 60+ days' }
  ]
}

export const getPaymentsTopLevelOptions = (): OptionType[] => {
  return [
    { key: '1', value: 'By Year' },
    { key: '2', value: 'By Month' }
  ]
}
