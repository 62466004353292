import { FC, useState } from 'react'
import { DetailsProps } from './types'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { icons, blueHouseLogo } from '../../../../../assets'
import { Typography, Box, Grid, Tooltip, Button } from '@material-ui/core'
import DownloadButton from '../DownloadButton'
import Modal from '../../molecules/Modal'
import AddContactModal from '../AddContactModal'

const EstimateHeader: FC<DetailsProps> = ({
  title, line1, line2, approved, sent, mail, total, onClick, showBetterStyle = false, pendingFurther = false
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles
  const [openModal, setOpenModal] = useState(false)
  const hasRange = total.includes('-')

  return (
    <Box className={classes.root} id='user-and-item-information'>
      <Grid container spacing={1} style={{ flexWrap: 'wrap' }}>
        <Grid item xs={12} lg={3}>
          <Box className={classes.rows}>
            {!showBetterStyle && <img src={blueHouseLogo} alt='' className={classes.logo} />}
            <Box className={classes.column}>
              {!showBetterStyle && <Typography className={classes.title}> {title} </Typography>}
              <Typography className={classes.text}> {line1} </Typography>
              <Typography className={classes.text2}> {line2} </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} lg={2}>
          <Box className={classes.rows}>
            <icons.Mail className={classes.icon} />

            <Typography className={classes.subtitle}> Mail: </Typography>
          </Box>
          <Tooltip title={mail}>
            <Typography className={`${classes.detail} ${classes.email}`}> {mail} </Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={6} lg={2}>
          <Box className={classes.rows}>
            <icons.Send className={classes.icon} />
            <Typography className={classes.subtitle}> Sent: </Typography>
          </Box>
          <Typography className={classes.detail}> {sent} </Typography>
        </Grid>

        <Grid item xs={6} lg={2}>
          <Box className={classes.rows}>
            <icons.AssignmentTurnedIn className={classes.icon} />
            <Typography className={classes.subtitle}> Approved on: </Typography>
          </Box>
          {
            pendingFurther
              ? (
                <Box className={styles.pendingFurtherContainer}>
                  <Typography variant='caption'> Pending Further Approval </Typography>
                </Box>)
              : <Typography className={classes.detail}> {approved} </Typography>
          }
        </Grid>

        {/* <Box className={classes.rows}> */}
        <Box className={classes.rows}>
          <Typography className={`${classes.total} ${hasRange ? classes.smallTotal : ''}`}> Total: </Typography>
          <Typography className={`${classes.totalValue} ${hasRange ? classes.smallTotal : ''}`}> {total} </Typography>
        </Box>
        <Box style={{ marginLeft: "auto" }} className={classes.rows}>
          <DownloadButton
            text='PDF'
            onClick={onClick}
            showBetterStyle={showBetterStyle}
          />
          <Button
            type='submit'
            size='large'
            className={`${classes.button} ${classes.buttonContacts}`}
            onClick={() => setOpenModal(true)}
            startIcon={<icons.ContactMail />}
          >
            Contacts
          </Button>
          <Modal
            open={openModal}
            setOpen={() => setOpenModal(false)}
            size="xl"
            className={classes.modal}
            title={<Grid>
              <Typography variant='h5' style={{ paddingLeft: 0 }}>Estimate Contacts</Typography>
              <Typography style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}>Contacts you add will be added to the contact list.</Typography>
            </Grid>
            }
          >
            <AddContactModal setOpenModal={setOpenModal} />
          </Modal>
        </Box>
        {/* </Box> */}
      </Grid>
    </Box>
  )
}

export default EstimateHeader
