/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import env from "@beam-australia/react-env"
import { diff } from 'deep-object-diff'

import { toast } from "react-toastify"
import { Box, Button, CircularProgress, FormControlLabel, Grid, Link, Radio, RadioGroup, Typography } from "@material-ui/core"
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe, StripeError } from "@stripe/stripe-js"

import { icons, stripeError } from "assets"
import { newOrderMaintenanceActions, promosActions, subscriptionActions, subscriptionsActions, userActions } from "ducks/actions"
import { getNewOrderMaintenanceValue, getSubscription, getUser, getNewOrderMaintenanceToCreate, isAuthorized, getTerritory } from "ducks/selectors"

import PaymentMethods from "components/pages/User/Settings/PaymentMethods"
import { PaymentMethod } from "ducks/user/types"
import NewCardForm from "components/UI/CustomUI/molecules/NewCardForm"
import { CONTACT_ROLE, getDifferenceBetweenObjects, history, isEmpty } from "helpers/index"

import useStyles from './styles'
import { isMobile } from "react-device-detect"
import OrderSummary from "../components/OrderSummary"
import { UpdateRequest } from "api/types"
import { getSubscriptions } from "ducks/subscriptions/selectors"
import { SubscriptionsPlan } from "ducks/types"
import { round } from "lodash"
import ArrowOutwardIcon from '@material-ui/icons/ArrowUpwardRounded'
import { IconBanner } from "../Onboarding/components/icons"
import { NewMaintenanceModalsContext } from "../../Modals/context"
import { d } from "msw/lib/glossary-dc3fd077"


const stripeKey = env('STRIPE_PUBLIC_KEY') ?? ''
const stripePromise = loadStripe(stripeKey)

interface ErrorMessageI {
  error?: {
    title: string, subtitle: string
  }
}

const ErrorMessage = ({ error }: ErrorMessageI) => {
  const classes = useStyles()

  if (!error) return null;

  return <Grid item xs={12} className={classes.errorMessage}>
    <img src={stripeError} alt='' />
    <Box className={classes.messageContainer}>
      <Typography variant='caption' className={classes.errorMessageTitle}>
        {error.title}
      </Typography>
      <Typography variant='caption' className={classes.errorMessageSubtitle}>
        {error.subtitle}
      </Typography>
    </Box>
  </Grid>
}

const PaymentContent = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const push = history.usePush()

  const {
    dispatch: dispatchContext
  } = useContext(NewMaintenanceModalsContext)


  const { paymentMethods } = useSelector(getUser)

  const subscription = useSelector(getSubscription())
  const subscriptionsPlan = useSelector(getSubscriptions("plans"))

  const orderMaintenance = useSelector(getNewOrderMaintenanceToCreate())
  const promoCode = useSelector(getNewOrderMaintenanceValue('promoCode'))
  const clientType = useSelector(getNewOrderMaintenanceValue('clientType'))
  const gift = useSelector(getNewOrderMaintenanceValue('gift'))
  const promo = useSelector(getNewOrderMaintenanceValue('promo'))
  const isGift = useSelector(getNewOrderMaintenanceValue('gift'))
  const protectPlan = useSelector(getNewOrderMaintenanceValue('protectPlan'))
  const price = useSelector(getNewOrderMaintenanceValue('price'))
  const priceLoading = useSelector(getNewOrderMaintenanceValue('priceLoading'))
  const mainContact = useSelector(getNewOrderMaintenanceValue('mainContact'))
  const isAgent = [CONTACT_ROLE.LISTING_AGENT].includes(clientType)
  const authorized = useSelector(isAuthorized)
  const territory = useSelector(getTerritory())

  const [cardIsCompleted, setCardIsCompleted] = useState(false)
  const [plansToMap, setPlansToMap] = useState<SubscriptionsPlan[]>(subscriptionsPlan || [])

  const defaultPaymentMethod = paymentMethods && paymentMethods.find(payment => payment?.isDefault)
  const [paymentInfo, setPaymentInfo] = useState<PaymentMethod | null>(null)
  const [optionSelected, setOptionSelected] = useState(subscriptionsPlan?.length ? subscriptionsPlan[0].id : null)

  useEffect(() => {
    defaultPaymentMethod && setPaymentInfo(defaultPaymentMethod)
  }, [paymentMethods])


  const [loading, setLoading] = useState(false)
  const [newCard, setNewCard] = useState(false)
  const [errorMessage, setErrorMessage] = useState<{ title: string, subtitle: string } | undefined>(undefined)
  const subscriptionIsFree = orderMaintenance?.price && promo?.amount ? round(orderMaintenance?.price - promo?.amount, 2) === 0 : false

  const isDisabled =
    (isMobile && isEmpty(paymentInfo))
    || loading
    || (isEmpty(paymentInfo) && !newCard)
    || ((!isMobile && newCard) && !cardIsCompleted && !subscriptionIsFree)

  const newCreditCardButton = useRef<HTMLInputElement>(null)

  const handleSuccess = () => {
    const clientTypeSaved = clientType
    toast.success('Payment Accepted!')
    push('maintenance/success')
    setLoading(false)
    dispatch(newOrderMaintenanceActions.clearOrderMaintenance())
    if (clientTypeSaved === CONTACT_ROLE.HOMEOWNER) {
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "showSurvey", value: true }))
    }
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "clientType", value: clientTypeSaved }))
  }

  const onChangePayment = (ev: ChangeEvent<HTMLInputElement>) => {
    setNewCard(false)
    const newSelected = paymentMethods[Number(ev.target.value)]
    setPaymentInfo(newSelected)
  }

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setLoading(true)
    setErrorMessage(undefined)

    const { clientSecret, ...orderMaintenanceToPatch } = orderMaintenance

    if (!isEmpty(subscription) && subscription?.id) {
      if (promo && orderMaintenance.price) {
        orderMaintenanceToPatch.price = orderMaintenance.price - promo.amount
      }

      const differences = diff(subscription, orderMaintenanceToPatch)

      const subscriptionDifferences: UpdateRequest = getDifferenceBetweenObjects(differences)

      //If payment failed and the user went back on steps and changed the subscription we PATCH the existing subscription
      if (subscriptionDifferences?.length) {
        dispatch(subscriptionActions.updateSubscription({ subscriptionId: subscription?.id, request: subscriptionDifferences }, (succ, subscription) => {
          if (!succ) {
            setLoading(false)
          }
          if (succ && (paymentInfo?.id || paymentInfo?.stripeId)) {
            //if subscription is created but last payment failed we try again
            if (newCard && !isMobile) newCreditCardButton?.current?.click()
            else paySubscription(subscription?.clientSecret as string)
          }
        }))
      } else {
        if (newCard && !isMobile) newCreditCardButton?.current?.click()
        else paySubscription(orderMaintenance?.clientSecret || subscription?.clientSecret)
      }
    } else {
      let newData = {
        ...orderMaintenance
      } as any
      delete newData.address;
      delete newData.mainContact;
      delete newData.serviceable;
      delete newData.clientType;
      delete newData.preferredCommunicationPhone;
      delete newData.preferredCommunicationText;
      delete newData.preferredCommunicationMail;
      delete newData.disableNext;

      if (!gift) {
        newData.giftBox = null;
      }

      dispatch(subscriptionActions.createSubscription({ ...newData, homeowner: { ...orderMaintenance.homeowner, type: 'Client' }, purchaser: { ...orderMaintenance.purchaser, type: 'Client', clientType: mainContact.userClientType } }, (succ, clientSecret) => {
        if (succ) {
          if (!clientSecret) {
            handleSuccess()
          }
          if (newCard) {
            newCreditCardButton?.current?.click()
          } else {
            clientSecret && paySubscription(clientSecret)
          }
        } else {
          setLoading(false)
        }
      }))
    }

    if (!stripe || !elements || loading) return
  }

  function handleStripeErrorMessage(error: StripeError) {
    switch (error.code) {
      case "payment_intent_incompatible_payment_method":
        setErrorMessage({ title: "Whoops!", subtitle: "Looks like you haven't attached a payment method. Please attach a card and then complete your purchase." })
        return;
      case "card_declined":
        if (error.decline_code === "insufficient_funds") {
          setErrorMessage({ title: "Whoops!", subtitle: "Looks like you haven't attached a payment method. Please attach a card and then complete your purchase." })
        } else {
          setErrorMessage({ title: "Whoops!", subtitle: error.message || "Looks like you we have an error. Please try again" })
        }
        return;
      default:
        setErrorMessage({ title: "Whoops!", subtitle: error.message || "Looks like you we have an error. Please try again" })
        return;
    }
  }

  const paySubscription = async (clientSecret: string, paymentInfoId?: string) => {
    if (subscriptionIsFree) {
      handleSuccess()
    }
    if (clientSecret && stripe) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentInfoId ? paymentInfoId : (paymentInfo?.stripeId || paymentInfo?.id),
      })
      if (paymentIntent?.status === "succeeded") {
        handleSuccess()
      }
      if (error) {
        setLoading(false)
        handleStripeErrorMessage(error)
      }
    }
  }

  const handleCreatedPaymentMethodSuccess = (paymentMethod: any) => {
    setPaymentInfo({ ...paymentMethod, stripeId: paymentMethod.id })
    if (authorized) {
      paymentMethod?.id && dispatch(userActions.updateUserList({
        attr: 'paymentMethods', opp: 'add', list: [{
          isDefault: true, stripeId: paymentMethod?.id
        }], showError: false
      }, (updateSucc) => {
        subscription?.clientSecret && paySubscription(subscription?.clientSecret, paymentMethod?.id)
        if (updateSucc) {
          dispatch(userActions.fetchCurrentUser(() => {
            // setLoading(false)
            setNewCard(false)
          }))
        } else {
          setLoading(false)
        }
      }))
    } else {
      subscription?.clientSecret && paySubscription(subscription?.clientSecret, paymentMethod?.id)
      setNewCard(false)
    }
  }

  const fetchPlans = useCallback(() => {
    dispatch(subscriptionsActions.fetchSubscriptionsPlans((fetchSuccess, plans) => {
      if (fetchSuccess) {
        if (plans?.length) {
          const plan = plans[0]
          dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValues({
            attrs: {
              services: plan?.services,
              protectPlan: plan?.protectPlan,
              title: plan?.title,
              price: plan?.price,
              stripeProductPriceId: plan?.stripeProductPriceId,
              servicePeriodMonths: plan?.servicePeriodMonths,
            }
          }))
          if (!isGift && plan?.promoCodeForClient) {
            dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'promoCode', value: plan?.promoCodeForClient }))
          }
        }
      }
    }))
  }, [dispatch])

  useEffect(() => {
    fetchPlans()
  }, [fetchPlans])

  useEffect(() => {
    if (!paymentMethods?.length) setNewCard(true)
  }, [])


  const handleChange = (event: any) => {
    setOptionSelected(event.target.value)

    const planSelected = subscriptionsPlan && subscriptionsPlan.find((plan: any) => plan.id === event.target.value)
    if (promoCode && planSelected?.stripeProductPriceId) {
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'priceLoading', value: true }))

      dispatch(promosActions.getPromo({
        promoCode,
        stripeProductPriceId: planSelected.stripeProductPriceId,
      }, (succ, promo) => {
        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'priceLoading', value: false }))
        if (succ && promo) {
          dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'promo', value: promo }))
        } else {
          dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'promo', value: null }))
          dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'promoCode', value: null }))
        }
      }))
    }

    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValues({
      attrs: {
        services: planSelected?.services,
        protectPlan: planSelected?.protectPlan,
        title: planSelected?.title,
        price: planSelected?.price,
        stripeProductPriceId: planSelected?.stripeProductPriceId,
        servicePeriodMonths: planSelected?.servicePeriodMonths,
        promoCode: !isGift && planSelected?.promoCodeForClient ? planSelected?.promoCodeForClient : promoCode,
      }
    }))
  }

  useEffect(() => {
    // const isValidTerrirory = subscriptionsPlan.map()
    let auxPlans: SubscriptionsPlan[] = []
    if (subscriptionsPlan?.length) {
      subscriptionsPlan.map((plan: SubscriptionsPlan) => {
        if (plan.validTerritoryIds.includes(territory?.id || "")) {
          auxPlans.push(plan)
        }
      })
    }
    setPlansToMap(auxPlans)
  }, [subscriptionsPlan, territory])

  useEffect(() => {
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "activeStepProgress", value: isAgent ? 5 : 3 }))
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "totalStepProgress", value: isAgent ? 5 : 3 }))
  }, [])


  return (
    <div style={{ height: "fit-content" }}>
      <Box className={classes.banner} mt="1.56rem">
        <Typography className={classes.bannerText}>
          Get to know all the membership details!
        </Typography>
        <Box style={{ cursor: "pointer" }} onClick={() => {
          dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
          dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'FAQS' })
        }}>
          <Typography className={classes.bannerTextFAQ}>
            FAQs! <ArrowOutwardIcon className={classes.icon} />
          </Typography>
        </Box>
        <IconBanner className={classes.iconBanner} />
      </Box>
      <Grid container spacing={2} className={classes.paymentContainer}>
        {/* {(loading || loadingPlans) && <FullScreenLoader opacity={0.5} />} */}
        <Grid item lg={7}>
          {!isGift &&
            <Grid item className={classes.planSelector}>
              <Typography className={classes.nextTypo} variant={isMobile ? 'h5' : 'h6'}>Payment Options</Typography>
              <RadioGroup value={optionSelected} onChange={handleChange} className={`${classes.radioContainer}`}>
                {plansToMap.map((plan: SubscriptionsPlan, index: number) =>
                  <FormControlLabel
                    key={index}
                    value={plan.id}
                    control={
                      <Radio
                        checkedIcon={
                          <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
                        }
                        icon={<icons.RadioButtonUnchecked />}
                      />
                    }
                    label={
                      <Grid>
                        <Typography className={classes.radioTitle}>{plan.description}</Typography>
                        <Typography className={classes.radioSubtitle}>${plan.price}/{plan.description === "Annual" ? 'year' : 'quarterly'}</Typography>
                      </Grid>
                    }
                    className={`${classes.spacedItems} ${classes.plan} ${optionSelected === plan.id ? classes.selectedPlan : ''}`}
                  />
                )}
              </RadioGroup>
              <Grid className={classes.disclaimer}>
                <Typography className={classes.disclaimerText}>
                  <strong>Terms</strong>: Membership will auto-renew unless cancelled by the homeowner.  For the quarterly payment option, after the initial payment today, you will be automatically charged 75 days later, and 90 days later thereafter.
                </Typography>
                <ArrowOutwardIcon className={classes.icon} style={{ color: "#423C24" }} />
                {/*   <icons.CallMade htmlColor="var(--bosscat-blue-600)" className={classes.disclaimerIcon} /> */}
              </Grid>
            </Grid>
          }
          <Typography className={`${classes.title} ${classes.nextTypo}`} variant={isMobile ? 'h5' : 'h6'}> {isMobile ? 'Payment' : 'Credit card information'} </Typography>

          <Grid item>
            {paymentMethods?.length > 0 &&
              <Grid item container direction="column">
                <Grid item className={classes.paymentMethodContainer}>
                  <PaymentMethods
                    onChange={onChangePayment}
                    selected={!newCard && paymentInfo}
                  />
                </Grid>
              </Grid>
            }
            <Box className={`${classes.spacedItems} ${!isMobile ? classes.paymentMethod : ''} ${classes.addNewCard}`} style={errorMessage ? { borderColor: "#E01F1F" } : {}}>
              <Grid item className={classes.newCardFormContainer}>
                <NewCardForm
                  submitButtonRef={newCreditCardButton}
                  onSuccess={handleCreatedPaymentMethodSuccess}
                  onFocus={() => {
                    setNewCard(true)
                  }}
                  customErrorMessage={(error) => <ErrorMessage error={{ title: "Whoops!", subtitle: error }} />}
                  finishLoading={() => {
                    // setLoading(false)
                  }}
                  type={isMobile ? 'modal' : 'inline'}
                  showButton={isMobile}
                  onChange={(isCompleted) => setCardIsCompleted(isCompleted)}
                />
                <ErrorMessage error={errorMessage} />
              </Grid>
              {!isMobile &&
                <Grid item>
                  <Radio
                    checked={newCard}
                    classes={{ checked: classes['Mui-checked'] }}
                    onChange={() => { setNewCard(true) }}
                  />
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>

        <Grid item lg={5} container spacing={3} className={classes.orderSummaryFlex}>
          <Grid item spacing={3} lg={12} style={{ paddingRight: 0 }}>
            <OrderSummary />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent="space-between" className={`${classes.buttonsContainer} ${authorized ? classes.buttonContainerAuth : ""}`}>
        <Button
          onClick={() => {
            dispatch(newOrderMaintenanceActions.goBackOrderMaintenance())
          }}
          className={classes.buttonBack}
          disabled={loading}
          startIcon={<icons.ArrowBack className={classes.buttonSubmitIcon} />}>
          Back
        </Button>
        <Button
          onClick={handleSubmit}
          className={classes.buttonSubmit}
          disabled={isDisabled}
          style={isDisabled ? { color: "rgba(0, 0, 0, 0.26)", backgroundColor: "rgba(0, 0, 0, 0.12)" } : {}}
          endIcon={!loading && <icons.ArrowForward className={classes.buttonSubmitIcon} />}>
          {loading || priceLoading ? <CircularProgress size="1.5rem" className={classes.spinner} /> : `Pay $${round(price - (promo?.amount || 0), 2)}/${protectPlan === "PLAN_A" ? 'year' : 'quarter'}`}
        </Button>
      </Grid>
    </div>
  )
}

const Payment = () => {
  return (
    <Elements stripe={stripePromise} options={{
      fonts: [{
        src: 'url(/assets/fonts/Lato-Regular.ttf) format(ttf)',
        family: 'Lato-Normal'
      }],
      locale: 'auto'
    }} >
      <PaymentContent />
    </Elements>
  )
}

export default Payment
