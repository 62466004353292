/* eslint-disable react-hooks/exhaustive-deps */
import useStyles from './styles'
import { Box } from '../../../../UI'
import Work from './Work'
import { useState } from 'react'
import UtmParamsWrapper from '../../../Auth/UtmParamsWrapper'
import { logo } from 'assets/index'
import { useSelector } from 'react-redux'
import { isAuthorized } from 'ducks/selectors'

const Account = () => {
  const classes = useStyles()
  const [showRegister, setShowRegister] = useState(false)
  const authorized = useSelector(isAuthorized)

  return (
    <Box className={classes.root} aria-label='estimate-account'>
      <Box className={classes.content}>
        {!authorized && <img src={logo} alt="" style={{ width: '225px' }} />}
        {/* 
        {showRegister ? (
          <Register />
        ) : ( */}
        <UtmParamsWrapper>
          <Work /* setShowRegister={setShowRegister} */ />
        </UtmParamsWrapper>
        {/*   )} */}
      </Box>
    </Box>
  )
}

export default Account
