/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from 'helpers'
import { Punchlist } from 'api'
import { estimateTypes, FetchEstimateAction, ApprovalEstimateAction, ShareEstimateAction, UpdateEstimateByPathAction } from './types'
import { estimateActions } from 'ducks/actions'
import { getEstimate } from './selectors'
import * as Sentry from '@sentry/react'
import { Estimate } from 'ducks/types'
import { UpdateRequest } from 'api/types'

export function* fetchEstimate({ payload, callback }: FetchEstimateAction): SagaIterator {
  let success = false
  try {
    const estimate = yield call(Punchlist.estimates.getEstimateById, payload)

    if (estimate) {
      yield put(estimateActions.setEstimate(estimate))
    }
    success = true
    if (callback) yield call(callback, success, estimate)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* shareEstimate({ payload, callback }: ShareEstimateAction): SagaIterator {
  try {
    const { id } = yield select(getEstimate())
    yield call(Punchlist.estimates.share, id, payload)
    yield call(toast.success, 'The estimate was shared successfully!')
    if (callback) yield call(callback, true)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateByPath({
  payload,
  callback
}: UpdateEstimateByPathAction): SagaIterator {
  try {
    const { id: estimateId } = yield select(getEstimate())

    if (estimateId) {
      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        payload
      )

      if (!isEmpty(estimate)) {
        yield put(estimateActions.setEstimate(estimate))
        if (callback) yield call(callback, true)
      }


    }
    if (callback) yield call(callback, false)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* approval({ payload, callback }: ApprovalEstimateAction): SagaIterator {
  let success = false
  try {
    const res = yield call(Punchlist.estimates.approval, payload.id as string, payload)

    if (res) { success = true }
    if (callback) yield call(callback, success, res.approvalStatus)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('approve the estimate'))
    if (callback) yield call(callback, false, '')
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(estimateTypes.FETCH_ESTIMATE, fetchEstimate)
  yield takeLatest(estimateTypes.APPROVAL, approval)
  yield takeLatest(estimateTypes.SHARE_ESTIMATE, shareEstimate)
  yield takeLatest(estimateTypes.UPDATE_ESTIMATE_BY_PATH, updateEstimateByPath)
}
