import { FC } from 'react'
import { DetailsProps } from './types'
import useStyles from './styles'
import { blueHouseLogo } from '../../../../../assets'
import { Typography, Box, Grid } from '@material-ui/core'
import DownloadButton from '../DownloadButton'

const InvoiceHeader: FC<DetailsProps> = ({
  title, addressLine1, addressLine2, mail, invoice, phone, onClick
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} id='user-and-item-information'>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
          <Box className={classes.rows}>
            <img src={blueHouseLogo} alt='' className={classes.logo} />
            <Box className={classes.column}>
              <Typography variant='subtitle2' className={classes.title}> {title} </Typography>
              <Typography variant='caption' className={classes.text}> {addressLine1} </Typography>
              <Typography variant='caption' className={classes.text}> {addressLine2} </Typography>
              <Typography variant='caption' className={classes.textHigh}> {phone} </Typography>
              <Typography variant='caption' className={classes.textHigh}> {mail} </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box className={classes.rows} style={{ justifyContent: 'flex-end' }}>
            <Typography variant='subtitle2' className={classes.totalValue}> {invoice} </Typography>
            <DownloadButton
              text='PDF'
              onClick={onClick}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default InvoiceHeader
