import { FC, useState, useEffect, useCallback } from 'react'
import { Box, SideMenu, UserMenu } from '../../UI'
import { icons, calendar } from '../../../assets'
import useStyles from './styles'
import { TemplateProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, isAuthorized } from '../../../ducks/selectors'
import { USER_TYPE } from 'helpers/constants'
import { subscriptionsActions } from 'ducks/actions'
import { getSubscriptionsValue, territoryIsAvailableForMaintenance } from 'ducks/subscriptions/selectors'

const Template: FC<TemplateProps> = ({ children, showEstimateOptions = true }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const [avatar, setAvatar] = useState(user.picture)
  const authorized = useSelector(isAuthorized)
  const cityIsAvailableForMaintenance = useSelector(territoryIsAvailableForMaintenance(user.territory?.id))
  const subscriptionsPlans = useSelector(getSubscriptionsValue('plans'))
  const showOrderMaintenance = [USER_TYPE.BROKER, USER_TYPE.INSPECTOR, USER_TYPE.INSTITUTIONAL, USER_TYPE.HOME_BUYER, USER_TYPE.HOMEOWNER].includes(user.clientType)

  const updateUserAvatar = (
    prop: CustomEventInit<{
      newPicture: string
    }>
  ) => {
    setAvatar(prop.detail?.newPicture ?? '')
  }

  useEffect(() => {
    setAvatar(user.picture)
    subscriptionsPlans.length <= 0 && authorized && fetchPlans()
  }, [user])

  const fetchPlans = useCallback(() => {
    dispatch(subscriptionsActions.fetchSubscriptionsPlans((fetchSuccess, plans) => {
    }))
  }, [dispatch])

  const options = [
    {
      text: 'Dashboard',
      title: 'Dashboard',
      icon: icons.Apps,
      path: 'dashboard',
    },
    {
      text: 'Estimates',
      title: 'Estimates',
      icon: icons.Assignment,
      path: 'estimates',
    },
    {
      text: 'Jobs',
      title: 'Jobs',
      icon: icons.Build,
      path: 'jobs'
    },
    {
      text: 'Payments',
      title: 'Payments',
      icon: icons.CreditCard,
      path: 'payments',
    }
     /*,
    {
      text: 'Properties',
      title: 'Properties',
      icon: icons.House,
      path: 'properties'
    } */,
  ]

  const estimateOptions = {
    icon: calendar,
    title: 'Get an Estimate',
    options: [
      {
        text: 'Upload Inspection PDF',
        variant: 'contained' as 'contained',
        //path: 'getEstimate'
        path: 'estimate?authorized=true',
      },
      {
        text: 'Create Repair List',
        variant: 'outlined' as 'outlined',
        //path: 'repairList'
        path: 'estimate?authorized=true',
      },
    ],
  }

  useEffect(() => {
    document.addEventListener('profilePictureChange', updateUserAvatar)
    return document.removeEventListener('profilePictureChange', () => { })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box className={authorized ? classes.root : ''}>
      {authorized && <UserMenu avatar={avatar} />}
      {authorized && <SideMenu options={options} estimateOptions={showEstimateOptions ? estimateOptions : undefined} showOrderMaintenance={showOrderMaintenance} />}
      {children}
    </Box>
  )
}

export default Template
