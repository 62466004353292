import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      margin: '30px 0px 10px 0px'
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    rows: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 12px',
        gap: '.5rem',
        justifyContent: 'space-between'
      }
    },
    rowRight: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-end !important',
      justifyContent: 'flex-end !important',
      [theme.breakpoints.down('md')]: {
        alignContent: 'center !important',
        justifyContent: 'center !important'
      }
    },
    autocomplete: {
      width: '90px',
      height: '32px',
      margin: '0px 15px'
    },
    title: {
      fontFamily: 'Lato',
      fontWeight: 500,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 20px 0px 0px',
      color: 'var(--head-text-color)',
      minWidth: '60px',
      [theme.breakpoints.down('md')]: {
        minWidth: '20px',
        margin: '0px'
      }
    },
    subtitle: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--head-text-color)'
    },
    subtitleSelected: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--white-color)'
    },
    text: {
      minWidth: '106px',
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '5px 16px 0px 2px',
      color: 'var(--head-text-color)'
    },
    detail: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--bosscat-blue-600)'
    },
    total: {
      fontFamily: 'Lato',
      fontWeight: 700,
      lineHeight: '58px',
      fontSize: '24px',
      margin: '0px 0px 0px 0px',
      color: 'var(--head-text-color)'
    },
    totalValue: {
      fontFamily: 'Lato',
      fontWeight: 500,
      lineHeight: '58px',
      fontSize: '24px',
      margin: '0px 4px',
      color: 'var(--head-text-color)'
    },
    icon: {
      color: 'var(--bosscat-blue-600)'
    },
    logo: {
      height: '66px',
      margin: '0px 9px 0px 0px'
    },
    pdf: {
      height: '64px',
      width: '64px',
      [theme.breakpoints.down('md')]: {
        height: '44px',
        width: '44px'
      }
    },
    button: {
      height: '33px',
      width: '136px',
      minWidth: '136px',
      margin: '0px 2px',
      textTransform: 'none',
      border: '1px solid var(--bosscat-blue-600)',
      borderRadius: '8px',
      backgroundColor: 'var(--row-background)',
      '&:hover': {
        backgroundColor: 'var(--row-background)'
      },
      [theme.breakpoints.down('md')]: {
        width: '100px'
      }
    },
    combo: {
      height: '33px',
      width: '104px',
      minWidth: '100px',
      margin: '0px 6px',
      textTransform: 'none',
      borderRadius: '8px',
      backgroundColor: 'var(--row-background)',
      '&:hover': {
        backgroundColor: 'var(--row-background)'
      },
      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    },
    comboSelected: {
      height: '33px',
      width: '104px',
      minWidth: '100px',
      margin: '0px 6px',
      textTransform: 'none',
      borderRadius: '8px',
      backgroundColor: 'var(--bosscat-blue-600)',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-600)'
      },
      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    },
    iconCombo: {
      color: 'var(--head-text-color)'
    },
    iconSelected: {
      color: 'var(--white-color)'
    },
    switch: {
      width: '28px !important',
      height: '22px !important',
      padding: '2px 0px !important',
      // margin: '6px 5px',
      alignSelf: 'center',
      display: 'flex !important',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)'
        }
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        top: -16,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: 'var(--bosscat-blue-600)'
          }
        }
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
          duration: 200
        })
      },
      '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
      }
    }
  })
)
