import { createStyles, makeStyles, Theme as ThemeMUI } from "@material-ui/core";


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        root: {
            gap: '2rem',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                padding: '1rem',
            }
        },
        header: {
            background: '#263E7D',
            borderRadius: '8px',
            color: 'white',
            padding: '.5rem !important',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
            width: '100%',
        },
        headerTitle: {
            [theme.breakpoints.down('md')]: {
                width: '100%'
            }
        },
        Arrow: {
            transform: "rotate(45deg)",
            color: "#FFC633",
        },
        VipLink: {
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            color: "#FFC633",
            textTransform: 'initial',
            textDecoration: 'underline',
            fontFamily: 'LatoNormal',
            marginLeft: '1rem',
            [theme.breakpoints.down('md')]: {
                width: "100%",
                justifyContent: "flex-end",
                fontSize: "12px",
                marginTop: "8px"
            },
        },
        title: {
            fontFamily: 'LatoNormal',
            fontWeight: 500,
            fontSize: 30,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                marginBottom: '1rem'
            }
        },
        moranga: {
            fontFamily: 'LatoNormal',
            fontWeight: 500,
        },
        text: {
            fontFamily: 'LatoNormal',
            textAlign: 'center'
        },
        maintenanceButton: {
            width: 'fit-content',
            textTransform: 'none',
            background: 'var(--head-text-color)',
            color: 'var(--orange-300)',
            borderRadius: 8,
            fontFamily: 'LatoNormal',
            padding: '1rem',
            "@global": {
                '.MuiButton-label': {
                    paddingTop: 0
                }
            },
            [theme.breakpoints.down('sm')]: {
                padding: '1rem',
            },
            '&:hover': {
                background: 'var(--button-focus-color)'
            }
        },
        buttonContainer: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }
        },
        boxContainer: {
            gap: '1.5rem',
            flexWrap: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
            }
        },
        box: {
            flexWrap: 'nowrap',
            boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
            borderRadius: 8,
            padding: 16,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        spacing: {
            gap: '1rem'
        },
        boxImg: {
            width: 32,
            height: 32
        },
        boxTitle: {
            fontSize: 20,
            color: 'var(--bosscat-blue-600)',
            fontFamily: 'Lato'
        },
        boxSubtitle: {
            width: '80%'
        },
        perks: {
            display: 'flex',
            gap: '1rem',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
            }
        },
        perk: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        giftImg: {
            width: 160,
            borderRadius: 4
        },
        trustpilot: {
            background: '#EDFEF5',
            justifyContent: 'space-around',
            [theme.breakpoints.down('sm')]: {
                gap: '1rem',
            }
        },
        bold: {
            fontFamily: 'LatoBold'
        },
        trustpilotTitle: {
            fontSize: 24,
            lineHeight: 1,
            marginLeft: '.3rem'
        },
        star: {
            width: 25,
            height: 25
        },
        starWhite: {
            width: 25,
            height: 25,
            background: "#02B679",
            padding: '.2rem'
        },
        starContainer: {
            display: 'flex',
            gap: '.2rem'
        },
        reviews: {
            display: 'flex',
            gap: '.3rem'
        },
        container: {
            gap: '1rem',
            flexWrap: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
            }
        },
        teamTitle: {
            fontSize: 20
        },
        supportTeam: {
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                textAlign: 'center'
            }
        },
        supportTeamContainer: {
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center'
            }
        },
        supportTeamText: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        disclaimer: {
            color: '#72748D',
            fontSize: 16,
            textAlign: 'center',
            width: '70%',
            marginBottom: '1rem',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        }
    }))
