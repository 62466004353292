import { FC } from 'react'
import { Grid, Typography, Box, Avatar, Tag, Button } from '../../../../UI'
import { ItemProps } from './types'
import useStyles from './styles'
import { newEstimate, newJob } from '../../../../../assets'
import {
  RESOURCE_TYPE,
  history,
  ESTIMATE_PUBLIC_STATUS,
} from '../../../../../helpers'
import { capitalize } from 'lodash'
import { useIsXsScreen } from '../../../../../hooks'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const DashboardItem: FC<ItemProps> = ({ item }) => {
  const classes = useStyles()
  const [xsScreen] = useIsXsScreen()
  const push = history.usePush()

  const isEstimate = item.resourceType === RESOURCE_TYPE.ESTIMATE

  const viewUrl = isEstimate ? 'p/estimates/' : 'p/jobs/'

  const disabledView = isEstimate
    ? item.tag === ESTIMATE_PUBLIC_STATUS.IN_PROGRESS
    : false

  return (
    <Grid container item xs={12} spacing={6} className={classes.root}>
      <Grid item lg={1} xs={12} className={classes.itemContainer}>
        <Typography className={classes.smallInfo}>Ref:</Typography>
        <Typography className={classes.dateInfo}>{item.publicId}</Typography>
      </Grid>
      <Grid item lg={3} xs={12}>
        <Box className={classes.titleContainer}>
          <Avatar
            alt=""
            src={item.resourceType === RESOURCE_TYPE.ESTIMATE ? newEstimate : newJob}
            className={classes.iconJob}
            variant="square"
          />
          <Box className={classes.titleDetail}>
            <Typography className={classes.dateInfo}>
              <Typography
                component="span"
                className={classes.dateInfo}
                style={{ color: 'var(--bosscat-blue-600)' }}
              >
                {capitalize(item.resourceType) + ":"}
              </Typography>
              {` ${item.title}`}
            </Typography>
            <Typography className={classes.smallInfo}>
              {item.address}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {xsScreen && (
        <Grid item xs={12}>
          <Tag type={item.tag} className={classes.status} variation="body1" />
        </Grid>
      )}
      <Grid item lg={2} xs={12} className={classes.itemContainer}>
        <Typography className={classes.smallInfo}>Initiated:</Typography>
        <Typography className={classes.dateInfo}>
          {item.initDate && item.initDate !== '' ? item.initDate : '-'}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.itemContainer}>
        <Typography className={classes.smallInfo}>Completed:</Typography>
        <Typography className={classes.dateInfo}>
          {item.completedDate && item.completedDate !== ''
            ? item.completedDate
            : '-'}
        </Typography>
      </Grid>
      {!xsScreen && (
        <Grid item xs={2}>
          <Tag type={item.tag} className={classes.status} variation="body1" />
        </Grid>
      )}
      <Grid
        item
        xs={2}
        style={{ display: 'flex', justifyContent: 'center' }}
        className={classes.buttonContainer}
      >
        <Button
          type="submit"
          variant="contained"
          disabled={disabledView}
          className={classes.button}
          onClick={() => {
            push(`${viewUrl}${item.resourceId}`)
          }}
          endIcon={<ArrowForwardIcon style={{ width: '14px', height: '14px' }} />}
        >
          View
        </Button>
      </Grid>
    </Grid>
  )
}

export default DashboardItem
