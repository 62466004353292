import { StatusType } from 'components/pages/Desktop/OrderMaintenance/components/GiftServiceItem'
import {
  APPROVAL_TYPE,
  CONTACT_ROLE,
  ITEM_CATEGORY,
  ITEM_STATUS,
  ESTIMATE_PUBLIC_STATUS,
  ESTIMATE_STATUS,
  FILE_TYPE,
  FORM_TYPE,
  JOB_PUBLIC_STATUS,
  JOB_STATUS,
  REPAIR_TIMELINE,
  JOB_ITEM_PUBLIC_STATUS,
  PAYMENT_TYPE,
  PAYMENT_RESOURCE_TYPE,
  PAYMENT_STATUS,
  JOB_PAYMENT_STATUS,
  USER_TYPE,
  PROTECT_PLAN_TYPES,
  SUSCRIPTION_STATUS,
} from '../helpers'
import { UserType } from './user/types'

export interface PunchlistFile {
  id: string
  name: string
  description: string
  fileType: FILE_TYPE
  fileUrl: string
  uploadedOn: number
  uploadedBy: string
  fileName?: string;
}

export interface Item {
  orderId: string
  orderPublicId: string
  estimateId: string
  estimatePublicId: string
  itemId: string
  title: string
  category: ITEM_CATEGORY
  inspectionReportReference: string
  quantity: number
  laborRate: number
  unitLaborHours: number
  unitMaterialCost: number
  margin: number
  status: ITEM_STATUS
  publicJobStatus: JOB_ITEM_PUBLIC_STATUS
  disclaimer: string
  severity: string
  inspectionReportNote: string
  requestedRepairNote: string
  fieldNote: string
  punchlistTechNote: string
  imageFiles: Array<PunchlistFile>
  siteVisitId: string
  estimatedPrice: number
  siteVisitNotes: string
  siteVisitComplete: boolean
  totalPrice: number
  totalCost: number
  totalLaborHours: number
  totalLaborCost: number
  totalMaterialCost: number
  unitCost: number
  unitPrice: number
  measurement: string
  uploaded?: boolean
  editable?: boolean
  htmlFiles?: File[]
  publicRepairNote?: string
  maxPriceRange?: number
  minPriceRange?: number
}

export interface Job {
  id: string
  estimateId: string
  properties: EstimateProperties
  status: JOB_STATUS
  publicStatus: JOB_PUBLIC_STATUS
  accessDetails: string
  approvalNotes: string
  payAtClose: boolean
  inCollection: boolean
  writtenOff: boolean
  percentItemsComplete: number
  createdOn: number
  createdBy: string
  dueOn: number
  startedOn: number
  expectedCompletedOn: number
  preferredCompletedOn: number
  completedOn: number
  completedBy: string
  lastModifiedOn: number
  lastModifiedBy: string
  paymentStatus: JOB_PAYMENT_STATUS
}

export interface Order {
  orderId: string;
  orderName: string;
  orderPublicId: string;
  statusType: string;
  estimateId: string;
  estimatePublicId: string;
  scheduledStartTimestamp: number;
  scheduledEndTimestamp: number;
  bidPrice: number;
  totalValue: null | number;
  totalCost: null | number;
  vendorId: string;
  companyName: string;
  companyContact: string;
  assigneeId: null | number;
  assigneeName: null | string;
  address: Address;
  checkedInBy: null | string;
  checkedInTimestamp: null | number;
  completedTimestamp: null | number;
  completedBy: null | string;
  submittedTimestamp: null | number;
  type: string;
  csmAcceptanceTimestamp: null | number;
  acceptedBy: null | string;
  adminFlags: null | string;
  dispatchedTimestamp: null | number;
  dispatchedBy: null | string;
  pricingModel: PricingModel;
  nextTask: null | string;
  dispatchNotes: null | string;
  holdNotes: null | string;
  vendorAcceptedTimestamp: number | number;
  incentives: null | string;
  vendorPaymentTimestamp: null | number;
  vendorPaidBy: null | string;
  paymentType: null | string;
  completedByName: null | string;
  acceptedByName: null | string;
  vendorPaidByName: null | string;
  paymentAmount: null | string;
  assignedByName: null | string;
  assignedByAddress: null | string;
  assignedByPicture: null | string;
  incentiveTotalAmount: number;
  incentiveStartDeadline: null | string;
}

export interface PricingModel {
  itemMarginType: string;
  itemMarginValue: null;
  autoUpdate: null;
  items: any[];
}


export interface EstimateContact {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  role: CONTACT_ROLE | USER_TYPE
  sendEstimate: boolean
  isPayer: boolean
  isScheduler: boolean
  approvalType: APPROVAL_TYPE
  approvedOn: number
  fullName: string
  preferredCommunicationMethods: Array<string>
  insertedOn?: number
  zipCode?: string
  tosAccepted?: boolean
  companyName?: boolean
  institutionalRole?: string
  userClientType?: string
}

export interface Address {
  city: string
  state: string
  line_1: string
  line_2: string
  zipCode: string
  latitude: number
  longitude: number
  county?: string
  country: string
}

export interface Property extends Address {
  id: string
  userId: string
  fullAddress: string
  createdOn: number
  createdBy: string
}

export interface Territory {
  id: string
  title: string
  phone: string
  email: string
  zipCodes: string[]
  visible: boolean
  serviceable: boolean
  latitude: number
  longitude: number
}

export interface EstimateProperties {
  id?: string
  address: Address
  files?: PunchlistFile[]
  territory?: Territory
  publicId?: string
  affiliate?: string
  closingDate?: number | null
  contacts?: Array<Partial<EstimateContact>>
  userId: string
  clientNotes?: string
  totalValue?: number
  taxRate?: number
  taxAmount?: number
  discount?: number
  minimum?: number
  evaluationFee?: number
  payAtClose?: boolean
  accessDetails?: string
  preferredCompletedOn?: number | null
  invoiceId?: string
  repairTimeline?: REPAIR_TIMELINE
  propertyToBeSold?: boolean
  nar?: boolean | null
  totalWithTax?: number | null
}

export interface Estimate {
  id: string
  properties: Partial<EstimateProperties>
  status: ESTIMATE_STATUS
  publicStatus: ESTIMATE_PUBLIC_STATUS
  createdOn: number
  createdBy: string
  lastModifiedOn: number
  lastModifiedBy: string
  dueOn: number
  deliveredOn: number
  deliveryServiceId: string
  deliveryServiceCost: number
  formType: FORM_TYPE
  repairTimeline: REPAIR_TIMELINE
  approvedOn: number
  approvedBy: string
  approvalStatus: string
  propertyToBeSold: boolean
  clientNotes: string
  jobNeedsAttention?: boolean
  minPriceRange?: number | null
  maxPriceRange?: number | null

  shouldUpdateRole?: boolean
  success?: true
  sharedByEmail?: string
  sharedByName?: string
  marketingProperties?: {
    source?: string;
    medium?: string;
    campaign?: string;
    content?: string;
    promo?: string;
  }
}

export interface PriceItem {
  id: string
  publicTitle: string
  margin: number
  unitCost: number
  unitPrice: number
  laborRate: number
  unitLaborHours: number
  unitMaterialCost: number
  title: string
  category: ITEM_CATEGORY
  severity: string
  disclaimer: string
}

export interface Payment {
  id: string
  createdOn: number
  createdBy: string
  type: PAYMENT_TYPE
  amount: number
  resourceId: string
  resourceType: PAYMENT_RESOURCE_TYPE
  status: PAYMENT_STATUS
  stripeSourceId: string
  stripeTransactionId: string
  stripePaymentId: string
  stripeFee: number
  payerEmail: string
  memo: string
  address: string
  isRefund: boolean
}

export interface PaymentOption {
  id: string
  title: string
  date: number
  day: string
  subtitle: string
  enable: boolean
  best: boolean
  price: number
  days: number
}

export interface EstimateService {
  id: string
  title: string
  date: number
  days: number
  cost: number
  serviceable: true
  vip: true
}

export interface Invoice {
  id: string
  total: number
  subtotal: number
  preTaxTotal: number
  tax: number
  taxRate: number
  amountDue: number
  amountPaid: number
  discount: number
  discountRate: number
  subtotalCompleted: number
  subtotalPending: number
  currency: string
  createdOn: number
  dueDate: number
  resourceId: string
  resourceType: string
  address: Address
  preparedFor: Partial<EstimateContact>
  outstandingBalance: number
}


export interface GiftBox {
  insertedOn: number
  message: string
  shippingAddress: Address
  trackingId: string
  deliveryDate: number
  expeditedShippingCost: number
}

export type Subscription = {
  id: string
  protectPlan: PROTECT_PLAN_TYPES | null
  title: string
  services: SubscriptionsPlanService[]
  homeowner: Partial<UserType> | null
  purchaser: UserType | null
  propertyAddress: Address | null
  files: PunchlistFile[]
  status: SUSCRIPTION_STATUS | null
  price: number | null
  insertedOn: number | null
  expiresOn: number | null
  giftBox: GiftBox | null
  renewals: number | null
  gift: boolean
  stripeProductPriceId: string
  clientSecret: string
  servicePeriodMonths?: number
}

export type SubscriptionsPlan = {
  id: string
  protectPlan: PROTECT_PLAN_TYPES | null
  title: string
  description: string,
  price: number,
  stripeProductPriceId: string
  services: SubscriptionsPlanService[]
  validTerritoryIds: string[],
  servicePeriodMonths: number,
  promoCodeForClient: string
}

export interface SubscriptionsPlanService {
  title: string;
  startMonth: number;
  status: StatusType;
  orderId: string | null;
  jobId: string | null;
  requestedDate: string | null;
  scheduledDate: string | null;
}

export enum REFERRAL_TYPE {
  EMAIL = 'EMAIL',
  SALES_TEAM = 'SALES_TEAM',
  WEBSITE = 'WEBSITE',
  MARKETING = 'MARKETING',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  NAR = 'NAR',
  EMPLOYEE = 'EMPLOYEE',
  OTHER = 'OTHER',
}