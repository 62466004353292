import { FC, useEffect } from 'react'
import { Box, Typography, Button, Grid } from '../../../../../UI'
import useStyles from './styles'
import { uploadDocs, checkList, icons } from '../../../../../../assets'
import Inspection from './Inspection'
import Repairs from './Repairs'
import { CONTACT_ROLE, FORM_TYPE, history } from '../../../../../../helpers'
import { estimateActions, estimatesActions, newEstimateActions } from '../../../../../../ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNewEstimateStep,
  getNewEstimateSubstep,
  getNewEstimateValue,
  getTerritory,
  isAuthorized
} from '../../../../../../ducks/selectors'
import { useStepperContext } from '../../../../../../hooks/useStepperContext'

const Assignment: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const serviceable = useSelector(getNewEstimateValue('serviceable'))
  const formType = useSelector(getNewEstimateValue('formType'))
  const clientType = useSelector(getNewEstimateValue('clientType'))
  const isOther = [CONTACT_ROLE.LISTING_AGENT, CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)
  const isInstitutional = [CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)
  const showOptions = serviceable

  const activeOption = useSelector(getNewEstimateValue('formType'))
  const activeSubStep = useSelector(getNewEstimateSubstep())
  const activeStep = useSelector(getNewEstimateStep())
  const { goBack } = useStepperContext()
  const territory = useSelector(getTerritory())

  const isNar = useSelector(getNewEstimateValue('isNar'))
  const affiliate = useSelector(getNewEstimateValue('affiliate'))
  const authorized = useSelector(isAuthorized)

  const pushParams = history.usePushParams()


  useEffect(() => {
    activeStep === 1 && serviceable && !formType && pushParams(`?${authorized ? 'p' : 'o'}=2`)
  }, [activeStep, formType])

  useEffect(() => {
    dispatch(newEstimateActions.setNewEstimateValue({ attr: "activeStepProgress", value: 1 }))
    dispatch(newEstimateActions.setNewEstimateValue({ attr: "totalStepProgress", value: isOther ? isInstitutional ? 2 : 4 : 3 }))
  }, [])


  if (activeOption === FORM_TYPE.CLIENT_INSPECTION_REPORT) {
    return <Inspection goBack={() => {
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr: 'formType',
          value: undefined,
        })
      )
    }} />
  }
  if (activeOption === FORM_TYPE.CLIENT_REPAIR_LIST) {
    return <Repairs goBack={() => {
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr: 'formType',
          value: undefined,
        })
      )
    }} />
  }

  const handleSelectFormType = (type: FORM_TYPE) => {
    dispatch(
      estimatesActions.fetchEstimatesServices(
        {
          formType: type,
          serviceable: territory?.serviceable || false,
          isNar,
          affiliate
        }, () => { })
    )
    dispatch(
      newEstimateActions.updateEstimate(
        { formType: type },
        (success, estimate) => {
          if (success && estimate?.id) {
            dispatch(
              newEstimateActions.setNewEstimateValue({
                attr: 'formType',
                value: type
              })
            )
          }
        }
      )
    )
  }




  return (
    <>

      {showOptions ? (
        <>
          <Box style={{ margin: 'auto 0' }} className={classes.root} aria-label='steps-assignment'>
            <Typography variant='h4' className={classes.title}>
              Choose from two easy ways to get an estimate
            </Typography>
            <Box className={classes.options}>
              <Box className={classes.option}>
                <img src={checkList} alt='' className={classes.optionImage} />
                <Typography className={classes.optionTitle}>
                  UPLOAD AN INSPECTION REPORT (PDF FORMAT)
                </Typography>
                <Typography
                  className={classes.optionSubtitle}
                >
                  We’ll price out the inspector’s recommended repairs.
                </Typography>
                <Typography
                  className={classes.optionSubtitle}
                >
                  You’ll be able to upload a list, photos, or additional
                  documents as well.
                </Typography>
                <Button
                  variant='contained'
                  className={classes.optionButton}
                  onClick={() =>
                    handleSelectFormType(FORM_TYPE.CLIENT_INSPECTION_REPORT)}
                >
                  Upload inspection report
                </Button>
              </Box>
              <Typography className={classes.or}>OR</Typography>
              <Box className={classes.option}>
                <img src={uploadDocs} alt='' className={classes.optionImage} />
                <Typography className={classes.optionTitle}>
                  List your repairs and renovations
                </Typography>
                <Typography
                  variant='subtitle2'
                  className={classes.optionSubtitle}
                >
                  Describe your project and upload pictures/videos to get accurate
                  pricing.
                </Typography>
                <Typography
                  variant='subtitle2'
                  className={classes.optionSubtitle}
                >
                  <ul style={{ margin: '0' }}>
                    <li>Renovations</li>
                    <li>Remodels</li>
                    <li>Pre-listing improvements</li>
                    <li>Repair List</li>
                  </ul>
                </Typography>
                <Button
                  variant='contained'
                  className={classes.optionButton}
                  onClick={() =>
                    handleSelectFormType(FORM_TYPE.CLIENT_REPAIR_LIST)}
                >
                  Let’s go!
                </Button>
              </Box>
            </Box>
            <Grid
              item
              container
              xs={12}
              style={{ display: 'flex', marginTop: 2 }}
              justifyContent='space-between'
            >
              <Button
                type='submit'
                variant='outlined'
                size='small'
                className={classes.buttonBack}
                startIcon={<icons.ArrowBack />}
                onClick={goBack}
              // disabled={!serviceable}
              >
                Back
              </Button>
              {/*
              <Button
                type='submit'
                variant='contained'
                size='small'
                className={classes.buttonNext}
                endIcon={<icons.ArrowForward />}
                onClick={updateEstimate}
              >
                Upload
              </Button> */}
            </Grid>
          </Box>
        </>
      ) : (
        <Inspection goBack={() => {
          dispatch(
            newEstimateActions.setNewEstimateValue({
              attr: 'formType',
              value: null
            })
          )
          serviceable ? dispatch(newEstimateActions.setNewEstimateSubstep(activeSubStep - 1)) : dispatch(newEstimateActions.setNewEstimateStep(activeStep - 1))
        }}
        />
      )}
    </>
  )
}

export default Assignment
