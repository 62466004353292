import axios from '../axios'
import { ApiController } from './types'

const base = '/payments'

const apiController: ApiController = {
  getPayments: async (year) =>
    await axios
      .get(`${base}${year ? `?year=${year}` : ''}`)
      .then((response) => response.data),

  getPaymentById: async (paymentId) =>
    await axios.get(`${base}/${paymentId}`).then((response) => response.data),

  getStripeSecret: async (request) =>
    await axios
      .post(`${base}/stripe/secret`, request)
      .then((response) => response.data),

  getStripeSecretSetup: async () =>
    await axios
      .post(`${base}/stripe/secret/setup`)
      .then((response) => response.data),
}

export default apiController
