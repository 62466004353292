import { FC, useState } from 'react'
import clsx from 'clsx'
import { StepperProps } from './types'
import { Step, StepIconProps, StepConnector, StepLabel, withStyles, Box, StepButton, Button } from '../../..'
import { Stepper as MaterialStepper, Typography } from '../../../MaterialUI'
import useStyles from './styles'
import { bosscatLogo, icons } from '../../../../../assets'
import { ScrollWrapper } from '../../../../templates'
import { StepperContext } from '../../../../../hooks/useStepperContext'
import { useIsXsScreen, useIsSmScreen } from '../../../../../hooks'

const ColorlibConnector = withStyles({
  alternativeLabel: { zIndex: 0, top: 22 },
  active: { '& $line': { backgroundColor: 'var(--bosscat-blue-600)' } },
  completed: { '& $line': { backgroundColor: 'var(--bosscat-blue-600)' } },
  line: { height: 5, border: 0, backgroundColor: '#EEE', borderRadius: 2 }
})(StepConnector)

const Stepper: FC<StepperProps> = ({
  steps, activeStep = 0, withLogo = false, setActiveStepAction, saveNewValueAction,
  margin = '', center = false, fixButtons = false, title = '', subtitle = '', variant = '',
  showHeader = true,
  hasButtons = false,
  hasCounter = false,
  className = '',
  absoluteClass = '',
  disableNext = false,
  controlClassname = '',
  showProgress = false,
  activeStepProgress,
  totalStepProgress,
  logoAndProgressClass = ''
}) => {
  const classes = useStyles({ withLogo })
  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const content = steps[activeStep]?.content ?? 'Nothing here..'
  const nextText = steps[activeStep]?.nextText ?? null
  const buttonBig = steps[activeStep]?.buttonBig ?? null
  const actionNext = steps[activeStep]?.actionNext ?? null
  const titeBack = steps[activeStep]?.titeBack ?? null
  const actionBack = steps[activeStep]?.actionBack ?? null
  const disableBack = steps[activeStep]?.disableBack ?? null

  const [canGoForward, setCanGoForward] = useState(false)
  const [showWarnings, setShowWarnings] = useState(false)

  const showLogo = withLogo
  const showTitle = title.length > 0 && !xsScreen
  const showSubtitle = subtitle.length > 0

  const setActiveStep = (step: number) => {
    if (setActiveStepAction) setActiveStepAction(step)
  }

  const goForward = () => {
    if (canGoForward) {
      if (actionNext) actionNext()
      else setActiveStep(activeStep + 1)
    } else setShowWarnings(true)
  }
  const goBack = () => { setActiveStep(activeStep - 1) }

  const saveNewValue = (attr: any, value: any) => {
    if (saveNewValueAction) saveNewValueAction({ attr, value })
  }

  const GetStepsIcons = (props: StepIconProps) => {
    const classes = useStyles({ withLogo })
    const { active, completed } = props

    const iconsObject = {} as any
    for (let i = 0; i < steps.length; i++) {
      iconsObject[i + 1] = steps[i].icon
    }
    const icons: { [index: string]: React.ReactElement } = iconsObject

    return (
      <div
        className={clsx(classes.stepIcon, {
          [classes.activeStep]: active,
          [classes.completedStep]: completed
        })}
      >
        {icons[String(props.icon)]}
      </div>
    )
  }

  return (
    <>
      {!fixButtons ?
        <Box className={`${classes.root} ${className}`} style={{ margin: margin }}>
          <Box className={showLogo ? classes.withLogo : ''}>
            {showLogo && showProgress ? <div className={`${classes.logoAndProgress} ${logoAndProgressClass}`}>
              {showLogo && <img src={bosscatLogo} alt='BOSSCAT Logo' style={{ width: '160px' }} />}
              {showProgress && totalStepProgress && activeStepProgress && <div className={classes.progressContainer}>
                <div className={classes.progressBarContainer}>
                  <div className={classes.progressBarFill} style={{ width: `${(activeStepProgress / totalStepProgress) * 100}%` }} />
                </div>
                <p className={classes.progressSteps}>{`${activeStepProgress}/${totalStepProgress}`}</p>
              </div>
              }
            </div> : showLogo && <img src={bosscatLogo} alt='BOSSCAT Logo' style={{ width: '160px' }} />
            }
            {showHeader && <MaterialStepper
              activeStep={activeStep} className={variant === '' ? classes.stepper : classes.stepperOnboard}
              alternativeLabel connector={<ColorlibConnector />}
            >
              {steps.map((_step, index) => (
                <Step key={index} className={classes.step}>
                  <StepButton disabled>
                    <StepLabel StepIconComponent={GetStepsIcons} />
                  </StepButton>
                </Step>
              ))}
            </MaterialStepper>}
          </Box>
          <ScrollWrapper>
            <Box
              style={{
                display: 'grid',
                gridAutoRows: nextText || !disableBack ? 'auto min-content' : 'auto',
                height: '100%',
                marginRight: xsScreen ? '10px' : '0px'
              }}
            >
              <StepperContext.Provider value={{ goBack, goForward, setCanGoForward, showWarnings, setShowWarnings, saveNewValue }}>
                {content}
              </StepperContext.Provider>
              {(nextText || !disableBack) && (
                <Box id="stepper-controls" className={`${classes.controls} ${controlClassname}`}>
                  {((!disableBack && activeStep > 0) || actionBack) &&
                    <Box style={{ display: 'flex', justifyContent: 'left' }}>
                      <Button
                        type='submit'
                        variant='outlined'
                        size='small'
                        className={classes.buttonReverse}
                        startIcon={<icons.ArrowBack />}
                        onClick={actionBack ?? goBack}
                        fullWidth
                      >
                        {titeBack ?? 'Back'}
                      </Button>
                    </Box>}
                  {nextText && (
                    <Box style={{ display: 'flex', justifyContent: 'right' }}>
                      <Button
                        type='submit'
                        variant='contained'
                        size='small'
                        disabled={disableNext}
                        className={(xsScreen && !((!disableBack && activeStep > 0) || actionBack)) || buttonBig ? classes.buttonBig : classes.button}
                        endIcon={<icons.ArrowForward />}
                        onClick={goForward}
                        fullWidth
                      >
                        {nextText}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              <Box className={classes.counterContainer}>
                {hasCounter &&
                  <Box
                    className={classes.counterStep}
                  >
                    <Typography className={classes.counter}>{activeStep + 1}/{steps?.length}</Typography>
                  </Box>
                }
                {hasButtons && (
                  <Box className={classes.counterButtonsContainer}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={goBack}
                      className={classes.counterButton}
                      style={activeStep === 0 ? { opacity: 0.5 } : {}} >
                      <icons.ArrowBackIos
                        style={{ color: '#fff', width: '.6rem' }}
                      />
                    </Button>
                    <Button
                      disabled={(activeStep === steps.length - 1) || disableNext}
                      style={(activeStep === steps.length - 1) || disableNext ? { opacity: 0.5 } : {}}
                      onClick={goForward}
                      className={classes.counterButton}>
                      <icons.ArrowForwardIos
                        style={{ color: '#fff', width: '.6rem' }}
                      />
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </ScrollWrapper>
        </Box>
        :
        <Box className={`${classes.rootButtons} ${className}`} style={{ margin: margin }}>
          <Box className={showLogo || showTitle ? classes.withLogo : ''}>
            {showLogo && showProgress ? <div className={`${classes.logoAndProgress} ${logoAndProgressClass}`}>
              {showLogo && <img src={bosscatLogo} alt='BOSSCAT Logo' style={{ width: '160px' }} />}
              {showProgress && totalStepProgress && activeStepProgress && <div className={classes.progressContainer}>
                <div className={classes.progressBarContainer}>
                  <div className={classes.progressBarFill} style={{ width: `${(activeStepProgress / totalStepProgress) * 100}%` }} />
                </div>
                <p className={classes.progressSteps}>{`${activeStepProgress}/${totalStepProgress}`}</p>
              </div>
              }
            </div> : showLogo && <img src={bosscatLogo} alt='BOSSCAT Logo' style={{ width: '160px' }} />
            }
            {showTitle && <Box className={classes.title}>
              <Typography variant='h4'> {title} </Typography>
              {showSubtitle && <Typography variant='h6'>{subtitle}</Typography>}
            </Box>}
            {showHeader && <MaterialStepper
              activeStep={activeStep} className={variant === '' ? classes.stepper : classes.stepperOnboard}
              alternativeLabel connector={<ColorlibConnector />}
            >
              {steps.map((_step, index) => (
                <Step key={index} className={classes.step}>
                  <StepButton disabled>
                    <StepLabel StepIconComponent={GetStepsIcons} />
                  </StepButton>
                </Step>
              ))}
            </MaterialStepper>}
          </Box>
          <ScrollWrapper absoluteClass={absoluteClass}>
            <Box
              style={{
                display: 'grid',
                gridAutoRows: nextText || !disableBack ? 'auto min-content' : 'auto',
                height: '100%',
                marginRight: xsScreen ? '10px' : '0px',
                marginBottom: '80px'
              }}
            >
              <StepperContext.Provider value={{ goBack, goForward, setCanGoForward, showWarnings, setShowWarnings, saveNewValue }}>
                {content}
              </StepperContext.Provider>
            </Box>
          </ScrollWrapper>
          {(nextText || !disableBack) && (
            <Box id="stepper-controls" className={`${classes.controls} ${controlClassname}`}>
              {((!disableBack && activeStep > 0) || actionBack) &&
                <Box style={{ display: 'flex', justifyContent: 'left' }}>
                  <Button
                    type='submit'
                    variant='outlined'
                    size='small'
                    className={classes.buttonReverse}
                    startIcon={<icons.ArrowBack />}
                    onClick={actionBack ?? goBack}
                    fullWidth
                  >
                    {titeBack ?? 'Back'}
                  </Button>
                </Box>}
              {nextText && (
                <Box style={{ display: 'flex', justifyContent: 'right' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={disableNext}
                    size='small'
                    className={(xsScreen && !((!disableBack && activeStep > 0) || actionBack)) || buttonBig ? classes.buttonBig : classes.button}
                    endIcon={<icons.ArrowForward />}
                    onClick={goForward}
                    fullWidth
                  >
                    {nextText}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      }
    </>

  )
}

export default Stepper
