import { Button, CircularProgress, Grid } from "@material-ui/core"
import { Autocomplete, TextFieldLabel } from "components/UI/CustomUI/molecules"
import { APPROVAL_TYPE, CONTACT_ROLE, getContactRoleOpt, PHONE_MASK_INPUT, PHONE_MASK_REGEXP, PHONE_MASK_REGEXP_NO_SCOPE, validateEmail } from "helpers/index"
import { isEmpty } from "lodash"
import { useState } from "react"

import useStyles from './styles'
import { icons } from 'assets'
import { estimateActions } from "ducks/actions"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { NewContactProps } from "./types"

interface NewContact {
  firstName: string,
  lastName: string,
  email: string
  phone: string,
  role: CONTACT_ROLE | null
  sendEstimate: boolean
  isPayer: boolean,
  approvalType: APPROVAL_TYPE
}

const NewContactTab = ({ setOpenModal, onSuccess }: NewContactProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [showWarnings, setShowWarnings] = useState(false)
  const [loading, setLoading] = useState(false)

  const [newContact, setNewContact] = useState<NewContact>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: null,
    sendEstimate: true,
    isPayer: false,
    approvalType: APPROVAL_TYPE.NOT_NEEDED
  })

  const isValidEmail = !isEmpty(newContact.email) && validateEmail(newContact.email)

  const phoneInvalid = isEmpty(newContact.phone) || !(PHONE_MASK_REGEXP.test(newContact.phone) || PHONE_MASK_REGEXP_NO_SCOPE.test(newContact.phone))

  const validate =
    !isEmpty(newContact.firstName) &&
    !isEmpty(newContact.lastName) &&
    !isEmpty(newContact.email) &&
    validateEmail(newContact.email) &&
    !isEmpty(newContact.phone) &&
    !isEmpty(newContact.role)


  const onSubmit = () => {
    if (validate) {
      setLoading(true)
      dispatch(estimateActions.updateEstimateByPath([{ op: 'add', path: `/properties/contacts/-`, value: newContact }],
        (succ: boolean) => {
          setLoading(false)
          onSuccess()
          succ && toast.success('The contact was added successfully')
        }))
    } else {
      setShowWarnings(true)
    }
  }

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={6}>
        <TextFieldLabel
          label='Name'
          type='text'
          value={newContact.firstName}
          error={showWarnings && isEmpty(newContact.firstName)}
          onChange={(event) => { setNewContact({ ...newContact, firstName: event.target.value }) }}
          placeholder='Enter Name'
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldLabel
          label='Last Name'
          type='text'
          value={newContact.lastName}
          error={showWarnings && isEmpty(newContact.lastName)}
          onChange={(event) => { setNewContact({ ...newContact, lastName: event.target.value }) }}
          placeholder='Enter Last Name'
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldLabel
          label='Email Address'
          type='email'
          value={newContact.email}
          error={showWarnings && !isValidEmail}
          onChange={(event) => { setNewContact({ ...newContact, email: event.target.value }) }}
          placeholder='mail@email.com'
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldLabel
          label='Phone Number'
          type='tel'
          value={newContact.phone?.toString() || ''}
          mask={PHONE_MASK_INPUT}
          error={
            showWarnings &&
            phoneInvalid
          }
          onChange={(event) => setNewContact({ ...newContact, phone: event.target.value?.replaceAll('-', '') })}
          autoComplete='off'
          placeholder='555-555-5555'
          shouldScrollOnError
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          label='Role'
          value={getContactRoleOpt()?.find(contact => contact?.key === newContact?.role)}
          options={getContactRoleOpt()}
          onChange={(value: any) => {
            setNewContact({ ...newContact, role: value.key })
          }}
          error={
            showWarnings &&
            !getContactRoleOpt()?.find(contact => contact?.key === newContact?.role)
          }
          placeholder="Select Role"
        />
      </Grid>
      <Grid className={classes.buttonsContainer}>
        <Button
          type='submit'
          variant='outlined'
          color='primary'
          className={classes.cancel}
          fullWidth
          onClick={() => setOpenModal(false)}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submit}
          fullWidth
          endIcon={!loading && <icons.Add />}
          onClick={onSubmit}
        >
          {loading ? <CircularProgress size="1.6rem" className={classes.spinner} /> : 'Add Contact'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default NewContactTab