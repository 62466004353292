import { Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { FC, useState } from "react"
import { OutOfAreaModalProps } from "./types"
import ooaImg from 'assets/icons/maintenance/ooa.png'

import styles from './styles.module.scss'
import { icons } from 'assets'
import { useDispatch, useSelector } from "react-redux"
import { newOrderMaintenanceActions, subscriptionActions } from "ducks/actions"
import { getNewOrderMaintenanceValue, getOrderMaintenanceValue, getUser, isAuthorized } from "ducks/selectors"
import { history } from "helpers/index"

const OutOfAreaModal: FC<OutOfAreaModalProps> = ({ setOpen }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const user = useSelector(getUser)
  const mainContact = useSelector(getNewOrderMaintenanceValue('mainContact'))
  const authorized = useSelector(isAuthorized)
  const query = history.useQuery()
  const source = query.get('utm_source') || query.get('utmsource')
  const medium = query.get('utm_medium')
  const campaign = query.get('utm_campaign')
  const content = query.get('utm_content')
  const address = useSelector(getNewOrderMaintenanceValue("propertyAddress"))

  const handleSubscribeToNotification = () => {
    setLoading(true)

    dispatch(
      subscriptionActions.addSubscriptionToNotificationList((succ) => {
        setLoading(false)
        if (succ) {
          setShowSuccess(true)
          // setOpen(false)
        }
      }, { ...mainContact, userProperties: { source, medium, campaign, content }, address })
    )
  }

  // const greeting = showSuccess ? 'Great' : 'Hey'
  const text = showSuccess
    ? 'You’ll be the first to be notified once we start covering more areas!'
    : 'BOSSCAT Home Services is growing rapidly but not currently available in your area.'

  
  const subtitle = authorized && !user.onProtectPlanNotificationList ?
    'Click below to be notified when our VIP Maintenance Membership is available in your area.'
    : 'Sign up for updates to receive special promotions when we land in your neighborhood.'
  return (
    <Grid className={styles.OutOfAreaModal}>
      <img src={ooaImg} alt="Out Of Area" className={styles.OutOfAreaModal__img} />
      {/*  <Typography variant="h4" className={styles.OutOfAreaModal__title}>{`${greeting} ${user?.firstName || mainContact?.firstName ? `, ${user?.firstName || mainContact?.firstName}` : ''}!`}</Typography> */}

      <Typography className={styles.OutOfAreaModal__text}>
        {text}
      </Typography>

      {!showSuccess && <Typography className={styles.OutOfAreaModal__text}>
        {subtitle}
      </Typography>}

      {/*       {!showSuccess && mainContact.email && <Typography className={styles.OutOfAreaModal__text}>
        We are not yet providing services in your area.
        <Typography className={styles.OutOfAreaModal__text}>
          Click below to be notified when our  VIP Maintenance Membership  is available in your area.
        </Typography> </Typography>
      } */}

      {(!showSuccess && mainContact.email) || (authorized && !user.onProtectPlanNotificationList) ?
        <Button
          variant="contained"
          className={styles.OutOfAreaModal__button}
          endIcon={!loading && <icons.CheckCircle className={styles.OutOfAreaModal__button__icon} />
          }
          onClick={handleSubscribeToNotification}
        >
          {loading
            ? <CircularProgress />
            : 'I want to be notified'
          }
        </Button> : <></>}
    </Grid>
  )
}

export default OutOfAreaModal