import { Address, Estimate, PaymentOption } from 'ducks/types'
import {
  NewEstimateValue,
  NewEstimateValues,
  newEstimateTypes,
  NewEstimateActions,
  ReplaceInterface
} from './types'
import { FetchEstimatesServicesPayload } from 'ducks/estimates/types'

const actions = {
  cleanNewEstimate: (): NewEstimateActions => ({
    type: newEstimateTypes.CLEAN,
    payload: null
  }),
  eraseNewEstimate: (): NewEstimateActions => ({
    type: newEstimateTypes.ERASE,
    payload: null
  }),
  setNewEstimateStep: (payload: number): NewEstimateActions => ({
    type: newEstimateTypes.SET_STEP,
    payload
  }),
  setNewEstimateSubstep: (payload: number): NewEstimateActions => ({
    type: newEstimateTypes.SET_SUBSTEP,
    payload
  }),
  setNewEstimateValue: (payload: NewEstimateValue): NewEstimateActions => ({
    type: newEstimateTypes.SET_VALUE,
    payload
  }),
  setNewEstimateValues: (payload: NewEstimateValues): NewEstimateActions => ({
    type: newEstimateTypes.SET_VALUES,
    payload
  }),
  createEstimate: (
    payload: PaymentOption | null,
    callback: (succ: boolean, estimateId?: string) => void
  ): NewEstimateActions => ({
    type: newEstimateTypes.CREATE_ESTIMATE,
    payload,
    callback
  }),
  createEstimateV2: (
    callback: (succ: boolean, estimateId?: string) => void,
    payload?: Partial<Estimate>
  ): NewEstimateActions => ({
    type: newEstimateTypes.CREATE_ESTIMATE_V2,
    callback,
    payload,
  }),
  updateEstimatePaymentOption: (
    payload: PaymentOption | null,
    callback: (succ: boolean, estimateId?: string) => void
  ): NewEstimateActions => ({
    type: newEstimateTypes.UPDATE_ESTIMATE_PAYMENT_OPTION,
    payload,
    callback
  }),
  updateEstimate: (
    payload: Partial<Estimate> | null,
    callback: (succ: boolean, estimate?: Estimate) => void
  ): NewEstimateActions => ({
    type: newEstimateTypes.UPDATE_ESTIMATE,
    payload,
    callback
  }),
  updateEstimateV3: (
    payload: Array<any> | null,
    callback: (succ: boolean, estimateId?: string) => void
  ): NewEstimateActions => ({
    type: newEstimateTypes.UPDATE_ESTIMATE_V3,
    payload,
    callback
  }),
  updateEstimateAddress: (
    payload: Address | null,
    callback: (succ: boolean, estimateId?: string) => void
  ): NewEstimateActions => ({
    type: newEstimateTypes.UPDATE_ESTIMATE_ADDRESS,
    payload,
    callback
  }),
  replaceValueInEstimate: (
    payload: ReplaceInterface,
    callback: (succ: boolean, estimateId?: string) => void
  ): NewEstimateActions => ({
    type: newEstimateTypes.REPLACE_CONTACT_IN_ESTIMATE,
    payload,
    callback
  }),
  deleteValueInEstimate: (
    payload: string,
    callback: (succ: boolean, estimateId?: string) => void
  ): NewEstimateActions => {
    return ({
      type: newEstimateTypes.DELETE_VALUE_IN_ESTIMATE,
      payload,
      callback
    })
  },
  fetchEstimatesServices: (
    payload: FetchEstimatesServicesPayload,
    callback?: (succ: boolean, services: Array<any>) => void
  ): NewEstimateActions => ({
    type: newEstimateTypes.NEW_FETCH_ESTIMATES_SERVICES,
    payload,
    callback
  })
}

export default actions
