import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content auto',
      [theme.breakpoints.down('sm')]: {
        padding: '12px !important'
      },
      '@global': {
        '.MuiGrid-item': {
          paddingRight: 0
        },
      }
    },
    list: {
      margin: '20px 0px'
    },
    subHeader: {
      backgroundColor: 'var(--progress-color-back)'
    },
    listItem: {
      justifyContent: 'space-between'
    },
    item: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px'
    },
    itemType: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '5px 0px 0px 0px'
    },
    itemEnd: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      alignSelf: 'flex-end',
      maxWidth: '100px'
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '58px',
      color: 'var(--head-text-color)',
      marginRight: '10px'
    },
    price: {
      fontFamily: 'Lato',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '58px',
      color: 'var(--head-text-color)'
    },
    icon: {
      color: 'var(--head-text-color)',
      margin: '18px 8px'
    },
    expand: {
      color: 'var(--white-color)'
    },
    expandBox: {
      width: '26px',
      height: '26px',
      borderRadius: '4px',
      backgroundColor: 'var(--bosscat-blue-600)',
      justifyContent: 'center',
      alignItems: 'center'
    },
    chart: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '300px',
      margin: '10px 20px 20px 80px',
      [theme.breakpoints.down('lg')]: {
        margin: '10px 20px 20px 20px'
      }
    },
    titleChart: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '30px',
      color: 'var(--head-text-color)'
    },
    detail: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--row-background)'
    },
    button: {
      height: '33px',
      width: '80px',
      minWidth: '80px',
      margin: '0px 2px',
      textTransform: 'none',
      border: '1px solid var(--bosscat-blue-600)',
      borderRadius: '8px',
      backgroundColor: 'var(--row-background)',
      '&:hover': {
        backgroundColor: 'var(--row-background)'
      }
    },
    buttonContent: {
      justifyContent: 'flex-end'
    },
    percent: {
      margin: '2px 20px',
      padding: '8px',
      height: '40px',
      width: '48px',
      borderRadius: 8
    },
    itemPercent: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--white-color)'

    },
    number: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '5px 0px 0px 5px'
    },
    info: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      alignSelf: 'center',
      margin: '8px 22px'
    },
    termsBtn: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      alignSelf: 'center'
    },
    buttonReverse: {
      width: '236px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '2px solid',
      margin: '0px 12px'
    },
    box: {
      width: '260px',
      height: '100px',
      backgroundColor: 'var(--progress-color-back)'
    }
  })
)
