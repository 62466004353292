import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '500px',
      borderRadius: '16px',
    },
    header: {
      background: 'var(--background-color-secondary)'
    },
    title: {
      fontSize: 20,
    },
    tbody: {
      fontSize: 14,
      fontFamily: 'LatoNormal',
      color: 'var(--input-color)',
    },
    errorMessage: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: '16px',
      padding: '8px 16px',
      borderRadius: '8px',
      background: 'var(--expired-color-back)'
    },
    errorColor: { borderColor: 'var(--error-color)' },
    errorTopColor: { borderTopColor: 'var(--error-color)' },
    errorBottomColor: { borderBottomColor: 'var(--error-color)' },
    errorLeftColor: { borderLeftColor: 'var(--error-color)' },
    errorRightColor: { borderRightColor: 'var(--error-color)' },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    dropzone: {
      minHeight: '200px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '180px'
      },
      '@global': {
        '.MuiDropzoneArea-text': {
          fontSize: 16
        }
      }
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      margin: '1rem 0'
    },
    button: {
      width: '100%',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontFamily: 'LatoNormal',
    },
    buttonCancel: {
      textTransform: 'inherit',
      color: 'var(--bosscat-blue-600)',
      fontFamily: 'LatoNormal',
    }
  })
)
