import { useCurrentBreakpoint } from './useCurrentBreakpoint'

type MediaQueryHook = [boolean, string];

const useMediaQuery = (breakpoint: string): MediaQueryHook => {
  const currentBreakpoint = useCurrentBreakpoint()

  const breakpointMatch = currentBreakpoint === breakpoint

  return [breakpointMatch, currentBreakpoint]
}

const useIsXsScreen = (): MediaQueryHook => {
  const [breakpointMatch, currentBreakpoint] = useMediaQuery('xs')
  return [breakpointMatch, currentBreakpoint]
}

const useIsSmScreen = (): MediaQueryHook => {
  const [breakpointMatch, currentBreakpoint] = useMediaQuery('sm')
  return [breakpointMatch, currentBreakpoint]
}

const useIsMdScreen = (): MediaQueryHook => {
  const [breakpointMatch, currentBreakpoint] = useMediaQuery('md')
  return [breakpointMatch, currentBreakpoint]
}

const useIsLgScreen = (): MediaQueryHook => {
  const [breakpointMatch, currentBreakpoint] = useMediaQuery('lg')
  return [breakpointMatch, currentBreakpoint]
}

const useIsXlScreen = (): MediaQueryHook => {
  const [breakpointMatch, currentBreakpoint] = useMediaQuery('xl')
  return [breakpointMatch, currentBreakpoint]
}

export {
  useMediaQuery,
  useIsXsScreen,
  useIsSmScreen,
  useIsMdScreen,
  useIsLgScreen,
  useIsXlScreen
}
