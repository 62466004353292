import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    address: {
        fontFamily: 'LatoNormal'
    },
    state: {
        fontSize: '12px',
        fontFamily: 'LatoNormal',
        color: 'var(--input-color)'
    }
  })
)
