/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import useStyles from './styles'
import Details from './Details'
import ExpeditePage from './ExpeditePage'
import Register from './Register'
import { Navigation, PrivateLoader, ScrollWrapper } from '../../../../templates'
import { DispatchPaymentCreditCardProvider } from '../../../../UI/CustomUI/organisms/PaymentCreditCard/context'
import { estimateActions, estimatesActions } from 'ducks/actions'
import { CONTACT_ROLE, FORM_TYPE, history } from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate, getNewEstimateData, getNewEstimateValue, isAuthorized } from 'ducks/selectors'
import { useReplace } from 'helpers/history'
import { bosscatLogo, createAccountLoading } from '../../../../../assets'


const Success: FC = () => {
  const dispatch = useDispatch()
  const replace = useReplace()
  const [pageLoading, setPageLoading] = useState(false)
  const [loading, setLoading] = useState(false)


  const classes = useStyles()
  const { id: estimateId } = history.getParamValues()
  const authorized = useSelector(isAuthorized)

  const estimate = useSelector(getEstimate())
  const newEstimate = useSelector(getNewEstimateData())
  const clientType = useSelector(getNewEstimateValue('clientType'))
  const [clientTypeSaved, setClientTypeSaved] = useState(clientType)

  const [page, setPage] = useState<'success' | 'expedite' | 'register' | null>(
    'success'
  )

  const goBack = () => setPage('success')

  useEffect(() => {
    authorized ? fetchEstimate() : setPage('success')
  }, [])



  useEffect(() => {
    if (!authorized) {
      if (clientTypeSaved === CONTACT_ROLE.LISTING_AGENT) {
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      }
    }
  }, [clientTypeSaved])

  useEffect(() => {
    if (!authorized && estimate?.id !== estimateId && newEstimate?.id !== estimateId) {
      replace('/')
    }
  }, [estimate, newEstimate])

  const fetchEstimate = useCallback(() => {
    setPageLoading(true)

    dispatch(
      estimateActions.fetchEstimate(estimateId, (succ: boolean, estimate) => {
        if (succ) dispatch(estimatesActions.fetchEstimatesServices({ serviceable: estimate?.properties.territory?.serviceable as boolean, formType: estimate?.formType as FORM_TYPE, isNar: estimate?.properties.nar }, () => {
          setPage('success')
          setPageLoading(false)
        }))
        else replace('/')
      })
    )
  }, [dispatch, estimateId])

  return (
    <PrivateLoader loading={pageLoading}>
      <Navigation>
        <>
          <Box className={classes.root} aria-label='estimate-success'>
            <Box className={classes.content} aria-label='success-content'>
              <ScrollWrapper>
                <>
                  {page === 'register' && <Register goBack={goBack} />}
                  {page === 'expedite' && (
                    <DispatchPaymentCreditCardProvider>
                      <ExpeditePage goBack={goBack} />
                    </DispatchPaymentCreditCardProvider>
                  )}
                  {page === 'success' && <Details setPage={setPage} />}
                </>
              </ScrollWrapper>
            </Box>
            <Box className={classes.footer}>
              <Box className={classes.footerContent}>
                <Typography variant='body2'>
                  Questions? Speak with a live Customer Care Professional
                </Typography>
                <Typography variant='body2'>
                  <a href='tel:1-888-887-8624'>1-888-887-8624</a> M-F, 8:00am - 6:00pm
                  EST
                </Typography>
              </Box>
            </Box>
          </Box>
          <div className={`${classes.loading} ${loading ? '' : classes.loadingHidden}`}>
            <img src={bosscatLogo} alt='BOSSCAT Logo' style={{ width: '160px' }} />
            <Box className={classes.loadingContainer}>
              <img src={createAccountLoading} alt="BOSSCAT loading" style={{ width: "240px", height: "240px" }} />
              <Typography className={classes.loadingCopy}>A BOSSCAT account will be created, and a temporary password will be sent to your email.</Typography>
              <CircularProgress size={40} />
            </Box>
          </div>
        </>
      </Navigation>
    </PrivateLoader>
  )
}
export default Success
