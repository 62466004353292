/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newEstimateActions } from '../../../../ducks/actions'
import { NewEstimateValue } from '../../../../ducks/newEstimate/types'
import {
  getEstimatesServices,
  getNewEstimateStep,
  getNewEstimateValue,
  getUser,
  isAuthorized
} from '../../../../ducks/selectors'
import { HashWrapper, Navigation, PrivateLoader } from '../../../templates'
import useStyles from './styles'
import { Box, Stepper, Typography } from '../../../UI'
import { icons } from '../../../../assets'
import { StepperProps } from '../../../UI/CustomUI/organisms/Stepper/types'

import { Location, Assignment, Payment } from './Steps'
import Account from './Account'
import { CONTACT_ROLE, USER_TYPE } from '../../../../helpers'
import { useIsXsScreen } from '../../../../hooks'
import Project from './Steps/Assignment/Inspection/Project'
import Contact from './Steps/Assignment/Inspection/Contact'
import UserInformation from './Steps/UserInformation'

const Estimate = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [xsScreen] = useIsXsScreen()

  const authorized = useSelector(isAuthorized)
  const activeStep = useSelector(getNewEstimateStep())
  const [pageLoading, setPageLoading] = useState(true)
  const user = useSelector(getUser)
  const services = useSelector(getEstimatesServices)
  const estimate = useSelector(getNewEstimateValue('estimate'))
  const clientType = useSelector(getNewEstimateValue('clientType'))
  const activeStepProgress = useSelector(getNewEstimateValue('activeStepProgress'))
  const totalStepProgress = useSelector(getNewEstimateValue('totalStepProgress'))

  const isNar = user?.nar || !!new URLSearchParams(window.location.search).get('nar')
  const affiliate = user?.affiliate || (new URLSearchParams(window.location.search).get('affiliate') ?? '')

  useEffect(() => {
    setPageLoading(false)
  }, [activeStep])

  useEffect(() => {
    /* dispatch(
      newEstimateActions.setNewEstimateStep(authorized ? (estimate?.id ? activeStep : -1) : activeStep > 0 ? activeStep : -1)
    ) */
    if (authorized) {
      // dispatch(newEstimateActions.setNewEstimateValue({ attr: 'userIsloggedIn', value: authorized }))
      if (estimate.createdBy !== user.id) {
        // If the user id doesn't match with the estimate id we clean the estimate since was created as a public user
        dispatch(newEstimateActions.eraseNewEstimate())
      }
    }
  }, [authorized])

  useEffect(() => {
    dispatch(
      newEstimateActions.setNewEstimateValues({
        attrs: {
          isNar: (clientType === CONTACT_ROLE.LISTING_AGENT) ? isNar : false,
          affiliate: (clientType === CONTACT_ROLE.LISTING_AGENT) ? affiliate : '',
        }
      })
    )

    if (authorized) {
      dispatch(
        newEstimateActions.setNewEstimateValues({
          attrs: {
            clientType: user.clientType === USER_TYPE.HOMEOWNER ? user.clientType :
              user.clientType === USER_TYPE.INSTITUTIONAL ? CONTACT_ROLE.INSTITUTIONAL_INVESTOR :
                CONTACT_ROLE.LISTING_AGENT,
            affiliate,
            isNar
          }
        })
      )
    }
  }, [user, isNar, affiliate, clientType])

  const steps: StepperProps['steps'] = [
    {
      icon: <icons.Assignment />,
      content: <Assignment />,
      disableBack: true
    },
  ]

  if (clientType !== CONTACT_ROLE.INSTITUTIONAL_INVESTOR) {
    steps.push({
      content: <Project />,
      disableBack: true
    })
  }

  if (clientType === CONTACT_ROLE.LISTING_AGENT) {
    steps.push({
      content: <Contact />,
      disableBack: true
    })
  }

  steps.push(
    {
      content: <UserInformation />,
      disableBack: true
    },
    {
      content: <Payment />,
    }
  )

  if (services?.length === 1 && services[0]?.cost === 0) {
    // if we have only one service and with price 0 we avoid the payment screen
    steps.pop()
  }


  return (
    <HashWrapper>
      <Navigation>
        <Box className={classes.root}>
          <Box className={classes.content}>
            {activeStep === -1
              ? (
                <Account />
              )
              : (
                <PrivateLoader loading={pageLoading}>
                  <Stepper
                    withLogo={!authorized}
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStepAction={(step: number) =>
                      dispatch(newEstimateActions.setNewEstimateStep(step))}
                    saveNewValueAction={(value: NewEstimateValue) =>
                      dispatch(newEstimateActions.setNewEstimateValue(value))}
                    fixButtons
                    showHeader={false}
                    showProgress
                    activeStepProgress={activeStepProgress}
                    totalStepProgress={totalStepProgress}
                    className={classes.stepper}
                  />
                </PrivateLoader>
              )}
          </Box>
          {!xsScreen && <Box className={classes.footer}>
            <Box className={classes.footerContent}>
              <Typography variant='body2'>
                Questions? Speak with a live Customer Care Professional
              </Typography>
              <Typography variant='body2'>
                <a href='tel:1-888-887-8624'>1-888-887-8624</a> M-F, 8:00am - 6:00pm
                EST
              </Typography>
            </Box>
          </Box>}
        </Box>
      </Navigation>
    </HashWrapper>
  )
}

export default Estimate
