import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import html2canvas from 'html2canvas'
import {
  EstimateHeader,
  TypeViews,
  List,
  Button,
  EstimateDetails,
  BottomBarEstimate,
  ListSubheader,
  Typography,
  Box,
  Divider,
  DoughnutChart,
  Grid,
  StatusBar,
  WarningBar
} from 'components/UI'
import {
  redPoint,
  yellowPoint,
  greenPoint,
  purlplePoint,
  marks,
  icons
} from 'assets'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { STATUS_BAR_STATE } from 'components/UI/CustomUI/molecules/StatusBar/types'
import {
  DesktopPage,
  OverlayLoader,
  PrivateLoader
} from 'components/templates'
import {
  ITEM_CATEGORY,
  ITEM_PRIORITY,
  ITEM_STATUS,
  ESTIMATE_PUBLIC_STATUS,
  ESTIMATE_VIEW,
  getCategoryIcon,
  history,
  isEmpty,
  round,
  formatTimestamp,
  capitalize,
  APPROVAL_TYPE,
  sortItemsByCategories,
} from 'helpers'
import {
  getEstimate,
  getEstimateChartData,
  getEstimateItems,
  getEstimateItemsGroupBy,
  getUser,
  getUserId
} from 'ducks/selectors'
import {
  estimateActions,
  estimateItemsActions,
} from '../../../../../ducks/actions'
import { Item } from '../../../../../ducks/types'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from '../../../../../hooks'
import EstimateItem from './EstimateItem'
import EstimateAlert from './EstimateAlert'
import EstimateHeaderAlert from './EstimateHeaderAlert'
import ServiceAreaWarningBanner from './ServiceAreaWarningBanner'
import { generatePdf } from './ItemsPdf'
import ContactUsModal from './ContactUsModal'
import PriceDetail from './PriceDetail'
import JobLinkBanner from './JobLinkBanner'
import { BANNER_STATUS } from './JobLinkBanner/constants'
import * as Sentry from '@sentry/react'
import NewEstimateModal from '../NewEstimateModal'
import MobileShareModal from './ShareModal/MobileShareModal'
import DesktopShareModal from './ShareModal/DesktopShareModal'
import ModalBanner from '../../OrderMaintenance/ModalBanner'
import GoldBanner from 'components/UI/CustomUI/molecules/GoldBanner'
import { MaintenanceModalsContext } from '../../OrderMaintenance/Dashboard/context'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import PerksModal from '../../OrderMaintenance/Dashboard/PerksModal'

const getPriorityColor = (item: ITEM_PRIORITY) => {
  switch (item) {
    case ITEM_PRIORITY.Urgent:
      return redPoint
    case ITEM_PRIORITY.High:
      return redPoint
    case ITEM_PRIORITY.Medium:
      return yellowPoint
    case ITEM_PRIORITY.Low:
      return greenPoint
    case ITEM_PRIORITY.Cosmetic:
      return purlplePoint
  }
}

const getApprovedColor = (item: string) => {
  switch (item) {
    case 'Approved':
      return greenPoint
    case 'Rejected':
      return redPoint
  }
}

// Speak to an expert, DEPRECATED
/*const handleSpeak = (): void => {
  const link = document.createElement('a')
  link.href =
    'https://meetings.hubspot.com/bpardo/ask-an-expert?__hstc=53988791.297afc30294ec2462928eab138bbc6e9.1643848612769.1643848612769.1643987758518.2&__hssc=53988791.1.1643987758518&__hsfp=4004690923'
  link.target = '_blank'
  link.click()
} */

const getStatusBar = (actualPosition: number): any[] => {
  const list = [
    { text: 'Composing', state: STATUS_BAR_STATE.TODO },
    { text: 'Released - Pending Approval', state: STATUS_BAR_STATE.TODO },
    { text: 'Approved', state: STATUS_BAR_STATE.TODO },
    { text: 'Expired', state: STATUS_BAR_STATE.TODO }
  ]

  list.forEach((_elem, index) => {
    if (index < actualPosition) list[index].state = STATUS_BAR_STATE.DONE
    if (index === actualPosition) list[index].state = STATUS_BAR_STATE.ACTUAL
  })

  return list
}

const RenderChart = ({
  data,
  totalValue,
  handleRepairView,
  groupedItems,
  selectedType,
  showBetterStyle = false
}: {
  data: any
  totalValue: number
  handleRepairView: () => void
  groupedItems: any
  selectedType: ESTIMATE_VIEW
  showBetterStyle?: boolean
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const [xsScreen] = useIsXsScreen()
  const classes = showBetterStyle ? stylesBetter : styles

  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      className={classes.listItem}
      id='doughnut-chart-full-data'
    >
      <Grid item xs={4} md={12} lg={4}>
        <Typography className={classes.titleChart}> Repair View: </Typography>
        <Box className={classes.chart}>
          <DoughnutChart data={data} title={`$${round(totalValue, 2)}`} />
        </Box>
      </Grid>

      <Grid container item xs={7} md={12} lg={6}>
        {!xsScreen && (
          <Grid container item xs={12} className={classes.buttonContent}>
            <Button
              type='submit'
              variant='contained'
              size='small'
              className={classes.button}
              onClick={() => handleRepairView()}
            >
              <Typography className={classes.detail}> Collapse </Typography>
            </Button>
          </Grid>
        )}

        {!isEmpty(groupedItems) &&
          Object.keys(groupedItems).map((itemKey, index) => {
            const items: any[] = groupedItems[itemKey]
            const price = items.reduce((acc: number, obj: Item) => {
              return obj.status !== ITEM_STATUS.REJECTED
                ? acc + obj.totalPrice
                : selectedType === ESTIMATE_VIEW.APPROVED
                  ? acc + obj.totalPrice
                  : acc
            }, 0)
            let itemCategory =
              selectedType === ESTIMATE_VIEW.TYPE
                ? ITEM_CATEGORY[itemKey as keyof typeof ITEM_CATEGORY]
                : itemKey

            itemCategory = itemCategory
              .split('/')
              .map((i) => capitalize(i))
              .join('/')

            const targetIndex = data.labels.findIndex((label: string) => label.toUpperCase().includes(itemKey))

            return (
              price > 0 && (
                <Fragment key={itemKey}>
                  <Grid
                    container
                    item
                    key={targetIndex}
                    style={{ justifyContent: 'center' }}
                  >
                    <Box className={classes.percentContainer}>
                      <Box
                        style={{
                          backgroundColor:
                            data.datasets[0].backgroundColor[targetIndex]
                        }}
                        className={classes.percent}
                      >
                        <Typography
                          className={classes.itemPercent}
                          style={{ color: data.datasets[0].labelColor[targetIndex] }}
                        >
                          {data.datasets[0].data[targetIndex] + '%'}
                        </Typography>
                      </Box>
                      <Typography className={classes.itemType}>
                        {itemCategory + ': '}
                      </Typography>
                      <Typography className={classes.number}>
                        {`$${round(price, 2)}`}
                      </Typography>
                    </Box>
                  </Grid>
                </Fragment>
              )
            )
          })}
      </Grid>
    </Grid>
  )
}


const EstimateView = () => {
  const [xsScreen] = useIsXsScreen()
  const mounted = useRef(false);
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const dispatch = useDispatch()
  const push = history.usePush()
  const goBack = history.useGoBack()

  const user = useSelector(getUser)

  const { id: estimateId } = history.getParamValues()
  const estimate = useSelector(getEstimate())

  const { properties, deliveredOn, approvedOn, publicStatus, approvalStatus, sharedByEmail, sharedByName } =
    estimate ?? {}
  const {
    publicId,
    territory,
    address,
    contacts,
    minimum = 0,
    affiliate,
    discount,
    nar,
    taxAmount,
    totalWithTax = 0,
    taxRate
  } = properties ?? {}
  const {
    line_1: street,
    line_2: street2,
    city,
    state,
    zipCode
  } = address ?? {}
  const {
    title: territoryTitle = '',
    // phone: territoryPhone = '',
    // email: territoryEmail = '',
    serviceable
  } = territory ?? {}
  const {
    firstName = '',
    lastName = '',
    email = ''
  } = contacts && contacts.length > 0 ? contacts[0] : {}

  const territoryPhone = '888-887-8624'
  const territoryEmail = 'customercare@bosscathome.com'

  const showBetterStyle = !isEmpty(affiliate) && affiliate === 'better'
  const classes = showBetterStyle ? stylesBetter : styles


  const line1 = (street ? `${street} ` : '') + (street2 ? `#${street2}` : '')
  const line2 = `${city}, ${state} ${zipCode}`

  const [pageLoading, setPageLoading] = useState(true)
  const [itemsLoading, setItemsLoading] = useState(false)
  const [expand, setExpand] = useState(false)
  const [selectedType, setSelectedType] = useState<ESTIMATE_VIEW>(
    ESTIMATE_VIEW.TYPE
  )
  const [hideRemoved, setHideRemoved] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [statusList, setStatusList] = useState<any>([])
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openContactUsModal, setOpenContactUsModal] = useState(false)
  const userId = useSelector(getUserId)
  const showButtons = true

  const groupedItems = sortItemsByCategories(useSelector(
    getEstimateItemsGroupBy(selectedType, hideRemoved)
  ))

  const items = useSelector(getEstimateItems(false))

  const shared = estimate?.sharedByEmail != null
  const punchlistPlanner = serviceable !== undefined && !serviceable && !shared
  const estimateIsUnder5000 = estimate?.properties?.totalValue && estimate?.properties?.totalValue < 5000 ? true : false
  const estimateTtalPrice = [...items]?.reduce((acc: number, obj: Item) => {
    return acc + obj.totalPrice
  }, 0)
  const estimateFallsBellow5000 = estimateIsUnder5000 && estimateTtalPrice > 5000

  const maintenanceBannerTitle = estimateFallsBellow5000 ? 'You now qualify for 25% off one year of VIP Home Maintenance.' :
    'Congratulations! Your estimate qualifies for our May promotion.'

  const maintenanceBannerSubtitle = estimateFallsBellow5000 ? 'If you add more items and approve an order of $5,000 or more, you will receive the VIP Home Maintenance for free!' : estimateIsUnder5000 ? 'If you place an order in May, you will have the opportunity to purchase a year of VIP Home Maintenance at 25% off! ' : 'When you approve an order of $5,000 or more during the month of May, you will receive a FREE year of VIP Home Maintenance!'

  const contact = estimate?.properties?.contacts?.find(
    (contact) => contact.id === userId
  )

  const bannerStatus = useMemo(() => {
    if (estimate?.jobNeedsAttention === true) return BANNER_STATUS.NEEDS_ATTENTION
    if (estimate?.publicStatus === ESTIMATE_PUBLIC_STATUS.APPROVED) return BANNER_STATUS.IN_PROGRESS
    return BANNER_STATUS.OTHER
  }, [estimate])

  const showApproveButton =
    ((estimate?.approvalStatus === 'PENDING_FURTHER' &&
      contact?.approvalType === APPROVAL_TYPE.REQUIRED) ||
      estimate?.approvalStatus === 'NOT_APPROVED' ||
      estimate?.approvalStatus === 'MISSING_DETAILS') && estimate.publicStatus !== ESTIMATE_PUBLIC_STATUS.EXPIRED

  useEffect(() => {
    let statusPosition = -1
    if (publicStatus === ESTIMATE_PUBLIC_STATUS.IN_PROGRESS) statusPosition = 0
    if (publicStatus === ESTIMATE_PUBLIC_STATUS.COMPLETED && !approvedOn) {
      statusPosition = 1
    }
    if (approvedOn !== null) statusPosition = 2
    if (publicStatus === ESTIMATE_PUBLIC_STATUS.EXPIRED) statusPosition = 3

    setStatusList(getStatusBar(statusPosition))
  }, [approvedOn, publicStatus])

  const fetchEstimateItems = useCallback(
    (loadings = true) => {
      if (loadings) setItemsLoading(true)
      dispatch(
        estimateItemsActions.fetchEstimateItems(
          estimateId,
          (_succ: boolean) => {
            if (mounted.current) {
              setItemsLoading(false)
            } else {
              return null
            }
          }
        )
      )
    },
    [dispatch, estimateId]
  )

  const fetchEstimateView = useCallback(
    (loadings = true) => {
      if (loadings && mounted.current) setPageLoading(true)
      dispatch(
        estimateActions.fetchEstimate(estimateId, (_succ: boolean) => {
          if (mounted.current) setPageLoading(false)
          if (!_succ) {
            push(`p/dashboard`)
            // goBack()
          } else {
            if (mounted.current) fetchEstimateItems(loadings)
          }
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, estimateId, fetchEstimateItems]
  )

  useEffect(() => {
    mounted.current = true;
    fetchEstimateView()
    return () => {
      mounted.current = false
    }
  }, [fetchEstimateView])

  const handleSubmit = (type: ESTIMATE_VIEW): void => {
    setSelectedType(type)
  }

  const handleRepairView = (): void => {
    setExpand(!expand)
  }

  const data = getEstimateChartData(selectedType, hideRemoved, showBetterStyle)

  const getIcon = (item: any) => {
    switch (selectedType) {
      case ESTIMATE_VIEW.TYPE:
        return (
          <img
            src={getCategoryIcon(item, showBetterStyle)}
            alt=''
            className={classes.icon}
          />
        )
      case ESTIMATE_VIEW.PRIORITY:
        return (
          <img src={getPriorityColor(item)} alt='' className={classes.icon} />
        )
      case ESTIMATE_VIEW.APPROVED:
        return (
          <img src={getApprovedColor(item)} alt='' className={classes.icon} />
        )
    }
  }

  const handleApprove = (): void => {
    push(`p/estimates/${estimate?.id}/approve`)
  }

  const handleRemove = (
    estimateId: string,
    itemId: string,
    removed: boolean
  ): void => {
    dispatch(
      estimateItemsActions.updateEstimateItem(
        {
          estimateId,
          itemId,
          partialItem: {
            status: removed ? ITEM_STATUS.APPROVED : ITEM_STATUS.REJECTED
          }
        },
        () => {
          fetchEstimateView(false)
        }
      )
    )
  }

  const handleShare = (): void => {
    setOpenShareModal(true)
  }

  const handlePdf = async () => {
    if (!downloadingPdf) {
      setDownloadingPdf(true)
      const userInformationNode = document.getElementById(
        'user-and-item-information'
      )
      const userInfoGrid = userInformationNode
        ?.childNodes[0] as any as HTMLElement
      const punchlistLogo = userInfoGrid.children[0]?.children[0]
        ?.children[0] as HTMLElement
      const mailIcon = userInfoGrid.children[1]?.children[0] as HTMLElement
      const sentIcon = userInfoGrid.children[2]?.children[0] as HTMLElement
      const approvedOnIcon = userInfoGrid.children[3]
        ?.children[0] as HTMLElement

      let doughnutChartNode: null | HTMLElement = null
      let chartCanvas: HTMLCanvasElement | null = null
      try {
        //* Deletion of the collapse button for the image in the chart
        doughnutChartNode = document.getElementById('doughnut-chart-full-data')
        const doughnutCharGridNode = doughnutChartNode?.childNodes[1]
        const collapseButton = doughnutCharGridNode?.childNodes[0]
        doughnutCharGridNode?.removeChild(collapseButton!)
        //* -------------------------------------------- *\\

        chartCanvas = await html2canvas(doughnutChartNode!)
        doughnutCharGridNode?.insertBefore(
          collapseButton!,
          doughnutCharGridNode?.firstChild
        )
      } catch (error) {
        Sentry.captureException(error);
        // console.log(`%cError: %c${error}`, `font-size: 18px; font-weight: 600;`, `color: rgb(244, 17, 21);`)
      }

      const [
        punchlistLogoCanvas,
        mailIconCanvas,
        sentIconCanvas,
        approvedOnIconCanvas
      ] = await Promise.all([
        html2canvas(punchlistLogo!),
        html2canvas(mailIcon!),
        html2canvas(sentIcon!),
        html2canvas(approvedOnIcon!)
      ])
      const punchlistLogoUri = punchlistLogoCanvas.toDataURL('image/png', 1.0)
      const chartUri = chartCanvas?.toDataURL('image/png', 1.0)
      const mailIconUri = mailIconCanvas.toDataURL('image/png', 1.0)
      const sentIconUri = sentIconCanvas.toDataURL('image/png', 1.0)
      const approvedOnIconUri = approvedOnIconCanvas.toDataURL('image/png', 1.0)
      await generatePdf({
        groupedItems: groupedItems,
        userInfo: {
          name: `${firstName} ${lastName}`,
          city: city ? `${city},` : '',
          address: street ?? '',
          sent: deliveredOn
            ? formatTimestamp(deliveredOn, 'MMMM Do, YYYY')
            : '',
          approvedOn: approvedOn
            ? formatTimestamp(approvedOn, 'MMMM Do, YYYY')
            : '',
          mail: email ?? '',
          taxRate: taxRate,
          taxAmount: taxAmount,
          nar: nar,
          total: total
        },
        imageUris: {
          punchlistLogoUri,
          chartUri,
          mailIconUri,
          sentIconUri,
          approvedOnIconUri
        },
        showBetterStyle
      })
      setDownloadingPdf(false)
    }
  }

  const [alert, setAlert] = useState(false)
  const [alertItem, setAlertItem] = useState<Item | null>(null)
  const [info, setInfo] = useState(false)

  const handleShowAlert = (item: Item): void => {
    setAlertItem(item)
    setAlert(true)
  }

  const showItemButtons = !shared && estimate?.publicStatus !== ESTIMATE_PUBLIC_STATUS.APPROVED

  const {
    state: { modalType, modalOpen },
    dispatch: dispatchContext
  } = useContext(MaintenanceModalsContext)

  const minPriceRange = estimate?.minPriceRange;
  const maxPriceRange = estimate?.maxPriceRange;

  const total = useMemo(() => {
    if (minPriceRange && maxPriceRange) {
      if (minPriceRange === null || maxPriceRange === null) {
        return '$'.concat(round(totalWithTax ?? 0, 2));
      }

      if (minPriceRange === maxPriceRange) {
        return '$'.concat(round(totalWithTax ?? 0, 2));
      }

      if (minPriceRange !== maxPriceRange) {
        return "$".concat(round(minPriceRange + (totalWithTax || 0), 0).slice(0, -3)).concat(" - $").concat(round(maxPriceRange + (totalWithTax || 0), 0).slice(0, -3));
      }
    }
    return '$'.concat(round(totalWithTax ?? 0, 2));
  }, [minPriceRange, maxPriceRange, totalWithTax]);

  return (
    <PrivateLoader loading={pageLoading}>
      <OverlayLoader loading={downloadingPdf}>
        <DesktopPage
          header={
            <EstimateDetails
              title={publicId ? `Estimate #${publicId}` : ''}
              location={territoryTitle}
              phone={territoryPhone}
              mail={territoryEmail}
              showBetterStyle={showBetterStyle}
              showPunchlistPlanner={punchlistPlanner}
              setInfo={() => setInfo(!info)}
              shared={shared}
              sharedByEmail={sharedByEmail}
              sharedByName={sharedByName}
            />
          }
        >
          <>
            <Modal
              open={modalOpen && modalType === "PERKS"}
              setOpen={() => dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })}
              size="xl"
              className={classes.modal}
              title={<Typography variant='h5' style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}>Four Seasons of Care List</Typography>}
            >
              <PerksModal />
            </Modal>
            {!serviceable && (estimate?.properties.affiliate === 'pillartopost' || estimate?.properties.affiliate === 'pillar to post') && !shared && <NewEstimateModal />}
            {info && (
              <Box className={classes.info}>
                <EstimateHeaderAlert
                  title={firstName.concat(' ', lastName)}
                  line1={line1}
                  line2={line2}
                  sent={
                    deliveredOn
                      ? formatTimestamp(deliveredOn, 'MMMM Do, YYYY')
                      : ''
                  }
                  approved={
                    approvedOn
                      ? formatTimestamp(approvedOn, 'MMMM Do, YYYY')
                      : ''
                  }
                  mail={email ?? ''}
                  total={`$${total}`}
                  onClick={handlePdf}
                  pendingFurther={approvalStatus === 'PENDING_FURTHER'}
                />
              </Box>
            )}
            {(!totalWithTax || totalWithTax < minimum) && (
              <WarningBar
                text={`BOSSCAT has a $${minimum} minimum for Jobs. If you'd like to discuss this or learn how to move forward, please contact us.`}
              />
            )}
            {xsScreen
              ? (
                <Box className={classes.addressBox}>
                  <Typography className={classes.total}>
                    {`Total: ${total}`}{' '}
                  </Typography>
                </Box>
              )
              : (
                !punchlistPlanner && (
                  <StatusBar
                    statusList={statusList}
                    showBetterStyle={showBetterStyle}
                  />
                )
              )}
            {/*  {serviceable && <GoldBanner
              title={maintenanceBannerTitle}
              subtitle={maintenanceBannerSubtitle}
              buttonAction={() => {
                dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'PERKS' })
              }}
              buttonText='Click here'
              className={classes.goldBanner}
            />} */}
            {!xsScreen && (
              <EstimateHeader
                title={firstName.concat(' ', lastName)}
                line1={line1}
                line2={line2}
                sent={
                  deliveredOn
                    ? formatTimestamp(deliveredOn, 'MMMM Do, YYYY')
                    : ''
                }
                approved={
                  approvedOn ? formatTimestamp(approvedOn, 'MMMM Do, YYYY') : ''
                }
                mail={email ?? ''}
                total={total}
                onClick={handlePdf}
                showBetterStyle={showBetterStyle}
                pendingFurther={approvalStatus === 'PENDING_FURTHER'}
              />
            )}
            <PrivateLoader loading={itemsLoading} building='fragment'>
              <>
                <TypeViews
                  handleSubmit={handleSubmit}
                  selectedType={selectedType}
                  handleRepairView={handleRepairView}
                  expand={expand}
                  handleHide={() => setHideRemoved(!hideRemoved)}
                  showBetterStyle={showBetterStyle}
                  isPunchlistPlanner={punchlistPlanner}
                />
                <JobLinkBanner jobStatus={bannerStatus} jobId={estimate?.id} />
                {(expand || xsScreen) && (
                  <RenderChart
                    data={data}
                    groupedItems={groupedItems}
                    handleRepairView={handleRepairView}
                    selectedType={selectedType}
                    totalValue={totalWithTax ?? 0}
                    showBetterStyle={showBetterStyle}
                  />
                )}

                {!isEmpty(groupedItems) && (
                  <List className={classes.list}>
                    {Object.keys(groupedItems).map((itemKey) => {
                      const items = groupedItems[itemKey]

                      const price = items.reduce((acc: number, obj: Item) => {
                        return acc + (obj?.status !== ITEM_STATUS.REJECTED ? obj.totalPrice : 0)
                      }, 0)
                      const itemCategory =
                        selectedType === ESTIMATE_VIEW.TYPE
                          ? ITEM_CATEGORY[itemKey as keyof typeof ITEM_CATEGORY]
                          : itemKey

                      let total = '$'.concat(round(price, 2))

                      const rangePriceMin = items.reduce((acc: number, obj: Item) => {
                        return acc + (obj?.status !== ITEM_STATUS.REJECTED ? obj.minPriceRange ? obj.minPriceRange : 0 : 0)
                      }, 0)

                      const rangePriceMax = items.reduce((acc: number, obj: Item) => {
                        return acc + (obj?.status !== ITEM_STATUS.REJECTED ? obj.maxPriceRange ? obj.maxPriceRange : 0 : 0)
                      }, 0)

                      if (rangePriceMin && rangePriceMax) {

                        if (rangePriceMin === null || rangePriceMax === null)
                          total = '$'.concat(round(price, 2))

                        if (rangePriceMin === rangePriceMax)
                          total = '$'.concat(round(price, 2))

                        if (rangePriceMin !== rangePriceMax)
                          total = "$".concat(round(rangePriceMin + (price || 0), 0).slice(0, -3)).concat(" - $").concat(round(rangePriceMax + (price || 0), 0).slice(0, -3))
                      }

                      return (
                        <Fragment key={itemKey}>
                          <ListSubheader className={classes.subHeader}>
                            <Box className={classes.row}>
                              {getIcon(itemKey)}
                              <Typography className={classes.title}>
                                {itemCategory}
                              </Typography>
                              <Typography className={classes.price}>
                                {total}
                              </Typography>
                            </Box>
                          </ListSubheader>
                          {itemCategory === ITEM_CATEGORY.EVALUATE && (
                            <Box className={classes.evaluateDisclamerBox}>
                              <icons.Warning
                                className={classes.evaluateDisclamerIcon}
                              />
                              <Typography
                                variant='body2'
                                className={classes.evaluateDisclamerText}
                              > {serviceable
                                ? (
                                  'Unfortunately, there was not enough information ' +
                                  'to give an accurate estimate for the items ' +
                                  'listed below. If desired, a BOSSCAT Pro can ' +
                                  'complete a site visit to further evaluate these ' +
                                  'repairs for a fee, or these items can be ' +
                                  'evaluated while on-site if other repairs are ' +
                                  'requested at no additional charge. All items ' +
                                  'estimated in this category will require further ' +
                                  'approval prior to our technicians performing ' +
                                  'repairs.')
                                : (
                                  'These items require an in-person evaluation ' +
                                  'in order to provide a cost estimate, which we ' +
                                  'cannot provide in your area. Please consult with ' +
                                  'a local specialist.')}
                              </Typography>
                            </Box>
                          )}
                          {items.map((item: Item, index: number) => {
                            return (
                              <Fragment key={item.itemId}>
                                <EstimateItem
                                  item={item}
                                  removed={item.status === ITEM_STATUS.REJECTED}
                                  onRemove={() =>
                                    handleRemove(
                                      item.estimateId,
                                      item.itemId,
                                      item.status === ITEM_STATUS.REJECTED
                                    )}
                                  showButtons={showItemButtons}
                                  showBetterStyle={showBetterStyle}
                                  showAlert={() => handleShowAlert(item)}
                                  isPunchlistPlanner={punchlistPlanner}
                                />
                                {index + 1 < items.length && (
                                  <Divider variant='fullWidth' component='li' />
                                )}
                              </Fragment>
                            )
                          })}
                        </Fragment>
                      )
                    })}
                  </List>
                )}

                {!serviceable && (
                  <ServiceAreaWarningBanner />
                )}

                <Divider variant='fullWidth' />
                <PriceDetail
                  discount={discount}
                  isNar={nar}
                  taxAmount={taxAmount}
                  totalValue={totalWithTax}
                  taxRate={taxRate}
                  priceRange={total}
                />

                {alert && alertItem && (
                  <Box className={classes.back}>
                    <Box className={classes.alert}>
                      <EstimateAlert
                        title={alertItem.title}
                        quantity={alertItem.quantity}
                        inspectionReportNote={alertItem.inspectionReportNote}
                        requestedRepairNote={alertItem.requestedRepairNote}
                        price={alertItem.totalPrice}
                        imageFiles={alertItem.imageFiles}
                        disclaimer={alertItem.disclaimer}
                        removed={alertItem.status === ITEM_STATUS.REJECTED}
                        showButtons={showButtons}
                        onRemove={() =>
                          handleRemove(
                            alertItem.estimateId,
                            alertItem.itemId,
                            alertItem.status === ITEM_STATUS.REJECTED
                          )}
                        showAlert={(value) => setAlert(value)}
                      />
                    </Box>
                  </Box>
                )}

                {!xsScreen ?
                  <DesktopShareModal
                    open={openShareModal}
                    setOpen={setOpenShareModal}
                    showBetterStyle={showBetterStyle}
                  /> :
                  <MobileShareModal
                    open={openShareModal}
                    setOpen={setOpenShareModal}
                    showBetterStyle={showBetterStyle}
                  />
                }

                <ContactUsModal
                  open={openContactUsModal}
                  setOpen={setOpenContactUsModal}
                  showBetterStyle={showBetterStyle}
                />

                <BottomBarEstimate
                  disabledForward={!totalWithTax || totalWithTax < minimum}
                  onApprove={handleApprove}
                  onShare={handleShare}
                  rmForward={false}
                  price={total}
                  showButtons={showButtons}
                  showBetterStyle={showBetterStyle}
                  showApproveButton={showApproveButton}
                  isPunchlistPlanner={punchlistPlanner}
                  shared={shared}
                />
                <img src={marks} alt='' className={classes.marks} />
              </>
            </PrivateLoader>
          </>
        </DesktopPage>
      </OverlayLoader>
    </PrivateLoader>
  )
}

export default EstimateView
