import axios from '../axios'
import { ApiController } from './types'

const base = '/territories'

const apiController: ApiController = {
  getTerritories: async () =>
    await axios
      .get(base)
      .then(response => response.data),

  getTerritoryByZipCode: async (zipCode) =>
    await axios
      .get(`${base}/zipCode/${zipCode}`)
      .then(response => response.data),

  getTerritoryRepairItems: async (territoryId, search) =>
    await axios
      .get(`${base}/${territoryId}/repairItems${search ? `?search=${search}` : ''}`)
      .then(response => response.data)

}

export default apiController
