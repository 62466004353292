import { emit } from "process"

export const AUTH0_CODES = {
  INVALID_PASSWORD: 'invalid_password',
  INVALID_SIGNUP: 'invalid_signup',
}

export enum EMAIL_TYPE {
  PRIMARY = 'PRIMARY',
  CC = 'CC',
  BCC = 'BCC',
}

export enum USER_TYPE {
  HOMEOWNER = 'HOMEOWNER',
  HOME_BUYER = 'HOME_BUYER',
  BROKER = 'BROKER',
  INSTITUTIONAL = 'INSTITUTIONAL',
  INSPECTOR = 'INSPECTOR',
  OTHER = 'OTHER',
  CLOSING_COORDINATOR = 'CLOSING_COORDINATOR',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
}

export enum ORDER_STATUS {
  APPROVED = 'APPROVED',
  DISPATCHED = 'DISPATCHED',
  REJECTED = 'REJECTED',
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
}

export enum ORDER_ITEM_STATUS {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  APPROVED = 'APPROVED',
}

export enum ORDER_FILE_TYPE {
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  ADDENDUM = 'ADDENDUM',
  PRO_REPORT = 'PRO_REPORT',
}

export enum ORDER_ITEM_IMAGE_TYPE {
  INSPECTION_IMAGE = 'INSPECTION_IMAGE',
  PRO_IMAGE = 'PRO_IMAGE',
}

export enum RESOURCE_TYPE {
  ESTIMATE = 'ESTIMATE',
  JOB = 'JOB',
}

export enum ESTIMATE_STATUS {
  NEW = 'NEW',
  COMPOSING = 'COMPOSING',
  IN_REVIEW = 'IN_REVIEW',
  RELEASED = 'RELEASED',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
}

export enum ESTIMATE_PUBLIC_STATUS {
  IN_PROGRESS = 'In Progress',
  EXPIRED = 'Expired',
  COMPLETED = 'Completed',
  APPROVED = 'Approved',
}

export enum ITEM_STATUS {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  NEW = 'NEW',
}

export enum ITEM_CATEGORY {
  ELECTRICAL = 'Electrical',
  PLUMBING = 'Plumbing',
  HVAC = 'HVAC',
  WINDOWS_DOORS = 'Windows/Doors',
  MISCELLANEOUS = 'Miscellaneous',
  CL100 = 'CL100',
  CRAWLSPACE = 'Crawlspace',
  EXTERIOR = 'Exterior',
  INTERIOR = 'Interior',
  ATTIC = 'Attic',
  ROOF = 'Roof',
  UNCATEGORIZED = 'UNCATEGORIZED',
  EVALUATE = 'Evaluate',
  EXCLUDED = 'Excluded',
}

export enum ITEM_PRIORITY {
  Urgent = 'Urgent',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Cosmetic = 'Cosmetic',
}

export enum JOB_STATUS {
  SCHEDULING = 'SCHEDULING',
  DEPOSIT_PENDING = 'DEPOSIT_PENDING',
  DISPATCH_PENDING = 'DISPATCH_PENDING',
  DISPATCHED = 'DISPATCHED',
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  RECALLED = 'RECALLED',
}

export enum JOB_PAYMENT_STATUS {
  NOT_INVOICED = 'NOT_INVOICED',
  INVOICE_SENT = 'INVOICE_SENT',
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
  PAID = 'PAID',
  IN_COLLECTION = 'IN_COLLECTION',
  COMPLETE = 'COMPLETE',
  RECALLED = 'RECALLED',
}

export enum JOB_PUBLIC_STATUS {
  SCHEDULING = 'Scheduling',
  DISPATCHED = 'Dispatched',
  HOLD = 'Hold',
  IN_PROGRESS = 'In Progress',
  NEEDS_ATTENTION = 'Needs Attention',
  COMPLETED = 'Completed',
}

export enum JOB_ITEM_PUBLIC_STATUS {
  SCHEDULED = 'Scheduled',
  DISPATCHED = 'Dispatched',
  IN_PROGRESS = 'In Progress',
  HOLD = 'Hold',
  COMPLETED = 'Completed',
  NEEDS_ATTENTION = 'Needs Attention',
}

export enum FORM_TYPE {
  CLIENT_INSPECTION_REPORT = 'CLIENT_INSPECTION_REPORT',
  CLIENT_REPAIR_LIST = 'CLIENT_REPAIR_LIST',
  INTERNAL = 'INTERNAL',
  LEGACY_FORM = 'LEGACY_FORM',
}

export enum REPAIR_TIMELINE {
  PRELISTING = 'PRELISTING',
  ASAP = 'ASAP',
  BEFORE_CLOSE = 'BEFORE_CLOSE',
  AFTER_CLOSE = 'AFTER_CLOSE',
  NEVER = 'NEVER',
  ONE_WEEK = 'ONE_WEEK',
  THIRTY_DAYS = 'THIRTY_DAYS',
  SIXTY_DAYS = 'SIXTY_DAYS',
  OVER_SIXTY_DAYS = 'OVER_SIXTY_DAYS'

}

export enum CONTACT_ROLE {
  LISTING_AGENT = 'LISTING_AGENT',
  BUYERS_AGENT = 'BUYERS_AGENT',
  BUYERS_CLOSING_COORDINATOR = 'BUYERS_CLOSING_COORDINATOR',
  SELLERS_CLOSING_COORDINATOR = 'SELLERS_CLOSING_COORDINATOR',
  HOMEOWNER = 'HOMEOWNER',
  INSPECTOR = 'INSPECTOR',
  OTHER = 'OTHER',
  HOME_BUYER = 'HOME_BUYER',
  HOME_SELLER = 'HOME_SELLER',
  INSTITUTIONAL_INVESTOR = 'INSTITUTIONAL_INVESTOR',
}

export const SHARE_ROLE = [
  {
    role: 'HOME_SELLER',
    label: 'Seller'
  },
  {
    role: 'HOME_BUYER',
    label: 'Buyer'
  },
  {
    role: 'LISTING_AGENT',
    label: 'Listing Agent'
  },
  {
    role: 'BUYERS_AGENT',
    label: "Buyer's Agent"
  },
  {
    role: 'BUYERS_CLOSING_COORDINATOR',
    label: "Buyer's Closing Coordinator"
  },
  {
    role: 'SELLERS_CLOSING_COORDINATOR',
    label: "Sellers' Closing Coordinator"
  },
  {
    role: 'OTHER',
    label: "Other"
  },
]

export enum APPROVAL_TYPE {
  NOT_NEEDED = 'NOT_NEEDED',
  REQUIRED = 'REQUIRED',
  APPROVED = 'APPROVED',
  PAY_AT_CLOSE = 'PAY_AT_CLOSE',
}

export enum FILE_TYPE {
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  INSPECTION_IMAGE = 'INSPECTION_IMAGE',
  ADDENDUM = 'ADDENDUM',
  PRO_REPORT = 'PRO_REPORT',
  PRO_IMAGE = 'PRO_IMAGE',
  REPAIR_LIST = 'REPAIR_LIST',
  CLIENT_CONTRACT = 'CLIENT_CONTRACT'
}

export enum PAYMENT_METHOD_TYPE {
  CARD = 'card',
}

export enum PAYMENT_METHOD_BRAND {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}

export enum ESTIMATE_VIEW {
  TYPE = 'Type',
  PRIORITY = 'Priority',
  APPROVED = 'Approved',
}

export enum JOB_VIEW {
  CATEGORY = 'category',
  STATUS = 'status',
}

export enum FEEDBACK_TYPE {
  ORDER = 'ORDER',
  ESTIMATE = 'ESTIMATE',
  ITEM = 'ITEM',
  SITE_VISIT = 'SITE_VISIT',
  JOB = 'JOB',
}

export enum PAYMENT_TYPE {
  CHECK = 'CHECK',
  CREDITCARD = 'CREDITCARD',
  ACH = 'ACH',
}

export enum PAYMENT_RESOURCE_TYPE {
  ORDER = 'ORDER',
  ESTIMATE = 'ESTIMATE',
  ITEM = 'ITEM',
  SITE_VISIT = 'SITE_VISIT',
  JOB = 'JOB',
  PAYMENT = 'PAYMENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum PAYMENT_STATUS {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum OCCUPANCY_STATUS  {
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
}

export enum INSPECTION_STATUS  {
  NEW = 'NEW',
}

export enum ESTIMATE_VIEW_COLORS_PRIORITY {
  Urgent = '#E01F1F',
  High = '#E01F1F',
  Medium = '#FFC633',
  Low = '#1CCD83',
  Cosmetic = '#5643D4',
}

export enum ESTIMATE_VIEW_COLORS_APPROVED {
  Approved = '#1CCD83',
  ApprovedBetter = '#1b8860',
  Rejected = '#E01F1F',
}

export const ESTIMATE_VIEW_COLORS_TYPES = [
  '#0E2E41',
  '#2B515D',
  '#4E7778',
  '#779E92',
  '#491A5F',
  '#7A23A3',
  '#BA72DB',
  '#5D55B4',
  '#3228AF',
  '#151050',
  '#EFBA32',
  '#BD8C0F',
  '#84620A',
  '#5F4607',
]

export const ESTIMATE_VIEW_BETTER_COLORS_TYPES = [
  '#073126',
  '#004733',
  '#99D6A9',
  '#CCE9D3',
  '#F0F7F1',
  '#BF271B',
  '#F2ABA6',
  '#F03333',
  '#F9B3B3',
  '#EF8032',
  '#F8C4A0',
  '#FFBC14',
  '#F8EBC9',
]

export enum PROTECT_PLAN_TYPES {
  PLAN_A = "PLAN_A",
  PLAN_B = "PLAN_B",
  PLAN_C = "PLAN_C",
  PLAN_D = "PLAN_D"
}

export enum SUSCRIPTION_STATUS {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE"
}