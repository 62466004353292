import { FC } from 'react'
import { DetailsProps } from './types'
import useStyles from './styles'
import { icons, blueHouseLogo } from '../../../../../../assets'
import { Typography, Box, Grid } from '@material-ui/core'

const EstimateHeaderAlert: FC<DetailsProps> = ({
  title, line1, line2, approved, sent, mail, total, onClick, pendingFurther = false
}) => {
  const styles = useStyles()
  const classes = styles

  return (
    <Box className={classes.root} id='user-and-item-information'>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={3}>
          <Box className={classes.rows}>
            <img src={blueHouseLogo} alt='' className={classes.logo} />
            <Box className={classes.column}>
              <Typography className={classes.title}> {title} </Typography>
              <Typography className={classes.text}> {line1} </Typography>
              <Typography className={classes.text2}> {line2} </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={2} className={classes.rows}>
          <icons.Mail className={classes.icon} />
          <Typography className={classes.detail}> {mail} </Typography>
        </Grid>

        <Grid item xs={12} lg={2} className={classes.rows}>
          <icons.Send className={classes.icon} />
          <Typography className={classes.detail}> {sent} </Typography>
        </Grid>

        <Grid item xs={12} lg={2} className={classes.rows}>
          <icons.AssignmentTurnedIn className={classes.icon} />
          {
            pendingFurther ? (
              <Box className={styles.pendingFurtherContainer}>
                <Typography variant='caption'> Pending Further Approval </Typography>
              </Box>
            ) : (
              <Typography className={classes.detail}> {approved.length > 0 ? approved : '-'} </Typography>
            )
          }
        </Grid>

        <Grid item xs={12} lg={2} className={classes.rows}>
          <Typography className={classes.detail} style={{ marginLeft: '30px' }}> <b>Total: </b> {total} </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EstimateHeaderAlert
