import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { UserDetails, Grid } from '../../../../UI'
import {
  USER_TYPE,
  history,
  CONTACT_ROLE,
  APPROVAL_TYPE,
  parsePhoneFromNumToStr,
  getContactRoleOptions,
  getPaymentOptions,
  ESTIMATE_PUBLIC_STATUS,
} from '../../../../../helpers'
import useStyles from './styles'
import { DesktopPage, PrivateLoader } from '../../../../templates'
import { icons } from '../../../../../assets'
import Stepper from '../../../../UI/CustomUI/organisms/Stepper'
import { Approval, Information, Submit } from './Steps'
import { NewEstimateValue } from '../../../../../ducks/newEstimate/types'
import { estimateActions } from '../../../../../ducks/actions'
import { EstimateContact, Estimate } from '../../../../../ducks/types'
import { getUser } from '../../../../../ducks/user/selectors'
import {
  getUserId,
  getEstimate,
  getConfigValue,
  getUserPrimaryEmail,
  getUserFullName,
  getUserFullAddress,
  getUserPhone
} from '../../../../../ducks/selectors'
import { ApproveContext } from './context.provider'
import { IApproveContext } from './context'
import SecondaryApproval from './Steps/SecondaryApproval'
import EstimateAlreadyApproved from './EstimateAlreadyApproved'

const BROKER = USER_TYPE.BROKER
const INSTITUTIONAL = USER_TYPE.INSTITUTIONAL

const ApproveViewComponent: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading] = useState(true)
  const steps = []
  const [activeStep, setActiveStep] = useState(0)
  const [othersInfo /* , setOthersInfo */] = useState(true)
  const goBack = history.useGoBack()
  const userId = useSelector(getUserId)
  const estimate = useSelector(getEstimate())
  const { id, properties } = estimate ?? {}
  const { id: estimateId } = history.getParamValues()

  const userFullName = useSelector(getUserFullName)
  const userType = useSelector(getConfigValue('userType'))
  const userAddress = useSelector(getUserFullAddress)
  const userMail = useSelector(getUserPrimaryEmail)

  const { address, oneClickApproval } = useSelector(getUser)

  const push = history.usePush()

  const userPhone = parsePhoneFromNumToStr(
    Number.parseInt(useSelector(getUserPhone))
  )


  // form 1
  const [clientNotes, setClientNotes] = useState('')
  const [startDate, setStartDate] = useState<number | undefined>(undefined)
  const [closingDate, setClosingDate] = useState<number | undefined>(undefined)
  const [remember, setRemember] = useState('')
  const [flexible, setFlexible] = useState('')
  const [flexibleClosing, setFlexibleClosing] = useState('')
  const initialRole = getContactRoleOptions()[0]
  const [role, setRole] = useState<any>(initialRole)

  // form 2
  const initialResponsible = getPaymentOptions()[0]
  const [responsible, setResponsible] = useState(initialResponsible)
  const [signature, setSignature] = useState('')
  const [company, setCompany] = useState('')
  const [mailCompany, setMailCompany] = useState('')
  const [phoneCompany, setPhoneCompany] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [mail, setMail] = useState('')
  const [status, setStatus] = useState('')
  const [terms, setTerms] = useState(false)
  const [unknownInformation, setUnknownInformation] = useState(false)
  const [unknownInformation2, setUnknownInformation2] = useState(false)
  const [schedulerEmail, setSchedulerEmail] = useState('')
  const [schedulerFirstName, setSchedulerFirstName] = useState('')
  const [schedulerLastName, setSchedulerLastName] = useState('')
  const [schedulerPhone, setSchedulerPhone] = useState('')
  const [scheduler, setScheduler] = useState('me')

  const [consent, setConsent] = useState(false)

  const initialState: IApproveContext = {
    form_1: {
      clientNotes, closingDate, flexible,
      remember, role, startDate, flexibleClosing,
      setClientNotes, setClosingDate, setFlexible, setFlexibleClosing,
      setRemember, setRole, setStartDate
    },
    form_2: {
      company, mail, mailCompany, name, phone,
      phoneCompany, responsible, signature, status,
      terms, unknownInformation, unknownInformation2,
      schedulerEmail, schedulerFirstName, schedulerLastName, schedulerPhone, scheduler,
      setCompany, setMail, setMailCompany, setName,
      setPhone, setPhoneCompany, setResponsible,
      setSignature, setStatus, setTerms,
      setUnknownInformation, setUnknownInformation2,
      setSchedulerEmail, setSchedulerFirstName, setSchedulerLastName, setSchedulerPhone, setScheduler
    },
    consent, setConsent,
  }
  const contact = estimate?.properties?.contacts?.find(contact => contact.id === userId)
  const showSecondaryApproval = estimate?.approvalStatus === "PENDING_FURTHER" && contact?.approvalType === APPROVAL_TYPE.REQUIRED
  const companyRoles = [USER_TYPE.BROKER, USER_TYPE.CLOSING_COORDINATOR, USER_TYPE.INSPECTOR, USER_TYPE.INSTITUTIONAL, USER_TYPE.PROPERTY_MANAGER]
  
  const showApproveFlow =
    ((estimate?.approvalStatus === 'PENDING_FURTHER' &&
      contact?.approvalType === APPROVAL_TYPE.REQUIRED) ||
      estimate?.approvalStatus === 'NOT_APPROVED' ||
      estimate?.approvalStatus === 'MISSING_DETAILS') && estimate.publicStatus !== ESTIMATE_PUBLIC_STATUS.EXPIRED

  /* const handleSubmit = (event: React.MouseEvent<EventTarget>): void => {
    setOthersInfo(!othersInfo)
    if (activeStep === 1) {
      setActiveStep(activeStep - 1)
    }
  } */

  const handleBack = (): void => {
    goBack()
  }

  const handleSaveNewValue = (value: NewEstimateValue): {} => {
    switch (value.attr) {
      case 'clientNotes':
        setClientNotes(value.value)
        break
      case 'signature':
        setSignature(value.value)
        break
      case 'startDate':
        setStartDate(value.value)
        break
      case 'closingDate':
        setClosingDate(value.value)
        break
      case 'remember':
        setRemember(value.value)
        break
      case 'flexible':
        setFlexible(value.value)
        break
      case 'responsible':
        setResponsible(value.value)
        break
      case 'company':
        setCompany(value.value)
        break
      case 'phoneCompany':
        setPhoneCompany(value.value)
        break
      case 'mailCompany':
        setMailCompany(value.value)
        break
      case 'name':
        setName(value.value)
        break
      case 'phone':
        setPhone(value.value)
        break
      case 'mail':
        setMail(value.value)
        break
      case 'role':
        setRole(value.value)
        break
    }
    return {}
  }

  const setActiveStepAction = (step: number): {} => {
    if (step === 2 || (oneClickApproval && activeStep === 0)) {
      const add = address
      const cd =
        closingDate === undefined
          ? moment(new Date()).unix()
          : moment(closingDate).unix()
      const sd =
        startDate === undefined ? moment(new Date()).unix() : moment(startDate).unix()
      const [firstName, ...lastName] = signature.split(' ')
      const [firstNameHw, ...lastNameHw] = name.split(' ')
      setPageLoading(true)

      const contacts = [] as Array<Partial<EstimateContact>>
      if (companyRoles.includes(userType)) {
        contacts.push({
          firstName: firstName,
          lastName: lastName.join(' '),
          email: userMail,
          role: role.key !== '' ? role.key as CONTACT_ROLE : userType,
          approvalType: APPROVAL_TYPE.APPROVED,
          isPayer: oneClickApproval ? true : false,
          isScheduler: scheduler === 'me',
        })
      } else {
        contacts.push({
          firstName: firstName,
          lastName: lastName.join(' '),
          email: userMail,
          role: userType,
          approvalType: APPROVAL_TYPE.APPROVED,
          isPayer: responsible.value === 'Homeowner (at the time of repairs)',
          isScheduler: scheduler === 'me'
        })
      }

      if (companyRoles.includes(userType)) {
        contacts.push({
          firstName: firstNameHw,
          lastName: lastNameHw.join(' '),
          phone: phone,
          email: mail,
          role: CONTACT_ROLE.HOMEOWNER,
          approvalType: APPROVAL_TYPE.REQUIRED,
          isPayer: responsible.value === 'Homeowner (at the time of repairs)',
          isScheduler: scheduler === 'homeowner'
        })
      }
      
      if (scheduler === 'other') {
        contacts.push({
          firstName: schedulerFirstName,
          lastName: schedulerLastName,
          phone: schedulerPhone,
          email: schedulerEmail,
          role: CONTACT_ROLE.OTHER,
          approvalType: APPROVAL_TYPE.REQUIRED,
          isScheduler: true
        })
      }

      if (responsible.value !== 'Homeowner (at the time of repairs)') {
        contacts.push({
          firstName: company,
          phone: phoneCompany,
          email: mailCompany,
          role: CONTACT_ROLE.OTHER,
          approvalType:
            responsible.value === 'Pay at Close'
              ? APPROVAL_TYPE.PAY_AT_CLOSE
              : APPROVAL_TYPE.REQUIRED,
          isPayer: true
        })
      }

      const payload: Partial<Estimate> = {
        id: id,
        properties: {
          address: properties?.address ?? add,
          payAtClose: responsible.value === 'Pay at Close',
          accessDetails: clientNotes,
          preferredCompletedOn: flexible === 'true' ? null : sd,
          closingDate: flexibleClosing === 'true' ? null : cd,
          contacts: contacts,
          userId: userId,
          clientNotes,
          totalValue: properties?.totalValue ?? 0
        }
      }

      dispatch(
        estimateActions.approval(
          payload,
          (success: boolean, status: string) => {
            setPageLoading(false)
            if (success) {
              setStatus(status)
              setActiveStep(step)
              // fetchEstimate()
            }
          }
        )
      )
    } else {
      setActiveStep(step)
    }
    return {}
  }

  const fetchEstimate = useCallback(() => {
    setPageLoading(true)

    dispatch(
      estimateActions.fetchEstimate(estimateId, (_succ: boolean) => {
        setPageLoading(false)
      })
    )
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, estimateId]
  )

  useEffect(() => {
    //We only fetch the estimate if the one on redux doesn´t exist or is different from the one on the url
    fetchEstimate()
  }, [fetchEstimate])

  if (!showApproveFlow) {
    return <EstimateAlreadyApproved />
  }

  const submitSecondaryApproval = (): void => {

    const payload: Partial<Estimate> = {
      id: id,
      properties: {
        address: properties?.address ?? address,
        contacts: [{
          firstName: contact?.firstName,
          lastName: contact?.lastName,
          email: contact?.email,
          role: contact?.role,
          isPayer: contact?.isPayer,
          approvalType: APPROVAL_TYPE.APPROVED
        }],
        userId: userId,
      }
    }
    dispatch(
      estimateActions.approval(
        payload,
        (success: boolean, status: string) => {
          setPageLoading(false)
          if (success) {
            setStatus(`${status}__SECONDARY`)
            setActiveStep(1)
          }
        }
      )
    )
  }

  if (showSecondaryApproval) {
    steps.push({
      icon: <icons.EventNote />,
      content: <SecondaryApproval />,
      nextText: 'Submit Approval',
      actionBack: handleBack,
      actionNext: submitSecondaryApproval,
      titeBack: 'Back to Estimate'
    },
      {
        icon: <icons.CheckCircle />,
        content: <Submit statusFinal={status} />,
        disableBack: true
      },
    )
  } else {
    steps.push({
      icon: <icons.EventNote />,
      content: <Approval othersInfo={othersInfo} />,
      nextText: 'Next',
      actionBack: handleBack,
      titeBack: 'Back to Estimate',
    })

    if (oneClickApproval) {
      steps.push({
        icon: <icons.CheckCircle />,
        content: <Submit statusFinal={status} />,
        disableBack: true
      })
    } else {
      steps.push({
        icon: <icons.Person />,
        content: <Information />,
        nextText: 'Submit Approval',
        disableBack: false
      })

      steps.push({
        icon: <icons.CheckCircle />,
        content: <Submit statusFinal={status} />,
        disableBack: true
      })
    }

    /*  */
  }


  return (
    <ApproveContext.Provider value={initialState}>
      <DesktopPage title='Repairs Approval' absoluteClass={classes.root}>
        <PrivateLoader loading={pageLoading}>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <UserDetails
              primary={userFullName}
              secondary={[userType, userAddress, userPhone]}
            />
            {/* Hide for now
    activeStep !== steps.length - 1 &&
      <Box className={classes.box}>
        <Typography variant='subtitle2' className={classes.info}>
          This Estimate is not for you?
        </Typography>
        <Button
          variant='outlined'
          size='small'
          className={classes.buttonReverse}
          onClick={handleSubmit}
        >
          <Typography variant='subtitle2' className={classes.termsBtn}>
            {othersInfo ? 'Let’s add others info' : 'Let’s add my info'}
          </Typography>
        </Button>
    </Box> */}
          </Grid>
          <Stepper
            steps={steps}
            activeStep={activeStep}
            setActiveStepAction={(step: number) => setActiveStepAction(step)}
            saveNewValueAction={(value: NewEstimateValue) =>
              handleSaveNewValue(value)
            }
            margin={activeStep !== steps.length - 1 ? '-20px 0px' : ''}
          />
        </PrivateLoader>
      </DesktopPage>
    </ApproveContext.Provider>
  )
}

export default ApproveViewComponent
