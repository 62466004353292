import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      margin: '0 auto',
      gridTemplateRows: 'auto min-content',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '24px !important'
      }
    },
    stepper: {
      margin: '1.5rem 0',
      // position: "fixed",
      // bottom: 0,
      // left: 0,
      // width: "100%",
      // maxWidth: "1250px",
    },
    content: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      padding: '0px 12px 0px 24px',
      maxWidth: '1250px ',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 12px 0px 12px',
      }
    },
    footer: {
      background: 'rgba(249, 250, 250, 1)'
    },
    footerContent: {
      display: 'grid',
      gap: '4px',
      maxWidth: '1250px', // theme.breakpoints.values.xxl,
      width: '100%',
      margin: '0 auto',
      padding: '24px 0 12px 24px'
    }
  })
)
