import { ChartProps } from './types'
import { Doughnut } from 'react-chartjs-2'
import { Typography } from '../../../../UI'
import { useIsXsScreen } from '../../../../../hooks'
import useStyles from './styles'

const DoughnutChart = (props: ChartProps): JSX.Element => {
  const classes = useStyles()
  const [xsScreen] = useIsXsScreen()

  return (
    <div>
      {xsScreen && <Doughnut
        id='doughnut-chart'
        data={props.data}
        height={160}
        width={160}
        options={{
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: prop => prop.label
              }
            }
          }
        }}
      />}
      {!xsScreen && <Doughnut
        id='doughnut-chart'
        data={props.data}
        options={{
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: prop => prop.label
              }
            }
          }
        }}
      />}
      {props.title && (
        <Typography className={classes.title}>{props.title}</Typography>
      )}
    </div>
  )
}
export default DoughnutChart
