import { FC, MouseEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid,
  Typography,
  FormControlLabel,
  Avatar,
  Checkbox,
  Box,
  Button,
  BottomLink,
  TextFieldLabel,
  PasswordLabel,
} from '../../../UI'
import useStyles from './styles'
import { validateEmail, isEmpty, history } from '../../../../helpers'
import { HashWrapper, PunchlistLoader } from '../../../templates'
import { authActions, newEstimateActions, newOrderMaintenanceActions, userActions } from '../../../../ducks/actions'
import { arrow } from '../../../../assets'
import { Link } from 'react-router-dom'
import UtmParamsWrapper from '../UtmParamsWrapper'
import CallMadeIcon from '@material-ui/icons/CallMade';

const Login: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = history.usePush()

  const query = history.useQuery()
  const redirect = query.get('redirect') || undefined
  const queryParams = query.toString()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(true)

  const [loading, setLoading] = useState(false)
  // const [wrongPassword, setWrongPassword] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [error, setError] = useState('')
  const [errorLogin, setErrorLogin] = useState(false)
  const [accountExists, setAccountExists] = useState(false)

  const [submit, setSubmit] = useState(false)

  const fetchUserExists = (event: MouseEvent): void => {
    if (error) {
      push('register')
    }
    event.preventDefault()
    // setSubmit(true)
    setLoginError(false)
    if (validateEmail(email)) {
      setLoading(true)
      if (redirect === 'new-estimate') {
        dispatch(
          newEstimateActions.setNewEstimateValue({
            attr: 'redirectAfterLogin',
            value: true,
          })
        )
      }
      if (redirect === 'new-maintenance') {
        dispatch(
          newOrderMaintenanceActions.setNewOrderMaintenanceValue({
            attr: 'redirectAfterLogin',
            value: true,
          })
        )
      }

      dispatch(userActions.fetchUserHead({
        email,
        userType: 'Client'
      }, (success) => {
        if (success) {
          setAccountExists(true)
          setLoading(false)
        } else {
          setError('The email does not have an account')
          setLoading(false)
        }
      }))
    }
  }

  const handleLogIn = (event: MouseEvent) => {
    event.preventDefault()
    setSubmit(true)
    setLoginError(false)
    if (validateEmail(email) && !isEmpty(password)) {
      setLoading(true)
      if (redirect === 'new-estimate') {
        dispatch(
          newEstimateActions.setNewEstimateValue({
            attr: 'redirectAfterLogin',
            value: true,
          })
        )
      }
      if (redirect === 'new-maintenance') {
        dispatch(
          newOrderMaintenanceActions.setNewOrderMaintenanceValue({
            attr: 'redirectAfterLogin',
            value: true,
          })
        )
      }
      dispatch(
        authActions.loginRequest({
          email,
          password,
          remember,
          callback: (success) => {
            setErrorLogin(true)
            setLoading(false)
          },
        })
      )
    }
  }

  return (
    <HashWrapper error={loginError} setError={setLoginError}>
      <UtmParamsWrapper>
        <PunchlistLoader loading={loading}>
          <Grid className={classes.container}>
            <Typography className={classes.title}>WELCOME BACK</Typography>
            <Typography className={classes.subTitle}>Let's sign in!</Typography>
            <Box className={`${classes.center} ${!isEmpty(error) && classes.borderContainer}`}>
              {!isEmpty(error) &&
                <>
                  <Typography variant='h5' className={classes.noAccountContainerTitle}>
                    There’s no account!
                  </Typography>
                  <Typography>
                    Let’s create an account first to see your Estimate or try another email.
                  </Typography>
                </>
              }
              <form className={classes.form}>
                {!accountExists && <TextFieldLabel
                  label="Email:"
                  type="email"
                  // autoComplete='email username'
                  value={email}
                  error={(submit && (isEmpty(email) || !validateEmail(email))) || !isEmpty(error)}
                  helperText={
                    submit &&
                    (isEmpty(email) || !validateEmail(email)) &&
                    'Provide your email to sign in'
                  }
                  onChange={(event) => {
                    setEmail(event.target.value)
                    setError('')
                  }}
                  placeholder="Enter your email here"
                />}

                <PasswordLabel
                  label="Password:"
                  value={password}
                  error={submit && (isEmpty(password) || !isEmpty(errorLogin))}
                  helperText={
                    submit &&
                    ((isEmpty(password) && 'Provide your password to sign in'))
                  }
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Enter your password"
                  className={`${classes.pass} ${(!isEmpty(error) || !accountExists) && classes.hide}`}
                />

                {!isEmpty(error) &&
                  <Typography
                    variant="body2"
                    color="error"
                    style={{ padding: '16px' }}
                  >
                    {error}
                  </Typography>
                }

                {errorLogin &&
                  <Typography
                    variant="body2"
                    color="error"
                    style={{ padding: '16px' }}
                  >
                    Oops! Wrong email or password. Check it and type it again.
                  </Typography>
                }

                {loginError && (
                  <Typography
                    variant="body2"
                    color="error"
                    style={{ padding: '0 16px' }}
                  >
                    An error occurred while trying to sign in, please try again.
                  </Typography>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className={classes.submit}
                  onClick={accountExists ? handleLogIn : fetchUserExists}
                  // onClick={handleLogIn}
                  fullWidth
                  endIcon={<Avatar alt="" src={arrow} className={classes.icon} />}
                >
                  {accountExists ? 'Sign in' : isEmpty(error) ? 'Next' : 'Go to Create Account'}
                </Button>

                {!isEmpty(error) && <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className={classes.tryAnotherButton}
                  onClick={() => {
                    setError('')
                  }}
                  fullWidth
                >
                  Try another Email
                </Button>}

                {isEmpty(error) &&
                  <>
                    <Grid container className={classes.grid}>
                      <Grid item xs>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={remember}
                              onChange={() => setRemember(!remember)}
                              name="remember"
                              color="primary"
                            />
                          }
                          label={<Typography variant="body2" style={{ fontSize: '18px', lineHeight: '27px' }}>Remember me</Typography>}
                        />
                      </Grid>
                      <Grid item>
                        <Link className={classes.actionLink} to={`/reset${queryParams ? `?${queryParams}` : ''}`}>
                          Forgot password?
                        </Link>
                      </Grid>
                    </Grid>

                    <BottomLink
                      question="Don’t have an account?"
                      linkText="Create an account"
                      linkTo={`/register${queryParams ? `?${queryParams}` : ''}`}
                      icon={<CallMadeIcon color='inherit' style={{ width: '18px', height: '18px' }} />}
                    />
                  </>
                }
              </form>
            </Box>
          </Grid>
        </PunchlistLoader>
      </UtmParamsWrapper>
    </HashWrapper>
  )
}

export default Login
