const className = (...args: string[]) => {
  return args.join(' ')
}

const useClassName = () => {
  const useClassNames = (...args: string[]) => className(...args)
  return useClassNames
}

export { useClassName }
