import React, { FC, useMemo } from 'react'
import Banner from './Banner'
import { inProgress, needsAttention } from 'assets/index'
import { BannerMainProps, JobLinkBannerProps } from './types'
import { BANNER_STATUS } from './constants'
import { Box } from '@material-ui/core'

const PROPERTY = {
  IN_PROGRESS: {
    iconSrc: inProgress,
    title: 'Your Job is on the go!',
    description:
      'Our Pros are doing their best with the repairs. You can track how it is going by clicking the button.',
    status: true,
  },
  NEEDS_ATTENTION: {
    iconSrc: needsAttention,
    title: 'This Job needs attention!',
    description:
      'Further action is required from you in order to continue with this job. Click the button for details.',
    status: false,
  },
}

const JobLinkBanner: FC<JobLinkBannerProps> = ({ jobStatus, jobId }) => {
  const bannerProp = useMemo(() => {
    switch (jobStatus) {
      case BANNER_STATUS.IN_PROGRESS:
        return PROPERTY.IN_PROGRESS
      case BANNER_STATUS.NEEDS_ATTENTION:
        return PROPERTY.NEEDS_ATTENTION
      default:
        return {} as BannerMainProps
    }
  }, [jobStatus])

  if (jobStatus === BANNER_STATUS.OTHER) return null
  return (
    <Box marginTop={2.5} marginBottom={1}>
      <Banner bannerProp={bannerProp} jobId={jobId} />
    </Box>
  )
}

export default JobLinkBanner
