import axios from 'axios'
import { ApiController } from './types'

const apiController: ApiController = {
  uploadFile: async (signedURL: string, file: File) =>
    await axios
      .put(signedURL, file, {
        headers: {
          'Content-Type': file.type
        }
      })
      .then(response => response)
}

export default apiController
