import { Box, Typography, Button } from '@material-ui/core'
import { homeOwner, icons } from '../../../../../../../../assets'
import { useIsXsScreen } from '../../../../../../../../hooks'
import useStyles from './styles'

const SpeakWithUs = () => {
    const classes = useStyles()
    const [xsScreen] = useIsXsScreen()
    const pd = xsScreen ? '5px' : '10px'
    const hg = xsScreen ? '160px' : '300px'

    return (
        <Box className={classes.speakContainer}>
            <Box>
                <img
                    alt='numberimage'
                    src={homeOwner}
                    style={{
                        position: 'relative',
                        right: '0px',
                        padding: pd,
                        height: hg
                    }}
                />
            </Box>
            <Box>
                <Box className={classes.containerDetail}>
                    <Typography className={classes.speakTitle} variant={!xsScreen ? 'h4' : 'h6'}><span> Let's talk about </span> your project</Typography>
                    <Typography className={classes.subtitle}>Speak with a live Customer Care Professional M-F, 8:00am - 6:00pm EST</Typography>
                    {!xsScreen
                        ? <a href='tel:1-888-887-8624' className={classes.phone}>1-888-887-8624</a>
                        : <Button
                            variant='contained'
                            className={classes.phoneButton}
                            onClick={() => window.open('tel:1-888-887-8624', '_blank')}
                            startIcon={<icons.Phone />}
                        >
                            1-888-887-8624
                        </Button>}
                </Box>
            </Box>
        </Box>
    )
}

export default SpeakWithUs
