import Estimate from './Estimate'
import Success from './Estimate/Success'

export const Pages: any = {
  estimate: Estimate,
}

export const PagesViews: any = {
  estimate: Success,
}
