import { Button, Grid, Typography } from "@material-ui/core"
import { ArrowForward } from "@material-ui/icons"
import { successHands } from "assets/index"
import { getEstimate, getUser } from "ducks/selectors"
import { history } from "helpers/index"
import { useSelector } from "react-redux"
import useStyles from './styles'

const EstimateAlreadyApproved = () => {
  const classes = useStyles()
  const push = history.usePush()
  const estimate = useSelector(getEstimate())
  const user = useSelector(getUser)

  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.pageTitle}>
        <Typography variant="h4">Repairs Approval</Typography>
      </Grid>
      <Grid className={classes.flexContainer}>
        <img src={successHands} alt="Success Hands" />
        <Typography className={classes.title}>
          Hey, {user.firstName}
        </Typography>
        <Typography className={classes.subtitle}>
          This estimate is already approved.
          Click below to review.
        </Typography>
        <Button
          type="submit"
          variant="contained"
          size="large"
          className={classes.button}
          onClick={() => push(`p/estimates/${estimate?.id}`)}
          fullWidth
          endIcon={<ArrowForward />}
        >
          Go to Estimate
        </Button>
      </Grid>

    </Grid>
  )
}

export default EstimateAlreadyApproved