import { FC } from 'react'
import { DateRangePicker } from 'react-date-range'
import { MultiDatePickerProps } from './types'
import useStyles from '../styles'

export const MultiDatePicker: FC<MultiDatePickerProps> = ({
  onChange, range
}) => {
  const classes = useStyles()

  return (
    <DateRangePicker
      editableDateInputs
      showDateDisplay={false}
      showMonthAndYearPickers={false}
      staticRanges={[]}
      inputRanges={[]}
      ranges={[{ ...range, key: 'dates' }]}
      onChange={(value) => {
        onChange({
          startDate: value.dates.startDate as Date ?? null,
          endDate: value.dates.endDate as Date ?? null
        })
      }}
      className={classes.date}
      classNames={{
        month: classes.month,
        months: classes.month,
        days: classes.days,
        dayToday: classes.dayToday,
        dayNumber: classes.dayNumber,
        dateRangePickerWrapper: classes.dateRangePickerWrapper,
        calendarWrapper: classes.calendarWrapper
      }}
    />
  )
}
