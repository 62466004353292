import { createStyles, makeStyles, Theme } from "../../..";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      backgroundColor: 'transparent',
      flexDirection: 'column',
      padding: '60px 20px'
    },
    contactButton: {
      backgroundColor: 'var(--hardware-haze-100)',
      boxShadow: '0px 1px 1px rgba(9, 25, 49, 0.24), 0px 0px 1px rgba(9, 25, 49, 0.31)',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      cursor: 'pointer',
      gap: '8px'
    },
    availabilityTextContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10
    },
    availabilityText: {
      color: '#72748D',
      fontSize: 16,
      fontFamily: 'LatoNormal'
    },
    itemContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0 0 10px 0',
      alignItems: 'center'
    },
    hover: {
      "&:hover": {
        backgroundColor: "rgba(237, 241, 242, 0.52)"
      }
    },
    icon: {
      height: 16,
      fill: '#1A2A55',
    },
    itemText: {
      fontWeight: 400,
      color: '#494A5A',
      fontFamily: 'LatoNormal'
    },
    img: {
      height: 18,
      marginLeft: 5,
      marginRight: 5
    }
  })
)
