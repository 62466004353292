import { FC } from 'react'

import { Tooltip, Box, Grid, Typography } from '../../../'
import { ValuesComponentProps } from './types'
import useStyles from './styles'
import { icons } from '../../../../../assets'
import { useClassName } from '../../../../../hooks'
import { Doughnut } from 'react-chartjs-2'

const ValuesChart: FC<ValuesComponentProps> = ({
  title,
  titleColor = 'primary',
  information,
  icon,
  data,
  info
}) => {
  const classes = useStyles()
  const className = useClassName()

  const getTitleColor = (): string => {
    switch (titleColor) {
      case 'primary':
        return classes.primaryColor
      case 'secondary':
        return classes.secondaryColor
      case 'info':
        return classes.informationColor
      case 'warning':
        return classes.warningColor
      case 'error':
        return classes.errorColor
      case 'success':
        return classes.successColor
    }
  }

  const getIcon = (): JSX.Element => {
    switch (titleColor) {
      case 'primary':
        return <icons.FormatListNumbered className={classes.icon} />
      case 'secondary':
        return <icons.FormatListNumbered className={classes.icon} />
      case 'info':
        return <icons.Restore className={classes.icon} />
      case 'warning':
        return <icons.Alarm className={classes.icon} />
      case 'error':
        return <icons.Warning className={classes.icon} />
      case 'success':
        return <icons.CheckCircle className={classes.icon} />
    }
  }

  return (
    <div className={className(classes.root, getTitleColor())}>
      <div className={classes.titleContainer}>
        {icon ?? getIcon()}
        <h1 className={classes.title}>{title}</h1>
      </div>

      <Tooltip title={information}>
        <icons.InfoOutlined className={classes.informationIcon} />
      </Tooltip>
      <Grid container spacing={1} className={classes.totalRequest}>
        <Grid item xs={6} className={classes.valueContainer}>
          <Doughnut
            data={data}
            options={{
              plugins: {
                legend: { display: false },
                tooltip: {
                  enabled: false,
                  callbacks: { label: prop => prop.label }
                }
              }
            }}
            className={classes.value}
          />
        </Grid>
        <Grid container item xs={6}>
          {info.map((item, index) =>
            <Grid container item key={index} className={classes.label}>
              <Grid item xs={8} style={{ display: 'flex' }}>
                <Box style={{ backgroundColor: data.datasets[0].backgroundColor[index] }} className={classes.percent} />
                <Typography className={classes.itemType}>
                  {item.type + ': '}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography className={classes.number}>
                  {item.count}
                </Typography>
              </Grid>
              {/* <Grid item xs={5}>
                <Typography className={classes.number}>
                  {`($${item.amount})`}
                </Typography>
              </Grid> */}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>

  )
}

export default ValuesChart
