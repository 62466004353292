import { Box, Button, createTheme, Grid, ThemeProvider, Typography } from '@material-ui/core';
import { icons, vipMedal } from 'assets';
import { DesktopPage, PrivateLoader } from 'components/templates';
import { Autocomplete, TextFieldLabel, ValuesComponent } from 'components/UI';
import Modal from 'components/UI/CustomUI/molecules/Modal';
import { docusignActions, subscriptionsActions } from 'ducks/actions';
import { getUser } from 'ducks/selectors';
import { getSubscriptions } from 'ducks/subscriptions/selectors';
import { USER_TYPE } from 'helpers/constants';
import { useIsXsScreen } from 'hooks/useMediaQuery';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../../../UI';
import DashboardGiftItemAgent from '../components/DashboardGiftItemAgent';
import DashboardGiftItemHomeOwner from '../components/DashboardGiftItemHomeOwner';
import { MaintenanceModalsContext } from './context';
import DashboardNoSubscriptions from './DashboardNoSubscriptions';
import FaqModal from './FaqModal';
import PerksModal from './PerksModal';
import useStyles from './styles';
import { canadaStates, states } from 'helpers/states';
import { getSelectedAsOption, getStatesOptionList, getStatesOptionListCanada, isEmpty } from 'helpers/index';

type Props = {}

const themeMobile = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                width: "100%"
            },
            scroller: {
                borderRadius: "8px"
            }
        },
        MuiTab: {
            root: {
                width: "50%"
            },
            wrapper: {
                flexDirection: "row",
                columnGap: "8px"
            }
        },
        MuiSvgIcon: {
            root: {
                '&:first-child': {
                    marginBottom: "0px"
                }
            },
        }
    },
});

const theme = createTheme({
    overrides: {
        MuiTabs: {
            scroller: {
                borderRadius: "8px"
            }
        },
        MuiTab: {
            wrapper: {
                flexDirection: "row",
                columnGap: "8px"
            }
        },
        MuiSvgIcon: {
            root: {
                '&:first-child': {
                    marginBottom: "0px"
                }
            },
        }
    }
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function DashboardMaintenance({ }: Props) {
    const [country, setCountry] = useState('United States')
    const [searchAddress, setSearchAddress] = useState('')
    const [searchState, setSearchState] = useState('')
    const [giftedSubscriptions, setGiftedSubscriptions] = useState<any>()
    const [haveForMeAndForGift, setHaveForMeAndForGift] = useState(false)
    const [docuSignAccessToken, setDocuSignAccessToken] = useState<any>()
    const [ownSubscriptions, setOwnSubscriptions] = useState<any>()
    const [subscriptionsState, setSubscriptionsState] = useState<any>()
    const [currentTab, setCurrentTab] = useState(0)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setCurrentTab(newValue);
        if (newValue === 0) {
            return setSubscriptionsState(giftedSubscriptions)
        }
        return setSubscriptionsState(ownSubscriptions)
    };

    const user = useSelector(getUser)

    const userIsHomeowner = [USER_TYPE.HOME_BUYER, USER_TYPE.HOMEOWNER].includes(user.clientType)

    const subscriptions = useSelector(getSubscriptions("subscriptions"))
    const showSearcher = useMemo(() => subscriptions.length > 6, [subscriptions])
    const showStateSearcher = useMemo(() => subscriptions.length > 6, [subscriptions])



    const classes = useStyles()
    const xsScreen = useIsXsScreen()

    const {
        state: { modalType, modalOpen },
        dispatch: dispatchContext
    } = useContext(MaintenanceModalsContext)

    const fetchSubscriptions = useCallback(() => {
        setLoading(true)
        dispatch(subscriptionsActions.fetchSubscriptions({}, succ => {
            setLoading(false)
        }))
    }, [dispatch])



    function separateSubscriptions() {
        let actualTab = currentTab;
        if (!subscriptions.length) return;
        let giftedSubs = subscriptions.filter((sub: any) => sub.homeowner.id !== user.id)
        let ownSubs = subscriptions.filter((sub: any) => sub.homeowner.id === user.id)
        if (giftedSubs.length && ownSubs.length) {
            setHaveForMeAndForGift(true)
        }
        if (ownSubs.length && !giftedSubs.length) {
            actualTab = 1;
        }
        setOwnSubscriptions(ownSubs)
        setGiftedSubscriptions(giftedSubs)
        setCurrentTab(actualTab)
        if (actualTab === 0) {
            return setSubscriptionsState(giftedSubs)
        }
        return setSubscriptionsState(ownSubs)
    }

    useEffect(() => {
        if (subscriptions) {
            separateSubscriptions()
        }
    }, [subscriptions])

    useEffect(() => {
        fetchSubscriptions()
    }, [])

    useEffect(() => {
        if (docuSignAccessToken) {
            //TODO: change the url for the corresponding to prod
            dispatch(docusignActions.getUserInfo({ token: docuSignAccessToken.access_token, docusignUrl: "https://account-d.docusign.com/oauth/userinfo" }, (succ, userInfo) => {
                if (succ) {
                    // call the embed ui url
                }
            }))
        }
    }, [docuSignAccessToken])

    useEffect(() => {
        if (searchAddress) {
            let subsFiltered = [];
            if (currentTab === 0) {
                subsFiltered = giftedSubscriptions.filter((sub: any) => sub.propertyAddress.fullAddress.toLowerCase().includes(searchAddress))
            } else {
                subsFiltered = ownSubscriptions.filter((sub: any) => sub.propertyAddress.fullAddress.toLowerCase().includes(searchAddress))
            }
            setSubscriptionsState(subsFiltered)
        } else {
            separateSubscriptions()
        }
    }, [searchAddress])

    useEffect(() => {
        if (searchState) {
            let subsFiltered = [];
            if (currentTab === 0) {
                subsFiltered = giftedSubscriptions.filter((sub: any) => sub.propertyAddress.state.toLowerCase() === searchState.slice(0, 2).toLowerCase())
            } else {
                subsFiltered = ownSubscriptions.filter((sub: any) => sub.propertyAddress.state.toLowerCase() === searchState.slice(0, 2).toLowerCase())
            }
            setSubscriptionsState(subsFiltered)
        } else {
            separateSubscriptions()
        }
    }, [searchState])

    useEffect(() => {
        if (userIsHomeowner) {
            setCurrentTab(1)
            setSubscriptionsState(subscriptions)
        }
    }, [userIsHomeowner])

    const allStates = useMemo(() => {
        let usStates = getStatesOptionList();
        let caStates = getStatesOptionListCanada();
        return [...usStates, ...caStates]
    }, [])

    return (
        <PrivateLoader loading={loading}>
            <DesktopPage title={isMobile ? 'Maintenance Dashboard' : 'Home Maintenance Dashboard'}>
                <Modal
                    open={modalOpen && modalType === "PERKS"}
                    setOpen={() => dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })}
                    size="xl"
                    className={classes.modal}
                >
                    <PerksModal />
                </Modal>
                <Modal
                    open={modalOpen && modalType === "FAQS"}
                    setOpen={() => dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })}
                    title={<Typography variant='h5' style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}>FAQ's</Typography>}
                    size="xl"
                    className={classes.modal}
                >
                    <FaqModal />
                </Modal>
                <ThemeProvider theme={isMobile ? themeMobile : theme}>
                    {subscriptions.length ?
                        <Box className={classes.Container}>
                            <Box className={classes.VipContainer}>
                                <img className={classes.VipMedal} src={vipMedal} alt="" />
                                <Box>
                                    <Typography className={classes.VipTitle}>VIP BOSSCAT Maintenance Member</Typography>
                                    <Grid container>
                                        {!isMobile &&
                                            <Typography className={classes.VipSubtitle}>
                                                {userIsHomeowner ? 'Enjoy exclusive BOSSCAT perks as a VIP member.' : 'Clients get exclusive BOSSCAT perks as a VIP member.'}
                                                <Button className={classes.VipLink}
                                                    onClick={() => {
                                                        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                                                        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'PERKS' })
                                                    }}
                                                >
                                                    See my perks
                                                    <icons.ArrowUpward className={classes.Arrow} />
                                                </Button>
                                            </Typography>
                                        }
                                        {!isMobile &&
                                            <Typography className={classes.VipSubtitle}>
                                                Have questions?
                                                <Button className={classes.VipLink}
                                                    onClick={() => {
                                                        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                                                        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'FAQS' })
                                                    }}>
                                                    See FAQ's
                                                    <icons.ArrowUpward className={classes.Arrow} />
                                                </Button>
                                            </Typography>
                                        }
                                    </Grid>
                                </Box>
                                {isMobile &&
                                    <Button className={classes.VipLink}
                                        onClick={() => {
                                            dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                                            dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'PERKS' })
                                        }}
                                    >
                                        See my perks
                                        <icons.ArrowUpward className={classes.Arrow} />
                                    </Button>}
                                {isMobile &&
                                    <Button className={classes.VipLink}
                                        onClick={() => {
                                            dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                                            dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'FAQS' })
                                        }}>
                                        See FAQ's
                                        <icons.ArrowUpward className={classes.Arrow} />
                                    </Button>
                                }
                            </Box>
                            <Box className={classes.Header}>
                                {(userIsHomeowner || (ownSubscriptions?.length && !giftedSubscriptions?.length)) ? null : haveForMeAndForGift ?
                                    <Tabs
                                        TabIndicatorProps={{ style: { backgroundColor: "var(--bosscat-blue-600)", zIndex: "-1", height: "100%" } }}
                                        value={currentTab}
                                        onChange={handleChange}
                                        aria-label="basic tabs example">
                                        <Tab className={classes.Tab} icon={<icons.CardGiftcard />} label={`Gifts (${giftedSubscriptions?.length})`} {...a11yProps(0)} />
                                        <Tab className={classes.Tab} icon={<icons.House />} label={`For me (${ownSubscriptions?.length})`} {...a11yProps(1)} />
                                    </Tabs>
                                    :
                                    <Grid item xs={12} sm={6} xl={3}>
                                        <ValuesComponent
                                            title='Gifts'
                                            titleColor='info'
                                            value={subscriptionsState?.length}
                                            icon={<icons.CardGiftcard className={classes.Icon} />}
                                            height={!xsScreen ? '120px' : '100px'}
                                        />
                                    </Grid>
                                }
                                <div className={classes.search}>
                                    {showStateSearcher ?
                                        <Box style={{ width: "48%" }}>
                                            <Autocomplete
                                                // label='STATE'
                                                onChange={(value: any) => setSearchState(value?.key)}
                                                value={!isEmpty(states) && !isEmpty(searchState)
                                                    ? getSelectedAsOption(states.find(opt => opt?.abbreviation === searchState) || canadaStates.find(opt => opt?.abbreviation === searchState))
                                                    : null}
                                                options={allStates}
                                                placeholder='Search by state'
                                                className={classes.inputAuto}
                                                classes={{
                                                    input: classes.inputAutocomplete,
                                                }}
                                                inputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box> : null}
                                    {showSearcher ?
                                        <Grid className={classes.Search} item xs={12} sm={6} xl={6}>
                                            <TextFieldLabel
                                                type='text'
                                                value={searchAddress}
                                                onChange={(event) => setSearchAddress(event.target.value)}
                                                placeholder='Search by Address...'
                                                autoComplete='off'
                                                icon={<icons.Search />}
                                            />
                                        </Grid> : null}
                                </div>
                            </Box>
                            <TabPanel value={currentTab} index={0}>
                                <Box className={classes.GiftsWrapper}>
                                    {subscriptionsState && subscriptionsState.map((sub: any, index: number) =>
                                        <Box key={`${sub.id}-${index}`} className={classes.GiftItem}>
                                            <DashboardGiftItemAgent subscription={sub} />
                                        </Box>)}
                                </Box>
                            </TabPanel>
                            <TabPanel value={currentTab} index={1}>
                                <Box className={classes.GiftsWrapper}>
                                    {subscriptionsState && subscriptionsState.map((sub: any, index: number) =>
                                        <Box style={{ width: "100%" }} key={`${sub.id}-${index}`}>
                                            <DashboardGiftItemHomeOwner subscription={sub} />
                                        </Box>)}
                                </Box>
                            </TabPanel>
                        </Box>
                        :
                        <DashboardNoSubscriptions />
                    }
                </ThemeProvider>
            </DesktopPage>
        </PrivateLoader>
    )
}

export default DashboardMaintenance
