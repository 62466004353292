import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      right: '46px',
      zIndex: 1001,
      [theme.breakpoints.down('md')]: {
        right: '0px',
        zIndex: 1003,
      },
    },
    dev: {
      position: 'absolute',
      top: '3px',
      right: '24px'
    },
    avatar: {
      margin: '0 0 0 auto',
      borderRadius: '8px',
      width: theme.spacing(5),
      height: theme.spacing(5),
      objectFit: 'cover'
    },
    avatarBox: {
      cursor: 'pointer'
    },
    loading: {
      color: theme.palette.primary.contrastText
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(3),
      paddingTop: '26px',
      [theme.breakpoints.down('md')]: {
        padding: 0,
        paddingTop: '14px',
      }
    },
    icon: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    },
    personIcon: {
      color: theme.palette.background.default,

      fontSize: theme.spacing(5)
    },
    menuPosition: {
      marginTop: '35px'
    },
    menuContent: {
      display: 'flex',
      marginTop: '8px'
    },
    menuItems: {
      padding: '8px 16px 8px 96px',
      '&:hover': {
        backgroundColor: 'var(--row-focus)'
      },
      '&:active': {
        backgroundColor: 'var(--row-active)'
      }
    },
    logOut: {
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: 'var(--expired-color-back)'
      },
      '&:active': {
        backgroundColor: 'var(--expired-color-back-active)'
      }
    },
    menuItemIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      paddingRight: theme.spacing(1),
      transform: 'translateY(-2px)'
    },
    firstMenuItem: {
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px'
    },
    lastMenuItem: {
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px'
    }
  })
)
