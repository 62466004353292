import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone'
import { Box, Typography } from '../../..'
import useStyles from './styles'
import { UploadDocumentsProps } from './types'
import { upload, file as fileIcon } from '../../../../../assets'
import { imageMimeTypes, applicationMimeTypes } from '../../../../../helpers/files'
import ActionButton from '../../atoms/ActionButton'
import { CircularProgress } from '@material-ui/core'

const allowedFilesExtensions = [...imageMimeTypes, ...applicationMimeTypes]

const UploadDocuments: FC<UploadDocumentsProps> = ({ file, dropzoneText = 'Upload File', error = false, className = '', onChange, allowedFiles }) => {
  const classes = useStyles()

  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    // update on file change
  }, [file])

  const handleAdd = (newFile: FileObject[]) => {
    setUploading(true)
    onChange(newFile[0].file, () => setUploading(false))
  }

  const handleDeleteFile = () => {
    onChange(null, () => setUploading(false))
  }

  return (
    <Box className={`${clsx(classes.upload, error ? classes.errorColor : '')} ${className}`}>
      {file !== null
        ? (
          <Box className={classes.filesBox}>
            <Box className={classes.file}>
              <img src={fileIcon} alt='' />
              <Typography
                variant='body1'
              >
                {file.name}
              </Typography>
              <Box className={classes.delete}>
                <ActionButton
                  backgroundColor='secondary'
                  icon='delete'
                  onClick={() => handleDeleteFile()}
                />
              </Box>
            </Box>
          </Box>
        )
        : (uploading
          ? (
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>)
          : (
            <DropzoneAreaBase
              // @ts-ignore
              Icon={() => <img src={upload} alt='' />}
              dropzoneClass={classes.dropzone}
              dropzoneText={dropzoneText}
              maxFileSize={200000000}
              acceptedFiles={allowedFiles || allowedFilesExtensions}
              onAdd={handleAdd}
            />)
        )}
    </Box>
  )
}

export default UploadDocuments
