import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    name: {
      width: '100%',
      minHeight: '54px',
      maxHeight: '54px',
      marginTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      marginRight: 15,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    signature: {
      width: '100%',
      minHeight: '54px',
      maxHeight: '54px',
      marginTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    role: {
      marginTop: '8px',
    },
    title: {
      color: 'var(--head-text-color)',
      fontSize: '18px',
    },
    disclaimer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      "@global": {
        '.MuiTypography-root': {
          paddingTop: '0px !important',
          fontFamily: 'LatoNormal'
        }
      }
    },
    terms: {
      paddingTop: '20px',
      marginLeft: '2px',
      color: 'var(--bosscat-blue-600)',
      transform: 'translateY(-1px)',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '.2rem !important'
      }
    },
    rigth: {
      justifyContent: 'flex-end'
    },
    termsButton: {
      border: '0px solid',
      textTransform: 'none',
      height: '35px',
      margin: '0px',
      '&:hover': {
        backgroundColor: 'var(--white-color)'
      }
    },
    phone: {
      "@global": {
        ".MuiOutlinedInput-notchedOutline": {
          border: '2px solid var(--border-color) !important',
        },
        ".MuiInputBase-input ": {
          color: '#AAACBB',
        }
      }
    },
    checkboxContainer: {
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'nowrap',
        background: '#FAFDFF',
        borderRadius: 12,
        margin:'1rem .4rem',
        '@global': {
          '.MuiTypography-caption': {
            [theme.breakpoints.down('sm')]: {
              fontFamily: 'LatoNormal'
            }
          }
        }
      },
      '@global': {
        '.MuiButtonBase-root': {
          [theme.breakpoints.down('sm')]: {
            padding: 0,
          }
        },
        '.MuiButton-label':{
          [theme.breakpoints.down('sm')]: {
            paddingTop: '0 !important',
          }
        },
        '.MuiTypography-root':{
          paddingTop: '0 !important',
          [theme.breakpoints.down('sm')]: {
            marginLeft: '1rem',
            lineHeight: '1rem'
          }
        }
      }
    }
  })
)
