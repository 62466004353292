import { FC, useEffect, useRef } from 'react'
import MaskedInput from 'react-text-mask'
import { Typography, TextField, Box } from '../../..'
import { TextMaskCustomProps } from '../../atoms/MaskedInput/types'
import useStyles from './styles'
import { TextFieldProps } from './types'
import { icons } from 'assets'

const TextMaskCustom: FC<TextMaskCustomProps> = (props) => {
  const { mask, inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  )
}

const TextFieldLabel: FC<TextFieldProps> = ({
  label = '',
  type = '',
  value,
  error = false,
  helperText,
  placeholder,
  className,
  onChange = () => { },
  autoComplete,
  mask = null,
  disabled = false,
  icon,
  onBlur,
  shouldScrollOnError,
  labelClasses = '',
  ...rest
}) => {
  const classes = useStyles()
  const selectText = (
    event: any
  ): void => {
    event.target.select();
  };

  const compRef = useRef<HTMLDivElement>(null)

  const scrollIntoView = () => {
    if (compRef.current) {
      compRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    error && shouldScrollOnError && scrollIntoView()
  }, [error])

  return (
    <div className={`${className} ${classes.TextFieldLabel}`} ref={compRef}>
      {label ?
        typeof label === "object" ?
          label
          :
          <Typography variant='body1' className={`${classes.title} ${labelClasses}`}>
            {label}
          </Typography>
        : null
      }
      <TextField
        {...rest}
        type={type}
        variant='outlined'
        size='small'
        onDoubleClick={selectText}
        autoComplete={autoComplete}
        value={value}
        error={error}
        helperText={helperText}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        InputProps={mask !== null
          ? { inputProps: { mask }, inputComponent: TextMaskCustom as any }
          : {
            inputProps: {
              onBlur: onBlur
            }
          }
        }
        disabled={disabled}
      />
      {icon ? <Box className={classes.Icon}>{icon}</Box> : null}
    </div>
  )
}

export default TextFieldLabel
