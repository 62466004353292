import { createTheme } from '.'

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xxl: true,
    xxxl: true
  }
}

export default createTheme({
  props: {
    MuiWithWidth: {
      initialWidth: 'lg'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 445,
      md: 588,
      lg: 868,
      xl: 1321, // 1024
      xxl: 1500,
      xxxl: 1800
    }
  },
  typography: {
    fontFamily: [
      'Lato',
      'Roboto Condensed',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: { size: '80px' },
    h2: { size: '62px' },
    h3: { size: '46px' },
    h4: { size: '32px' },
    h5: { size: '24px' },
    h6: { size: '20px' },
    subtitle1: { size: '18px' },
    subtitle2: { size: '16px' },
    body1: { size: '18px' },
    body2: { size: '16px' },
    button: { size: '16px' },
    caption: { size: '14px' }
  },
  palette: {
    background: {
      default: '#FFF'
    },
    primary: {
      light: 'rgba(58, 102, 255, 1)',
      main: 'rgb(47, 123, 247);',
      dark: 'rgba(26, 42, 85, 1)',
      contrastText: '#FFF'
    },
    secondary: {
      light: 'rgba(235, 81, 81, 1)',
      main: 'rgba(224, 31, 31, 1)',
      dark: 'rgba(202, 0, 0, 1)',
      contrastText: '#FFF'
    },
    text: {
      primary: 'rgba(26, 42, 85, 1)',
      secondary: 'rgba(170, 172, 187, 0.8)',
      disabled: 'rgba(204, 205, 214, 1)'
    },
    info: {
      main: 'rgba(0, 176, 240, 1)',
      dark: 'rgba(0, 123, 168, 1)',
      light: 'rgba(84, 200, 243, 1)',
      contrastText: '#FFF'
    },
    success: {
      main: 'rgba(23, 185, 117, 1)',
      dark: 'rgba(31, 156, 36, 1)',
      light: 'rgba(147, 218, 150, 1)',
      contrastText: '#FFF'
    },
    error: {
      main: 'rgba(244, 67, 54, 1)',
      dark: 'rgba(227, 27, 12, 1)',
      light: 'rgba(248, 128, 120, 1)',
      contrastText: '#FFF'
    },
    warning: {
      main: 'rgba(253, 179, 0, 1)',
      dark: 'rgba(206, 146, 0, 1)',
      light: 'rgba(255, 215, 119, 1)',
      contrastText: '#FFF'
    },
    divider: 'rgba(0, 0, 0, 0.12)'
  },
  overrides: {
    MuiTypography: {
      h1: { '@media (min-width: 588px)': { fontSize: '80px' } },
      h2: { '@media (min-width: 588px)': { fontSize: '60px' } },
      h3: { '@media (min-width: 588px)': { fontSize: '42px' } },
      h4: { '@media (min-width: 588px)': { fontSize: '32px' } },
      h5: { '@media (min-width: 588px)': { fontSize: '24px' } },
      h6: { '@media (min-width: 588px)': { fontSize: '22px' } },
      subtitle1: { '@media (min-width: 588px)': { fontSize: '20px' } },
      subtitle2: { '@media (min-width: 588px)': { fontSize: '18px' } },
      body1: { '@media (min-width: 588px)': { fontSize: '16px' } },
      body2: { '@media (min-width: 588px)': { fontSize: '14px' } },
      button: { '@media (min-width: 588px)': { fontSize: '14px' } },
      caption: { '@media (min-width: 588px)': { fontSize: '12px' } }
    },
    MuiTextField: {
      root: {
        color: 'rgba(47, 49, 51, 0.6)'
      }
    },
    MuiInputLabel: {
      outlined: {
        '@media (min-width: 588px)': { // md
          '&$marginDense': {
            transform: 'translate(14px, 18px) scale(1)'
          },
          '&$shrink': {
            transform: 'translate(14px, -6px) scale(0.75)'
          }
        }
      }
    },
    MuiInput: {
      underline: {
        borderBottomColor: '#FFF',
        '&:hover:not($disabled):after': { borderBottomColor: '#FFF' },
        '&:hover:not($disabled):before': { borderBottomColor: '#FFF' },
        '&:after': { borderBottomColor: '#FFF' },
        '&:before': { borderBottomColor: '#FFF' }
      }
    },
    MuiInputBase: { root: { height: '54px', marginRight: '6px' } },
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px'
      }
    },
    MuiButtonBase: { root: { height: '54px' } },
    MuiButton: {
      contained: {
        '&:hover': {
          backgroundColor: 'var(--button-focus-color) !important'
        }
      },
      outlined: {
        '&:hover': {
          borderColor: 'var(--button-focus-color)',
          color: 'var(--button-focus-color)'
        }
      },
      label: {
        paddingTop: '3px'
      }
    },
    MuiCheckbox: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent !important'
        }
      }
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: 'var(--bosscat-blue-600)'
      }
    },
    MuiCircularProgress: {
      circle: {
        strokeDasharray: '32px, 200px !important',
        strokeLinecap: 'round',
        color: 'var(--bosscat-blue-600)'
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(245, 247, 255, 1)'
        },
        '&$selected, &$selected:hover': {
          background: 'rgba(245, 247, 255, 1)',
          color: 'var(--bosscat-blue-600)',
          '& .MuiListItemIcon-root': {
            color: 'var(--bosscat-blue-600)'
          }
        }
      }
    },
    MuiBreadcrumbs: {
      separator: {
        colorPrimary: {
          color: 'var(--bosscat-blue-600)'
        }

      }
    }
  }
})
