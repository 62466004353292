import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '58px'
    },
    subtitle: {
      color: 'var(--text-color)',
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: '28px',
      margin: '30px 0px 8px 0px'
    },
    text: {
      color: 'var(--text-color)',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '24px'
    },
    detail: {
      color: 'var(--text-color)',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '22px'
    },
    line: {
      height: '2px',
      backgroundColor: 'var(--border-color)',
      margin: '20px 0px'
    },
    button: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      marginTop: '30px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  })
)
