import { FC, useState } from 'react'
import useStyles from './styles'
import { ActionButton } from '../../../CustomUI'
import { CounterProps } from './types'

const Counter: FC<CounterProps> = ({
  initialValue,
  onDecrease = () => { },
  onIncrease = () => { },
  color = 'primary'
}) => {
  const classes = useStyles()
  const [counter, setCounter] = useState<number>(initialValue)

  const increaseCounter = () => {
    setCounter(counter + 1)
    onIncrease(counter + 1)
  }

  const decreaseCounter = () => {
    setCounter(counter - 1)
    onDecrease(counter - 1)
  }

  return (
    <div className={classes.root}>
      <ActionButton
        backgroundColor={color}
        icon='remove'
        disabled={counter === 1}
        onClick={decreaseCounter}
      />
      <div className={classes.counterContainer}>
        <h1 className={classes.counter}>{counter}</h1>
      </div>
      <ActionButton
        backgroundColor={color}
        icon='add'
        onClick={increaseCounter}
      />
    </div>
  )
}

export default Counter
