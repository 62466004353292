import storeInfo from 'ducks/store'
import { orderBy as SortBy } from 'lodash'
import { capitalize, ESTIMATE_PUBLIC_STATUS, ESTIMATE_VIEW, isRoundPercentageOf, round } from 'helpers'
import { getEstimateItemsGroupBy } from 'ducks/selectors'
import { Estimate, Item } from 'ducks/types'
import { isPercentageOf } from 'helpers/index'
import {
  ESTIMATE_VIEW_COLORS_APPROVED,
  ESTIMATE_VIEW_COLORS_PRIORITY,
  ESTIMATE_VIEW_COLORS_TYPES,
  ESTIMATE_VIEW_BETTER_COLORS_TYPES,
  ITEM_STATUS
} from 'helpers/constants'
import { EstimatesIndicators } from './types'

export const getEstimates = (
  orderBy?: string,
  order?: 'asc' | 'desc',
  status?: ESTIMATE_PUBLIC_STATUS
) => (): Estimate[] => {
  const { estimates } = storeInfo.store.getState()
  if (!estimates || !estimates.length) return []
  if (status) { return estimates.filter(estimate => estimate.publicStatus === status) || [] }
  if (orderBy && order) return SortBy(estimates, orderBy, order) as Estimate[]
  return estimates
}

export const getEstimateById = (estimateId: string) => (): Estimate | null => {
  const { estimates } = storeInfo.store.getState()
  return estimates.find(estimate => estimate.id === estimateId) || null
}

export const getEstimatesIndicators = () => (): EstimatesIndicators => {
  const { config: { estimatePagination, estimateStats } } = storeInfo.store.getState()

  if (!estimatePagination || !estimateStats) {
    return { total: 0, inProgress: 0, expired: 0, completed: 0, approved: 0 }
  }

  return {
    total: estimatePagination?.total ?? 0,
    inProgress: estimateStats.find(stat => stat.status === 'In Progress')?.count ?? 0,
    expired: estimateStats.find(stat => stat.status === 'Expired')?.count ?? 0,
    completed: estimateStats.find(stat => stat.status === 'Completed')?.count ?? 0,
    approved: estimateStats.find(stat => stat.status === 'Approved')?.count ?? 0
  }
}

export const getEstimateChartData = (
  selectedType: ESTIMATE_VIEW,
  hideRemoved: boolean,
  showBetterStyle?: boolean
): any => {
  const estimates = getEstimateItemsGroupBy(selectedType, hideRemoved)()

  const data = {
    labels: [''],
    datasets: [
      {
        label: '',
        backgroundColor: [''],
        labelColor: [''],
        hoverOffset: 1,
        data: [0]
      }
    ]
  }

  let colors: string[]
  let colorLabels: string[]
  let labels: string[]
  let totalData: number[]
  let totalAmount: number

  switch (selectedType) {
    case ESTIMATE_VIEW.APPROVED:
      colors = []
      colorLabels = []
      labels = []
      totalData = []
      Object.keys(estimates).forEach((item: string) => {
        let total = 0
        colors.push(
          item === 'Approved'
            ? (showBetterStyle
              ? ESTIMATE_VIEW_COLORS_APPROVED.ApprovedBetter
              : ESTIMATE_VIEW_COLORS_APPROVED.Approved
            )
            : ESTIMATE_VIEW_COLORS_APPROVED.Rejected
        )
        colorLabels.push(item === 'Approved' && !showBetterStyle ? '#1A2A55' : '#FFF')
        estimates[item].forEach((element: Item) => {
          total += element.totalPrice
        })
        totalData.push(total)
        labels.push(`${capitalize(item)}:`)
      })
      data.datasets[0].backgroundColor = colors
      totalAmount = 0
      totalData.forEach(amount => (totalAmount += amount))
      data.datasets[0].data = totalData.map((item, index) => {
        labels[index] += ` $${round(item, 2)} (${isPercentageOf(item, totalAmount)}%)`
        return isPercentageOf(item, totalAmount)
      })
      data.labels = labels
      data.datasets[0].labelColor = colorLabels
      return data

    case ESTIMATE_VIEW.PRIORITY:
      colors = []
      colorLabels = []
      labels = []
      totalData = []
      Object.keys(estimates).forEach(item => {
        let total = 0
        switch (item) {
          case 'Urgent':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Urgent)
            colorLabels.push('#FFF')
            break
          case 'High':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.High)
            colorLabels.push('#FFF')
            break
          case 'Medium':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Medium)
            colorLabels.push('#1A2A55')
            break
          case 'Low':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Low)
            colorLabels.push('#1A2A55')
            break
          case 'Cosmetic':
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Cosmetic)
            colorLabels.push('#FFF')
            break
          default:
            colors.push(ESTIMATE_VIEW_COLORS_PRIORITY.Cosmetic)
            colorLabels.push('#FFF')
            break
        }
        estimates[item].forEach((element: Item) => {
          if (element.status !== ITEM_STATUS.REJECTED) {
            total += element.totalPrice
          }
        })
        totalData.push(total)
        labels.push(`${capitalize(item)}:`)
      })
      data.datasets[0].backgroundColor = colors
      totalAmount = 0
      totalData.forEach(amount => (totalAmount += amount))
      data.datasets[0].data = totalData.map((item, index) => {
        labels[index] += ` $${round(item, 2)} (${isPercentageOf(item, totalAmount)}%)`
        return isPercentageOf(item, totalAmount)
      })
      data.labels = labels
      data.datasets[0].labelColor = colorLabels
      return data

    case ESTIMATE_VIEW.TYPE:
      colors = []
      colorLabels = []
      labels = []
      totalData = []
      Object.keys(estimates).forEach((item, index) => {
        let total = 0
        colors.push(showBetterStyle
          ? ESTIMATE_VIEW_BETTER_COLORS_TYPES[index]
          : ESTIMATE_VIEW_COLORS_TYPES[index])
        estimates[item].forEach((element: Item) => {
          if (element.status !== ITEM_STATUS.REJECTED) {
            total += element.totalPrice
          }
        })
        colorLabels.push('#FFF')
        totalData.push(total)
        labels.push(`${capitalize(item)}:`)
      })
      data.datasets[0].backgroundColor = colors
      totalAmount = 0
      totalData.forEach(amount => (totalAmount += amount))
      data.datasets[0].data = totalData.map((item, index) => {
        labels[index] += ` $${round(item, 2)} (${isRoundPercentageOf(item, totalAmount)}%)`
        return isRoundPercentageOf(item, totalAmount)
      })
      data.labels = labels
      data.datasets[0].labelColor = colorLabels
      return data

    default:
      return data
  }
}
