import { useStripe, useElements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import { reportProblem } from 'assets/index'
import { FC, useEffect, useState } from 'react'
import { Button, Typography, Grid, STextField, Dialog, Box } from '../../../../UI'

import useStyles from './styles'
import { icons } from 'assets'

import { NewCardFormProps } from './types'
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js'
import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js'
import { StripeCardCvcElementChangeEvent } from '@stripe/stripe-js'
import { isEmpty } from 'helpers/index'

const Form: FC<NewCardFormProps> = ({ onSuccess, submitButtonRef, onFocus, showButton, onClose, onChange, finishLoading, customErrorMessage }) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()

  const cardNumberElement = elements?.getElement('cardNumber')
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [cardHolder, setCardHolder] = useState('')
  const [showWarning, setShowWarning] = useState(false)

  const [numberValid, setNumberValid] = useState(false)
  const [expiryValid, setExpiryValid] = useState(false)
  const [cvcValid, setCvcValid] = useState(false)


  useEffect(() => {
    onChange && onChange(numberValid && expiryValid && cvcValid && !isEmpty(cardHolder))
  }, [numberValid, expiryValid, cvcValid, cardHolder])

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    if (!stripe || !elements || loading || !cardHolder || !cardNumberElement) {
      setShowWarning(true)
      return
    } else setShowWarning(false)

    setErrorMessage(undefined)
    setLoading(true)

    const { error, paymentMethod } = await
      stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: { name: cardHolder },
      })

    if (error) {
      setErrorMessage(error.message)
      setLoading(false)
      finishLoading && finishLoading()
    } else if (paymentMethod) {

      onSuccess && onSuccess(paymentMethod)
      onClose && onClose()
      setLoading(false)
      finishLoading && finishLoading()
    } else {
      setLoading(false)
      finishLoading && finishLoading()
    }
  }

  useEffect(() => {
    //initialize the Stripe elements
    stripe && stripe.elements({});
  }, [])

  const cardStyle = {
    style: {
      base:
      {
        fontFamily: 'Lato-Normal,Roboto Condensed,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      }
    }
  }

  return (
    <>
      <Typography className={classes.newCardTitle}>
        Add new credit card
      </Typography>
      <form onSubmit={handleSubmit} onFocus={onFocus}>
        {stripe && elements && (
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: 'grid', gap: '12px', padding: '12px 0' }}>
              <Typography>Card Holder</Typography>
              <STextField
                className={classes.cardHolder}
                value={cardHolder}
                error={showWarning && !cardHolder}
                placeholder={'Card Holder Name'}
                onChange={(event: any) => { setCardHolder(event.target.value) }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'grid', gap: '4px', padding: '0' }}>
              <Typography>Card Info</Typography>
              <Grid container className={classes.cardFieldContainer}>
                <Grid item xs={12} className={`${classes.cardField} ${classes.cardNumber}`}>
                  <CardNumberElement
                    options={cardStyle}
                    onFocus={onFocus}
                    onChange={(event: StripeCardNumberElementChangeEvent) => setNumberValid(event.complete)}
                  />
                </Grid>
                <Grid item container>
                  <Grid item xs={6} className={`${classes.cardField} ${classes.cardExpiry}`}>
                    <CardExpiryElement
                      options={cardStyle}
                      onFocus={onFocus}
                      onChange={(event: StripeCardExpiryElementChangeEvent) => setExpiryValid(event.complete)}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.cardField}>
                    <CardCvcElement
                      options={cardStyle}
                      onFocus={onFocus}
                      onChange={(event: StripeCardCvcElementChangeEvent) => setCvcValid(event.complete)}
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            {errorMessage ? customErrorMessage ? customErrorMessage(errorMessage) : (
              <Grid item xs={12} className={classes.errorMessage}>
                <img src={reportProblem} alt='' />
                <Typography variant='caption' style={{ paddingTop: '4px' }}>
                  {errorMessage}
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.submit}
                fullWidth
                style={{ display: showButton ? 'block' : 'none' }}
                ref={submitButtonRef}
              >
                Add Payment Method
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  )
}

const NewCardModal: FC<NewCardFormProps> = ({ onSuccess, submitButtonRef, onFocus, type, showButton, onClose, onChange }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  return (
    <>
      <Button
        endIcon={<icons.CreditCard className={classes.creditCardIcon} />}
        className={classes.button}
        onClick={() => setOpen(true)}
      >
        Add Credit Card
      </Button>
      <Dialog onClose={handleClose} open={open} >
        <Box className={classes.modal}>
          <Form
            onSuccess={onSuccess}
            submitButtonRef={submitButtonRef}
            onFocus={onFocus}
            type={type}
            showButton={showButton}
            onClose={handleClose}
            onChange={onChange}
          />
        </Box>

      </Dialog>
    </>
  )
}

const NewCardForm: FC<NewCardFormProps> = ({
  onSuccess, submitButtonRef, onFocus, type = "inline", showButton, onClose, onChange, finishLoading, customErrorMessage }) => {
  if (type === "inline") {
    return <Form
      onSuccess={onSuccess}
      submitButtonRef={submitButtonRef}
      onFocus={onFocus}
      type={type}
      showButton={showButton}
      finishLoading={finishLoading}
      onChange={onChange}
      customErrorMessage={customErrorMessage}
    />
  } else {
    return <NewCardModal
      onSuccess={onSuccess}
      submitButtonRef={submitButtonRef}
      onFocus={onFocus}
      type={type}
      showButton={showButton} />
  }
}

export default NewCardForm
