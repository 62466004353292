import { FC } from 'react'
import { TextFieldProps } from './types'
import useStyles from './styles'

import { TextField as MaterialTextField } from '@material-ui/core'

const TextField: FC<TextFieldProps> = ({
  type,
  value,
  error,
  fullWidth,
  helperText,
  margin,
  size,
  placeholder,
  onChange,
  autoComplete,
  disabled = false,
  InputProps = {}
}) => {
  const { root, focused, notchedOutline, input } = useStyles()
  const selectText = (
    event: any
  ): void => {
    event.target.select();
  };
  return (
    <MaterialTextField
      disabled={disabled}
      value={value}
      type={type}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      margin={margin}
      onChange={onChange}
      size={size}
      variant='outlined'
      //onDoubleClick={selectText}
      autoComplete={autoComplete}
      placeholder={placeholder}
      label=''
      inputProps={autoComplete ? {
        form: {
          autocomplete: { autoComplete }
        },
      } : undefined}
      InputProps={{
        classes: disabled
          ? { root, input }
          : { root, focused, notchedOutline, input },

        ...InputProps,
      }}
    />
  )
}

export default TextField
