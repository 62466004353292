import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'


export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      margin: '30px 20px 0px 20px'
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    rows: {
      display: 'flex',
      flexDirection: 'row'
    },
    title: {
      fontFamily: 'Lato',
      fontWeight: 500,
      color: 'var(--head-text-color)'
    },
    text: {
      fontFamily: 'Lato',
      fontWeight: 400,
      color: 'var(--input-color)'
    },
    textHigh: {
      fontFamily: 'Lato',
      color: 'var(--bosscat-blue-600)'
    },
    totalValue: {
      fontFamily: 'Lato',
      fontWeight: 500,
      margin: '0px 4px',
      color: 'var(--head-text-color)'
    },
    icon: {
      height: '15px',
      color: 'var(--head-text-color)',
      margin: '2px 7px 0px 0px'
    },
    logo: {
      height: '66px',
      margin: '0px 9px 0px 0px'
    },
    pdf: {
      height: '64px',
      width: '64px',
      [theme.breakpoints.down('md')]: {
        height: '44px',
        width: '44px'
      }
    },
    button: {
      height: '64px',
      width: '64px',
      margin: '0px 2px 0px 15px',
      backgroundColor: 'var(--row-background)',
      '&:hover': {
        backgroundColor: 'var(--row-background)'
      },
      [theme.breakpoints.down('md')]: {
        height: '44px',
        width: '44px',
        margin: '0px 2px 0px 5px'
      }
    }
  })
)
