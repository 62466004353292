import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    autocomplete: {
      width: '237px',
    },
    mapContainer: {
      display: 'flex',
      width: '100%',
      height: '90%',
      padding: '16px 0px',
    },
    tooltip: {
      backgroundColor: 'var(--white-color) !important',
      color: 'var(--text-color) !important',
      width: '96px',
      height: 'auto',
    },
    tooltipTitle: {
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--text-color)',
    },
  })
)
