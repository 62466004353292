import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      minHeight: '100vh',
      minWidth: '280px',
      backgroundColor: 'var(--bosscat-grey-200)',
      borderRight: '1px solid var(--bosscat-black-200);'
    },
    rootMobile: {
      width: '100%',
      maxWidth: '350px',
      minHeight: '100vh',
      position: 'absolute',
      zIndex: 99993,
      backgroundColor: 'white'
    },
    slideMenuBack: {
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      zIndex: 99992,
      backgroundColor: 'rgba(0,0,0,0.2)'
    },
    logo: {
      display: 'flex',
      width: '100%',
      paddingTop: theme.spacing(3),
      paddingLeft: '16px',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        '@global': {
          'img': {
            maxWidth: '200px'
          }
        }
      }
    },
    menuButton: {
      position: 'absolute',
      zIndex: 99994,
      margin: theme.spacing(1),
      marginLeft: 0
    },
    menuIcon: {
      fontSize: '1.2em'
    },
    icon: {
      minWidth: '35px',
      paddingLeft: '4px'
    },
    options: {
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    option: {
      position: 'relative',
      marginTop: '8px',
      minHeight: '48px',
      borderRadius: '8px',
      color: 'var(--bosscat-black-400)',
      paddingLeft: '0',
      paddingRight: '0',
    },
    selectedOption: {
      position: 'relative',
      marginTop: '8px',
      minHeight: '48px',
      borderRadius: '8px',
      color: 'var(--input-color)',
      paddingLeft: '0',
      paddingRight: '0',
      backgroundColor: 'white !important',
      boxShadow: '0px 1px 1px rgba(9, 25, 49, 0.24), 0px 0px 1px rgba(9, 25, 49, 0.31)'
    },
    divider: {
      margin: '8px 24px'
    },
    subOptions: {
      display: 'grid',
      gap: '16px',
      padding: '24px',
      width: '100%',
      paddingBottom: '0px'
    },
    line: {
      width: '90%',
      height: 0,
      border: '1px solid #CCCDD6',
      alignSelf: 'center',
      marginTop: '24px',
      margin: 'auto'
    },
    button: {
      width: '100%',
      height: '60px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontSize: '18px',
      lineHeight: '150%',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      padding: '1.7rem 0',
      "@global": {
        '.MuiButton-label': {
          paddingTop: 0
        }
      }
    },
    maintenanceButton: {
      background: 'var(--bosscat-black-1000)',
      color: 'var(--repairs-yellow-500)'
    },
    buttonReverse: {
      width: '100%',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderRadius: 8,
      border: '3px solid'
    },

    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      border: '2px solid var(--bosscat-blue-600)',
      boxSizing: 'border-box',
      borderRadius: '16px'
      // alignItems: 'center'
    },
    titleDetail: {
      display: 'flex',
      flexDirection: 'row'
    },
    iconJob: {
      marginTop: '8px',
      marginLeft: '8px',
      height: '48px',
      width: '62px'
    },
    iconArrow: {
      marginTop: '8px',
      marginRight: '8px',
      height: '14px',
      width: '14px'
    },
    arrow: {
      transform: "rotate(-90deg)"
    },
    smallInfo: {
      fontFamily: 'NEXT_ART_Normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: 'var(--date-color)',
      marginRight: '8px',
      marginBottom: '8px',
      marginLeft: '8px'
      // margin: '8px'
    },
    getEstimate: {
      display: 'grid',
      gap: '12px',
      borderRadius: '12px',
      border: '2px solid var(--bosscat-blue-600)',
      padding: '12px'
    },
    getEstimateTitle: {
      display: 'flex',
      gap: '12px'
    },
    newItemWrapper: {
      background: 'var(--repairs-yellow-100)',
      borderRadius: '4px',
      padding: "2px 4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "5px"
    },
    newItem: {
      color: "var(--repairs-yellow-700)",
      fontSize: '12px',
      lineHeight: '18px',
      marginRight: "2px"
    },
    newItemImg: {
      width: "18px",
      height: "18px"
    },
    FirstTimeMedal: {
      width: "158px",
      height: "180px",
      [theme.breakpoints.down('md')]: {
        width: "124px",
        height: "140px",
      },
    },
    FirstTimeWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      padding: "16px",
      backgroundColor: "#F9FAFA",
      [theme.breakpoints.down('md')]: {
        padding: "32px 16px",
        marginTop: "48px"
      },
    },
    FirstTimeTitle: {
      fontSize: "30px",
      fontFamily: "LatoNormal",
      textAlign: "center",
      margin: "24px 0 16px",
      [theme.breakpoints.down('md')]: {
        fontSize: "22px",
      },
    },
    FirstTimeSubtitle: {
      fontSize: "20px",
      textAlign: "center",
      [theme.breakpoints.down('md')]: {
        fontSize: "18px",
        fontFamily: "NEXT_ART_Normal",
      },
    }
  })
)
