import { FC } from 'react'
import { Typography, Box } from '../../..'
import { icons } from '../../../../../assets'
import useStyles from './styles'
import { StatusBarProps } from './types'

const WarningBar: FC<StatusBarProps> = ({ text }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <icons.Warning className={classes.warning} />
      <Typography
        variant='caption'
        className={classes.text}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default WarningBar
