import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'LatoBlack',
      fontWeight: 900,
      paddingBottom: '8px',
      color: "#0B060F",
      fontSize: '18px'
    },
    TextFieldLabel: {
      '@global': {
        '.MuiInputBase-root': {
          boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
        }
      },
      position: "relative"
    },
    Icon: {
      position: "absolute",
      top: "50%",
      right: "24px",
      transform: "translateY(-50%)",
      width: "24px",
      height: "24px",
      color: "#AAACBB"
    }
  })
)
