import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    testimonials: {
      padding: '4rem 7rem 7rem',
      display: 'flex',
      gap: '5rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '2rem'
      }
    },
    testimonialTitle: {
      color: '#A5965A',
      fontSize: '18px',
      fontWeight: 700,
      textAlign: 'center'
    },
    testimonialComment: {
      color: '#A5965A',
      fontSize: '18px',
      fontWeight: 700,
      textAlign: 'center',
      maxWidth: "70%",
      margin: "auto",
      [theme.breakpoints.down('sm')]: {
        maxWidth: "100%",
      }
    },
    testimonialSubtitle: {
      textAlign: 'center',
      fontSize: '16px'
    },
    formTitle: {
      width: '100% !important',
      [theme.breakpoints.down('sm')]: {
        padding: 0
      }
    },
    success: {
      position: "fixed",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 1)",
      padding: "24px 16px",
      transition: "opacity .5s ease-in-out, visibility .5s ease-in-out"
    },
    successContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      '& .MuiCircularProgress-circle': {
        color: "#01AA59 !important"
      }
    },
    headerLogos: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1.5rem',
      "@global": {
        'img': {
          'padding': '0 1rem',
          '&:first-child': {
            borderRight: '2px solid var(--border-color)'
          }
        }
      }
    },
    successCopy: {
      fontFamily: 'LatoNormal',
      fontSize: 18,
      maxWidth: "500px",
      textAlign: "center",
      marginBottom: "4rem"
    },
    successHidden: {
      opacity: 0,
      visibility: "hidden",
    },
    button: {
      height: '58px',
      width: '200px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      marginTop: '20px',
      marginBottom: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontFamily: 'LatoBold',
    },
  })
)
