import { FC } from 'react'
import { Box, Typography } from '../../../../../UI'
import { PriceDetailProps } from './types'
import useStyles from './styles'
import { round } from 'helpers'


const PriceDetail: FC<PriceDetailProps> = ({
    discount,
    taxAmount,
    isNar,
    totalValue,
    taxRate,
    priceRange
}) => {
    const styles = useStyles()
    const discountText = isNar && discount && (discount * -1) <= 5 ? 'NAR Exclusive Discount (5%)' : 'Discount'

    const discountExp = (discount || 0) * -1
    const subtotal = ((totalValue as number - (taxAmount || 0)) / ((100 - discountExp) / 100))
    const discountValue = subtotal * (discountExp / 100)

    return (
        <Box className={styles.root}>
            {(discount || taxAmount) && subtotal ?
                <Box className={styles.row}>
                    <Typography variant='body2' className={styles.body2}>Sub Total:</Typography>
                    <Typography variant='body2' className={styles.price}>${round(subtotal, 2)}</Typography>
                </Box>
                : null
            }

            {discount && discount !== 0 ?
                <Box className={styles.row}>
                    <Typography variant='body2' className={styles.body2}>{discountText}:</Typography>
                    <Typography variant='body2' className={styles.price}>-${round(discountValue, 2)}</Typography>
                </Box>
                : null
            }

            {taxAmount && taxAmount !== 0 ?
                <Box className={styles.row}>
                    <Typography variant='body2' className={styles.body2}>Taxes ({round((taxRate || 0) * 100, 2)}%):</Typography>
                    <Typography variant='body2' className={styles.price}>${round(taxAmount, 2)}</Typography>
                </Box>
                : null
            }

            {(totalValue || priceRange) && <Box className={styles.row}>
                <Typography variant='body2' className={styles.body2}>Total:</Typography>
                <Typography variant='body2' className={styles.price}>{`${priceRange ? priceRange : `$${round(totalValue ?? 0, 2)}`}`}</Typography>
            </Box>}
        </Box>
    )
}

export default PriceDetail
