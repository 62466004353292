import axios from '../axios'
import { ApiController } from './types'

const base = '/properties'

const apiController: ApiController = {
  getProperties: async (state, city, zipCode) =>
    await axios
      .get(base, { params: { state, city, zipCode } })
      .then(response => response.data),

  getPropertyById: async (propertyId: string) =>
    await axios
      .get(`${base}/${propertyId}`)
      .then(response => response.data),

  createProperty: async (request) =>
    await axios
      .post(base, request)
      .then(response => response.data),

  updateProperty: async (propertyId: string, request) =>
    await axios
      .patch(`${base}/${propertyId}`, request)
      .then(response => response.data),

  deleteProperty: async (propertyId: string) =>
    await axios
      .delete(`${base}/${propertyId}`)
      .then(response => response.data),

  uploadProperties: async (request) =>
    await axios
      // .post(`${base}/upload`, request)
      .post(`${base}/upload`, request, { headers: {'Content-Type': 'multipart/form-data' }}) // file.type 
      .then(response => response.data)
      
}

export default apiController
