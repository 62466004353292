import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  configActions,
  estimateActions,
  estimatesActions,
  newEstimateActions,
  paymentsActions,
  territoriesActions,
} from '../../../../../../ducks/actions'
import {
  getNewEstimateFullAddress,
  getNewEstimateValue,
  getPaymentOptions,
  getTerritory,
  isAuthorized,
} from '../../../../../../ducks/selectors'
import { PaymentOption } from '../../../../../../ducks/types'
import { ESTIMATE_STATUS, FORM_TYPE, history, isEmpty, RESOURCE_TYPE } from '../../../../../../helpers'
import { PrivateLoader } from '../../../../../templates'
import { Box, Payment, PaymentModal } from '../../../../../UI'

const PaymentStep: FC = () => {
  const dispatch = useDispatch()
  const push = history.usePush()
  const isNar = useSelector(getNewEstimateValue('isNar'))
  const affiliate = useSelector(getNewEstimateValue('affiliate'))
  const formType = useSelector(getNewEstimateValue('formType'))
  const clientType = useSelector(getNewEstimateValue('clientType'))

  const estimateFullAddress = useSelector(getNewEstimateFullAddress)
  const paymentOptions = useSelector(getPaymentOptions())
  const { zipCode } = useSelector(getNewEstimateValue('address'))
  const estimate = useSelector(getNewEstimateValue('estimate'))
  const paymentOption = useSelector(getNewEstimateValue('paymentOption'))
  const territory = useSelector(getTerritory())

  const [loading, setLoading] = useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [price, setPrice] = useState(0)
  const [resourceId, setResourceId] = useState('')
  const pushParams = history.usePushParams()
  const authorized = useSelector(isAuthorized)
  const [clientTypeSaved, setClientTypeSaved] = useState(clientType)


  useEffect(() => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'paymentOption',
        value: {},
      })
    )
  }, [])

  useEffect(() => {
    if (!territory && zipCode) {
      setLoading(true)

      dispatch(territoriesActions.fetchTerritory(zipCode, (succ, terr) => {
        setLoading(false)
        dispatch(
          estimatesActions.fetchEstimatesServices(
            {
              formType: formType || FORM_TYPE.CLIENT_INSPECTION_REPORT,
              serviceable: terr?.serviceable || false,
              isNar,
              affiliate
            },
            () => {
              setLoading(false)
            }
          )
        )
      }))

    }
    // else setLoading(false)
  }, [territory, zipCode, dispatch])

  const stopOverlay = () => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: false })
    )
  }

  const handleClose = () => {
    stopOverlay()
    setClientSecret('')
    setPrice(0)
    setResourceId('')
  }

  const handleSuccess = (selectedOption?: any) => {
    dispatch(
      newEstimateActions.updateEstimate(
        {
          dueOn: selectedOption?.date ?? paymentOption?.date,
          properties: { totalValue: selectedOption?.price ?? paymentOption?.price },
          deliveryServiceId: selectedOption?.id ?? paymentOption?.id,
          status: ESTIMATE_STATUS.NEW
        },
        (success, estimate) => {
          if (success && estimate?.id) {
            dispatch(estimateActions.setEstimate(estimate))
            dispatch(newEstimateActions.setNewEstimateValue({ attr: "clientType", value: clientTypeSaved }))
            push(`estimate/${estimate.id}`)
            toast.success('Estimate Created!')
            stopOverlay()
          } else stopOverlay()
        }
      )
    )
  }

  const handlePayment = (selectedOption: PaymentOption) => {
    const selectedPrice = selectedOption.price
    setPrice(selectedPrice)
    setResourceId(estimate.id)

    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'paymentOptions',
        value: paymentOptions,
      })
    )

    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'paymentOption',
        value: selectedOption,
      })
    )

    if (selectedPrice > 0) {
      dispatch(
        paymentsActions.fetchStripeSecret(
          {
            amount: selectedPrice,
            resourceId: estimate.id,
            resourceType: RESOURCE_TYPE.ESTIMATE,
            memo: estimate?.properties?.address?.fullAddress ? `BOSSCAT Estimate: ${estimate?.properties?.address?.fullAddress}` : undefined
          },
          (secret?: string) => {
            if (secret && !isEmpty(secret)) {
              setClientSecret(secret)
            } else {
              stopOverlay()
            }
          }
        )
      )
    } else {
      handleSuccess(selectedOption)
    }
  }

  useEffect(() => {
    pushParams(`?${authorized ? 'p' : 'o'}=5`)
  }, [])

  useEffect(() => {
    dispatch(newEstimateActions.setNewEstimateValue({ attr: "activeStepProgress", value: undefined }))
    dispatch(newEstimateActions.setNewEstimateValue({ attr: "totalStepProgress", value: undefined }))
  }, [])


  return (
    <Box style={{ margin: 'auto 0' }}>
      <PaymentModal
        open={!isEmpty(clientSecret) && price > 0 && !isEmpty(resourceId)}
        setOpen={handleClose}
        resourceId={resourceId}
        clientSecret={clientSecret}
        price={price}
        onSuccess={handleSuccess}
      //returnUrl={`${window.location.origin}/estimate/${resourceId}`}
      />
      <PrivateLoader loading={loading} building="fragment">
        {paymentOptions && (
          <Payment
            days={paymentOptions}
            serviceable={territory !== null && territory.serviceable}
            address={estimateFullAddress}
            type="Estimate"
            publicEstimate
            onPaymentSuccess={handlePayment}
          />
        )}
      </PrivateLoader>
    </Box>
  )
}

export default PaymentStep
