import { FormControlLabel, Radio } from "@material-ui/core"

import { FC } from "react"

import { FormRadioButtonProps } from "./types"

import { icons } from 'assets'
import styles from './styles.module.scss'


const FormRadioButton: FC<FormRadioButtonProps> = ({
  value,
  label,
  className,
  style,
  onClick,
  checked,
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          checkedIcon={
            <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
          }
          icon={<icons.RadioButtonUnchecked />}
          checked={checked}
          onClick={onClick}
        />}
      label={label}
      className={`${className} ${styles.FormRadioButton}`}
      style={style || { borderColor: checked ? 'var(--border-border-info)' : 'var(--border-border)' }}
    />
  )
}

export default FormRadioButton
