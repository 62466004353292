import { UpdateRequest } from 'api/types'
import { Estimate } from 'ducks/types'
import { EstimateActions, estimateTypes, ShareEstimateRequest } from './types'

const actions = {
  fetchEstimate: (
    payload: string,
    callback?: (succ: boolean, estimate?: Estimate) => void
  ): EstimateActions => ({
    type: estimateTypes.FETCH_ESTIMATE,
    payload,
    callback
  }),
  setEstimate: (payload: Estimate): EstimateActions => ({
    type: estimateTypes.SET_ESTIMATE,
    payload
  }),
  updateEstimateByPath: (payload: UpdateRequest, callback?: (succ: boolean) => void): EstimateActions => ({
    type: estimateTypes.UPDATE_ESTIMATE_BY_PATH,
    payload,
    callback
  }),
  approval: (payload: Partial<Estimate>, callback?: (succ: boolean, status: string) => void): EstimateActions => ({
    type: estimateTypes.APPROVAL,
    payload,
    callback
  }),
  shareEstimate: (payload: Partial<ShareEstimateRequest>, callback?: (succ: boolean) => void): EstimateActions => ({
    type: estimateTypes.SHARE_ESTIMATE,
    payload,
    callback
  })
}

export default actions
