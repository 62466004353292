import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid #eeeeee',
        borderRadius: '12px',
        padding: '0.6rem',
        margin: '1rem 0',
        boxShadow: '3px 3px 14px 0px rgb(0 0 0 / 11%)'
      },
      '& >.MuiGrid-item': {
        [theme.breakpoints.down('lg')]: {
          padding: '.2rem',
          width: '100%'
        },
      }
    },
    dateInfo: {
      fontFamily: 'LatoNormal',
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--black-color)'
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    titleDetail: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    iconJob: {
      margin: '8px',
      height: '42px',
      width: '42px',
      background: 'rgba(249, 250, 250, 1)',
      padding: '10px',
      borderRadius: '8px'
    },
    iconPay: {
      margin: '8px',
      height: '26px',
      width: '62px'
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--head-text-color)',
      margin: '8px'
    },
    price: {
      display: 'flex',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--head-text-color)',
      margin: '8px',
      justifyContent: 'flex-end'
    },
    smallInfo: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: 'var(--date-color)'
    },
    transparentText: {
      color: 'rgba(0,0,0,0)'
    },
    button: {
      width: '100px',
      height: '40px',
      textTransform: 'none',
      backgroundColor: 'var(--bosscat-grey-200)',
      color: 'var(--bosscat-blue-600)',
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: 700,
      fontFamily: 'LatoNormal',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--bosscat-grey-300) !important'
      },
      '&:disabled': {
        backgroundColor: 'var(--bosscat-grey-300) !important',
        color: 'var(--bosscat-grey-800) !important'
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: 40
      }
    },
    buttonContainer: {
      [theme.breakpoints.down('lg')]: {
        maxWidth: '100%'
      }
    },
    status: {
      textTransform: 'uppercase',
      fontSize: '16px'
    },
    itemContainer: {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          marginRight: '.7rem !important'
        }
      }
    }
  })
)
