import { Box } from '@material-ui/core'
import GiftSelector from './components/GiftSelector'
import HomeToBeServiced from './components/HomeToBeServiced'
import HomeownerInformation from './components/HomeownerInformation'

const GiftInformation = () => {
  return (
    <>
      <Box>
        <GiftSelector />
        <HomeToBeServiced />
        <HomeownerInformation />
      </Box>
    </>
  )
}

export default GiftInformation
