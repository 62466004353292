import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 138,
      height: 32,
      [theme.breakpoints.down('sm')]: {
        marginTop: 12
      }
    },
    counterContainer: {
      display: 'flex',
      width: 58,
      height: 32,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: theme.palette.grey[200],
      borderWidth: 2,
      borderRadius: 8,
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
    },
    counter: {
      fontSize: 16,
      color: theme.palette.grey[700]
    }
  })
)
