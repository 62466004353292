import { FC } from 'react'
import { BottomMarks, blackHouse, blueHouse, greenHouse, image0, image1, image2, image3, redHouse } from '../../../../../assets'
import { LoginRandomAnimationProps } from './types'
import useStyles from './styles'
import { useClassName } from '../../../../../hooks'
import { Box, Typography } from '../../../'

const LoginRandomAnimation: FC<LoginRandomAnimationProps> = ({ onlyImage = false, index = 0 }) => {
  const classes = useStyles()
  const className = useClassName()
  const bottomImgs = [greenHouse, blueHouse, blackHouse, blackHouse]
  const images = [
    {
      uri: image0,
      text: (
        <>
          <Typography className={classes.descTitle}>Get an online estimate</Typography>
          <Typography className={classes.descDetail}>
            Simply upload your inspection report PDF or provide your repair list. We’ll send accurate pricing right to your email.
          </Typography>
        </>
      )
    },
    {
      uri: image1,
      text: (
        <>
          <Typography className={classes.descTitle}>We Coordinate Everything</Typography>
          <Typography className={classes.descDetail}>
            Our skilled contractors complete your repairs on time. Their home repairs are backed by our one-year warranty.
          </Typography>
        </>
      )
    },
    {
      uri: image2,
      text: (
        <>
          <Typography className={classes.descTitle}>We Coordinate Everything</Typography>
          <Typography className={classes.descDetail}>
            After you book, your Success Manager assembles your handyman team. From there, they'll make sure your project stays on track.
          </Typography>
        </>
      )
    },
    {
      uri: image3,
      text: (
        <>
          <Typography className={classes.descTitle}>modify & book</Typography>
          <Typography className={classes.descDetail}>
            Your estimate is an online shopping cart. Remove repairs you don’t wish to complete, then book your project with a click.
          </Typography>
        </>
      )
    }
  ]

  const renderRandomImage = () => {
    return (
      <div className={classes.root}>
        {/*
        <div className={classes.imagesContainer}>
          <AnimationFrame fill='#2DDAF1' className={classes.form1} />
          <ClippingAnimationFrame style={{
            backgroundImage: `url(${images[index].uri})`
          }} fill="#FFF" className={classes.form2} />
        </div>
        */}
        <img alt='' style={onlyImage ? { width: '75%' } : {}} className={classes.image} src={images[index].uri} />
        {!onlyImage && <Box className={classes.textBase}>{images[index].text}</Box>}
        {!onlyImage && <img src={bottomImgs[index]} alt='' style={{ right: 0, bottom: 0, position: 'absolute' }} />}
        {/* {!onlyImage && <BottomMarks className={classes.bottomMarks} />} */}
      </div>
    )
  }

  return renderRandomImage()
}

export default LoginRandomAnimation
