import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      margin: '0 auto',
      gridTemplateRows: 'auto min-content',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '24px !important'
      }
    },
    container: {

    },
    noAbsolute: {
      position: 'relative'
    },
    stepper: {
      margin: '1.5rem 0'
    },
    stepperAuth: {
      margin: '4rem 0 0 0',
    },
    control: {
      position: "sticky",
      bottom: 0
    },
    controlAuth: {
      position: "sticky",
      bottom: 0
    },
    logoAndProgressNoAuth: {
      paddingInline: "2px"
    },
    loading: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 1)",
      padding: "24px 16px"
    },
    loadingContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      '& .MuiCircularProgress-circle': {
        color: "#01AA59 !important"
      }
    },
    loadingCopy: {
      fontFamily: 'LatoNormal',
      fontSize: 18,
      maxWidth: "250px",
      textAlign: "center",
      marginBottom: "4rem"
    },
    loadingHidden: {
      opacity: 0,
      visibility: "hidden",
      transition: "opacity .5s ease-in-out, visibility .5s ease-in-out"
    },
    content: {
      width: '100%',
      // height: '100%',
      margin: '0 auto',
      padding: '0px 12px 0px 24px',
      maxWidth: '1250px ',
      height: '96vh',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 16px 0px 16px',
        marginTop: '32px !important'
      }
    },
    footer: {
      background: 'rgba(249, 250, 250, 1)'
    },
    footerContent: {
      display: 'grid',
      gap: '4px',
      maxWidth: '1250px', // theme.breakpoints.values.xxl,
      width: '100%',
      margin: '0 auto',
      padding: '24px 0 12px 24px'
    },
    modal: {
      '@global': {
        '.MuiPaper-root': {
          width: '100%',
          height: '100%'
        },
        '.MuiButtonBase-root': {
          padding: '0 !important',
          display: 'flex',
          position: 'inherit',
          alignItems: 'flex-start'
        },
        '.MuiIconButton-root': {
          padding: '0 !important',
        }
      }
    },
    modalSm: {
      '@global': {
        '.MuiPaper-root': {
          width: '550px',
          height: 'fit-content'
        }
      }
    },
    modalBanner: {
      margin: '0px 20px 0px 50px',
      [theme.breakpoints.down('sm')]: {
        padding: '4px',
        margin: '12px',
      }
    }
  })
)
