import { State } from 'ducks/reducers'
import storeInfo from 'ducks/store'
import { UserType } from './types'
import { concatSeparator, EMAIL_TYPE, getNameFromAbb } from 'helpers'

export const getUser = (state: State): UserType => state.user

export const getUserId = (): string => {
  const { user } = storeInfo.store.getState()
  return user.id
}

export const getUserPrimaryEmail = (): string => {
  const { user } = storeInfo.store.getState()
  const primaryEmail = user?.email?.find(e => e?.emailType === EMAIL_TYPE.PRIMARY)?.email || ''
  return primaryEmail || 'No email registered'
}

export const getUserFullName = (): string => {
  const { user } = storeInfo.store.getState()
  const { firstName, lastName } = user
  const fullName = concatSeparator(firstName, lastName, ' ')
  return fullName || 'No name registered'
}

export const getUserPhone = (): string => {
  const { user: { phone } } = storeInfo.store.getState()
  return phone?.toString() || 'No phone'
}

export const getUserAddress = (): string => {
  const { user } = storeInfo.store.getState()
  const { city, line_1: street1, line_2: unit, state } = user.address
  const cityState = concatSeparator(city ?? '', state ?? '', ', ')
  const streetUnit = concatSeparator(street1 ?? '', unit ?? '', ', ')
  const address = concatSeparator(streetUnit ?? '', cityState ?? '', ', ')
  return address || 'No address'
}

export const getUserFullAddress = (): string => {
  const { user } = storeInfo.store.getState()
  const { line_1: street1, line_2: street2, city, state, zipCode } = user.address
  // if (!street1 || !street2) return 'No address registered'
  let fullAddress = concatSeparator(street1, street2, ' ')
  fullAddress = concatSeparator(fullAddress, city, ', ')
  fullAddress = concatSeparator(fullAddress, getNameFromAbb(state), ', ')
  fullAddress = concatSeparator(fullAddress, zipCode, ', ')

  return fullAddress || 'No address registered'
}
