import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ScrollWrapper } from '../..'
import { configActions } from '../../../../ducks/actions'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from '../../../../hooks'
import { Box, Typography } from '../../../UI'
import useStyles from './styles'
import { TemplateProps } from './types'

const Template: FC<TemplateProps> = ({
  children, title, header, relativeClass = '', absoluteClass = ''
}) => {
  const classes = useStyles()

  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const [mdScreen] = useIsMdScreen()
  const smallScreen = xsScreen || smScreen || mdScreen
  const dispatch = useDispatch()


  useEffect(() => {
    return () => {
      dispatch(configActions.setConfigValue({ type: 'redirectPath', value: null }))
    }
  }, [dispatch])

  return (
    <Box className={classes.root}>
      {header}
      {title && (
        typeof title === 'string' ?
          <Typography variant='h4' className={classes.title}>
            {title}
          </Typography>
          :
          title
      )}
      <ScrollWrapper
        relativeClass={relativeClass}
        absoluteClass={absoluteClass}
        relativeStyle={smallScreen ? { width: '100%' } : {}}
        absoluteStyle={smallScreen ? { padding: '0' } : { padding: '0 48px' }}
      >
        {children}
      </ScrollWrapper>
    </Box>
  )
}

export default Template
