import { TextField, InputAdornment } from "@material-ui/core";
import { icons } from "assets"
import useStyles from './styles'
import { SearchInputProps } from "./types";

export default function SearchInput({ value, onChange }: SearchInputProps) {
    const classes = useStyles()
    return (
        <TextField
            fullWidth
            value={value}
            placeholder='Search...'
            onChange={onChange}
            variant='standard'
            className={classes.textfield}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <icons.Search />
                    </InputAdornment>
                ),
            }}
        />
    )
}