/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import {
  propertiesTypes, FetchPropertiesAction, CreatePropertyAction,
  UpdatePropertyAction, DeletePropertyAction, UploadPropertiesAction
} from './types'
import { propertiesActions } from 'ducks/actions'
import { getUserId } from 'ducks/user/selectors'
import { UpdateRequest } from 'api/types'
import { Address } from 'ducks/types'
import * as Sentry from '@sentry/react'

export function* fetchProperties({ payload, callback }: FetchPropertiesAction): SagaIterator {
  let success = false
  try {
    const state = payload.state ?? undefined
    const city = payload.city ?? undefined
    const zipCode = payload.zipCode ?? undefined

    const properties = yield call(Punchlist.properties.getProperties, state, city, zipCode)

    if (properties) yield put(propertiesActions.setProperties(properties))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the properties'))
    if (callback) yield call(callback, false)
  }
}

export function* createProperty({ payload, callback }: CreatePropertyAction): SagaIterator {
  let success = false
  try {
    const userId = yield select(getUserId)
    const properties = yield call(Punchlist.properties.createProperty, [{ userId, ...payload }])

    if (properties) {
      yield put(propertiesActions.setProperties(properties))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('create a property'))
    if (callback) yield call(callback, false)
  }
}

export function* updateProperty({ payload, callback }: UpdatePropertyAction): SagaIterator {
  let success = false

  try {
    const { propertyId, partialAddress } = payload
    const request: UpdateRequest = []

    for (const attr in partialAddress) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: partialAddress[attr as keyof Address]
      })
    }

    yield call(Punchlist.properties.updateProperty, propertyId, request)
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update a property'))
    yield call(callback, false)
  }
}

export function* deleteProperty({ payload, callback }: DeletePropertyAction): SagaIterator {
  let success = false

  try {
    yield call(Punchlist.properties.deleteProperty, payload)

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('delete a property'))
    yield call(callback, false)
  }
}

export function* uploadProperties({ payload, callback }: UploadPropertiesAction): SagaIterator {
  let success = false

  try {
    const formData = new FormData()
    formData.append('File', payload.file)
    const properties = yield call(Punchlist.properties.uploadProperties, { file: formData })

    if (properties) {
      yield put(propertiesActions.setProperties(properties))
      success = true
    } else { call(toast.error, errorTextTryingTo('upload a property')) }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('upload a property'))
    yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(propertiesTypes.FETCH_PROPERTIES, fetchProperties)
  yield takeLatest(propertiesTypes.CREATE_PROPERTY, createProperty)
  yield takeLatest(propertiesTypes.UPDATE_PROPERTY, updateProperty)
  yield takeLatest(propertiesTypes.DELETE_PROPERTY, deleteProperty)
  yield takeLatest(propertiesTypes.UPLOAD_PROPERTIES, uploadProperties)
}
