import { FC, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { PrivateLoader } from '../../../../templates'
import { Box, Autocomplete } from '../../../../UI'
import { MapViewProps } from './types'
import useStyles from './styles'
import env from '@beam-australia/react-env'
import MapTooltip from '../../../../UI/CustomUI/atoms/MapTooltip'

const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''

const defaultCenter = { lat: 40, lng: -100 }

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '150px',
  borderRadius: '8px'
}

const MapView: FC<MapViewProps> = ({ defaultSelection, territories }) => {
  const classes = useStyles()

  const [contentLoading, setContentLoading] = useState(true)
  const [coordinates, setCoordinates] = useState(
    {
      lat: defaultSelection.data.latitude,
      lng: defaultSelection.data.longitude
    } ?? defaultCenter
  )
  const [value, setValue] = useState(defaultSelection)
  const [properties, setProperties] = useState<any[]>([])
  const [territoriesOptions, setTerritoriesOptions] = useState<any[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  useEffect(() => {
    filterOptions()
    filterProperties()
    setContentLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMapClick = (cords: { lat: number; lng: number }) => {
    setCoordinates(cords)
  }

  const filterOptions = () => {
    let alreadyIn: string[] = []
    const territoriesTemp = territories.filter(item => {
      const optTitle = `${item.data.state} - ${item.data.city}`
      if (!alreadyIn.includes(optTitle)) {
        alreadyIn.push(optTitle)
        return item
      }
      return null
    })
    setTerritoriesOptions(territoriesTemp)
  }

  const filterProperties = (newValue = value) => {
    const propertiesTemp = territories.map((territory) => {
      const {
        line_1,
        line_2,
        city,
        state,
        latitude,
        longitude
      } = territory.data
      return {
        data: {
          address: `${line_1}, ${line_2}`,
          city,
          state
        },
        cords: {
          lat: latitude,
          lng: longitude
        }
      }
    }).filter((item) => {
      const { state } = item.data
      return state === newValue.data.state
    })
    setProperties(propertiesTemp)
  }

  const changeMapFocus = (newValue = value) => {
    setCoordinates({
      lat: newValue.data.latitude,
      lng: newValue.data.longitude
    })
  }

  return (
    <PrivateLoader loading={contentLoading}>
      <Autocomplete
        label=''
        value={value}
        options={territoriesOptions}
        onChange={(value: any) => {
          setValue(value)
          filterProperties(value)
          changeMapFocus(value)
        }}
        classes={{
          root: classes.autocomplete
        }}
      />
      <Box className={classes.mapContainer}>
        <GoogleMap
          center={coordinates}
          zoom={10}
          mapContainerStyle={containerStyle}
          options={{ clickableIcons: false }}
          onClick={e => {
            if (e.latLng) {
              const cords = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
              }
              handleMapClick(cords)
            }
          }}
        >
          {properties.map((property, index) => {
            return (
              <MapTooltip key={index} property={property}/>
            )
          })}
        </GoogleMap>
      </Box>
    </PrivateLoader>
  )
}

export default MapView
