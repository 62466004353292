import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>

  createStyles({
    imagePicker: {
      width: 64,
      height: 64,
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--white-color)',
      borderRadius: 8,
      borderWidth: 2,
      borderColor: theme.palette.grey[400],
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
    },
    clickable: {
      cursor: 'pointer'
    },
    marginRight: {
      marginRight: 16
    },
    icon: {
      fontSize: 30,
      color: theme.palette.grey[400]
    },
    hiddenInput: {
      display: 'none'
    },
    image: {
      display: 'flex',
      objectFit: 'cover',
      width: 64,
      height: 64,
      borderRadius: 8
    },
    deleteButton: {
      position: 'absolute',
      top: 4,
      right: 4,
      width: 12,
      height: 12,
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.background.default,
      borderRadius: 2,
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
    }
  })
)
