import { formatParams } from '../../../helpers';
import axios from '../axios'
import { ApiController } from './types'

const base = '/users'
const baseToCreate = '/clients'

const apiController: ApiController = {
  getCurrentUser: async () =>
    await axios
      .get(`${base}/me`)
      .then(response => response.data),

  createUser: async (request) =>
    await axios
      .post(baseToCreate, request)
      .then(response => response.data),

  updateUser: async (id: string, request) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then(response => response.data),

  getAdminClients: async () =>
    await axios
      .get(`${base}/clients`)
      .then(response => response.data),

  inviteNewClient: async (request) =>
    await axios
      .post(`${base}/invite`, request)
      .then(response => response.data),

  getUserHead: async (params) => {
    const parameters = formatParams(params);
    return await axios
      .head(`${base}?${parameters}`)
      .then(response => response.data)
  },

}

export default apiController
