import { FC } from 'react'
import { Grid, Typography, Box, ListItemText, Button } from '../../../../../UI'
import Inspections from '../Inspections'
import { ItemProps } from './types'
import useStyles from './styles'
import { icons } from '../../../../../../assets'
import { PunchlistFile } from '../../../../../../ducks/types'

const EstimateAlert: FC<ItemProps> = ({
  title,
  inspectionReportNote,
  removed,
  onRemove,
  imageFiles,
  showAlert,
  showButtons,
  quantity,
  disclaimer,
  requestedRepairNote
}) => {
  const styles = useStyles()
  const classes = styles

  const handleRemove = (): void => {
    onRemove()
  }

  const handleShowAlert = (value: boolean): void => {
    if (showAlert) showAlert(value)
  }

  return (
    <Grid>

      <Box className={classes.containerBox}>
        <ListItemText
          classes={{ root: classes.label }}
          className={classes.item}
          primary='Item:'
        />
        <ListItemText
          classes={{ root: classes.label }}
          className={classes.item}
          primary={title}
        />
        <Typography variant='body1' className={classes.descr}>
          {'Quantity:' + quantity}
        </Typography>
        {disclaimer &&
          <Box className={`${classes.containerBox} `}>
            <Typography>Disclaimer: </Typography>
            <Typography variant='body1' className={`${classes.descr} ${classes.italic}`}> {disclaimer} </Typography>
          </Box>}

        {inspectionReportNote && (
          <>
            <ListItemText
              classes={{ root: classes.label }}
              className={classes.item}
              primary='Notes:'
            />
            <Typography variant='body1' className={classes.descr}>
              {inspectionReportNote}
            </Typography>
          </>
        )}
        {requestedRepairNote && (
          <>
            <ListItemText
              classes={{ root: classes.label }}
              className={classes.item}
              primary='Requested Repair:'
            />
            <Typography variant='body1' className={classes.descr}>
              {requestedRepairNote}
            </Typography>
          </>
        )}
      </Box>
      {imageFiles && imageFiles.length > 0 && (
        <Grid item>
          <Inspections>
            {(imageFiles as unknown) as PunchlistFile[]}
          </Inspections>
        </Grid>
      )}
      <Grid container className={classes.buttonContent}>
        {showButtons && <Button
          type='submit'
          variant='contained'
          size='small'
          className={removed ? classes.buttonAdd : classes.buttonRemove}
          onClick={() => handleRemove()}
          endIcon={removed ? <icons.Add /> : <icons.Delete />}
        >
          {removed ? 'Add Item' : 'Remove'}
        </Button>}
        <Button
          type='submit'
          variant='contained'
          size='small'
          className={classes.buttonClose}
          onClick={() => handleShowAlert(false)}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  )
}

export default EstimateAlert
