import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  Text,
  View,
} from '@react-pdf/renderer'
import {
  formatTimestamp,
  getCompressedImageUrl,
  round,
} from '../../../../../../helpers'
import logo from '../../../../../../assets/icons/custom/blue_logo.png'
import moment from 'moment'

import Moranga from '../../../../../../assets/fonts/Moranga-Medium.otf'
import MorangaR from '../../../../../../assets/fonts/Moranga-Regular.otf'
import LatoBold from '../../../../../../assets/fonts/Lato-Bold.ttf'
import LatoNormal from '../../../../../../assets/fonts/Lato-Regular.ttf'
import BetterSansRegular from '../../../../../../assets/fonts/BetterSans-Bold.otf'
import BetterSansBold from '../../../../../../assets/fonts/BetterSans-Regular.otf'

import style from './style'
import styleBetter from './styleBetter'

Font.register({ family: 'Moranga', src: Moranga })
Font.register({ family: 'MorangaR', src: MorangaR })
Font.register({ family: 'LatoBold', src: LatoBold })
Font.register({ family: 'LatoNormal', src: LatoNormal })
Font.register({ family: 'BetterSansRegular', src: BetterSansRegular })
Font.register({ family: 'BetterSansBold', src: BetterSansBold })

const FooterView = ({ showBetterStyle }) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <>
      <Text fixed style={styles.printDate}>
        {formatTimestamp(moment().unix(), 'MM/DD/YYYY HH:mm')}
      </Text>
      <Text
        fixed
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        style={styles.pageNumber}
      />
    </>
  )
}

const ItemView = ({ item, showBetterStyle }) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <View style={styles.bodyItem}>
      <View
        style={[styles.containerHorizontal, styles.content, styles.justifyEnd]}
      >
        <Text style={[styles.itemText, styles.textBold]}>
          {`$ ${round(item.totalPrice, 2)}`}
        </Text>
      </View>
      <View
        style={[
          styles.containerHorizontal,
          styles.content,
          styles.justifyBetween,
        ]}
      >
        <Text style={[styles.itemText, styles.textBold, styles.itemTitle]}>
          {`${item.title} • QTY: ${item.quantity}`}
        </Text>
      </View>
      <View style={[styles.content]}>
        <Text style={[styles.itemText, styles.textBold, styles.marginVertical]}>
          'Inspection / Repair Notes: '
        </Text>
        <Text
          style={[
            styles.itemText,
            styles.leftSeparation,
            styles.marginVertical,
          ]}
        >
          {`${
            !item.inspectionReportNote || item.inspectionReportNote === ''
              ? 'No notes in this item'
              : item.inspectionReportNote
          }`}
        </Text>
      </View>

      {item.imageFiles?.length > 0 ? (
        <View style={styles.imageContainer}>
          <Text
            style={[
              styles.itemText,
              styles.textBold,
              styles.title,
              styles.textMedium,
            ]}
          >
            After Repairs:
          </Text>
          <View
            style={[styles.content, styles.itemImages, styles.topSeparation]}
          >
            {item.imageFiles?.map((imgFile, index) => (
              <Image
                key={index}
                src={getCompressedImageUrl(imgFile.fileUrl)}
                style={styles.image}
              />
            ))}
          </View>
        </View>
      ) : null}
    </View>
  )
}

const ItemSectionView = ({
  items,
  groupKey,
  index,
  price,
  showBetterStyle,
}) => {
  const styles = showBetterStyle ? styleBetter : style
  const displayGroupKey =
    groupKey === 'Needs Attention'
      ? `${groupKey} : $${round(price, 2)}`
      : groupKey

  return (
    <View
      key={index + Math.floor(Math.random() * 1000 + 1)}
      style={[styles.container, styles.topSeparation]}
    >
      <View style={[styles.containerHorizontal, styles.sectionTitle]}>
        <Text style={styles.title}>{`${displayGroupKey}: `}</Text>
      </View>
      {items.map((item, indx) => (
        <View key={indx}>
          <ItemView item={item} showBetterStyle={showBetterStyle} />
          {indx < items.length - 1 && (
            <View
              style={[
                styles.separator,
                item.imageFiles?.length > 0
                  ? styles.bigSpacing
                  : styles.littleSpacing,
              ]}
            />
          )}
        </View>
      ))}
    </View>
  )
}

const JobInfoView = ({ jobInfo, imageUris, showBetterStyle }) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <>
      <View
        style={[
          styles.containerHorizontal,
          styles.verticalSeparator,
          styles.justifyBetween,
        ]}
      >
        <View style={[styles.containerHorizontal]}>
          <View style={styles.itemContainer}>
            {imageUris?.punchlistLogoUri && (
              <Image
                src={imageUris.punchlistLogoUri}
                style={styles.userInfoImage}
              />
            )}
          </View>
          <View style={styles.itemContainer}>
            <Text style={[styles.itemText, styles.textBold, styles.address]}>
              {jobInfo.address}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={[
          styles.containerHorizontal,
          styles.verticalSeparator,
          styles.justifyBetween,
        ]}
      >
        <View
          style={[
            styles.containerHorizontal,
            styles.verticalSeparator,
            styles.marginHorizontal,
          ]}
        >
          <View style={[styles.containerHorizontal, styles.verticalSeparator]}>
            <Text style={[styles.userInfoText, styles.textBold]}>Started:</Text>
            <Text style={[styles.userInfoText, styles.startedOn]}>
              {formatTimestamp(jobInfo?.startedOn, 'MM/DD/YYYY HH:mm')}
            </Text>
          </View>
          <View style={styles.estimateIdContainer}>
            <Text style={[styles.userInfoText, styles.estimateId]}>
              Estimate #{jobInfo?.estimateId}
            </Text>
          </View>
        </View>
        <View style={[styles.containerHorizontal, styles.verticalSeparator]}>
          <Text style={[styles.textBold]}>
            {jobInfo?.proerties?.totalWithTax}
          </Text>
        </View>
      </View>
    </>
  )
}

const CompleteBodyView = ({
  groupedItems,
  jobInfo,
  imageUris,
  showBetterStyle,
}) => {
  const styles = showBetterStyle ? styleBetter : style
  let totalPrice = 0
  return (
    <View style={styles.bodyItems}>
      <JobInfoView
        imageUris={imageUris}
        jobInfo={jobInfo}
        showBetterStyle={showBetterStyle}
      />
      {imageUris?.chartUri && (
        <Image src={imageUris.chartUri} style={styles.chartImage} />
      )}
      {Object.keys(groupedItems).map((groupKey, index) => {
        const items = groupedItems[groupKey]
        const price = items.reduce((acc, obj) => {
          return acc + (obj?.status !== 'REJECTED' ? obj.totalPrice : 0)
        }, 0)
        totalPrice += price
        return (
          <ItemSectionView
            key={index}
            groupKey={groupKey}
            index={index}
            items={items}
            price={price}
            showBetterStyle={showBetterStyle}
          />
        )
      })}
      {jobInfo.taxRate > 0 ||
        (jobInfo.nar && (
          <View
            style={[
              styles.containerHorizontal,
              styles.sectionTitle,
              styles.justifyBetween,
            ]}
          >
            <Text style={styles.subtitle}>Subtotal:</Text>
            <Text style={styles.subtitle}>{`$ ${round(totalPrice, 2)}`}</Text>
          </View>
        ))}
      {jobInfo.taxRate > 0 && (
        <View
          style={[
            styles.containerHorizontal,
            styles.sectionTitle,
            styles.justifyBetween,
          ]}
        >
          <Text style={styles.subtitle}>Tax:</Text>
          <Text style={styles.subtitle}>{`(${round(
            jobInfo.taxRate * 100,
            2
          )}%) ${round(jobInfo.taxAmount ?? 1, 2)}`}</Text>
        </View>
      )}
      {jobInfo.nar && (
        <View
          style={[
            styles.containerHorizontal,
            styles.sectionTitle,
            styles.justifyBetween,
          ]}
        >
          <Text style={styles.subtitle}>NAR Exclusive Discount (5%):</Text>
          <Text style={styles.subtitle}>{`-${round(
            totalPrice * 0.05,
            2
          )}`}</Text>
        </View>
      )}
      <View
        style={[
          styles.containerHorizontal,
          styles.sectionTitle,
          styles.justifyBetween,
        ]}
      >
        <Text style={[styles.title, styles.titleBold]}>Total:</Text>
        <Text style={[styles.price, styles.textBold]}>
          {jobInfo.totalPrice}
        </Text>
        {/* <Text style={[styles.price, styles.textBold]}>{`$ ${round(
          jobInfo.totalPrice,
          2
        )}`}</Text> */}
      </View>
    </View>
  )
}

const ItemsPdf = ({ groupedItems, jobInfo, imageUris, showBetterStyle }) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.logoView}>
          <Image style={styles.logo} src={logo} alt="punchlist" />
        </View>
        <CompleteBodyView
          groupedItems={groupedItems}
          imageUris={imageUris}
          jobInfo={jobInfo}
          showBetterStyle={showBetterStyle}
        />
        <FooterView showBetterStyle={showBetterStyle} />
      </Page>
    </Document>
  )
}

const download = (blob, name) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name + '.pdf')
  if (document.body) document.body.appendChild(link)
  link.click()
}

export const generatePdf = async ({
  groupedItems,
  jobInfo,
  imageUris,
  showBetterStyle,
}) => {
  download(
    await pdf(
      <ItemsPdf
        groupedItems={groupedItems}
        jobInfo={jobInfo}
        imageUris={imageUris}
        showBetterStyle={showBetterStyle}
      />
    ).toBlob(),
    `Job_${new Date().getTime()}`
  )
}
