/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import {
  territoriesTypes,
  FetchTerritoriesAction,
  FetchTerritoryAction,
  FetchRepairItemsAction,
} from './types'
import { territoriesActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'

export function* fetchTerritories({
  callback,
}: FetchTerritoriesAction): SagaIterator {
  let success = false
  try {
    const territories = yield call(Punchlist.territories.getTerritories)

    if (territories) {
      yield put(territoriesActions.setTerritories(territories))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the territories'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchTerritory({ payload, callback }: FetchTerritoryAction): SagaIterator {
  let success = false
  try {
    const territory = yield call(
      Punchlist.territories.getTerritoryByZipCode,
      payload
    )

    if (territory) {
      yield put(territoriesActions.setTerritory(territory))
    }
    success = true
    if (callback) yield call(callback, success, territory)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the territory'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchRepairItems({
  payload,
  callback,
}: FetchRepairItemsAction): SagaIterator {
  let success = false
  try {
    const territoryId = payload.territoryId ?? undefined
    const search = payload.search ?? undefined

    const repairItems = yield call(
      Punchlist.territories.getTerritoryRepairItems,
      territoryId,
      search
    )

    if (repairItems) {
      yield put(territoriesActions.setRepairItems(repairItems))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the list of repair items'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(territoriesTypes.FETCH_TERRITORIES, fetchTerritories)
  yield takeLatest(territoriesTypes.FETCH_TERRITORY, fetchTerritory)
  yield takeLatest(territoriesTypes.FETCH_REPAIR_ITEMS, fetchRepairItems)
}
