import { ReferralSubscriptionPayload } from 'api/punchlist/subscriptions/types'
import { UpdateRequest } from 'api/types'
import { EstimateContact, Subscription } from 'ducks/types'

/** TYPES **/
export const subscriptionTypes = {
  FETCH_SUBSCRIPTION: 'FETCH_SUBSCRIPTION',
  SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
  CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
  ADD_SUBSCRIPTION_TO_NOTIFICATION_LIST: 'ADD_SUBSCRIPTION_TO_NOTIFICATION_LIST',
  INITIALIZE_SUBSCRIPTION: 'INITIALIZE_SUBSCRIPTION',
  CLEAR_SUBSCRIPTION: 'CLEAR_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  REFERRAL_SUBSCRIPTION: 'REFERRAL_SUBSCRIPTION',
}

export interface UpdateSubscriptionParams {
  subscriptionId: string
  request: UpdateRequest
}

/** ACTIONS **/
export interface FetchSubscriptionAction {
  type: typeof subscriptionTypes.FETCH_SUBSCRIPTION
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetSubscriptionAction {
  type: typeof subscriptionTypes.SET_SUBSCRIPTION
  payload: Subscription
}

export interface AddSubscriptionToNotificationListAction {
  type: typeof subscriptionTypes.CREATE_SUBSCRIPTION
  callback?: (succ: boolean) => void
  payload?: Partial<EstimateContact>
}
export interface CreateSubscriptionAction {
  type: typeof subscriptionTypes.CREATE_SUBSCRIPTION
  payload: Partial<Subscription>
  callback?: (succ: boolean, clientSecret: string | null) => void
}

export interface UpdateSubscriptionAction {
  type: typeof subscriptionTypes.UPDATE_SUBSCRIPTION
  payload: UpdateSubscriptionParams
  callback?: (succ: boolean, subscription?: Subscription) => void
}
export interface InitializeSubscriptionAction {
  type: typeof subscriptionTypes.INITIALIZE_SUBSCRIPTION
  payload: Partial<Subscription>
  callback?: (succ: boolean) => void
}

export interface ClearSubscriptionAction {
  type: typeof subscriptionTypes.CLEAR_SUBSCRIPTION
  payload: null
}

export type SubscriptionActions =
  | FetchSubscriptionAction
  | SetSubscriptionAction
  | CreateSubscriptionAction
  | AddSubscriptionToNotificationListAction
  | UpdateSubscriptionAction

/** REDUCER **/
export type SubscriptionType = Subscription | null
