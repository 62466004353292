import axios from '../axios'
import { ApiController } from './types'

const base = '/files'

const apiController: ApiController = {
  uploadFile: async (request) =>
    await axios
      .post(base, request)
      .then(response => response.data)
}

export default apiController
