import { FC } from 'react'
import { Typography, Password } from '../../..'
import useStyles from './styles'
import { PasswordProps } from './types'

const PasswordLabel: FC<PasswordProps> = ({ label, value, error, helperText, placeholder, className, onChange }) => {
  const classes = useStyles()

  return (
    <div className={className}>
      <Typography variant='body1' className={classes.title}> {label} </Typography>
      <Password
        value={value}
        error={error}
        helperText={helperText}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
      />
    </div>
  )
}

export default PasswordLabel
