import React from 'react'
import DashboardMaintenance from 'components/pages/Desktop/OrderMaintenance/Dashboard'
import { MaintenanceModalsProvider } from 'components/pages/Desktop/OrderMaintenance/Dashboard/context'
import FullScreenLoader from 'components/pages/Desktop/OrderMaintenance/FullScreenLoader'
import MaintenanceSuccess from 'components/pages/Desktop/OrderMaintenance/Success'
import ErrorBoundary from 'components/templates/ErrorBoundary'
import { FC } from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import Login from '../components/pages/Auth/Login'
import Register from '../components/pages/Auth/Register'
import Reset from '../components/pages/Auth/Reset'
import ChangePassword from '../components/pages/Desktop/ChangePassword'
import Dashboard from '../components/pages/Desktop/Dashboard'
import Estimates from '../components/pages/Desktop/Estimates'
import ApproveViewComponent from '../components/pages/Desktop/Estimates/ApproveView'
import EstimateView from '../components/pages/Desktop/Estimates/EstimateView'
import Jobs from '../components/pages/Desktop/Jobs'
import Invoice from '../components/pages/Desktop/Jobs/Invoice'
import JobView from '../components/pages/Desktop/Jobs/JobView'
import Payments from '../components/pages/Desktop/Payments'
import PaymentDetail from '../components/pages/Desktop/Payments/PaymentDetail'
import ReceiptScreen from '../components/pages/Desktop/Payments/Receipt'
import Estimate from '../components/pages/PublicPages/Estimate'
import Success from '../components/pages/PublicPages/Estimate/Success'
import SuccessMaintenance from '../components/pages/PublicPages/Maintenance/Success'
import SuccessInspection from '../components/pages/PublicPages/Inspection/Success'
import Admin from '../components/pages/User/Admin'
import Profile from '../components/pages/User/Profile'
import RegisterLogged from '../components/pages/User/Register'
import Settings from '../components/pages/User/Settings'
import AuthRoutes from './AuthRoutes'
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import Widget from '../components/pages/PublicPages/Widget'
import Maintenance from 'components/pages/PublicPages/Maintenance'
import { NewMaintenanceModalsProvider } from 'components/pages/PublicPages/Maintenance/Modals/context'
import Inspection from 'components/pages/PublicPages/Inspection'
import MellohomeLandingEstimate from 'components/pages/PublicPages/Mellohome/Estimate'
import MellohomeLandingMaintenance from 'components/pages/PublicPages/Mellohome/Maintenance'


const Routes: FC = () => (
  <Router>
    <ErrorBoundary>
      <Switch>
        <Route exact path='/estimate'>
          <PublicRoutes>
            <Estimate />
          </PublicRoutes>
        </Route>
        <Route exact path='/mellohome/repairs'>
          <PublicRoutes>
            <MellohomeLandingEstimate />
          </PublicRoutes>
        </Route>
        <Route exact path='/mellohome/maintenance'>
          <PublicRoutes>
            <MellohomeLandingMaintenance />
          </PublicRoutes>
        </Route>
        <Route exact path='/maintenance'>
          <PublicRoutes>
            <NewMaintenanceModalsProvider>
              <Maintenance />
            </NewMaintenanceModalsProvider>
          </PublicRoutes>
        </Route>
        <Route exact path='/p/gift'>
          <Redirect to='/maintenance' />
        </Route>
        <Route exact path='/maintenance/success'>
          <PublicRoutes>
            <SuccessMaintenance />
          </PublicRoutes>
        </Route>
        <Route exact path='/inspection'>
          <PublicRoutes>
            <Inspection />
          </PublicRoutes>
        </Route>
        <Route exact path='/inspection/success'>
          <PublicRoutes>
            <SuccessInspection />
          </PublicRoutes>
        </Route>
        <Route exact path='/widget'>
          <PublicRoutes>
            <Widget />
          </PublicRoutes>
        </Route>
        <Route exact path='/estimate/:id'>
          <PublicRoutes>
            <Success />
          </PublicRoutes>
        </Route>
        <Route exact path='/p/dashboard'>
          <PrivateRoutes>
            <Dashboard />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/jobs'>
          <PrivateRoutes>
            <Jobs />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/jobs/:id'>
          <PrivateRoutes>
            <JobView />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/payments'>
          <PrivateRoutes>
            <Payments />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/payments/:id'>
          <PrivateRoutes>
            <PaymentDetail />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/payments/:id/receipt/:estimateId'>
          <PrivateRoutes>
            <ReceiptScreen />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/estimates'>
          <PrivateRoutes>
            <Estimates />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/shared-estimates/:id'>
          <PrivateRoutes>
            <EstimateView />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/shared-estimates'>
          <PrivateRoutes>
            <Estimates shared />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/changePassword'>
          <PrivateRoutes>
            <ChangePassword />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/estimates/:id/approve'>
          <PrivateRoutes>
            <ApproveViewComponent />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/jobs/:id/invoice/:subId'>
          <PrivateRoutes>
            <Invoice />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/estimates/:id'>
          <PrivateRoutes>
            <MaintenanceModalsProvider>
              <EstimateView />
            </MaintenanceModalsProvider>
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/gift/success'>
          <PrivateRoutes>
            <MaintenanceSuccess />
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/maintenance'>
          <PrivateRoutes>
            <MaintenanceModalsProvider>
              <DashboardMaintenance />
            </MaintenanceModalsProvider>
          </PrivateRoutes>
        </Route>
        <Route exact path='/p/docusignSuccess'>
          <FullScreenLoader />
        </Route>
        <Route exact path='/u/profile'>
          <PrivateRoutes>
            <Profile />
          </PrivateRoutes>
        </Route>
        <Route exact path='/u/settings'>
          <PrivateRoutes>
            <Settings />
          </PrivateRoutes>
        </Route>
        <Route exact path='/u/admin'>
          <PrivateRoutes>
            <Admin />
          </PrivateRoutes>
        </Route>
        <Route exact path='/u/register'>
          <PrivateRoutes showMenu={false}>
            <RegisterLogged />
          </PrivateRoutes>
        </Route>
        <Route path='/login'>
          <AuthRoutes>
            <Login />
          </AuthRoutes>
        </Route>
        <Route path='/register'>
          <AuthRoutes location={{ pathname: 'register' }}>
            <Register />
          </AuthRoutes>
        </Route>
        <Route path='/reset'>
          <AuthRoutes>
            <Reset />
          </AuthRoutes>
        </Route>
        <Route path='/'>
          <AuthRoutes>
            <Login />
          </AuthRoutes>
        </Route>
        <Redirect from='*' to='/p/dashboard' />
      </Switch>
    </ErrorBoundary>
  </Router>
)

export default Routes
