import { FC } from 'react'
import { DetailsProps } from './types'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import clsx from 'clsx'
import {
  icons,
  logo,
  betterInspect,
  info,
  bosscatPlanner as punchlistPlannerImage,
} from '../../../../../assets'
import { Typography, Box, Button } from '@material-ui/core'
import {
  useIsXsScreen,
  useIsSmScreen,
  useIsMdScreen,
  useIsLgScreen,
} from '../../../../../hooks'

import SharedEstimateIndicator from '../../../../pages/Desktop/Estimates/EstimateView/SharedEstimateIndicator'

const EstimateDetails: FC<DetailsProps> = ({
  title,
  location,
  phone,
  mail,
  showBetterStyle = false,
  showPunchlistPlanner = false,
  setInfo,
  shared,
  sharedByEmail = '',
  sharedByName = ''
}) => {
  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const [mdScreen] = useIsMdScreen()
  const [lgScreen] = useIsLgScreen()
  const smallScreen = xsScreen || smScreen || mdScreen || lgScreen
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  const handleInfo = (): void => {
    if (setInfo) setInfo()
  }
  return (
    <Box className={classes.container}>
      {showBetterStyle && (
        <Box
          style={{
            display: 'flex',
            gap: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '24px',
          }}
        >
          <img src={logo} alt="" style={{ width: '124px', marginTop: '8px' }} />
          <Typography variant="subtitle1"> / </Typography>
          <img src={betterInspect} alt="" style={{ width: '124px' }} />
        </Box>
      )}
      {showPunchlistPlanner ? (
        <Box
          className={clsx(
            classes.plannerBox,
            !smallScreen && classes.plannerPadding
          )}
        >
          {!smallScreen && <Box />}
          <img
            className={classes.plannerImg}
            src={punchlistPlannerImage}
            alt=""
          />
          {!smallScreen && (
            <Box className={classes.plannerEmail}>
              <icons.Mail className={classes.icon} />
              <Typography className={classes.text}>{mail}</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box className={classes.root}>
          {!showBetterStyle && (
            <Typography className={xsScreen ? classes.titleSnd : classes.title}>
              {' '}
              {title}{' '}
            </Typography>
          )}
          {xsScreen && (
            <Button
              type="submit"
              variant="contained"
              size="small"
              className={classes.button}
              onClick={() => handleInfo()}
            >
              <img src={info} className={classes.info} alt="map pointer" />
            </Button>
          )}
          {!xsScreen && <icons.LocationOn className={classes.icon} />}
          {!xsScreen && (
            <Typography className={classes.text}>{location}</Typography>
          )}
          {!xsScreen && <icons.Phone className={classes.icon} />}
          {!xsScreen && (
            <Typography className={classes.text}>{phone}</Typography>
          )}
          {!xsScreen && <icons.Mail className={classes.icon} />}
          {!xsScreen && (
            <Typography className={classes.text}>{mail}</Typography>
          )}
          {showBetterStyle && (
            <Typography className={classes.title}>{title}</Typography>
          )}
        </Box>
      )}
      {shared && (
        <SharedEstimateIndicator
          sharedByEmail={sharedByEmail}
          sharedByName={sharedByName}
        />
      )}
      {showPunchlistPlanner && smallScreen && (
        <Box className={classes.plannerEmailSmall}>
          <icons.Mail className={classes.icon} />
          <Typography className={classes.text}>{mail}</Typography>
        </Box>
      )}
    </Box>
  )
}

export default EstimateDetails
