import { FC, useState } from 'react'
import { Grid, Typography, Box, ListItem, ListItemText, Button, Tag } from '../../../../../UI'
import Inspections from '../Inspections'
import { EstimateItemProps } from './types'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { icons } from '../../../../../../assets'
import { PunchlistFile } from '../../../../../../ducks/types'
import { round, history, ITEM_CATEGORY } from '../../../../../../helpers'
import { useIsXsScreen } from '../../../../../../hooks'
import { Counter } from '../../../../../UI/CustomUI'
import { estimateItemsActions, estimateActions } from '../../../../../../ducks/actions'
import { useDispatch } from 'react-redux'

const EstimateItem: FC<EstimateItemProps> = ({
  item,
  removed,
  onRemove,
  showButtons,
  showBetterStyle = false,
  showAlert,
  isPunchlistPlanner = false
}) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const [xsScreen] = useIsXsScreen()
  const classes = showBetterStyle ? stylesBetter : styles
  const { title, quantity, inspectionReportReference, inspectionReportNote, totalPrice, imageFiles, disclaimer, requestedRepairNote, publicRepairNote, minPriceRange, maxPriceRange, category } = item
  const { id: estimateId } = history.getParamValues()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    if (xsScreen) {
      if (showAlert) showAlert()
    } else {
      setOpen(!open)
    }
  }

  const handleRemove = (): void => {
    onRemove()
  }

  const fetchEstimateView = () => {
    dispatch(
      estimateActions.fetchEstimate(estimateId, () => {
      })
    )
  }

  const handleCount = (count: number) => {
    dispatch(estimateItemsActions.updateEstimateItem({
      estimateId: item.estimateId,
      itemId: item.itemId,
      partialItem: { quantity: count }
    }, () => {
      fetchEstimateView()
    }))
  }

  const getTotal = (): string => {

    if (minPriceRange && maxPriceRange) {

      if (minPriceRange === null || maxPriceRange === null)
        return '$'.concat(round(totalPrice, 2))

      if (minPriceRange === maxPriceRange)
        return '$'.concat(round(totalPrice, 2))

      if (minPriceRange !== maxPriceRange)
        return "$".concat(round(minPriceRange, 2).slice(0, -3)).concat(" - $").concat(round(maxPriceRange, 2).slice(0, -3))
    }

    return '$'.concat(round(totalPrice, 2))
  }


  return (
    <Grid container spacing={2} className={classes.root}>
      <ListItem
        className={classes.listItem}
        onClick={() => handleClick()}
        button
        style={{
          backgroundColor: `${open ? 'var(--background-color-secondary)' : 'var(--white-color)'
            }`
        }}
      >
        <Box className={classes.row}>
          {removed ? <Tag className={classes.rightSeparation} type='Expired'>REMOVED</Tag> : null}
          <Typography className={classes.item}> {title} </Typography>
          <Typography className={classes.itemDesc}> • </Typography>
          <Typography className={classes.itemDesc}> {`QTY: ${!isPunchlistPlanner ? quantity : ''}`} </Typography>
          {isPunchlistPlanner && (
            <Box style={{ zoom: 0.7 }}>
              <Counter
                initialValue={item.quantity}
                onIncrease={handleCount}
                onDecrease={handleCount}
                color={showBetterStyle ? 'better' : 'primary'}
              />
            </Box>
          )}
          <Typography className={classes.itemDesc}> • </Typography>
          <Typography className={classes.itemDesc}> {`REF: ${inspectionReportReference ?? ''}`} </Typography>
        </Box>

        {!xsScreen && (
          <Box className={classes.row}>
            <ListItemText
              className={classes.itemEnd}
              primary={getTotal()}
            />
            <Box className={classes.expandBox}>
              {' '}
              {open
                ? (
                  <icons.ExpandLess className={classes.expand} />
                )
                : (
                  <icons.ExpandMore className={classes.expand} />
                )}
            </Box>
          </Box>)}
      </ListItem>
      <Box hidden={!open} className={classes.container}>
        {disclaimer && (
          <Box className={classes.containerBox} display="flex">
            <Typography variant='body1' component='span' className={`${classes.descr} ${classes.italic}`}>
              <Typography display='inline' className={classes.title}>Disclaimer: </Typography>
              {disclaimer}
            </Typography>
          </Box>
        )}
        {inspectionReportNote && (
          <Box className={classes.containerBox}>
            <Typography variant='body1' component='span' className={`${classes.descr}`}>
              <Typography display='inline' className={classes.title}>Notes: </Typography>
              {inspectionReportNote}
            </Typography>
          </Box>
        )}
        {requestedRepairNote && (
          <Box className={classes.containerBox}>
            <Typography variant='body1' component='span' className={`${classes.descr}`}>
              <Typography display='inline' className={classes.title}>Requested Repair: </Typography>
              {requestedRepairNote}
            </Typography>
          </Box>
        )}
        {publicRepairNote && (
          <Box className={classes.containerBox}>
            <Typography variant='body1' className={`${classes.descr}`}>
              <Typography display='inline' className={classes.title}>Repair Notes: </Typography>
              {publicRepairNote}
            </Typography>
          </Box>
        )}
        {imageFiles && imageFiles.length > 0 && (
          <Grid item>
            <Inspections>
              {(imageFiles as unknown) as PunchlistFile[]}
            </Inspections>
          </Grid>
        )}
        {showButtons && (
          <Grid container className={classes.buttonContent}>
            <Button
              type='submit'
              variant='contained'
              size='small'
              className={removed ? classes.buttonAdd : classes.buttonRemove}
              onClick={() => handleRemove()}
              endIcon={removed ? <icons.Add /> : <icons.Delete />}
            >
              {removed ? 'Add Item' : 'Remove'}
            </Button>
          </Grid>
        )}
      </Box>

    </Grid >
  )
}

export default EstimateItem
