import React, { ChangeEvent, useEffect } from 'react'
import useStyles from './styles'
import { BottomLink, Grid, LinearProgress, Radio, RadioGroup, Typography } from '../../../../../UI'
import { isEmpty } from 'lodash'
import { USER_TYPE, agentOptions, institutionalOptions } from '../../../../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { useStepperContext } from '../../../../../../hooks/useStepperContext'
import { getNewEstimateValue, getUser } from '../../../../../../ducks/selectors'
import FormRadioButton from "components/UI/CustomUI/atoms/FormRadioButton"
import { authActions } from 'ducks/actions'


const ProfessionalType = ({ isInstitutional }: { isInstitutional: boolean }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const options = isInstitutional ? institutionalOptions : agentOptions

  const { saveNewValue, setCanGoForward } = useStepperContext()
  const mainContact = useSelector(getNewEstimateValue('mainContact'))

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedOption = options.find(option => option.label === e.target.value)
    saveNewValue(isInstitutional ? 'institutionalRole' : 'clientType', selectedOption?.key)
  }

  useEffect(() => {
    const validate = isInstitutional ? !isEmpty(user.institutionalRole) : !isEmpty(user.clientType)
    setCanGoForward(validate)
  }, [user])

  return (
    <>
      <Grid item>
        <Typography variant="h5" className={classes.title}>awesome!</Typography>
        <Typography align='center'> Tell us about your Professional Type.</Typography>
      </Grid>
      <Grid item>
        <LinearProgress variant="determinate" value={50} />
        <Typography variant="h5" className={classes.subtitle}>Professional Type</Typography>
      </Grid>
      <Grid item spacing={2} className={classes.options}>
        <RadioGroup
          value={options.find(option => option.key === (isInstitutional ? user.institutionalRole || mainContact.institutionalRole : user.clientType || mainContact.clientType))?.label}
          onChange={handleChange}
          className={classes.radioContainer}
        >
          {options.map((option, index) =>
            <FormRadioButton
              key={index}
              value={option.label}
              control={<Radio color='primary' />}
              label={<Typography variant='caption'>{option?.label}</Typography>} className={classes.radio}
            />)
          }
        </RadioGroup>
        <BottomLink
          question='Already have an account?'
          linkText='Sign in here'
          onClick={() => dispatch(authActions.logoutRequest())}
        />
      </Grid>
    </>
  )
}

export default ProfessionalType
