/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import env from '@beam-australia/react-env'
import { FC, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getNewEstimateValue,
  getNewEstimateValues,
  getNewEstimateSubstep,
  isAuthorized
} from '../../../../../../../ducks/selectors'
import {
  isEmpty,
  ParseFilesUrl,
  FILE_TYPE,
  applicationMimeTypes,
  selectableFileTypes,
  history,
  CONTACT_ROLE
} from '../../../../../../../helpers'
import {
  Grid,
  Typography,
  UploadDocuments,
  Button,
  Checkbox,
  MultilineText
} from '../../../../../../UI'
import { NewEstimateType } from '../../../../../../../ducks/newEstimate/types'
import { useStepperContext } from '../../../../../../../hooks/useStepperContext'
import { PunchlistFile } from '../../../../../../../ducks/types'
import {
  filesActions,
  newEstimateActions,
  newInspectionActions
} from '../../../../../../../ducks/actions'
import { useIsXsScreen } from '../../../../../../../hooks'

import { icons } from '../../../../../../../assets'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import useStyles from './styles'
import { PublicLoader } from '../../../../../../templates'
import Project from './Project'
import Contact from './Contact'

const FILES_URL = env('FILES_URL') ?? ''

const Inspection: FC<{ goBack: () => void }> = ({ goBack }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const pushParams = history.usePushParams()

  const {
    showWarnings,
    saveNewValue,
    setCanGoForward,
    setShowWarnings,
    goForward
  } = useStepperContext()

  const { clientNotes } = useSelector(getNewEstimateValues())
  const inspectionFiles = useSelector(getNewEstimateValue('inspectionFiles'))
  const addendumFiles = useSelector(getNewEstimateValue('addendumFiles'))

  const [uploadingFile, setUploadingFile] = useState<boolean>(false)
  // const [consent, setConsent] = useState(false)
  const [loading, setLoading] = useState(false)
  const activeStep = useSelector(getNewEstimateSubstep())
  const serviceable = useSelector(getNewEstimateValue('serviceable'))
  const inspectionConsent = useSelector(getNewEstimateValue('inspectionConsent'))
  const [xsScreen] = useIsXsScreen()
  const clientType = useSelector(getNewEstimateValue('clientType'))
  const isOther = [CONTACT_ROLE.LISTING_AGENT, CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)
  const isInstitutional = [CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)

  const authorized = useSelector(isAuthorized)

  const validate: boolean =
    inspectionConsent && inspectionFiles?.length > 0

  useEffect(() => {
    setCanGoForward(validate)
  }, [validate, inspectionFiles])

  useEffect(() => {
    setShowWarnings(false)
  }, [])


  const updateEstimate = () => {
    const files: PunchlistFile[] = []
    if (inspectionFiles) inspectionFiles.forEach((file: any) => files.push(file))
    if (addendumFiles) addendumFiles.forEach((file: any) => files.push(file))
    if (validate) {
      setLoading(true)
      files &&
        dispatch(
          newEstimateActions.updateEstimate(
            { properties: { files, clientNotes } },
            (success, estimate) => {
              setLoading(false)
              if (success && estimate?.id) {
                goForward()
                /*  dispatch(
                   newEstimateActions.setNewEstimateSubstep(activeStep + 1)
                 ) */
              }
            }
          )
        )
    } else {
      setShowWarnings(true)
    }
  }

  const updateNewEstimate = useCallback(
    (attr: keyof NewEstimateType, value: any) => {
      saveNewValue(attr, value)
    },
    [saveNewValue]
  )

  const handleInspectionChange = (
    files: null | File[],
    callback: () => void
  ) => {
    setUploadingFile(true)
    const oldFiles = inspectionFiles ? [...inspectionFiles] : []
    if (files) {
      dispatch(
        filesActions.uploadFiles(files, (uploadSucc, fileNameList) => {
          if (uploadSucc && fileNameList) {
            const newFiles = fileNameList.map((fileName, index) => {
              if (!isEmpty(fileName)) {
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? ''
                  }),
                  description: `Estimate Inspection - ${index + 1}`,
                  fileType: FILE_TYPE.INSPECTION_REPORT
                }
              }
            })
            setLoading(false)
            updateNewEstimate('inspectionFiles', [...oldFiles, ...newFiles])
          }
          if (callback) callback()
        })
      )
    } else {
      if (callback) callback()
      updateNewEstimate('inspectionFiles', null)
    }
    setUploadingFile(false)
  }

  const handleAddendumChange = (
    files: null | File[],
    callback: () => void
  ) => {
    setUploadingFile(true)
    const oldFiles = addendumFiles ? [...addendumFiles] : []
    if (files) {
      dispatch(
        filesActions.uploadFiles(files, (uploadSucc, fileNameList) => {
          if (uploadSucc && fileNameList) {
            const newFiles = fileNameList.map((fileName, index) => {
              if (!isEmpty(fileName)) {
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? ''
                  }),
                  description: `Estimate Inspection - ${index + 1}`,
                  fileType: FILE_TYPE.ADDENDUM
                }
              }
            })
            setLoading(false)
            updateNewEstimate('addendumFiles', [...oldFiles, ...newFiles])
          }
          if (callback) callback()
        })
      )
    } else {
      if (callback) callback()
      updateNewEstimate('inspectionFiles', null)
    }
    setUploadingFile(false)
  }

  const handleDeleteInspectionFile = (newFiles: PunchlistFile[] | null, type: FILE_TYPE) => {
    if (type === FILE_TYPE.INSPECTION_REPORT) {
      updateNewEstimate('inspectionFiles', newFiles)
    } else {
      updateNewEstimate('addendumFiles', newFiles)
    }
  }

  useEffect(() => {
    activeStep === -1 && pushParams(`?${authorized ? 'p' : 'o'}=2a`)
  }, [activeStep])

  useEffect(() => {
    dispatch(newInspectionActions.setNewInspectionValue({ attr: "activeStepProgress", value: 1 }))
    dispatch(newInspectionActions.setNewInspectionValue({ attr: "totalStepProgress", value: isOther ? isInstitutional ? 2 : 4 : 3 }))
  }, [])

  return (
    <PublicLoader loading={loading} compact>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant='h4' className={classes.title}>
            Let’s upload your <span>Inspection PDF</span>
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant='body1' className={classes.label}>
              Upload Home Inspection Report
            </Typography>
            <UploadDocuments
              error={showWarnings && (!inspectionFiles || inspectionFiles?.length === 0)}
              files={inspectionFiles ?? null}
              dropzoneText='Upload Inspection PDF'
              onChange={
                !uploadingFile
                  ? (files, callback) =>
                    handleInspectionChange(
                      files,
                      callback
                    )
                  : () => { }
              }
              className={classes.dropzone}
              handleDelete={(newFiles) => handleDeleteInspectionFile(newFiles, FILE_TYPE.INSPECTION_REPORT)}
              allowedFiles={applicationMimeTypes}
              sx={{ minHeight: '180px !important' }}
            />
          </Grid>
          <Grid item container xs={12} spacing={xsScreen ? 0 : 2} className={classes.addendum}>
            <Grid item xs={12} lg={6}>
              <Typography variant='body1' className={classes.label}>
                Addendum, photos, and additional documents (optional):
              </Typography>
              <UploadDocuments
                files={addendumFiles ?? null}
                dropzoneText='Upload files'
                onChange={
                  !uploadingFile
                    ? (files, callback) =>
                      handleAddendumChange(
                        files,
                        callback
                      )
                    : () => { }
                }
                className={classes.dropzone}
                handleDelete={(newFiles) => handleDeleteInspectionFile(newFiles, FILE_TYPE.ADDENDUM)}
                allowedFiles={selectableFileTypes}
                sx={{ minHeight: '180px !important' }}
              />
            </Grid>
            <Grid item xs={12} lg={6} className={classes.clientNotes}>
              <Typography variant='body1' className={classes.label}>
                Any special requests or comments? (optional)
              </Typography>
              <MultilineText
                value={clientNotes}
                className={classes.textArea}
                onChange={(event: any) => {
                  updateNewEstimate('clientNotes', event.target.value)
                }}
                placeholder='Add your comments here'
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.consent}>
            <Checkbox
              checked={inspectionConsent}
              onChange={() => updateNewEstimate('inspectionConsent', !inspectionConsent)}
              name='consent'
              color='primary'
              style={{
                color: showWarnings && !inspectionConsent ? 'red' : 'primary'
              }}
            />
            <Typography variant='caption' style={{ fontFamily: 'Lato' }}>
              As an authorized person or agent, I allow BOSSCAT to use any
              information obtained from me to provide, without limitation,
              estimates, bids, schedules, or any additional value add products
              and services. I acknowledge BOSSCAT will not sell any personal
              or identifying information. I agree to the <a href='https://bosscathome.com/terms/' target='_blank' rel='noreferrer'> Terms and Conditions</a>&nbsp;
              and <a href='https://bosscathome.com/privacy/' target='_blank' rel='noreferrer'>Privacy Policy</a>.
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ display: 'flex' }}
            justifyContent='space-between'
          >
            <Button
              type='submit'
              variant='outlined'
              size='small'
              className={classes.buttonBack}
              startIcon={<icons.ArrowBack />}
              onClick={goBack}
            // disabled={!serviceable}
            >
              Back
            </Button>
            <Button
              type='submit'
              variant='contained'
              size='small'
              className={classes.buttonNext}
              endIcon={<icons.ArrowForward />}
              onClick={updateEstimate}
              disabled={!validate}
            >
              Upload
            </Button>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography
                variant='caption'
                color='textSecondary'
                style={{ fontFamily: 'Lato' }}
              >
                As a final step, we will ask to create an account to be able
                to send your Estimate.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant='caption'
                color='textSecondary'
                style={{ fontFamily: 'Lato' }}
              >
                Having an account will also give you additional experiences
                like: Interactive Estimates, Lifecycle services and more.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>{/* ) */}
      {/* : activeStep === 0 && serviceable
          ? <Project
            goBack={() => dispatch(newEstimateActions.setNewEstimateSubstep(activeStep - 1))}
            goForward={() => dispatch(newEstimateActions.setNewEstimateSubstep(activeStep + 1))}
          />
          : <Contact
            goBack={() => dispatch(newEstimateActions.setNewEstimateSubstep(activeStep - 1))}
            goForward={() => goForward()}
          />} */}
    </PublicLoader>
  )
}

export default Inspection
