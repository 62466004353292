/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty, ITEM_CATEGORY, ITEM_STATUS } from 'helpers'
import { Punchlist } from 'api'
import { CreateEstimateItemsAction, estimateItemsTypes, FetchEstimateItemsAction, UpdateEstimateItemAction } from './types'
import { estimateItemsActions } from 'ducks/actions'
import { UpdateRequest } from 'api/types'
import { Item } from 'ducks/types'
import { getNewEstimateData } from 'ducks/newEstimate/selectors'
import * as Sentry from '@sentry/react'

export function* fetchEstimateItems({ payload, callback }: FetchEstimateItemsAction): SagaIterator {
  let success = false
  try {
    const estimateItems = yield call(Punchlist.estimates.getEstimateItems, payload)

    if (estimateItems) {
      yield put(estimateItemsActions.setEstimateItems(estimateItems))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the estimates items'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateItem({ payload, callback }: UpdateEstimateItemAction): SagaIterator {
  let success = false

  try {
    const { estimateId, itemId, partialItem } = payload
    const request: UpdateRequest = []

    for (const attr in partialItem) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: partialItem[attr as keyof Item]
      })
    }

    const estimateItem = yield call(Punchlist.estimates.updateEstimateItem, estimateId, itemId, request)

    if (!isEmpty(estimateItem)) {
      yield put(estimateItemsActions.setEstimateItemValues({ itemId, attrs: estimateItem }))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the estimate item'))
    if (callback) yield call(callback, false)
  }
}

export function* createEstimateItems({ payload, callback }: CreateEstimateItemsAction): SagaIterator {
  let success = true
  const items = payload
  const { id } = yield select(getNewEstimateData())
  try {
    if (items) {

      for (let index = 0; index < items.length; index++) {
        if (success) {
          try {
            const itemAdded = yield call(
              Punchlist.estimates.createEstimateItem,
              id as string,
              {
                ...items[index],
                status: ITEM_STATUS.NEW,
                category: items[index].category ?? ITEM_CATEGORY.UNCATEGORIZED
              } as Partial<Item>)

            if (!itemAdded || isEmpty(itemAdded.itemId)) {
              success = false
              yield call(toast.error, errorTextTryingTo('add an item to the repair list'))
            }
          } catch (error) {
            Sentry.captureException(error);
            success = false
            yield call(toast.error, errorTextTryingTo('add an item to the repair list'))
          }
        }
      }
    } else success = false

    // if (success) {
    //   const request: UpdateRequest = []
    //   //request.push({ op: 'add', path: '/status', value: ESTIMATE_STATUS.NEW })

    //   let estimate = yield call(Punchlist.estimates.updateEstimate, id, request)

    //   if (estimate && !isEmpty(estimate.id)) {
    //     yield put(newRepairListActions.setNewRepairListValue({ attr: 'estimate', value: estimate }))
    //   } else success = false
    // }

    if (!success) yield call(toast.error, errorTextTryingTo('create a repair list'))
    callback(success)

    //if (callback) yield call(callback, success, id ?? null)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('create a repair list'))
    callback(false)
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateItems({ payload, callback }: CreateEstimateItemsAction): SagaIterator {
  const items = payload
  const { id } = yield select(getNewEstimateData())

  if (items) {
    try {
      yield call(Punchlist.estimates.updateEstimateItems, id, items)
      callback(true)
    } catch (error) {
      Sentry.captureException(error);
      yield call(toast.error, errorTextTryingTo('add an item to the repair list'))
      callback(false)
    }
  } else callback(true)
}

export default function* saga(): SagaIterator {
  yield takeLatest(estimateItemsTypes.FETCH_ESTIMATE_ITEMS, fetchEstimateItems)
  yield takeLatest(estimateItemsTypes.UPDATE_ESTIMATE_ITEM, updateEstimateItem)
  yield takeLatest(estimateItemsTypes.UPDATE_ESTIMATE_ITEMS, updateEstimateItems)
  yield takeLatest(estimateItemsTypes.CREATE_ESTIMATE_ITEMS, createEstimateItems)
}
