import { FC, useEffect } from 'react'
import { Grid, Typography, Box, Button } from '../../../../../../UI'
import useStyles from './styles'
import {
  history,
  getHeaderStatus,
  getTextStatus,
} from 'helpers'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Props } from './types'
import { useStepperContext } from 'hooks/useStepperContext'

const Submit: FC<Props> = ({ statusFinal }) => {
  const classes = useStyles()
  const { setShowWarnings } = useStepperContext()
  const push = history.usePush()
  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    push('p/estimates')
  }

  return (
    <Grid container spacing={2} style={{ paddingTop: '8px' }}>
      <Grid container item xs={12} md={7} spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            {getHeaderStatus(statusFinal)}
          </Typography>
          <Typography className={classes.text}>
            {getTextStatus(statusFinal)}
          </Typography>
          <Box className={classes.line} />
          <Typography className={classes.detail}>
            Any problems, please contact us at 888-887-8624.
          </Typography>

          <Button
            type="submit"
            variant="contained"
            size="large"
            className={classes.button}
            onClick={() => handleClick()}
            fullWidth
          >
            Go Back to Estimates
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Submit
