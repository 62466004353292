import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateRows: 'min-content auto'
    },
    header: {
      padding: '30px 0px'
    },
    title: {
      fontSize: '28px',
      fontFamily: 'NextArtHeavy',
      fontWeight: 900,
      padding: '20px 0px 20px 50px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 0px 20px 70px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
        lineHeight: '58px',
        padding: '7px 0px 10px 55px'
      }
    }
  })
)
