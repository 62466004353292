/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* global google */
import { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import env from '@beam-australia/react-env'
import { Grid } from '@material-ui/core'

import { Typography, Button, STextField, AutocompleteSearch, Box } from '../../../../UI'
import { icons, mapPointer } from '../../../../../assets'

import { SelectAddressProps } from './types'
import { useClassName } from '../../../../../hooks'
import { flattenedAddress, isEmpty } from '../../../../../helpers'
import { territoriesActions } from '../../../../../ducks/actions'
import { Address } from '../../../../../ducks/types'

import useStyles from './styles'

const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''
const defaultCenter = { lat: 40, lng: -100 }
const START_SEARCHING_FROM = 3

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '150px',
  borderRadius: '8px'
}

const emptyAddress = {
  city: '',
  state: '',
  line_1: '',
  line_2: '',
  zipCode: '',
  latitude: defaultCenter.lat,
  longitude: defaultCenter.lng,
  county: '',
  country: ''
}

const SelectAddress: FC<SelectAddressProps> = ({
  xl = 6,
  showSave = false,
  onChange,
  onSubmit,
  onAdd,
  showWarnings = false,
  showVertical = false,
  edit = false,
  initAdd = '',
  customClass = '',
  loadAddress = null
}) => {
  const classes = useStyles()
  const className = useClassName()
  const dispatch = useDispatch()
  const savedAddress = loadAddress
  const Input = null as any

  const [address, setAddress] = useState<Address>(savedAddress?.line_1 ? savedAddress : emptyAddress)
  const [addressText, setAddressText] = useState<string>(savedAddress?.line_1 ? `${savedAddress?.line_1}, ${savedAddress?.city}, ${savedAddress?.state}` : initAdd)
  const [options, setOptions] = useState<any[]>([])
  const [coordinates, setCoordinates] = useState(savedAddress ? { lat: savedAddress.latitude, lng: savedAddress.longitude } : defaultCenter)

  const placesService = new google.maps.places.PlacesService(document.createElement('div'))
  const autoCompleteService = new google.maps.places.AutocompleteService()
  const geocoder = new google.maps.Geocoder()

  const hasCoordinates =
    coordinates.lat !== defaultCenter.lat &&
    coordinates.lng !== defaultCenter.lng

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  useEffect(() => {
    if (savedAddress) {
      setAddress({
        city: savedAddress.city,
        state: savedAddress.state,
        line_1: savedAddress.line_1,
        line_2: savedAddress.line_2,
        zipCode: savedAddress.zipCode,
        latitude: savedAddress.latitude,
        longitude: savedAddress.longitude,
        country: savedAddress.country,
        county: savedAddress.county
      })

      setAddressText(`${savedAddress?.line_1}, ${savedAddress?.city}, ${savedAddress?.state}`)
      setCoordinates({ lat: savedAddress.latitude, lng: savedAddress.longitude })
    }
  }, [initAdd])

  useEffect(() => {
    if (onChange) {
      onChange(address)
    }
    address.zipCode && dispatch(territoriesActions.fetchTerritory(address.zipCode))
  }, [address])

  useEffect(() => {
    const request = {
      input: addressText,
      componentRestrictions: { country: ['us', 'ca'] }
    }
    autoCompleteService.getPlacePredictions(request, (predictions: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const tempOptions = predictions.map((prediction: any) => {
          return {
            id: prediction.place_id,
            label: prediction.description,
            value: prediction.description
          }
        })
        setOptions(tempOptions)
      }
    })
  }, [addressText])

  const handleSelectedAddress = (e: any) => {
    if(e){
      placesService.getDetails({
        placeId: e?.id
      }, (place: any) => {
        const { city, state, zipCode, line_1, street_number, latitude, longitude, country, county } = flattenedAddress(place)
  
        setAddressText(e.label)
        setAddress({
          city,
          state: state,
          zipCode,
          line_1: `${street_number || ''} ${line_1 || ''}`,
          line_2: address.line_2,
          latitude,
          longitude,
          country,
          county
        })
        setCoordinates({ lat: latitude, lng: longitude })
      })
    }
   
  }
  const handleMapClick = async (cords: { lat: number; lng: number }) => {
    geocoder.geocode({
      location: {
        lat: cords.lat,
        lng: cords.lng
      }
    }, (results: any, status: any) => {
      const { city, state, zipCode, line_1, street_number, latitude, longitude, formatted_address } = flattenedAddress(results[0])
      setAddressText(formatted_address)
      setAddress({
        city,
        state: state,
        zipCode,
        line_1: `${street_number || ''} ${line_1 || ''}`,
        line_2: address.line_2,
        latitude,
        longitude,
        county: '',
        country: ''
      })
      setCoordinates(cords)
    })
  }

  const resetData = () => {
    setAddress(emptyAddress)
    setOptions([])
    setAddressText('')
  }

  const handleSubmit = (): void => {
    if (onSubmit) onSubmit(address)
    resetData()
  }

  const handleInput = (inputAddress: string): void => {
    resetData()
    setAddressText(inputAddress)
  }

  const handleOnBlur = () => {
    if (isEmpty(address.state)) {
      resetData()
    }
  }

  const onMapLoad = (map: google.maps.Map) => {
    setCoordinates(defaultCenter)
    if (navigator?.geolocation) {
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          setCoordinates({ lat, lng })
        }
      )
    }
  }

  return (
    <Grid container spacing={2} className={customClass}>
      <Grid item xs={12} xl={showVertical ? 12 : xl}>
        <Typography variant='subtitle2' className={classes.label}>
          Property Address:
        </Typography>
        {Input
          ? <Input />
          : (
            <AutocompleteSearch
              label=''
              error={showWarnings && isEmpty(addressText)}
              options={addressText.length > START_SEARCHING_FROM ? options : []}
              value={{ value: addressText }}
              valueInput={{ value: addressText }}
              renderOption={option => {
                return (
                  <Box className={classes.optionContainer}>
                    <img
                      src={mapPointer}
                      className={classes.pointerIcon}
                      alt='map pointer'
                    />
                    <Typography className={classes.mainDirection}>
                      {option.value}
                    </Typography>
                  </Box>
                )
              }}
              onChange={handleSelectedAddress}
              valueChange={handleInput}
              popupIcon={<icons.Search className={className(classes.addressSearcherIcon)} aria-label='' />}
              noOptionText
              onBlur={handleOnBlur}
            />
          )}
        <Typography variant='subtitle2' className={classes.label}>
          Unit Number:
        </Typography>
        <STextField
          value={address.line_2}
          className={classes.comboUnit}
          onChange={event => setAddress({ ...address, line_2: event.target.value })}
          placeholder='Apt/Suit/Unit Number'
        />
      </Grid>
      <Grid item xs={12} xl={showVertical ? 12 : xl}>
        {isLoaded && (
          <GoogleMap
            center={coordinates}
            zoom={hasCoordinates ? 18 : 3}
            mapContainerStyle={containerStyle}
            mapContainerClassName={customClass}
            options={{ clickableIcons: false }}
            // onLoad={!savedAddress.line_1 ? onMapLoad : undefined}
            onLoad={onMapLoad}
            onClick={e => {
              if (e.latLng) {
                const cords = {
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng()
                }
                handleMapClick(cords)
              }
            }}
          >
            {hasCoordinates && <Marker position={savedAddress ? { lat: savedAddress.latitude, lng: savedAddress.longitude } : defaultCenter} />}
          </GoogleMap>
        )}
      </Grid>
      {showSave && (
        <Grid item xs={12}>
          <Button
            type='submit'
            variant='contained'
            size='large'
            className={classes.submit}
            onClick={handleSubmit}
            fullWidth
            endIcon={<icons.Save className={classes.icon} />}
            disabled={edit ? false : isEmpty(address.state) || isEmpty(address.city) || isEmpty(address.zipCode) || isEmpty(address.line_1)}
          >
            {edit ? 'Update Property Info' : 'Save Property'}
          </Button>
        </Grid>
      )}
      {edit && (
        <Grid item xs={12}>
          <Button
            type='submit'
            variant='contained'
            size='large'
            className={classes.submit}
            onClick={onAdd}
            fullWidth
            endIcon={<icons.Add className={classes.icon} />}
          >
            Add new Property
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default SelectAddress
