import { Button, Checkbox, Grid, Typography } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getEstimate, getUserId } from '../../../../../../../ducks/selectors'
import { CONTACT_ROLE, getContactRoleOptions, PHONE_MASK_INPUT, workOrdersTerms } from '../../../../../../../helpers'
import { useStepperContext } from '../../../../../../../hooks/useStepperContext'
import { Autocomplete, STextField, TextFieldLabel } from '../../../../../../UI'
import { ApproveContext } from '../../context.provider'

import useStyles from './styles'

const SecondaryApproval = () => {
  const classes = useStyles()
  const estimate = useSelector(getEstimate())
  const userId = useSelector(getUserId)

  const { form_1, form_2, consent, setConsent } = useContext(ApproveContext)
  const contact = estimate?.properties?.contacts?.find(contact => contact.id === userId)

  const {
    setRole
  } = form_1

  const {
    terms,
    setTerms
  } = form_2

  const {
    showWarnings, saveNewValue, setShowWarnings, setCanGoForward
  } = useStepperContext()

  const handleSubmit = (event: React.MouseEvent<EventTarget>): void => {
    window.open(workOrdersTerms)
  }

  const updateValue = (att: string, value: string) => {
    // TODO: REMOVE SWITCH
    switch (att) {
      case 'role':
        typeof value !== 'string' && setRole(value)
        break
    }
    saveNewValue(att, value)
  }

  const validate: boolean = terms

  useEffect(() => {
    setCanGoForward(validate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  useEffect(() => {
    setShowWarnings(false)
    setConsent(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      spacing={2}
      style={{ paddingTop: '8px', display: 'flex', alignContent: 'start' }}
    >
      <Grid
        container item xs={12}
        className={classes.checkboxContainer}
      >
        <Checkbox
          checked={consent}
          onChange={() => setConsent(!consent)}
          name='consent'
          color='primary'
        />
        <Typography variant='caption' style={{ paddingTop: '20px' }}>
          I consent to receive SMS messages from BOSSCAT regarding the approved repairs.
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Typography className={classes.title}>
          Contact Information
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item lg={6} xs={12}>
          <Typography>Name:</Typography>
          <STextField
            className={classes.name}
            value={contact?.fullName}
            disabled
            placeholder='Homeowner name'
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextFieldLabel
            className={classes.phone}
            label='Cellphone:'
            type='tel'
            value={contact?.phone || ''}
            mask={PHONE_MASK_INPUT}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={2}>

        <Grid item lg={6} xs={12}>
          <Typography>Your email:</Typography>
          <STextField
            className={classes.signature}
            value={contact?.email}
            disabled
            placeholder='email@mail.com'
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography>Your role:</Typography>
          <Autocomplete
            className={classes.role}
            label=''
            value={{ key: '', value: contact?.role }}
            options={getContactRoleOptions()}
            onChange={(value: any) => {
              updateValue('role', value)
            }}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        {contact?.role === CONTACT_ROLE.HOMEOWNER && <Grid item className={classes.disclaimer}>
          <Checkbox
            checked
            name='homeowner'
            disabled
            color='default'
          />
          <Typography style={{ paddingTop: '20px' }}>
            I am the homeowner at the time of repairs
          </Typography>
        </Grid>}
        {contact?.isPayer && <Grid item className={classes.disclaimer}>
          <Checkbox
            checked
            name='payer'
            color='default'
            disabled
          />
          <Typography style={{ paddingTop: '20px' }}>
            I will be paying for the repairs
          </Typography>
        </Grid>}
      </Grid>
      <Grid container item xs={12} spacing={2}>\
        <Grid item xs={12}>
          <Typography>Your Signature:</Typography>
          <STextField
            className={classes.signature}
            value={contact?.fullName}
            placeholder='Signature'
            disabled
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} className={`${classes.checkboxContainer} ${classes.rigth}`}>
        <Checkbox
          checked={terms}
          onChange={() => setTerms(!terms)}
          name='consent'
          color='primary'
          style={{
            color: showWarnings && !terms ? 'red' : 'primary'
          }}
        />
        <Typography variant='caption' style={{ paddingTop: '20px' }}>
          I understand this signature is an agreement to BOSSCAT’s
          <Button
            type='submit'
            variant='outlined'
            size='small'
            className={classes.termsButton}
            onClick={handleSubmit}
          >
            <Typography variant='caption' className={classes.terms}>
              Terms and Conditions.
            </Typography>
          </Button>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default SecondaryApproval
