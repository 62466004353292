import { Box, Typography } from "@material-ui/core"
import { bosscatHomeServicesLogo, logo, mellohome, mellohomeTruck, mellohomeBgMaintenance, trustpilot } from "assets/index"
import useStyles from './styles'
import Banner from "../Banner"

const MellohomeHeader = () => {
  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.headerLogos}>
        <img src={bosscatHomeServicesLogo} className={classes.logo} alt='' />
        <img src={mellohome} className={classes.logo} alt='' />
      </Box>
      <Banner
        title={
          <>
            <Typography className={classes.title}> PUT YOUR HOME MAINTENANCE ON AUTOPILOT</Typography>
          </>
        }
        subtitle={
          <>
            <Typography className={classes.subtitle}>Keep your home running smoothly with a BOSSCAT home maintenance membership</Typography>
          </>
        }
        imgSrc={mellohomeTruck}
        badgeSrc={trustpilot}
      // color="var(--bosscat-blue-700)"
      // titleColor="var(--bosscat-blue-700)"
      />
      <Box className={classes.footer}>RECEIVE AN EXCLUSIVE GIFT FOR MELLOHOME CUSTOMERS</Box>
      {/* <img src={mellohomeBg} alt="mellohome" /> */}
    </Box>
  )
}

export default MellohomeHeader