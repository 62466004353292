import React, { ReactElement } from 'react'
import styles from './styles.module.scss'
import { Box, Typography } from '@material-ui/core'

const ServiceAreaWarningBanner = (): ReactElement => {
  return (
    <Box className={styles.banner}>
      <ul className={styles.list}>
        <li>
          <Typography className={styles.title}>
            This estimate has been provided solely to help you negotiate and plan
            home repairs.
          </Typography>
          <strong>
            <Typography className={styles.subtitle}>
              BOSSCAT does not provide or facilitate repair services in your
              area at this time. However, we are expanding and look forward to
              offering repair services for your area in the future.
            </Typography>

          </strong>
        </li>
      </ul>
    </Box>
  )
}

export default ServiceAreaWarningBanner
