import { FC, useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  Typography,
  DatePicker,
  STextField,
  Checkbox,
  Autocomplete,
  Button,
  TextFieldLabel,
  InputAdornment,
  Box,
  Radio,
  SvgIcon,
} from 'components/UI'
import { getConfigValue, getUser } from 'ducks/selectors'
import useStyles from './styles'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {
  USER_TYPE,
  getPaymentOptions,
  isEmpty,
  validateEmail,
  workOrdersTerms,
  PHONE_MASK_INPUT,
} from 'helpers'
import { useStepperContext } from 'hooks/useStepperContext'
import { ApproveContext } from '../../context.provider'
import { NorthEast, icons } from 'assets/index'
import clsx from 'clsx'
import { useIsXsScreen } from 'hooks/useMediaQuery'
import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'

const BROKER = USER_TYPE.BROKER
const INSTITUTIONAL = USER_TYPE.INSTITUTIONAL

const Information: FC = () => {
  const classes = useStyles()
  const { showWarnings, saveNewValue, setShowWarnings, setCanGoForward } =
    useStepperContext()
  const userType = useSelector(getConfigValue('userType'))
  const user = useSelector(getUser)
  const completeName = user?.firstName + ' ' + user?.lastName

  const [isXsScreen] = useIsXsScreen()

  const { form_2 } = useContext(ApproveContext)

  // FORM 2
  const {
    company,
    mail,
    mailCompany,
    name,
    phone,
    phoneCompany,
    responsible,
    signature,
    terms,
    unknownInformation,
    unknownInformation2,
    schedulerEmail,
    schedulerFirstName,
    schedulerLastName,
    schedulerPhone,
    scheduler,
    setCompany,
    setMail,
    setMailCompany,
    setName,
    setPhone,
    setPhoneCompany,
    setResponsible,
    setSignature,
    setTerms,
    setUnknownInformation,
    setUnknownInformation2,
    setSchedulerEmail,
    setSchedulerFirstName,
    setSchedulerLastName,
    setSchedulerPhone,
    setScheduler,
  } = form_2

  const responsiblesForPayment = getPaymentOptions()
  const initialStatus = responsiblesForPayment[0]

  const companyRoles = [USER_TYPE.BROKER, USER_TYPE.CLOSING_COORDINATOR, USER_TYPE.INSPECTOR, USER_TYPE.INSTITUTIONAL, USER_TYPE.PROPERTY_MANAGER]

  const [closingDateCompany, setClosingDateCompany] = useState(new Date())

  const signOk =
    completeName.replace(/\s/g, '').toUpperCase() ===
    signature.replace(/\s/g, '').toUpperCase()

  const handleSelectCompany = (date: Date) => {
    saveNewValue('closingDate', date.toString())
    setClosingDateCompany(date)
  }

  const getValidate = () => {
    const extra = responsible.key !== initialStatus.key
    const form1 =
      ((!isEmpty(name) &&
        !isEmpty(phone) &&
        !isEmpty(mail) &&
        validateEmail(mail)) ||
        unknownInformation) &&
      ((!isEmpty(schedulerFirstName) &&
        !isEmpty(schedulerLastName) &&
        !isEmpty(schedulerPhone) &&
        validateEmail(schedulerEmail)) ||
        scheduler !== 'other')

    const form2 =
      (!isEmpty(company) &&
        !isEmpty(mailCompany) &&
        validateEmail(mailCompany) &&
        !isEmpty(phoneCompany)) ||
      unknownInformation2

    if (companyRoles.includes(userType) && extra) {
      return signOk && form1 && form2 && terms
    } else if (companyRoles.includes(userType)) {
      return signOk && form1 && terms
    } else if (extra) {
      return signOk && form2 && terms
    } else {
      return signOk && terms
    }
  }
  const validate: boolean = getValidate()

  useEffect(() => {
    setCanGoForward(validate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (event: React.MouseEvent<EventTarget>): void => {
    window.open(workOrdersTerms)
  }

  const updateValue = (att: string, value: string) => {
    switch (att) {
      case 'name':
        setName(value)
        break
      case 'phone':
        setPhone(value)
        break
      case 'mail':
        setMail(value)
        break
      case 'signature':
        setSignature(value)
        break
      case 'company':
        setCompany(value)
        break
      case 'phoneCompany':
        setPhoneCompany(value)
        break
      case 'mailCompany':
        setMailCompany(value)
        break
      case 'schedulerEmail':
        setSchedulerEmail(value)
        break
      case 'schedulerFirstName':
        setSchedulerFirstName(value)
        break
      case 'schedulerLastName':
        setSchedulerLastName(value)
        break
      case 'schedulerPhone':
        setSchedulerPhone(value)
        break
    }
    saveNewValue(att, value)
  }

  const handleResponsible = (value: any) => {
    saveNewValue('responsible', value.value)
    setResponsible(value)
  }

  return (
    <Grid
      container
      spacing={2}
      style={{
        paddingTop: '8px',
        display: 'flex',
        alignContent: 'start',
        width: isXsScreen ? 'calc(100vw - 20px)' : 'auto',
        flexFlow: 'column',
      }}
    >
      {companyRoles.includes(userType) && (
        <>
          <Grid container spacing={2} className={classes.homeownerContainer}>
            <Grid container item xs={12}>
              <Typography className={classes.title}>
                Homeowner contact information at the time of repairs
              </Typography>
            </Grid>

            <Grid container item lg={10} xs={12}>
              <Typography className={classes.latoPrimaryText}>Name</Typography>
              <STextField
                className={clsx(
                  classes.name,
                  showWarnings && showWarnings && !name
                    ? classes.errorField
                    : {}
                )}
                value={name}
                error={showWarnings && !name}
                onChange={(event: any) => {
                  updateValue('name', event.target.value)
                }}
                placeholder="Homeowner name"
              />
            </Grid>

            <Grid container item xs={12} lg={10} spacing={2}>
              <Grid item xs={12} lg={6}>
                <Typography
                  className={classes.latoPrimaryText}
                  style={{ marginBottom: '8px' }}
                >
                  Cellphone
                </Typography>
                <TextFieldLabel
                  type="tel"
                  value={phone}
                  mask={PHONE_MASK_INPUT}
                  error={showWarnings && !phone}
                  onChange={(event: any) => {
                    updateValue('phone', event.target.value)
                  }}
                  placeholder="555-555-1234"
                  className={classes.cellphone}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography className={classes.latoPrimaryText}>
                  Email
                </Typography>
                <STextField
                  className={clsx(
                    classes.signature,
                    showWarnings && (!mail || !validateEmail(mail))
                      ? classes.errorField
                      : {}
                  )}
                  value={mail}
                  error={showWarnings && (!mail || !validateEmail(mail))}
                  onChange={(event: any) => {
                    updateValue('mail', event.target.value)
                  }}
                  placeholder="email@mail.com"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Checkbox
              checked={unknownInformation}
              onChange={() => setUnknownInformation(!unknownInformation)}
              name="consent"
              color="primary"
              style={{ padding: 0, height: '24px', marginRight: '10px' }}
            />
            <Typography className={classes.consentText}>
              I don’t have this information
            </Typography>
          </Grid>
        </>
      )}

      <Grid container item style={{ marginTop: '24px' }}>
        <Typography className={classes.title}>
          CONTACT FOR SCHEDULING THE REPAIR PROJECT
        </Typography>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item>
          <FormRadioButton
            label={
              <Typography
                className={classes.consentText}
                style={{ color: 'var(--text-text-primary)' }}
              >
                Me
              </Typography>
            }
            value={true}
            control={<Radio color="primary" />}
            onClick={() => setScheduler('me')}
            checked={scheduler === 'me'}
            className={classes.radioContainer}
          />
        </Grid>
        {companyRoles.includes(userType) && (
          <Grid item>
            <FormRadioButton
              label={
                <Typography
                  className={classes.consentText}
                  style={{ color: 'var(--text-text-primary)' }}
                >
                  Homeowner
                </Typography>
              }
              value={false}
              control={<Radio color="primary" />}
              onClick={() => setScheduler('homeowner')}
              checked={scheduler === 'homeowner'}
              className={classes.radioContainer}
            />
          </Grid>
        )}
        <Grid item>
          <FormRadioButton
            label={
              <Typography
                className={classes.consentText}
                style={{ color: 'var(--text-text-primary)' }}
              >
                Other
              </Typography>
            }
            value={false}
            control={<Radio color="primary" />}
            onClick={() => setScheduler('other')}
            checked={scheduler === 'other'}
            className={classes.radioContainer}
          />
        </Grid>
      </Grid>
      {scheduler === 'other' && (
        <Grid item container spacing={2} lg={10}>
          <Grid item xs={5}>
            <Typography className={classes.latoPrimaryText}>Name</Typography>
            <STextField
              className={clsx(
                classes.signature,
                showWarnings && !schedulerFirstName ? classes.errorField : {}
              )}
              value={schedulerFirstName}
              error={showWarnings && !schedulerFirstName}
              onChange={(event: any) => {
                updateValue('schedulerFirstName', event.target.value)
              }}
              placeholder="First Name"
            />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.latoPrimaryText}>
              Last Name
            </Typography>
            <STextField
              className={clsx(
                classes.signature,
                showWarnings && !schedulerLastName ? classes.errorField : {}
              )}
              value={schedulerLastName}
              error={showWarnings && !schedulerLastName}
              onChange={(event: any) => {
                updateValue('schedulerLastName', event.target.value)
              }}
              placeholder="Last Name"
            />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.latoPrimaryText}>Email</Typography>
            <STextField
              className={clsx(
                classes.signature,
                showWarnings && !schedulerEmail ? classes.errorField : {}
              )}
              value={schedulerEmail}
              error={showWarnings && !schedulerEmail}
              onChange={(event: any) => {
                updateValue('schedulerEmail', event.target.value)
              }}
              placeholder="Email"
            />
          </Grid>
          <Grid item xs={5}>
            <Typography
              className={classes.latoPrimaryText}
              style={{ marginBottom: '8px' }}
            >
              Phone:
            </Typography>
            <TextFieldLabel
              label=""
              type="tel"
              value={schedulerPhone}
              mask={PHONE_MASK_INPUT}
              error={showWarnings && !schedulerPhone}
              onChange={(event: any) => {
                updateValue('schedulerPhone', event.target.value)
              }}
              placeholder="555-555-1234"
              className={classes.cellphone}
            />
          </Grid>
        </Grid>
      )}

      <Grid container item style={{ marginTop: '24px' }}>
        <Typography className={classes.title}>Payment</Typography>
      </Grid>

      <Grid container item lg={10} spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography
            className={classes.latoPrimaryText}
            style={{ marginBottom: '4px' }}
          >
            Reponsible for Payment
          </Typography>
          <Autocomplete
            label=""
            placeholder="Responsible For Payment"
            value={responsible.key ? responsible : initialStatus}
            options={responsiblesForPayment}
            onChange={(value: any) => {
              handleResponsible(value)
            }}
          />
        </Grid>
      </Grid>

      {responsible.key !== initialStatus.key && (
        <>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingTop: '8px' }}
          >
            <Grid item xs={5}>
              <Typography className={classes.latoPrimaryText}>
                {responsible.key === '1' ? 'Title Company' : 'Name'}
              </Typography>
              <STextField
                className={clsx(
                  classes.signature,
                  showWarnings && !company ? classes.errorField : {}
                )}
                value={company}
                error={showWarnings && !company}
                onChange={(event: any) => {
                  updateValue('company', event.target.value)
                }}
                placeholder="Payer Name"
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                className={classes.latoPrimaryText}
                style={{ marginBottom: '8px' }}
              >
                Cellphone:
              </Typography>
              <TextFieldLabel
                label=""
                type="tel"
                value={phoneCompany}
                mask={PHONE_MASK_INPUT}
                error={showWarnings && !phoneCompany}
                onChange={(event: any) => {
                  updateValue('phoneCompany', event.target.value)
                }}
                placeholder="555-555-1234"
                className={classes.cellphone}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={5}>
              <Typography className={classes.latoPrimaryText}>
                Email:
              </Typography>
              <STextField
                className={clsx(
                  classes.signature,
                  showWarnings && (!mailCompany || !validateEmail(mailCompany))
                    ? classes.errorField
                    : {}
                )}
                value={mailCompany}
                error={
                  showWarnings && (!mailCompany || !validateEmail(mailCompany))
                }
                onChange={(event: any) => {
                  updateValue('mailCompany', event.target.value)
                }}
                placeholder="payer.email@mail.com"
              />
            </Grid>
            {responsible.key === '1' && (
              <Grid item xs={5}>
                <Typography className={classes.latoPrimaryText}>
                  Closing Date:
                </Typography>
                <DatePicker
                  minDate={new Date()}
                  onChange={handleSelectCompany}
                  value={closingDateCompany}
                />
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12}>
            <Checkbox
              checked={unknownInformation2}
              onChange={() => setUnknownInformation2(!unknownInformation2)}
              name="consent"
              color="primary"
              style={{ padding: 0, height: '24px', marginRight: '10px' }}
            />
            <Typography className={classes.consentText}>
              I don’t have this information
            </Typography>
          </Grid>
        </>
      )}

      <Grid container item xs={12} md={7}>
        <Typography className={classes.latoPrimaryText}>
          Your Signature:
        </Typography>
        <STextField
          className={clsx(
            classes.signature,
            showWarnings && !signOk ? classes.errorField : {},
            signOk ? classes.successField : {}
          )}
          value={signature}
          error={showWarnings && !signOk}
          onChange={(event: any) => {
            updateValue('signature', event.target.value)
          }}
          placeholder={user?.firstName + ' ' + user?.lastName}
          InputProps={
            signOk
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <icons.CheckCircle
                        style={{ color: 'var(--completed-color)' }}
                      />
                    </InputAdornment>
                  ),
                }
              : showWarnings && !signOk
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <icons.Error style={{ color: 'var(--expired-color)' }} />
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
        {showWarnings && !signOk && (
          <Box className={classes.helperRow}>
            <Typography variant="body2" className={classes.helperText}>
              {'Write'}
            </Typography>
            <Typography variant="body2" className={classes.helperName}>
              {user?.firstName + ' ' + user?.lastName}
            </Typography>
            <Typography variant="body2" className={classes.helperText}>
              {'as your signature to continue.'}
            </Typography>
          </Box>
        )}
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={12}
        className={`${classes.checkboxContainer}`}
      >
        <Checkbox
          checked={terms}
          onChange={() => setTerms(!terms)}
          name="consent"
          color="primary"
          style={{
            color: showWarnings && !terms ? 'red' : 'primary',
            padding: 0,
            height: '24px',
          }}
        />
        <Typography className={classes.consentText}>
          I understand this signature is an agreement to BOSSCAT
          <Button
            type="submit"
            variant="outlined"
            size="small"
            className={classes.termsButton}
            onClick={handleSubmit}
          >
            <Typography className={classes.terms}>
              Terms and Conditions.
            </Typography>
            <img src={NorthEast} alt="north_east" />
          </Button>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Information
