import storeInfo from 'ducks/store'
import { ITEM_PRIORITY, ITEM_STATUS, ESTIMATE_VIEW } from 'helpers'
import { Item } from 'ducks/types'

export const getEstimateItems = (hideRemoved: boolean) => (): Item[] | [] => {
  const { estimateItems } = storeInfo.store.getState()
  if (hideRemoved) return estimateItems.filter((item: Item) => item.status !== ITEM_STATUS.REJECTED) || []
  return estimateItems
}

export const getEstimateItemsGroupBy = (selectedType: ESTIMATE_VIEW, hideRemoved: boolean) => (): any => {
  const { estimateItems } = storeInfo.store.getState()
  let items = estimateItems
  if (hideRemoved) items = items.filter((item: Item) => item.status !== ITEM_STATUS.REJECTED)

  if (selectedType === ESTIMATE_VIEW.TYPE) {
    const groupByCategory = items.reduce((group: any, item: Item) => {
      const { category } = item
      group[category] = group[category] ?? []
      group[category].push(item)
      return group
    }, {})

    return groupByCategory
  }

  if (selectedType === ESTIMATE_VIEW.PRIORITY) {
    const groupByPriority: {
      [ITEM_PRIORITY.Urgent]?: Item[], [ITEM_PRIORITY.High]?: Item[],
      [ITEM_PRIORITY.Medium]?: Item[], [ITEM_PRIORITY.Low]?: Item[],
      [ITEM_PRIORITY.Cosmetic]?: Item[]
    } = {}

    const urgent = items.filter((item: Item) => item.severity === ITEM_PRIORITY.Urgent)
    const high = items.filter((item: Item) => item.severity === ITEM_PRIORITY.High)
    const medium = items.filter((item: Item) => item.severity === ITEM_PRIORITY.Medium)
    const low = items.filter((item: Item) => item.severity === ITEM_PRIORITY.Low)
    const cosmetic = items.filter((item: Item) => item.severity === ITEM_PRIORITY.Cosmetic)

    if (urgent.length > 0) groupByPriority.Urgent = urgent
    if (high.length > 0) groupByPriority.High = high
    if (medium.length > 0) groupByPriority.Medium = medium
    if (low.length > 0) groupByPriority.Low = low
    if (cosmetic.length > 0) groupByPriority.Cosmetic = cosmetic

    return groupByPriority
  }

  if (selectedType === ESTIMATE_VIEW.APPROVED) {
    const groupByApproved: { Approved?: Item[], Rejected?: Item[] } = {}
    const approved = items.filter((item: Item) => item.status !== ITEM_STATUS.REJECTED)
    const rejected = items.filter((item: Item) => item.status === ITEM_STATUS.REJECTED)
    if (approved.length > 0) groupByApproved.Approved = approved
    if (rejected.length > 0) groupByApproved.Rejected = rejected
    return groupByApproved
  }

  return { All: items }
}
