import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content min-content auto',
      height: '100%',
      width: '100%',
      margin: 0,
    },
    refresh: {
      width: '4%',
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        width: '100%'
      }
    },
    filter: {
      width: 'calc(32% - 24px)',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    filters: {
      width: 'calc(100vw - 300px)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 0,
        padding: '0 !important',
      },
    },
    tableContainer: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        padding: '12px'
      },
    },
    table: {
      paddingRight: '24px !important',
      [theme.breakpoints.down('md')]: {
        paddingRight: '0px !important',
      },
    },
    tableComponent: {
      position: 'relative',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.grey[200],
      borderRadius: 12,
    },
    pageChange: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 5,
      background: 'rgba(0,0,0,0.02)',
    },
    button: {
      width: '80%',
      height: '32px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700) !important',
      },
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
    },
    description: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '12px',
    },
    row: {
      '&:hover': {
        backgroundColor: 'var(--row-focus)',
      },
    },
    cellHead: {
      backgroundColor: 'var(--background-color-secondary)',
      color: 'var(--head-text-color)',
      borderWidth: 0,
      fontFamily: 'Lato',
      fontSize: '16px',
      padding: '12px !important'
    },
    cell: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 14,
      borderWidth: 0,
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '0px',
        paddingRight: '0px'
      },
    },
    idCell: {
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '8px',
        paddingRight: '8px'
      },
    },
    noItemContainer: {
      display: 'flex',
    },
    status: {
      textTransform: 'uppercase',
      fontSize: '16px',
    },
    tableSortLabel: {
      height: 'inherit !important',
      color: 'var(--head-text-color) !important',
      '&:hover': {
        color: 'var(--head-text-color)',
      },
    },
    statusSelect: {
      '& li:first-child': {
        '&:after': {
          content: '""',
          display: 'block',
          borderBottom: '2px solid var(--black-color)',
          borderRadius: '8px',
          transform: 'translateY(12px)',
          width: '100%',
        },
      },
    },
    expectedDeliveryLabel: {
      color: 'var(--expired-color)',
    },
    valueComponents: {
      display: 'grid',
      paddingRight: '12px !important',
      gridTemplateColumns: 'repeat(5, 1fr)',
      flexWrap: 'wrap',
      width: 'calc(100vw - 300px)',
      [theme.breakpoints.down('lg')]: {
        margin: 0,
        padding: '0 !important',
        marginTop: '1rem'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    valueItem: {
      gridColumn: 'span 1',
      [theme.breakpoints.down('lg')]: {
        gridColumn: 'span 2',
      },
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 5',
      },
    },
    tableOff: {
      position: 'relative',
      borderStyle: 'solid',
      borderWidth: 0,
      backgroundColor: 'transparent',
      borderRadius: 0,
      boxShadow: 'none',
      '@global': {
        '> .MuiGrid-root': {
          width: '100%',
          margin: 'auto',
        },
      },
    },
    iconButton: {
      maxWidth: '48px !important',
      minWidth: '48px !important',
      maxHeight: "48px !important",
      minHeight: "48px !important",
      marginLeft: '10px',
      marginTop: '32px',
      padding: 0,
      backgroundColor: '#FAFBFF',
      borderRadius: '32px',
      color: 'var(--bosscat-blue-600)',
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      }
    }
  })
)
