import storeInfo from 'ducks/store'
import { Estimate } from 'ducks/types'
import { EstimateType } from './types'

export const getEstimate = () => (): EstimateType => {
  const { estimate } = storeInfo.store.getState()
  return estimate
}

export const isEstimateServiceable = () => (): boolean => {
  const { estimate }: { estimate: Estimate } = storeInfo.store.getState()
  return estimate?.properties?.territory?.serviceable || false
}
