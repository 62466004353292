import { FC, useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  Typography,
  DatePicker,
  STextField,
  Checkbox,
  Autocomplete,
  TextFieldLabel,
} from '../../../../../../UI'
import useStyles from './styles'
import {
  USER_TYPE,
  getContactRoleOptions,
  getContactRoleOpt,
  isEmpty,
  validateEmail,
  OptionType,
  PHONE_MASK_INPUT,
} from '../../../../../../../helpers'
import { getConfigValue } from '../../../../../../../ducks/selectors'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Props } from './types'
import { useStepperContext } from '../../../../../../../hooks/useStepperContext'
import { ApproveContext } from '../../context.provider'
import moment from 'moment'

const HOMEOWNER = USER_TYPE.HOMEOWNER
const BROKER = USER_TYPE.BROKER

const Approval: FC<Props> = ({ othersInfo }) => {
  const classes = useStyles()
  const { setShowWarnings, setCanGoForward, showWarnings, saveNewValue } =
    useStepperContext()
  const userType = useSelector(getConfigValue('userType'))

  const { form_1, form_2, consent, setConsent } = useContext(ApproveContext)

  // FORM 1
  const {
    clientNotes,
    closingDate,
    flexible,
    flexibleClosing,
    remember,
    role,
    startDate,
    setClientNotes,
    setClosingDate,
    setFlexible,
    setFlexibleClosing,
    setRemember,
    setRole,
    setStartDate,
  } = form_1

  // FORM 2
  const { mail, name, phone, setMail, setName, setPhone } = form_2

  const [lastname, setLastname] = useState('')
  const [showCalendar, setShowCalendar] = useState(true)
  const [showCalendarClose, setShowCalendarClose] = useState(true)

  const handleStartDate = (date: Date) => {
    if (closingDate && date.valueOf() > closingDate)
      setClosingDate(date.valueOf())
    updateValue('startDate', date.valueOf())
    setStartDate(date.valueOf())
  }

  const handleSelect = (date: Date) => {
    updateValue('closingDate', date.valueOf())
    setClosingDate(date.valueOf())
  }

  const handleFlexible = (value: boolean) => {
    value === true ? setShowCalendar(false) : setShowCalendar(true)
    updateValue('flexible', value.toString())
    setFlexible(value.toString())

    updateValue('startDate', value ? null : undefined)
  }

  const handleFlexibleClosing = (value: boolean) => {
    value === true ? setShowCalendarClose(false) : setShowCalendarClose(true)
    updateValue('flexibleClosing', value.toString())
    updateValue('closingDate', value ? null : undefined)
    setFlexibleClosing(value.toString())
  }

  const updateValue = (
    att: string,
    value?: string | OptionType | number | null
  ) => {
    if (att === 'clientNotes' && typeof value === 'string') {
      setClientNotes(value)
    } else if (
      value &&
      att === 'role' &&
      typeof value !== 'string' &&
      typeof value !== 'number'
    ) {
      setRole(value)
    }
    saveNewValue(att, value)
  }

  const getValidate = () => {
    const form1 =
      !isEmpty(name) &&
      !isEmpty(lastname) &&
      !isEmpty(phone) &&
      !isEmpty(mail) &&
      validateEmail(mail)
    if ([HOMEOWNER].includes(userType) && !othersInfo) {
      return form1 && consent
    } else if ([HOMEOWNER].includes(userType)) {
      return (
        consent &&
        typeof closingDate !== 'undefined' &&
        typeof startDate !== 'undefined'
      )
    } else if (!othersInfo) {
      return form1
    } else {
      return (
        consent &&
        typeof closingDate !== 'undefined' &&
        typeof startDate !== 'undefined'
      )
    }
  }
  const validate: boolean = getValidate()

  useEffect(() => {
    setCanGoForward(validate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      spacing={2}
      style={{ paddingTop: '8px', display: 'flex', alignContent: 'start' }}
    >
      {!othersInfo && (
        <>
          <Grid container item xs={12}>
            <Typography className={classes.title}>
              Contact information
            </Typography>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            {[HOMEOWNER].includes(userType) && (
              <Grid item xs={4}>
                <Typography variant="body2">Role:</Typography>
                <Autocomplete
                  label=""
                  value={role}
                  options={getContactRoleOptions()}
                  onChange={(value: any) => {
                    setRole(value)
                  }}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <Typography variant="body2">Name:</Typography>
              <STextField
                className={classes.signature}
                value={name}
                error={showWarnings && !name}
                onChange={(event: any) => {
                  setName(event.target.value)
                }}
                placeholder="Your name"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Last Name:</Typography>
              <STextField
                className={classes.signature}
                value={lastname}
                error={showWarnings && !lastname}
                onChange={(event: any) => {
                  setLastname(event.target.value)
                }}
                placeholder="Your last name"
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              {/* <Typography variant='body2'>
                Cellphone:
              </Typography> */}
              <TextFieldLabel
                label="Cellphone"
                type="tel"
                // className={classes.signature}
                value={phone}
                mask={PHONE_MASK_INPUT}
                error={showWarnings && !phone}
                onChange={(event: any) => {
                  setPhone(event.target.value)
                }}
                placeholder="555-555-1234"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Email:</Typography>
              <STextField
                className={classes.signature}
                value={mail}
                error={showWarnings && (!mail || !validateEmail(mail))}
                onChange={(event: any) => {
                  setMail(event.target.value)
                }}
                placeholder="email@mail.com"
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid
        container
        item
        className={classes.consent}
        style={{
          borderColor:
            showWarnings && !consent
              ? 'var(--border-border-error-light)'
              : 'var(--border-border-info-light)',
        }}
      >
        <Checkbox
          checked={consent}
          onChange={() => setConsent(!consent)}
          name="consent"
          color="primary"
          style={{
            color: showWarnings && !consent ? 'red' : 'primary',
            padding: 0,
            height: '24px',
          }}
        />
        <Typography className={classes.consentText}>
          I consent to receive SMS messages from BOSSCAT regarding the approved
          repairs.
        </Typography>
      </Grid>

      <Grid container item xs={12} spacing={2}>
        {[BROKER].includes(userType) && (
          <Grid item xs={12} lg={4}>
            <Typography
              variant="body2"
              className={classes.question}
              style={{ marginTop: '24px' }}
            >
              What is your role in this transaction?
            </Typography>
            <Autocomplete
              label=""
              value={role}
              options={getContactRoleOpt()}
              onChange={(value: any) => {
                updateValue('role', value)
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container item style={{ marginTop: '8px' }}>
        <Typography className={classes.primaryText}> PROJECT DATES </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        xl={6}
        spacing={1}
        className={classes.container}
      >
        <Grid item xs={12} lg={8}>
          <Typography className={classes.latoPrimaryText}>
            {' '}
            When do you need this project completed?{' '}
          </Typography>
          <DatePicker
            minDate={new Date()}
            error={showWarnings && typeof startDate === 'undefined'}
            onChange={handleStartDate}
            value={startDate ? moment(startDate).toDate() : null}
            showPopUpCalendar={showCalendar}
          />
        </Grid>
        <Grid item xs={12} lg={4} className={classes.checkboxContainer}>
          <Checkbox
            checked={Boolean(flexible === 'true')}
            onChange={() => handleFlexible(!Boolean(flexible === 'true'))}
            name="flexible"
            color="primary"
            className={classes.checkbox}
          />
          <Typography className={classes.consentText}>
            {' '}
            I'm flexible{' '}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        xl={6}
        spacing={2}
        className={classes.container}
      >
        <Grid item xs={12} lg={8}>
          <Typography className={classes.latoPrimaryText}>
            {' '}
            When is the closing date?{' '}
          </Typography>
          <DatePicker
            error={showWarnings && typeof closingDate === 'undefined'}
            minDate={startDate ? moment(startDate).toDate() : new Date()}
            onChange={handleSelect}
            value={closingDate ? moment(closingDate).toDate() : null}
            showPopUpCalendar={showCalendarClose}
          />
        </Grid>
        <Grid item xs={12} lg={4} className={classes.checkboxContainer}>
          <Checkbox
            checked={Boolean(flexibleClosing === 'true')}
            onChange={() =>
              handleFlexibleClosing(!Boolean(flexibleClosing === 'true'))
            }
            name="flexibleClosing"
            color="primary"
            className={classes.checkbox}
          />
          <Typography className={classes.consentText}>
            {' '}
            Not applicable{' '}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={2} direction='column'>
        <Grid item>
          <Typography className={classes.primaryText}>
            Access details:
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.latoPrimaryText}>
            Please provide details on how the technician(s) can access the
            property
          </Typography>
          <STextField
            className={classes.comboUnit}
            value={clientNotes}
            //error={showWarnings && !clientNotes}
            onChange={(event: any) => {
              updateValue('clientNotes', event.target.value)
            }}
            placeholder="‘Susie Q will be at the property to let technicians in’; ‘The property is in a gated community with access code 123’; ‘The home has a lockcode with number 456’"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Approval
