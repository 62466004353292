import * as icons from '../assets'

export const getCategoryIcon = (category: string, better?: boolean): string => {
  if (better) {
    switch (category) {
      case 'ATTIC': return icons.atticBetter
      case 'CL100': return icons.cl100Better
      case 'CRAWLSPACE': return icons.crawlspaceBetter
      case 'ELECTRICAL': return icons.electricalBetter
      case 'EVALUATE': return icons.evaluateBetter
      case 'EXCLUDED': return icons.excludedBetter
      case 'EXTERIOR': return icons.exteriorBetter
      case 'HVAC': return icons.hvacBetter
      case 'INTERIOR': return icons.interiorBetter
      case 'MISCELLANEOUS': return icons.miscellaneousBetter
      case 'PLUMBING': return icons.plumbingBetter
      case 'ROOF': return icons.roofBetter
      case 'UNCATEGORIZED': return icons.uncategorizedBetter
      case 'WINDOWS_DOORS': return icons.windowsdoorsBetter
      default: return icons.hammer
    }
  }

  switch (category) {
    case 'ATTIC': return icons.attic
    case 'CL100': return icons.cl100
    case 'CRAWLSPACE': return icons.crawlspace
    case 'ELECTRICAL': return icons.electrical
    case 'EVALUATE': return icons.evaluate
    case 'EXCLUDED': return icons.excluded
    case 'EXTERIOR': return icons.exterior
    case 'HVAC': return icons.hvac
    case 'INTERIOR': return icons.interior
    case 'MISCELLANEOUS': return icons.miscellaneous
    case 'PLUMBING': return icons.plumbing
    case 'ROOF': return icons.roof
    case 'UNCATEGORIZED': return icons.uncategorized
    case 'WINDOWS_DOORS': return icons.windowsdoors
    default: return icons.hammer
  }
}
