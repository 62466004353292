import { Property, Address } from 'ducks/types'

/** TYPES **/
export const propertiesTypes = {
  FETCH_PROPERTIES: 'FETCH_PROPERTIES',
  SET_PROPERTIES: 'SET_PROPERTIES',
  CREATE_PROPERTY: 'CREATE_PROPERTY',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  DELETE_PROPERTY: 'DELETE_PROPERTY',
  UPLOAD_PROPERTIES: 'UPLOAD_PROPERTIES'

}

/** ACTIONS **/
export interface FetchPropertiesAction {
  type: typeof propertiesTypes.FETCH_PROPERTIES
  payload: { state?: string, city?: string, zipCode?: string }
  callback?: (succ: boolean) => void
}

export interface SetPropertiesAction {
  type: typeof propertiesTypes.SET_PROPERTIES
  payload: Property[]
}

export interface CreatePropertyAction {
  type: typeof propertiesTypes.CREATE_PROPERTY
  payload: Address
  callback: (succ: boolean) => void
}

export interface UpdatePropertyPayload {
  propertyId: string
  partialAddress: Partial<Address>
}

export interface UploadPropertyPayload {
  file: File 
}

export interface UpdatePropertyAction {
  type: typeof propertiesTypes.UPDATE_PROPERTY
  payload: UpdatePropertyPayload
  callback: (succ: boolean) => void
}

export interface DeletePropertyAction {
  type: typeof propertiesTypes.DELETE_PROPERTY
  payload: string
  callback: (succ: boolean) => void
}

export interface UploadPropertiesAction {
  type: typeof propertiesTypes.UPLOAD_PROPERTIES
  payload: UploadPropertyPayload
  callback: (succ: boolean) => void
}

export type PropertiesActions =
  | FetchPropertiesAction
  | SetPropertiesAction
  | CreatePropertyAction
  | UpdatePropertyAction
  | DeletePropertyAction
  | UploadPropertiesAction

/** REDUCER **/
export interface PropertiesType extends Array<Property> { }
