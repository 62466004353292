import { FC } from 'react'
import { Props } from './types'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { icons } from '../../../../../assets'
import { Typography, Box, Grid, Button, Switch } from '@material-ui/core'
import { ESTIMATE_VIEW } from '../../../../../helpers'
import { useIsXsScreen } from '../../../../../hooks'

const TYPE = ESTIMATE_VIEW.TYPE
const PRIORITY = ESTIMATE_VIEW.PRIORITY
const APPROVED = ESTIMATE_VIEW.APPROVED

const TypeViews: FC<Props> = ({
  selectedType, handleSubmit, handleRepairView, handleHide, expand, showBetterStyle = false, isPunchlistPlanner = false
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const [xsScreen] = useIsXsScreen()
  const classes = showBetterStyle ? stylesBetter : styles

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} direction='row' justifyContent='space-between' alignItems='center'>

        <Grid item xs={12} lg={6}>
          <Box className={classes.rows}>
            {!xsScreen && <Typography className={classes.title}>View by:</Typography>}
            <Button
              type='submit'
              variant='contained'
              size='small'
              className={selectedType === TYPE ? classes.comboSelected : classes.combo}
              endIcon={<icons.KeyboardArrowDown className={selectedType === TYPE ? classes.iconSelected : classes.iconCombo} />}
              onClick={() => handleSubmit(TYPE)}
            >
              <Typography className={selectedType === TYPE ? classes.subtitleSelected : classes.subtitle}>
                {TYPE}
              </Typography>
            </Button>

            <Button
              type='submit'
              variant='contained'
              size='small'
              className={selectedType === PRIORITY ? classes.comboSelected : classes.combo}
              endIcon={<icons.KeyboardArrowDown className={selectedType === PRIORITY ? classes.iconSelected : classes.iconCombo} />}
              onClick={() => handleSubmit(PRIORITY)}
            >
              <Typography className={selectedType === PRIORITY ? classes.subtitleSelected : classes.subtitle}>
                {PRIORITY}
              </Typography>
            </Button>

            {!isPunchlistPlanner && (
              <Button
                type='submit'
                variant='contained'
                size='small'
                className={selectedType === APPROVED ? classes.comboSelected : classes.combo}
                endIcon={<icons.KeyboardArrowDown className={selectedType === APPROVED ? classes.iconSelected : classes.iconCombo} />}
                onClick={() => handleSubmit(APPROVED)}
              >
                <Typography className={selectedType === APPROVED ? classes.subtitleSelected : classes.subtitle}>
                  {APPROVED}
                </Typography>
              </Button>
            )}
          </Box>
        </Grid>

        {!xsScreen && (
          <Grid item xs={12} lg={4}>
            <Box className={classes.rowRight}>
              <Box className={classes.rows}>
                <Switch className={classes.switch} onClick={handleHide} />
              </Box>
              <Typography className={classes.text}> Hide Removed </Typography>
              {!expand &&
                <Button
                  type='submit'
                  variant='contained'
                  size='small'
                  className={classes.button}
                  startIcon={<icons.PieChart className={classes.icon} />}
                  onClick={() => handleRepairView()}
                >
                  <Typography className={classes.detail}> Repair View </Typography>
                </Button>}
            </Box>
          </Grid>)}
      </Grid>
    </Box>
  )
}

export default TypeViews
