import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '12px 46px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '260px',
      marginTop: '4rem'
    },
    pageTitle: {
      width: '100%'
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '22px',
      textAlign: 'center'
    },
    subtitle: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      textAlign: 'center'
    },
    button: {
      margin: theme.spacing(3, 0, 2),
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      endIcon: {
        width: '5px',
        height: '5px'
      }
    }
  })
)
