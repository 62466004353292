import { createStyles, makeStyles, Theme } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: theme.palette.grey[100],
      padding: '16px',
      borderRadius: '16px',
    },
    image: {
      borderRadius: '12px',
      width: '146px',
      height: '126px',
      objectFit: 'cover',
      marginRight: '20px',
    },
    verticalContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    horizontalContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    name: {
      fontFamily: 'Lato',
      fontSize: '24px',
      margin: 'auto 0px',
    },
    separator: {
      width: 4,
      height: 4,
      backgroundColor: theme.palette.grey[700],
      margin: '0px 12px'
    },
    data: {
      fontFamily: 'LatoLight',
      fontSize: '16px',
      color: theme.palette.grey[700],
    },
    icons: {
      fontSize: '20px',
      color: theme.palette.grey[700],
      marginRight: '10px',
    },
    menuItemIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      paddingRight: theme.spacing(1),
      transform: 'translateY(-2px)'
    },
    avatar: {
      margin: '0 0 0 auto',
      borderRadius: '8px',
      width: theme.spacing(5),
      height: theme.spacing(5),
      objectFit: 'cover'
    },
    icon: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    },
    personIcon: {
      color: theme.palette.background.default,

      fontSize: theme.spacing(5)
    },
    loading: {
      color: theme.palette.primary.contrastText
    }
  })
)
