/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { select, call, put, takeLatest } from 'redux-saga/effects'

import { Auth, Punchlist } from 'api'
import { CreateUserRequest } from 'api/punchlist/users/types'
import { UpdateRequest } from 'api/types'
import { CONTACT_ROLE, EMAIL_TYPE, errorTextTryingTo, isEmpty, USER_TYPE } from 'helpers'
import { configActions, userActions } from 'ducks/actions'
import { getUserId, getUser, getNewEstimateValue } from 'ducks/selectors'
import moment from 'moment'
import * as Sentry from '@sentry/react'

import {
  userTypes,
  FetchCurrentUserAction,
  CreateUserAction,
  UserType,
  UpdateUserAction,
  UpdateUserListAction,
  FetchUserHeadAction
} from './types'

const parseUserAttrs = (user: Partial<UserType>): Partial<UserType> => {
  return {
    id: user.id || '',
    email: user.email || [],
    firstName: user.firstName || '',
    middleName: user.middleName || '',
    lastName: user.lastName || '',
    phone: user.phone || null,
    tosAccepted: user.tosAccepted || false,
    address: user.address || { city: '', state: '', line_1: '', line_2: '', zipCode: '', latitude: 0, longitude: 0, country: '', county: '' },
    picture: user.picture || '',
    registrationComplete: user.registrationComplete || false,
    type: user.type || '',
    clientType: user.clientType || USER_TYPE.HOMEOWNER,
    paymentMethods: user.paymentMethods || [],
    customerId: user.customerId || '',
    protectPlanIsNew: user.protectPlanIsNew || null,
    nar: user.nar || null,
    narId: user.narId || '',
    affiliate: user.affiliate || null,
    territory: user.territory,
    onProtectPlanNotificationList: user.onProtectPlanNotificationList,
    oneClickApproval: user.oneClickApproval,
    userProperties: user.userProperties
  }
}

export function* fetchCurrentUser({ callback }: FetchCurrentUserAction): SagaIterator {
  let success = false
  try {
    const data = yield call(Punchlist.users.getCurrentUser)
    if (!isEmpty(data) && !isEmpty(data?.id)) {
      Auth.set(data.id, 'punchlist-user-id', true);

      (window as any).pendo.initialize({
        visitor: {
          id: data.email
        },
        account: {
          id: data.email
        }
      });

      (window as any).pendo.updateOptions({
        visitor: {
          accountCreationDate: data.insertedOn ? moment.unix(data.insertedOn).utcOffset("+5:00").format('YYYY-MM-DDTHH:mm:ssZ') : '',
          completedJobCount: data.completedJobCount || 0,
          activeJobCount: data.activeJobCount || 0
        },
        account: {
          userType: data.clientType || '',
          affiliate: data.affiliate || '',
          nar: data.nar || false
        }
      })
      yield put(configActions.setConfigValue({ type: 'userType', value: data.clientType }))
      yield put(configActions.setConfigValue({ type: 'isAdmin', value: data.isAdmin }))
      yield put(userActions.setUserValues({ attrs: parseUserAttrs(data) }))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(callback, false)
  }
}

export function* createUser({ payload, callback }: CreateUserAction): SagaIterator {
  let success = false
  try {
    const authUserInfo = Auth.getUserInfo()
    const userInfo = payload || authUserInfo
    const mainContact = yield select(getNewEstimateValue('mainContact'))
    const isNar = yield select(getNewEstimateValue('isNar'))
    const affiliate = yield select(getNewEstimateValue('affiliate'))
    const { userProperties } = yield select(getUser) || {}
    if (userInfo && userInfo?.email) {
      const request = {
        email: [{
          emailType: EMAIL_TYPE.PRIMARY,
          email: userInfo?.email
        }],
        type: 'Client',
        tosAccepted: true
      } as CreateUserRequest

      if (!isEmpty(userInfo?.given_name)) {
        request.firstName = userInfo?.given_name
      }
      if (!isEmpty(userInfo?.family_name)) {
        request.lastName = userInfo?.family_name
      }

      // Add mainContact info for the agents flow
      if (!isEmpty(mainContact)) {
        if (!isEmpty(mainContact?.firstName)) request.firstName = mainContact?.firstName
        if (!isEmpty(mainContact?.lastName)) request.lastName = mainContact?.lastName
        if (!isEmpty(mainContact?.tosAccepted)) request.tosAccepted = mainContact?.tosAccepted
        if (!isEmpty(mainContact?.phone)) request.phone = parseInt(mainContact?.phone?.replaceAll('-', ''))
        if (!isEmpty(mainContact?.companyName)) request.companyName = mainContact?.companyName?.toLowerCase()
        if (mainContact?.zipCode) request.address = {
          zipCode: mainContact?.zipCode
        }
        if (mainContact?.role === "LISTING_AGENT") {
          request.registrationComplete = true
        }
        if (mainContact?.role) request.clientType = mainContact?.role === CONTACT_ROLE.LISTING_AGENT ? USER_TYPE.BROKER : USER_TYPE.HOMEOWNER
      }

      if (isNar) {
        request.clientType = USER_TYPE.BROKER
        request.nar = isNar
      }
      if (affiliate) {
        request.clientType = USER_TYPE.BROKER
        request.affiliate = affiliate
      }

      if (!isEmpty(userProperties)) {
        request.userProperties = userProperties
      }
      const data = yield call(Punchlist.users.createUser, request)
      if (!isEmpty(data) && !isEmpty(data.id)) {
        Auth.set(data.id, 'punchlist-user-id', true)
        yield put(configActions.setConfigValue({ type: 'userType', value: data.clientType }))
        yield put(userActions.setUserValues({ attrs: parseUserAttrs(data) }))
        success = true
      }
    }
    yield call(callback, success)
  } catch (error) {
    Sentry.captureException(JSON.stringify(error, Object.getOwnPropertyNames(error)))
    Sentry.captureException(error);
    yield call(callback, false)
  }
}

export function* updateUser({ payload, callback }: UpdateUserAction): SagaIterator {
  let success = false
  try {
    const userId = yield select(getUserId)
    const request: UpdateRequest = []

    for (const attr in payload) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: payload[attr as keyof UserType]
      })
    }

    const data = yield call(Punchlist.users.updateUser, userId, request)

    if (!isEmpty(data)) {
      yield put(configActions.setConfigValue({ type: 'userType', value: data.clientType }))
      yield put(userActions.setUserValues({ attrs: parseUserAttrs(data) }))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update user info'))
    yield call(callback, false)
  }
}

export function* updateUserList({ payload, callback }: UpdateUserListAction): SagaIterator {
  let success = false
  let removeData = null
  let addData = null
  let showError = typeof payload.showError !== "undefined" ? payload.showError : true

  try {
    const userId = yield select(getUserId)

    if (payload.opp === 'removeAll' || payload.opp === 'replace') {
      const requestRemove: UpdateRequest = []
      requestRemove.push({ op: 'remove', path: '/' + payload.attr })
      removeData = yield call(Punchlist.users.updateUser, userId, requestRemove)
    }

    if (payload.opp === 'add' || (payload.opp === 'replace' && removeData)) {
      const requestAdd: UpdateRequest = []
      for (const index in payload.list) {
        requestAdd.push({
          op: 'add',
          path: '/' + payload.attr + '/-',
          value: payload.list[parseInt(index)]
        })
      }
      addData = yield call(Punchlist.users.updateUser, userId, requestAdd)
    }

    if (payload.opp === 'removeAll' && removeData) {
      yield put(userActions.setUserValue({
        attr: payload.attr, value: []
      }))
      success = true
    }

    if (payload.opp === 'replace' && addData) {
      yield put(userActions.setUserValue({
        attr: payload.attr, value: payload.list
      }))
      success = true
    }

    if (payload.opp === 'add' && addData) {
      const user = yield select(getUser)
      yield put(userActions.setUserValue({
        attr: payload.attr, value: [...user[payload.attr], ...payload.list ?? []]
      }))
      success = true
    }

    yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    if (showError) {
      yield call(toast.error, errorTextTryingTo('update user info'))
    }
    yield call(callback, false)
  }
}

export function* fetchUserHead({ payload, callback }: FetchUserHeadAction): SagaIterator {
  try {
    yield call(Punchlist.users.getUserHead, payload)
    if (callback) yield call(callback, true)
  } catch (error) {
    Sentry.captureException(error);
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(userTypes.FETCH_CURRENT_USER, fetchCurrentUser)
  yield takeLatest(userTypes.CREATE_USER, createUser)
  yield takeLatest(userTypes.UPDATE_USER, updateUser)
  yield takeLatest(userTypes.UPDATE_USER_LIST, updateUserList)
  yield takeLatest(userTypes.FETCH_USER_HEAD, fetchUserHead)
}
