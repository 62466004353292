import { createContext } from 'react'
import { IApproveContext } from './context'

export const initialState: IApproveContext = {
  form_1: {
    clientNotes: '',
    closingDate: undefined,
    flexible: '',
    flexibleClosing: '',
    remember: '',
    role: {
      key: '',
      value: ''
    },
    startDate: undefined,
    setClientNotes: () => {},
    setClosingDate: () => {},
    setFlexible: () => {},
    setFlexibleClosing: () => {},
    setRemember: () => {},
    setRole: () => {},
    setStartDate: () => {}
  },
  form_2: {
    company: '',
    mail: '',
    mailCompany: '',
    name: '',
    phone: '',
    phoneCompany: '',
    responsible: {
      key: '',
      value: ''
    },
    signature: '',
    status: '',
    terms: false,
    unknownInformation: false,
    unknownInformation2: false,
    schedulerFirstName: '',
    schedulerLastName: '',
    schedulerEmail: '',
    schedulerPhone: '',
    scheduler: '',
    setCompany: () => {},
    setMail: () => {},
    setMailCompany: () => {},
    setName: () => {},
    setPhone: () => {},
    setPhoneCompany: () => {},
    setResponsible: () => {},
    setSignature: () => {},
    setStatus: () => {},
    setTerms: () => { },
    setUnknownInformation: () => {},
    setUnknownInformation2: () => {},
    setSchedulerFirstName: () => {},
    setSchedulerLastName: () => {},
    setSchedulerEmail: () => {},
    setSchedulerPhone: () => {},
    setScheduler: () => {},
  },
  consent: false,
  setConsent: () => {},
}

export const ApproveContext = createContext(initialState)
