import { createStyles, makeStyles, Theme } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px',
      margin: '0px 0px 20px 0px',
      borderRadius: '12px',
      backgroundColor: 'var(--expired-color-back)',
    },
    title: {
      color: theme.palette.secondary.main,
      fontSize: '24px',
      lineHeight: '58px',
    },
    titlePayments: {
      fontFamily: 'Lato',
      fontSize: '24px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    subtitle: {
      fontFamily: 'LatoLight',
      fontSize: '14px',
      color: theme.palette.grey[700],
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    description: {
      color: theme.palette.grey[700],
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: 'LatoLight',
      fontWeight: 400,
    },
    deleteAccountButton: {
      backgroundColor: theme.palette.secondary.light,
      width: '220px',
      height: '44px',
      borderRadius: '32px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      margin: '24px 0px',
    },
    addPaymentMethodButton: {
      width: '220px !important',
      height: '44px !important',
      borderRadius: '32px',
      borderColor: 'var(--bosscat-blue-600)',
      marginTop: '36px',
      color: 'var(--bosscat-blue-600)',
      '&:hover': {
        borderColor: 'var(--button-focus-color)',
        color: 'var(--button-focus-color)',
        backgroundColor: theme.palette.background.default,
      },
      '&:active': {
        borderColor: 'var(--button-focus-color)',
        color: 'var(--button-focus-color)',
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      },
    },
  })
)
