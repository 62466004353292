import { FC } from 'react'
import { CircularProgress, Box } from '../../../UI'
import useStyles from './styles'
import { useIsSmScreen, useIsXsScreen, useIsMdScreen } from '../../../../hooks'
import { LoaderProps } from '../types'

const OverlayLoader: FC<LoaderProps> = ({ children, loading = true }) => {
  const classes = useStyles()

  const [smScreen] = useIsSmScreen()
  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const smallScreen = xsScreen || smScreen || mdScreen

  return (
    <>
      {loading && (
        <Box className={classes.root}>
          <CircularProgress disableShrink size={smallScreen ? 56 : 96} thickness={4} />
        </Box>
      )}
      {children}
    </>
  )
}

export default OverlayLoader
