/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { icons, takeAction } from '../../../../assets'
import { configActions, dashboardActions, discoveryActions, newEstimateActions, subscriptionsActions, userActions } from '../../../../ducks/actions'
import { DashboardIndicators } from '../../../../ducks/dashboard/types'
import {
  getConfigDashboardDateRange, getConfigValue, getDashboardChartData, getDashboardChartInfo, getDashboardData, getDashboardIndicators, getNewEstimateValue, getUser
} from '../../../../ducks/selectors'
import { formatDateToUnixTimestamp, history } from '../../../../helpers'
import { useIsXsScreen } from '../../../../hooks'
import { DesktopPage, PrivateLoader } from '../../../templates'
import {
  Box, Button, Grid, Typography,
  ValuesChart, ValuesComponent, WelcomeModal, ZeroResultView
} from '../../../UI'
import { Range } from '../../../UI/CustomUI/atoms/DatePicker/types'
import DashboardItem from './DashboardItem'
import useStyles from './styles'
import FeedbackModal from './FeedbackModal'
import { REFERRAL_TYPE } from 'ducks/types'

const initialIndicators = {
  estimates: 0,
  activeOrders: 0,
  completedJobs: 0,
  completed: 0,
  estimatesCount: 0,
  jobsCount: 0
}

const initialChart = {
  labels: [],
  datasets: [
    {
      label: '',
      backgroundColor: ['var(--bosscat-blue-600)', '#FFC633'],
      labelColor: ['#FFF', '#1A2A55'],
      hoverOffset: 1,
      data: [0, 0]
    }
  ]
}

const initialChartInfo = [
  { type: 'Estimates', count: 0 },
  { type: 'Jobs', count: 0 }
]

const Dashboard: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = history.usePush()
  const [xsScreen] = useIsXsScreen()

  const initialRange = useSelector(getConfigDashboardDateRange())
  const dashboardData = useSelector(getDashboardData())
  const dashboardIndicators = useSelector(getDashboardIndicators(), shallowEqual)
  const dashboardChartData = useSelector(getDashboardChartData, shallowEqual)
  const dashboardChartInfo = useSelector(getDashboardChartInfo, shallowEqual)
  const user = useSelector(getUser)

  const [pageLoading, setPageLoading] = useState(!initialRange)
  const [listLoading, setListLoading] = useState(false)
  const [dateRange/* , setDateRange */] = useState<Range>(initialRange)
  const [indicators, setIndicators] = useState<DashboardIndicators>(initialIndicators)


  const [chartData, setChartData] = useState<any>(initialChart)
  const [chartInfo, setChartInfo] = useState<any>(initialChartInfo)

  //const showWelcomeEstimate = useSelector(getNewEstimateValue('showWelcome'))
  //const [openModal, setOpenModal] = useState(showWelcomeEstimate)

  const showReferralModal = useSelector(getConfigValue('showReferralModal'))

  const fetchDashboardData = useCallback(() => {
    setListLoading(true)

    dispatch(userActions.fetchCurrentUser(() => { }))

    dispatch(
      dashboardActions.fetchDashboardData(
        dateRange.startDate && dateRange.endDate
          ? {
            startDate: formatDateToUnixTimestamp(dateRange.startDate),
            endDate: formatDateToUnixTimestamp(dateRange.endDate)
          }
          : {},
        (_succ: boolean) => {
          setPageLoading(false)
          setListLoading(false)
        }
      )
    )
  }, [dateRange, dispatch])

  const handleSendFeedback = (option: REFERRAL_TYPE | string, details: string) => {
    // setShowFeedbackModal(false)
    dispatch(configActions.setConfigValue({ type: 'showReferralModal', value: false }))
    dispatch(discoveryActions.sendFeedback({ resourceId: user.id, resourceType: 'CLIENT', source: option, details }))
  }

  useEffect(() => {
    fetchDashboardData()
    dispatch(configActions.setConfigValue({ type: 'dashboardDateRange', value: dateRange }))
  }, [dateRange, dispatch, fetchDashboardData])

  useEffect(() => {
    setIndicators(listLoading ? initialIndicators : dashboardIndicators)
    setChartData(listLoading ? initialChart : dashboardChartData)
    setChartInfo(listLoading ? initialChartInfo : dashboardChartInfo)
  }, [listLoading, dashboardData])

  /* const handleSelect = (rangeSelected: Range) => {
    setDateRange(rangeSelected)
  } */

  const handleSubmit = (): void => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'showWelcome',
        value: false
      })
    )
  }

  return (
    <DesktopPage title='Dashboard'>
      <PrivateLoader loading={pageLoading}>
        <Grid container className={classes.root} style={{ marginLeft: xsScreen ? 0 : 'inherit' }}>
          {/* <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant='body1'> Search:</Typography>
              <SearchInput
                value={searchInput}
                onChange={handleSearchInputChange} />
            </Grid>
          </Grid> */}
          <Grid container item xs={12} className={classes.info} spacing={3}>
            {xsScreen &&
              <Grid item xs={12} sm={6} xl={3}>
                <ValuesChart
                  title='Total Requests'
                  titleColor='primary'
                  information='Total Requests stat'
                  icon={<icons.DonutLarge className={classes.icon} />}
                  data={chartData}
                  info={chartInfo}
                />
              </Grid>
            }
            <Grid item xs={12} sm={6} xl={3}>
              <ValuesComponent
                title='Estimates'
                titleColor='info'
                information='Total number of estimates requested'
                value={indicators.estimates}
                icon={<icons.Assignment className={classes.icon} />}
                height={!xsScreen ? '120px' : '100px'}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <ValuesComponent
                title='Active Jobs'
                titleColor='warning'
                information='Total number of approved jobs that have not yet been completed'
                value={indicators.activeOrders}
                icon={<icons.AssignmentLate className={classes.icon} />}
                height={!xsScreen ? '120px' : '100px'}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <ValuesComponent
                title='Completed Jobs'
                titleColor='success'
                information='Total number of jobs completed'
                value={indicators.completedJobs}
                icon={<icons.Build className={classes.icon} />}
                height={!xsScreen ? ' 120px' : '100px'}
              />
            </Grid>
            {!xsScreen && <Grid item xs={12} sm={6} xl={3}>
              <ValuesChart
                title='Total Requests'
                titleColor='primary'
                information='Total number of estimates and jobs'
                icon={<icons.DonutLarge className={classes.icon} />}
                data={chartData}
                info={chartInfo}
              />
            </Grid>}
          </Grid>
          <PrivateLoader loading={listLoading} building='fragment'>
            <Grid container item xs={12} className={classes.content}>
              {dashboardData?.length
                ? (
                  <>
                    <Grid className={classes.table} item xs={12}>
                      <Typography className={classes.active}>
                        Recent Activity
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.table}>
                      {dashboardData.length > 0
                        ? dashboardData.map((item, index) => (
                          <DashboardItem key={index} item={item} />
                        ))
                        : null}
                    </Grid>
                  </>
                )
                : (
                  <ZeroResultView
                    title="Let's Start!"
                    titleClassName={classes.noResultTitle}
                    description={
                      <>
                        Welcome to BOSSCAT!
                        <br />
                        Let's start by requesting your first Inspection Report Estimate or Project
                        List Estimate.
                      </>
                    }
                    descriptionClassName={classes.noResultDescription}
                    customButtonRender={
                      <Box className={classes.noResultButtonsContainer}>
                        <Button
                          variant='contained'
                          type='submit'
                          className={classes.noResultViewButton}
                          onClick={() => push('estimate')}
                        >
                          Get an Estimate
                        </Button>
                        {/* <Button
                          variant='outlined'
                          type='submit'
                          className={classes.noResultViewButtonAlt}
                          onClick={() => push('p/repairList')}
                        >
                          Create a Repair List
                        </Button> */}
                      </Box>
                    }
                    customIlustrationRender={
                      <img
                        src={takeAction}
                        alt='take action'
                        className={classes.ilustration}
                      />
                    }
                  />
                )}
            </Grid>
          </PrivateLoader>
        </Grid>
        <FeedbackModal
          open={showReferralModal}
          setOpen={() => {
            dispatch(configActions.setConfigValue({ type: 'showReferralModal', value: false }))
          }}
          onSubmit={handleSendFeedback}
        />
    {/*     <WelcomeModal
          open={openModal}
          setOpen={setOpenModal}
          onSubmit={handleSubmit}
        /> */}
      </PrivateLoader>
    </DesktopPage>
  )
}

export default Dashboard
