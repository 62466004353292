import {
  authTypes,
  AuthActions,
  LoginPayload,
  LogoutPayload,
  SignupPayload,
  ChangePasswordPayload,
  ParseHashPayload
} from './types'

const actions = {
  loginRequest: (payload: LoginPayload): AuthActions => ({
    type: authTypes.LOGIN_REQUEST,
    payload
  }),
  parseHashRequest: (payload: ParseHashPayload): AuthActions => ({
    type: authTypes.PARSE_HASH_REQUEST,
    payload
  }),
  logoutRequest: (payload?: LogoutPayload): AuthActions => ({
    type: authTypes.LOGOUT_REQUEST,
    payload: payload || null
  }),
  signupRequest: (payload: SignupPayload): AuthActions => ({
    type: authTypes.SIGNUP_REQUEST,
    payload
  }),
  changePasswordRequest: (payload: ChangePasswordPayload): AuthActions => ({
    type: authTypes.CHANGE_PASSWORD_REQUEST,
    payload
  })
}

export default actions
