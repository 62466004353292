/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { estimatesTypes, FetchEstimatesAction, FetchEstimatesStatsAction, FetchEstimatesServicesAction } from './types'
import { configActions, estimatesActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'

export function* fetchEstimates({ payload, callback }: FetchEstimatesAction): SagaIterator {
  let success = false
  try {
    const startDate = payload.startDate ?? undefined
    const endDate = payload.endDate ?? undefined
    const search = payload.search ?? undefined
    const status = payload.status ?? undefined
    const start = payload.start ?? 0
    const limit = payload.limit ?? 9999
    const sortBy = payload.sortBy ?? undefined
    const sortOrder = (payload.sortOrder?.toUpperCase()) as ('ASC' | 'DESC') ?? undefined
    const shared = payload.shared ?? false

    const estimates = yield call(Punchlist.estimates.getEstimates, startDate, endDate, status, start, limit, sortBy, sortOrder, search, shared)

    if (estimates && estimates.items) {
      const { items, ...noItems } = estimates
      yield put(estimatesActions.setEstimates(items))
      yield put(configActions.setConfigValue({ type: 'estimatePagination', value: noItems }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the estimates'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchEstimatesStats({ payload, callback }: FetchEstimatesStatsAction): SagaIterator {
  let success = false
  try {
    const startDate = payload.startDate ?? undefined
    const endDate = payload.endDate ?? undefined
    const status = payload.status ?? undefined
    const search = payload.search ?? undefined

    const estimateStats = yield call(Punchlist.estimates.getEstimatesStats, startDate, endDate, status, search)
    if (estimateStats) {
      yield put(configActions.setConfigValue({ type: 'estimateStats', value: estimateStats }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the estimate stats'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchEstimatesServices({ payload, callback }: FetchEstimatesServicesAction): SagaIterator {
  let success = false
  yield put(configActions.setConfigValue({ type: 'estimatesServices', value: [] }))

  try {
    const estimatesServices = yield call(Punchlist.estimates.getEstimatesServices, payload)

    if (estimatesServices) {
      yield put(configActions.setConfigValue({ type: 'estimatesServices', value: estimatesServices }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the estimate services'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(estimatesTypes.FETCH_ESTIMATES, fetchEstimates)
  yield takeLatest(estimatesTypes.FETCH_ESTIMATES_STATS, fetchEstimatesStats)
  yield takeLatest(estimatesTypes.FETCH_ESTIMATES_SERVICES, fetchEstimatesServices)
}
