import { FC, useState, useEffect } from 'react'
import { Box, Typography } from '../../../../UI'
import { icons, upload2 } from '../../../../../assets'
import useStyles from './styles'
import { useSelector } from 'react-redux'
import {
  getConfigValue,/*, getUser */
  getUser,
  getUserAddress,
  getUserFullName,
  getUserPhone,
  getUserPrimaryEmail
} from '../../../../../ducks/selectors'
import env from '@beam-australia/react-env'
import { ParseFilesUrl, parsePhoneFromNumToStr, isEmpty } from '../../../../../helpers'
import {
  Avatar, CircularProgress
} from '../../../../UI/MaterialUI'

const FILES_URL = env('FILES_URL') ?? ''

const SettingsHeader: FC = () => {
  const classes = useStyles()

  const fullName = useSelector(getUserFullName)
  const email = useSelector(getUserPrimaryEmail)
  const phoneNumber = parsePhoneFromNumToStr(
    Number.parseInt(useSelector(getUserPhone))
  )
  const address = useSelector(getUserAddress)
  const userType = useSelector(getConfigValue('userType'))
  const userData = useSelector(getUser)
  const { picture } = userData || {}
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [attempts, setAttempts] = useState(5)

  const handleError = () => {
    if (attempts > 0 && picture) {
      setAttempts(attempts - 1)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } else {
      setLoading(false)
      setError(true)
    }
  }

  const getPicture = (): JSX.Element => {
    if (loading) {
      return (
        <Box>
          <CircularProgress color='inherit' className={classes.loading} />
        </Box>
      )
    }

    if (!error) {
      return (
        <img
          onError={handleError}
          alt=''
          src={
            picture !== ''
              ? ParseFilesUrl({ baseUrl: FILES_URL, avatar: picture })
              : ''
          }
          className={classes.avatar}
        />
      )
    }
    return (
      <Avatar alt='' className={classes.icon}>
        <icons.Person
          fontSize='large'
          className={classes.personIcon}
        />
      </Avatar>
    )
  }

  useEffect(() => {
    setAttempts(3)
    !isEmpty(picture) && setError(false)
  }, [picture])

  useEffect(() => {
    setError(false)
    setLoading(false)
  }, [])

  return (
    <Box className={classes.root}>
      <Box className={classes.verticalContainer}>
        {getPicture()}
      </Box>
      <Box className={classes.verticalContainer}>
        <Box className={classes.horizontalContainer}>
          <Typography className={classes.name}>
            {fullName}
          </Typography>
          <div className={classes.separator} />
          <Typography className={classes.data}>{userType?.replace('_', ' ')}</Typography>
        </Box>
        <Box className={classes.horizontalContainer}>
          <icons.Mail className={classes.icons} />
          <Typography className={classes.data}>{email}</Typography>
        </Box>
        <Box className={classes.horizontalContainer}>
          <icons.Phone className={classes.icons} />
          <Typography className={classes.data}>{phoneNumber}</Typography>
        </Box>
        <Box className={classes.horizontalContainer}>
          <icons.Home className={classes.icons} />
          <Typography className={classes.data}>
            {address}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default SettingsHeader
