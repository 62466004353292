import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import { Avatar, BottomLink, Button, CircularProgress, Grid, TextFieldLabel, Typography } from '../../../../../UI'
import { isEmpty } from 'lodash'
import { history, validateEmail } from '../../../../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { configActions, userActions } from '../../../../../../ducks/actions'
import { arrow } from '../../../../../../assets'
import { useStepperContext } from '../../../../../../hooks/useStepperContext'
import { getConfigValue, getUser } from '../../../../../../ducks/selectors'


const UserInformation = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const query = history.useQuery()
  const queryParams = query.toString()

  const user = useSelector(getUser)
  const registerActiveStep = useSelector(getConfigValue('registerActiveStep'))

  const { showWarnings, setCanGoForward, setShowWarnings, saveNewValue } =
    useStepperContext()

  const [email, setEmail] = useState(user?.email?.length ? user.email[0]?.email : '')
  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')

  const [emailWarning, setEmailWarning] = useState('')

  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)

  const validate =
    !isEmpty(email) &&
    validateEmail(email) &&
    !isEmpty(firstName) &&
    !isEmpty(lastName)

  const handleSubmit = (event: React.MouseEvent<EventTarget>): void => {
    setShowWarnings(true)
    event.preventDefault()
    setSubmit(true)
    setEmailWarning('')
    if (isEmpty(email) || !validateEmail(email)) {
      setEmailWarning('Enter your email to create an account')
    }
    if (validate) {
      setLoading(true)
      dispatch(userActions.fetchUserHead({
        email,
        userType: 'Client'
      }, (success) => {
        if (success) {
          setEmailWarning('This Email already has an account with BOSSCAT')
          setLoading(false)
        } else {
          dispatch(configActions.setConfigValue({ type: 'registerActiveStep', value: registerActiveStep + 1 }))
          setLoading(false)
        }
      }))
    }
  }

  useEffect(() => {
    setCanGoForward(validate)
  }, [validate])

  return (
    <>
      <form className={classes.form} autoComplete='on'>
        <Typography variant="h5" className={classes.title}> Welcome</Typography>
        <Typography className={classes.subtitle}>Let’s create your account.</Typography>
        <Grid container spacing={3}>
          <Grid xs={12} md={6} item>
            <TextFieldLabel
              label="First Name:"
              error={showWarnings && isEmpty(firstName.trim())}
              onChange={(ev) => {
                setFirstName(ev.target.value)
                saveNewValue('firstName', ev.target.value)
              }}
              value={firstName}
              placeholder="Enter your name"
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextFieldLabel
              label="Last Name:"
              error={showWarnings && isEmpty(lastName.trim())}
              onChange={(ev) => {
                setLastName(ev.target.value)
                saveNewValue('lastName', ev.target.value)
              }}
              value={lastName}
              placeholder="Enter your last name"
            />
          </Grid>
          <Grid xs={12} item>
            <TextFieldLabel
              label='Email:'
              type='email'
              value={email}
              error={submit && !isEmpty(emailWarning)}
              helperText={submit && emailWarning}
              onChange={(event) => {
                setEmail(event.target.value)
                saveNewValue('email', [{ emailType: '', email: event.target.value }])
              }}
              placeholder='Enter your email here'
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          size='large'
          className={classes.submit}
          onClick={handleSubmit}
          endIcon={!loading && <Avatar alt='' src={arrow} className={classes.icon} />}
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress /> : "Create Password"}
        </Button>
        <BottomLink
          question='Have we already met?'
          linkText='Sign in here'
          linkTo={`/login${queryParams ? `?${queryParams}` : ''}`}
        />
      </form>
    </>
  )
}

export default UserInformation