import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // padding: '20px 0px',
      borderRadius: '16px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 16px',
      },
    },
    spacedItems: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px',
      borderRadius: '8px',
      margin: '0px',
      '&:hover': {
        backgroundColor: 'var(--row-focus)'
      },
      '&:active': {
        backgroundColor: 'var(--row-active)'
      }
    },
    horizontalContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '633px',
      // justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    cardLogoContainer: {
      display: 'flex',
      width: '64px',
      height: '32px',
      padding: '8px 7px',
      marginRight: '16px',
      borderRadius: '8px',
      backgroundColor: theme.palette.grey[100],
    },
    cardLogo: {
      margin: 'auto auto',
    },
    cardInformationContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '500px',
      justifyContent: 'space-between'
    },
    'Mui-checked': {
      color: 'var(--bosscat-blue-600) !important',
    },
    cardType: {
      fontFamily: 'Lato !important',
      marginRight: '4px',
    },
    cardInformation: {
      fontSize: '16px',
      color: theme.palette.grey[700],
      fontFamily: 'LatoLight',
    },
    selfEnd: {
      justifySelf: 'flex-end',
    },
    w100: {
      maxWidth: '633px',
      width: '100%',
      '@global': {
        '.MuiButtonBase-root': {
          '&:hover': {
            backgroundColor: 'transparent'
          },
        }
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 20px',
      },
    },
    addPaymentMethodButton: {
      width: '220px !important',
      height: '44px !important',
      borderRadius: '32px',
      borderColor: 'var(--bosscat-blue-600)',
      marginTop: '36px',
      color: 'var(--bosscat-blue-600)',
      '&:hover': {
        borderColor: 'var(--button-focus-color)',
        color: 'var(--button-focus-color)',
        backgroundColor: theme.palette.background.default,
      },
      '&:active': {
        borderColor: 'var(--button-focus-color)',
        color: 'var(--button-focus-color)',
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      },
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'LatoNormal'
    },
    close: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      borderColor: 'transparent',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'LatoNormal'
    },
    errorMessage: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: '8px',
      padding: '8px 16px !important',
      borderRadius: '8px',
      background: 'var(--expired-color-back)'
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    logo: {
      margin: '-70px -50px',
      zIndex: 1500,
      position: 'fixed'
    },
    header: {
      background: 'var(--background-color-secondary)',
      textAlign: 'center'
    },
    headerText: {
      paddingBottom: '8px'
    },
    rootModal: {
      maxWidth: '345px',
      borderRadius: '16px',
    },
    cardHolder: {
      width: '100%',
      minHeight: '40px',
      maxHeight: '40px',
      margiTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      fontFamily: 'LatoNormal',
      '&:hover': { borderRadius: 8 },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    cardNumber: {
      width: '100%',
      minHeight: '40px',
      maxHeight: '40px',
      marginTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      display: 'grid',
      alignItems: 'center',
      '&:hover': { borderRadius: 8 },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    content: {
      paddingBottom: '8px'
    },
    paymentMethod: {
      border: '2px solid #EBECEF',
      margin: '.5rem 0',
      padding: '1rem',
      alignItems: 'flex-start'
    },
    newCard: {
      padding: '1rem'
    },
    buttonSubmit: {
      backgroundColor: '#01AA59',
      color: 'white',
      margin: '2rem',
      borderRadius: '8px',
      fontSize: '18px',
      textTransform: 'inherit',
      fontFamily: 'LatoBold',
      padding: '.7rem 1rem',
      minWidth: '180px',
      pointerEvents: "fill",
      marginRight: '0 !important',
      filter: "drop-shadow(0px 3px 5px rgba(26, 42, 85, 0.2)) drop-shadow(0px 0px 1px rgba(26, 42, 85, 0.31))",
      '&:hover': {
        backgroundColor: '#0E905A'
      },
      '@global': {
        '.MuiButton-label': {
          padding: 0
        }
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 !important'
      },
    },
    titleContainer: {
      [theme.breakpoints.down('md')]: {
        marginLeft: '12px',
        paddingBottom: '0 !important'
      },
    },
    paymentMethodContainer: {
      [theme.breakpoints.down('md')]: {
        paddingTop: '0 !important'
      },
    },
    buttonsContainer: {
      position: 'fixed',
      bottom: '0rem',
      pointerEvents: "none",
      maxWidth: '1250px',
      paddingRight: '2.25rem',
      // marginTop: '5rem',
      [theme.breakpoints.down('sm')]: {
        display: "flex",
        flexDirection: "row",
        position: "fixed",
        // flexWrap: "nowrap", 
        maxWidth: '100vw',
        left: 0,
        bottom: "0rem",
        backgroundColor: "#fff",
        paddingInline: "1rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      },
    },
    buttonContainerAuth: {
      maxWidth: '-webkit-fill-available',
      paddingRight: "0.75rem"
    },
    buttonBack: {
      backgroundColor: '#FCFCFC',
      color: 'var(--bosscat-blue-600)',
      margin: '2rem',
      borderRadius: '8px',
      fontSize: '18px',
      textTransform: 'inherit',
      fontFamily: 'LatoBold',
      padding: '.7rem 1rem',
      minWidth: '180px',
      pointerEvents: "fill",
      marginLeft: '0 !important',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      '@global': {
        '.MuiButton-label': {
          padding: 0
        }
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 !important',
        minWidth: '130px'
      },
    },
    newCardFormContainer: {
      flex: 1
    },
    paymentContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      // maxHeight: "69vh"
    },
    title: {
      margin: '1.3rem 0',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '12px',
      },
    },
    orderSummaryFlex: {
      marginBottom: 80,
      margin: '1rem 0',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    buttonSubmitIcon: {
      width: '1rem'
    },
    addNewCard: {
      "@global": {
        '.MuiFormControlLabel-label': {
          width: '100%'
        }
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '12px',
      },
    },
    errorMessageTitle: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: "16px"
    },
    errorMessageSubtitle: {
      padding: "4px 0px",
      fontFamily: "LatoNormal",
      fontSize: "16px"
    },
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "10px"
    },
    planSelector: {
      margin: '1rem 0',
      [theme.breakpoints.down('sm')]: {
        margin: '12px',
      }
    },
    radioContainer: {
      flexDirection: 'row',
      gap: '1rem',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '0'
      }
    },
    plan: {
      width: '50%',
      border: '2px solid #EBECEF',
      margin: '.5rem 0',
      padding: '.7rem .4rem',
      alignItems: 'flex-start',
      flexDirection: 'row-reverse',
      '@global': {
        '.MuiButtonBase-root': {
          padding: 0,
          alignItems: 'flex-start',
          height: 'fit-content'
        }
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    selectedPlan: {
      borderColor: 'var(--bosscat-blue-600)'
    },
    radio: {

    },
    nextTypo: {
      fontFamily: 'NextArtBold'
    },
    radioTitle: {
      fontFamily: 'LatoNormal',
      fontSize: 18
    },
    radioSubtitle: {
      fontFamily: 'LatoNormal',
    },
    disclaimer: {
      display: 'flex',
      padding: '.7rem',
      borderRadius: 8,
      background: '#F6F5EF',
      marginTop: '.5rem',
      "@global": {
        ".MuiTypography-root": {
          fontSize: 14,
          fontFamily: 'LatoNormal',
        }
      }
    },
    disclaimerIcon: {
      width: '.9rem'
    },
    disclaimerText: {
      color: "#423C24"
    },
    banner: {
      position: 'relative',
      display: 'flex',
      maxHeight: "56px",
      gap: '0.5rem',
      borderRadius: '0.5rem',
      padding: '1rem',
      background:
        'linear-gradient(149deg, #423C24 0%, #635A36 53.65%, #847848 100%)',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      overflow: 'hidden',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        maxHeight: "fit-content",
      }
    },
    bannerText: {
      fontWeight: 'bold',
      fontSize: 18,
      color: 'white',
    },
    bannerTextFAQ: {
      fontWeight: 'bold',
      fontSize: 18,
      color: '#FFC531',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      transform: 'rotate(45deg)',
      fontSize: '1.5rem',
    },
    iconBanner: {
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
  })
)
