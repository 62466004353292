import moment from 'moment'
import { addDays } from '../../../helpers'
import axios from '../axios'
import { ApiController } from './types'
import { isEmpty } from 'lodash';

const base = '/jobs'

const apiController: ApiController = {
  getJobs: async (startDate, endDate, status, start, limit, sortBy, sortOrder, search) => {
    const endDatePlus1Day = endDate ? moment(addDays(endDate, 1, 1000)).unix() : null
    const params: any = { params: { startDate, endDate: endDatePlus1Day, status, start, limit, sortBy, sortOrder } }
    if (!isEmpty(search)) params.search = search

    return await axios
      .get(base, { params })
      .then(response => response.data)
  },

  getJobsStats: async (startDate, endDate, status) => {
    const endDatePlus1Day = endDate ? moment(addDays(endDate, 1, 1000)).unix() : null
    return await axios
      .get(`${base}/counts`, { params: { startDate, endDate: endDatePlus1Day, status } })
      .then(response => response.data)
  },

  getJobById: async (jobId: string) =>
    await axios
      .get(`${base}/${jobId}`)
      .then(response => response.data),

  getJobItems: async (jobId: string) =>
    await axios
      .get(`${base}/${jobId}/items`)
      .then(response => response.data),

  updateJobItem: async (
    jobId: string, itemId: string, request
  ) =>
    await axios
      .patch(`${base}/${jobId}/items/${itemId}`, request)
      .then(response => response.data),

  createJobs: async (request) =>
    await axios
      .post(`${base}`, request)
      .then(response => response.data)
}

export default apiController
