import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        ServiceContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "16px",
            width: "100%",
            borderRadius: "8px",
            border: "1px solid #EBECEF",
            boxShadow: "0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)",
            marginTop: "24px",
            [theme.breakpoints.down('md')]: {
                maxWidth: "95vw",
                rowGap: "8px"
            }
        },
        Header: {
            padding: "16px 16px 0px 16px",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column-reverse",
                alignItems: "flex-start",
                rowGap: "8px"
            }
        },
        Body: {
            padding: "0px 16px 0px 16px",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                alignItems: "flex-start",
                rowGap: "8px"
            }
        },
        ServiceWorker: {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down('md')]: {
                fontFamily: "Lato"
            }
        },
        ServiceLabel: {
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            fontFamily: "Lato",
            columnGap: "8px",
            [theme.breakpoints.down('md')]: {
                fontFamily: "Lato"
            }
        },
        ServiceValue: {
            fontFamily: "LatoNormal",
            color: "#494A5A"
        },
        Icon: {
            color: "var(--bosscat-blue-600)",
            width: "18px",
            height: "18px",
            marginRight: "10px"
        },
        Support: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            backgroundColor: "#FAFBFF",
            padding: "16px",
            color: "var(--bosscat-blue-600)",
            cursor: "pointer",
            transition: "all 350ms ease-in-out",
            '&:hover': {
                boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            },
            [theme.breakpoints.down('md')]: {
                width: "100%",
            }
        },
        Tag: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "8px",
            padding: "3px 8px",
            [theme.breakpoints.down('md')]: {
                width: "100%",
            }
        },
        TagText: {
            whiteSpace: "nowrap",
            fontFamily: "Lato",
            fontSize: 14
        },
        CompletedIcon: {
            width: "24px",
            height: "24px",
        },
        Buttons: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: "16px",
            marginTop: "40px",
        },
        ButtonPrim: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--bosscat-blue-600)",
            borderRadius: "8px",
            width: "100%",
            padding: "16px 24px",
            color: "#fff",
            cursor: "pointer",
            whiteSpace: "nowrap",
            filter: "drop-shadow(0px 3px 5px rgba(26, 42, 85, 0.2)) drop-shadow(0px 0px 1px rgba(26, 42, 85, 0.31))",
            [theme.breakpoints.down('md')]: {
                width: "100%",
            }
        },
        ButtonSec: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FAFBFF",
            width: "100%",
            borderRadius: "8px",
            padding: "16px",
            color: "var(--bosscat-blue-600)",
            cursor: "pointer",
            whiteSpace: "nowrap",
            transition: "all 350ms ease-in-out",
            '&:hover': {
                boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            },
            [theme.breakpoints.down('md')]: {
                width: "100%",
                borderRadius: "8px",
                columnGap: "8px",
                fontSize: "18px"
            }
        },
        Arrow: {
            transform: "rotate(-90deg)",
            marginLeft: "8px"
        },
        ArrowBack: {
            transform: "rotate(90deg)",
            marginRight: "8px",
            width: "16px",
            height: "16px",
        },
        CalendarIcon: {
            width: "16px",
            height: "16px",
            marginLeft: "8px"
        },
        GiftByLabel: {
            display: "flex",
            alignItems: "center",
            columnGap: "4px",
            fontSize: "16px",
            fontFamily: 'Lato',
            [theme.breakpoints.down('md')]: {
                fontSize: "14px"
            }
        },
        GiftByValue: {
            display: "flex",
            alignItems: "center",
            color: '#494A5A'
        },
        icon: {
            width: '1rem'
        },
        GiftByName: {
            fontFamily: "Lato",
            fontSize: "16px",
            [theme.breakpoints.down('md')]: {
                fontSize: "14px"
            }
        },
        GiftByCompany: {
            fontFamily: "LatoNormal",
            fontSize: "16px",
            marginLeft: "0.5ch",
            [theme.breakpoints.down('md')]: {
                fontSize: "14px"
            }
        },
        GiftByImage: {
            width: "44px",
            height: "44px",
            borderRadius: "4px",
            overflow: "hidden",
            objectFit: "cover",
            marginRight: "8px"
        },
        GiftByInfo: {
            marginLeft: '.5rem'
        },
        GiftByContainer: {
            backgroundColor: "#F9FAFA",
            padding: "16px",
            borderRadius: "0px 0px 8px 8px"
        },
        Toast: {
            backgroundColor: "#fff",
            border: "2px solid #93F0C9"
        },
        ToastTexts: {
            display: "flex",
            flexDirection: "column",
        },
        ToastTitle: {
            fontSize: "18px",
            fontFamily: 'LatoNormal',
            fontWeight: 700,
            color: "#0E905A",
        },
        ToastSubtitle: {
            fontSize: "16px",
            fontFamily: "LatoNormal",
        },
        ToastIcon: {
            color: "#0E905A",
            marginRight: "14px"
        },
        NeedContractWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "16px",
            padding: "24px"
        },
        NeedContractImage: {
            width: "200px",
            height: "200px"
        },
        NeedContractTitle1: {
            fontSize: "30px",
            display: "flex",
            alignItems: "center",
            fontFamily: 'LatoNormal',
            fontWeight: 500,
            columnGap: "8px",
            [theme.breakpoints.down('md')]: {
                flexWrap: "wrap",
                justifyContent: "center"
            }
        },
        NeedContractTitle2: {
            fontSize: "32px",
            fontFamily: "LatoBold",
            [theme.breakpoints.down('md')]: {
                textAlign: "center",
            }
        },
        NeedContractSubtitle: {
            fontSize: "20px",
            textAlign: "center",
            "& b": {
                fontFamily: "Lato",
                fontWeight: "500"
            }
            // display: "flex",
            // alignItems: "center",
            // whiteSpace: "nowrap"
        },
        text: {
            fontFamily: "LatoNormal",

        }
    }))
