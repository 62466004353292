/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { PrivateLoader, DesktopPage } from '../../../templates'
import SettingsHeader from './SettingsHeader'
import PaymentMethods from './PaymentMethods'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../../../ducks/actions'
import { getUser } from 'ducks/selectors'
import { Box, Typography } from '@material-ui/core'
// import NotificationSettings from './NotificationSettings'
// import { Notification } from './NotificationSettings/types'
// import { Box, Typography , Button } from '../../../UI'
import useStyles from './styles'

// import DeleteAccountModal from './DeleteAccountModal'

const Settings: FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { paymentMethods } = useSelector(getUser)

  const selected = paymentMethods.find(payment => payment.isDefault)
  // ! DUMMY DATA FOR TESTING. NEEDS TO BE CHANGED BY A SERVICE
  /*const notifications: Notification[] = [
    { active: true, name: 'Send me desktop notifications' },
    { active: true, name: 'Notify me Estimates and Jobs updates' },
    { active: true, name: 'Notify me new messages' }
  ]*/

  // const classes = useStyles()

  const [loading, setLoading] = useState(true)
  // const [notis, setNotis] = useState<Notification[]>(notifications)
  // const [modalOpen, setModalOpen] = useState<boolean>(false)

  /* const handleDeleteAccountPrompt = () => {
    // TODO: LOGIC TO PROMPT THE MODAL (WHICH NEEDS TO BE CREATED)
    setModalOpen(true)
  } */

  useEffect(() => {
    dispatch(userActions.fetchCurrentUser(() => {
      setLoading(false)
    }))
  }, [])

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newSelected = Number(ev.target.value)
    const newList = paymentMethods.map((card, index) => { return { ...card, isDefault: index === newSelected } })

    dispatch(userActions.updateUserList({
      attr: 'paymentMethods', opp: 'replace', list: newList
    }, (success) => {
      if (success) dispatch(userActions.fetchCurrentUser((_succ) => { }))
    }))
  }

  return (
    <DesktopPage title='Settings'>
      <PrivateLoader loading={loading}>
        <>
          <SettingsHeader />
          <Box style={{ marginTop: '2rem' }}>
            <Typography className={classes.titlePayments}>Payment Methods</Typography>
            {paymentMethods?.length > 0 && (
              <Typography className={classes.subtitle}>
                Select default payment method
              </Typography>
            )}
            <PaymentMethods onChange={handleChange} addNewType="modal" selected={selected} />
          </Box>
          {/*
          <NotificationSettings notifications={notis} handleChange={setNotis} />
          <Box className={classes.root}>
            <Typography className={classes.title}>
              Delete Account Permanently
            </Typography>
            <Typography className={classes.description}>
              By deleting permanently your account you will no longer be able to
              access to this account information, transactions and related
              content. This action can’t be undone.
            </Typography>
            <Button
              variant='contained'
              color='secondary'
              className={classes.deleteAccountButton}
              onClick={handleDeleteAccountPrompt}
            >
              Delete Account
            </Button> 
          </Box>
          <DeleteAccountModal open={modalOpen} setOpen={setModalOpen} />
        */}
        </>
      </PrivateLoader>
    </DesktopPage>
  )
}

export default Settings
